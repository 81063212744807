<script setup>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import Toastify from 'toastify-js'

const store = useStore()
const router = useRouter()


const sevenAQuestion1 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion1
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion1', value)
  }
})

const sevenAQuestion2 = computed({
  get() {
  return store.state.loan7a.sevenAQuestion2
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion2', value)
  }
})

const sevenAQuestion3 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion3
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion3', value)
  }
})
const sevenAQuestion4 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion4
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion4', value)
  }
})
const sevenAQuestion5 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion5
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion5', value)
  }
})

const sevenAQuestion5a = computed({
  get() {
    return store.state.loan7a.sevenAQuestion5a
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion5a', value)
  }
})

const sevenAQuestion5b1 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion5b1
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion5b1', value)
  }
})


const sevenAQuestion5b2 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion5b2
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion5b2', value)
  }
})

const sevenAQuestion5b3 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion5b3
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion5b3', value)
  }
})


const sevenAQuestion6 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion6
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion6', value)
  }
})
const sevenAQuestion7 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion7
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion7', value)
  }
})
const sevenAQuestion8 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion8
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion8', value)
  }
})
const sevenAQuestion9 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion9
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion9', value)
  }
})
const sevenAQuestion10 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion10
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion10', value)
  }
})
const sevenAQuestion11 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion11
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion11', value)
  }
})
const sevenAQuestion12 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion12
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion12', value)
  }
})

const sevenAQuestion13 = computed({
  get() {
    return store.state.loan7a.sevenAQuestion13
  },
  set(value) {
    store.commit('loan7a/setSevenAQuestion13', value)
  }
})



onMounted(()=> {})

function submitChanges() {
  store.commit('loan7a/setLoan7aStateObj', store.state.loan7a)
  store.dispatch('loan7a/submitFirebase')

  Toastify({
    node: cash('#success-notification-content_toast')
      .clone()
      .removeClass('hidden')[0],
    duration: 8000,
    newWindow: true,
    close: true,
    gravity: 'top',
    position: 'right',
    stopOnFocus: true
  }).showToast()

  router.push('/')
}

function cancelChanges() {
  Toastify({
    node: cash('#success-notification-content_toast_cancel')
      .clone()
      .removeClass('hidden')[0],
    duration: 8000,
    newWindow: true,
    close: true,
    gravity: 'top',
    position: 'right',
    stopOnFocus: true
  }).showToast()

  router.push('/')
}


</script>
<template>
  <div class='text-left text-2xl mt-5 mb-3'>
    7a SBA Loan (1919)
  </div>



  <div class="bg-white shadow-md rounded-md bg-blue-500 p-4">
    <div class="intro-y grid ">

      <div class="grid-rows-9 col-span-2">
        <div class="mb-5">
          <label>
            {{ $t('message.sevenAQuestion1') }}
          </label>
          <div class="form-check mt-3 mb-3">
            <input id="radio-switch-01" class="form-check-input" type="radio"
                   value="true"
                   v-model='sevenAQuestion1' />
            <label class="form-check-label" for="radio-switch-1">Yes</label>
          </div>

          <div class="form-check">
            <input id="radio-switch-01" class="form-check-input" type="radio"
                   value="false"
                   v-model='sevenAQuestion1' />
            <label class="form-check-label" for="radio-switch-1">No</label>
          </div>
        </div>

        <div class="pb-5">
          <label for="input-wizard-02"
                 class="form-label">{{ $t('message.sevenAQuestion2') }}</label>
          <div class="form-check mt-3 mb-3">
            <input id="radio-switch-02" class="form-check-input" type="radio"
                   value="true"
                   v-model='sevenAQuestion2' />
            <label class="form-check-label" for="radio-switch-2">Yes</label>
          </div>

          <div class="form-check">
            <input id="radio-switch-02" class="form-check-input" type="radio"
                   value="false"
                   v-model='sevenAQuestion2' />
            <label class="form-check-label" for="radio-switch-2">No</label>
          </div>
        </div>


      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion3') }}</label>
        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-03" class="form-check-input" type="radio"
                 value="true"
                 v-model="sevenAQuestion3" />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-03" class="form-check-input" type="radio"
                 value="false"
                 v-model="sevenAQuestion3" />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>
      </div>


      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion4') }}</label>
        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-04" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion4' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-04" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion4' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>
      </div>

        <div class="pb-5">
          <label for="input-wizard-5" class="form-label">{{ $t('message.sevenAQuestion5') }}</label>
          <div class="form-check mt-3 mb-3">
            <input id="radio-switch-51" name="radio-name-switch-51" class="form-check-input" type="radio" value="true" v-model='sevenAQuestion5' />
            <label class="form-check-label" for="radio-switch-51">Yes</label>
          </div>

          <div class="form-check">
            <input id="radio-switch-52" name="radio-name-switch-51" class="form-check-input" type="radio" value="false" v-model='sevenAQuestion5' />
            <label class="form-check-label" for="radio-switch-52">No</label>
          </div>
        </div>


      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion5a') }}</label>
        <input id="input-wizard-1" type="text" class="form-control"
               placeholder=""
               v-model='sevenAQuestion5a' />
      </div>

      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion5b') }}</label>
        <div class="form-check mt-3 mb-3">
          <input id="input-wizard-1" type="text" class="form-control mb-3"
                 placeholder="Principal Countries 1"
                 v-model='sevenAQuestion5b1' />

          <input id="input-wizard-1" type="text" class="form-control mb-3"
                 placeholder="Principal Countries 2"
                 v-model='sevenAQuestion5b2' />

          <input id="input-wizard-1" type="text" class="form-control mb-3"
                 placeholder="Principal Countries 3"
                 v-model='sevenAQuestion5b3' />
        </div>

      </div>


      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion6') }}</label>
        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion6' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion6' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>
      </div>

      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion7') }}</label>

        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion7' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion7' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>
      </div>


      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion8') }}</label>


        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion8' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion8' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>
      </div>

      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion9') }}</label>


        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion9' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion9' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>


      </div>

      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion10') }}</label>


        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion10' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion10' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>


      </div>

      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion11') }}</label>

        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion11' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion11' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>


      </div>


      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion12') }}</label>


        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion12' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion12' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>


      </div>

      <div class="pb-5">
        <label for="input-wizard-1"
               class="form-label">{{ $t('message.sevenAQuestion13') }}</label>

        <div class="form-check mt-3 mb-3">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="true"
                 v-model='sevenAQuestion13' />
          <label class="form-check-label" for="radio-switch-7">Yes</label>
        </div>

        <div class="form-check">
          <input id="radio-switch-7" class="form-check-input" type="radio"
                 value="false"
                 v-model='sevenAQuestion13' />
          <label class="form-check-label" for="radio-switch-7">No</label>
        </div>
      </div>


      </div>
    </div>
  </div>

  <div class="text-right mt-5">
    <button type="button" class="btn btn-outline-secondary w-24 mr-1"
            @click="cancelChanges">
      Cancel
    </button>
    <button type="button" class="btn btn-primary w-24 mr-1"
            @click="submitChanges">
      Save
    </button>
  </div>


  <div id="success-notification-content_toast"
       class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">CONGRATULATION</div>
      <div class="text-gray-600 mt-1">
        You have successfully save your Account Now data for Archymedes.
      </div>
    </div>
  </div>
  <div id="success-notification-content_toast_cancel"
       class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Account Now</div>
      <div class="text-gray-600 mt-1">
        You have cancelled saving of the AccountNow application for Archymedes.
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>