<template>
  <div>
    <div class="text-center px-16 py-10">
  <div class="font-semibold text-theme-25 dark:text-gray-300 text-3xl">
    {{$t('message.reviewTitle')}}
        </div>
      </div>

      <div class="px-16 py-5">
        <div class="overflow-x-auto">
            <div class="text-base text-gray-600">{{$t('message.ownerInformationHeading')}}</div>
          <table class="table">
            <thead>

            </thead>
            <tbody>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationFirstName')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.firstName}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationMiddleName')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.ownerInfo.middleName}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationLastName')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.ownerInfo.lastName}}
                  </div>
                </td>
              </tr>



              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationAddress1')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.ownerInfo.ownerInfoAddress1}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationAddress2')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.ownerInfo.ownerInfoAddress2}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationCity')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.ownerInfo.ownerInfoCity}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationState')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.ownerInfo.ownerInfoState}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationZipCode')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.ownerInfo.ownerInfoZipcode}}
                  </div>
                </td>
              </tr>








              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationBirthDate')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.dob}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationSS')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.ssn}}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationCitizen')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.citizen}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationPhone')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.phone}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationEmail')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.ownerInfoEmail}}
                  </div>
                </td>
              </tr>


              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationGender')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.gender}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationQuestion3')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.indictment}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationQuestion5')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.convicted}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationEthnicity')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.ethnicity}}
                  </div>
                </td>
              </tr>



              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationQuestion4')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.arrested}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationVeteran')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.veteran}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationRace')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.race}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.ownerInformationCreditScore')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.creditScore}}
                  </div>
                </td>
              </tr>



              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium">
                    {{$t('message.ownerInformationQuestion1')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.childSupport}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium">
                    {{$t('message.ownerInformationQuestion2')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.ownerInfo.suspendFederalAgency}}
                  </div>
                </td>
              </tr>



            </tbody>
          </table>
        </div>
      </div>



      <div class="px-16 py-5">
        <div class="overflow-x-auto">
            <div class="text-base text-gray-600">
              {{$t('message.businessInformationHeading')}}
           </div>
          <table class="table">
            <thead>

            </thead>
            <tbody>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationCompanyName')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{this.$store.state.requiredForm.bizInfo.companyName}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationDBA')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.bizInfo.dbaName}}
                  </div>
                </td>

              </tr>



              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationAddress1')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.bizInfo.address1}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationAddress2')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.bizInfo.address2}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationCity')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.bizInfo.city}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationState')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.bizInfo.state}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationZipCode')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                   {{store.state.requiredForm.bizInfo.zipcode}}
                  </div>
                </td>
              </tr>








              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationTaxId')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.bizInfo.taxId}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationDUNS')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.bizInfo.duns}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationBusinessContactNumber')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.bizInfo.businessContactNumber}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationIndustryType')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.bizInfo.industryType}}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationEntityType')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.bizInfo.entityType}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationEmail')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.bizInfo.email}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationWebsite')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.bizInfo.website}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.businessInformationYearsInBusiness')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{store.state.requiredForm.bizInfo.yrsInBusiness}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="px-16 py-5">
        <div class="overflow-x-auto">
            <div class="text-base text-gray-600">{{$t('message.sourceOfIncomeHeading')}}</div>
          <table class="table">
            <thead>

            </thead>
            <tbody>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.sourceOfIncomeSalary')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{this.$store.state.requiredForm.sourceIncome.salary}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.sourceOfIncomeNetInvestmentIncome')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.sourceIncome.netInvestIncome}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.sourceOfIncomeRealEstateIncome')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.sourceIncome.realEstateIncome}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.sourceOfIncomeTotalNetIncome')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.sourceIncome.totalNetIncome}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.sourceOfIncomeLifeInsuranceLoanAgainstLifeInsurance')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.sourceIncome.lifeInsuranceLoan}}
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>


      <div class="px-16 py-5">
        <div class="overflow-x-auto">
            <div class="text-base text-gray-600">{{$t('message.continuousLiabilities')}}</div>
          <table class="table">
            <thead>

            </thead>
            <tbody>
              <tr>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.continuousLiabilitiesOtherIncomeAsEndorserOrCoMaker')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                    {{this.$store.state.requiredForm.contingentLiabilities.otherIncome}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.continuousLiabilitiesLegalClaimsAndJudgmentsProvisionForFederal')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.contingentLiabilities.legalClaimsFederal}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.continuousLiabilitiesIncomeTax')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.contingentLiabilities.incomeTax}}
                  </div>
                </td>
                <td class="border-b dark:border-dark-5">
                  <div class="font-medium whitespace-nowrap">
                    {{$t('message.continuousLiabilitiesOtherSpecialDebt')}}
                  </div>
                  <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{store.state.requiredForm.contingentLiabilities.otherSpecialDebt}}
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>




  </div>
</template>


<script>
import { useStore } from '@/store'

export default {
  setup() {
      const store = useStore();
      return {
        store
      }
  }


}
</script>

<style>
</style>