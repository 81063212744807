<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Your Assessment</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-5 sm:py-20 mt-5">
      <!-- <div class="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20"> -->
        <!-- <div
          class="intro-x lg:text-center flex items-center lg:block flex-1 z-10"
        >
          <button class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-500 dark:bg-dark-1">
            <CheckIcon class="block mx-auto" />
          </button>
          <div class="lg:w-32 font-medium text-theme-20 lg:mt-3 ml-3 lg:mx-auto">
            Assessment Submitted
          </div>
        </div>
        <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-500 dark:bg-dark-1">
            <CheckIcon class="block mx-auto" />
          </button>
          <div class="lg:w-32 font-medium text-theme-20 lg:mt-3 ml-3 lg:mx-auto">
            Processing Assessment
          </div>
        </div>
        <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button class="w-10 h-10 rounded-full btn btn-success">
            <CheckIcon class="block mx-auto" />
          </button>
          <div class="lg:w-32 font-medium text-theme-20 lg:mt-3 ml-3 lg:mx-auto">
            <strong>Complete</strong>
          </div>
        </div> -->
        <!-- <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button
            class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1"
          >
            4
          </button>
          <div
            class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
          >
            Setup Billing Account
          </div>
        </div> -->
        <!-- <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button
            class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1"
          >
            5
          </button>
          <div
            class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
          >
            Finalize your purchase
          </div>
        </div> -->
        <!-- <div
          class="wizard__line hidden lg:block w-2/3 bg-gray-200 dark:bg-dark-1 absolute mt-5"
        ></div> -->
      <!-- </div> -->
      <div class="px-5 sm:px-20 ">
        <div class="font-medium text-base"><strong><i>Assessment Results</i></strong></div>
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
          <div class="intro-y box col-span-12 sm:col-span-6">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto">Your chance of approval is <strong class="text-theme-21">Low</strong>...</h2>
            </div>
            <div id="donut-chart" class="p-10">
              <div class="preview">
                <div class="alert alert-danger-soft show mb-2" role="alert">
              <div class="flex items-center">
                  <div class="font-medium text-lg">
                    <XOctagonIcon class="w-6 h-6 mr-2" /> Looks like we've got some work to do...
                  </div>
                  <div
                    class="ml-auto"
                  >
                    <CheckIcon class="block mx-auto" />
                  </div>
                </div>
                <div class="mt-3">
                  <p>After reviewing your information, we think you'll need to make some changes before you're ready to submit your application to the bank.</p>
                  <p>Check below for the changes that need to be made.</p>
                </div>
            </div>
              </div>
              
            </div>
          </div>
          <div class="intro-y box col-span- sm:col-span-6" style="position: relative;">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto"><strong>Next Steps</strong></h2>
            </div>
            <div class="intro-y box col-span- sm:col-span-6" style="position: relative;">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto"><strong>Assessment Feedback</strong></h2>
            </div>
            <!-- BEGIN: Feedback Accordian -->
            <div class="col-span-12 lg:col-span-6">
              <div class="intro-y box">
                <div id="basic-accordion" class="p-5">
                  <div class="preview">
                    <div id="faq-accordion-1" class="accordion">

                <div class="accordion-item">
                  <div id="faq-accordion-content-1" class="accordion-header">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-1"
                      aria-expanded="true"
                      aria-controls="faq-accordion-collapse-1"
                    >
                      Feedback #1
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-1"
                    class="accordion-collapse collapse show"
                    aria-labelledby="faq-accordion-content-1"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div
                      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
                    >
                      <p><strong>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</strong></p>  
                      <p>
                        Cumque quasi esse aperiam voluptatem laboriosam quidem enim placeat dicta nemo, distinctio, 
                        laudantium ratione velit, facilis commodi minus ducimus et vel perferendis 
                        quibusdam! Fugit, inventore iusto. Molestias eaque illo nostrum perferendis voluptas, 
                        quidem harum consequuntur tenetur laborum quisquam. Laborum quidem cumque beatae 
                        deleniti expedita rerum. Quasi, rem. Maxime dignissimos vel similique in.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <div id="faq-accordion-content-2" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-2"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-2"
                    >
                      Feedback #2
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-2"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-2"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div
                      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
                    >
                      <p><strong>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</strong></p>  
                      <p>
                        Cumque quasi esse aperiam voluptatem laboriosam quidem enim placeat dicta nemo, distinctio, 
                        laudantium ratione velit, facilis commodi minus ducimus et vel perferendis 
                        quibusdam! Fugit, inventore iusto. Molestias eaque illo nostrum perferendis voluptas, 
                        quidem harum consequuntur tenetur laborum quisquam. Laborum quidem cumque beatae 
                        deleniti expedita rerum. Quasi, rem. Maxime dignissimos vel similique in.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <div id="faq-accordion-content-3" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-3"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-3"
                    >
                      Feedback #3
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-3"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-3"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div
                      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
                    >
                      <p><strong>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</strong></p>  
                      <p>
                        Cumque quasi esse aperiam voluptatem laboriosam quidem enim placeat dicta nemo, distinctio, 
                        laudantium ratione velit, facilis commodi minus ducimus et vel perferendis 
                        quibusdam! Fugit, inventore iusto. Molestias eaque illo nostrum perferendis voluptas, 
                        quidem harum consequuntur tenetur laborum quisquam. Laborum quidem cumque beatae 
                        deleniti expedita rerum. Quasi, rem. Maxime dignissimos vel similique in.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <div id="faq-accordion-content-4" class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-accordion-collapse-4"
                      aria-expanded="false"
                      aria-controls="faq-accordion-collapse-4"
                    >
                      Feedback #4
                    </button>
                  </div>
                  <div
                    id="faq-accordion-collapse-4"
                    class="accordion-collapse collapse"
                    aria-labelledby="faq-accordion-content-4"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div
                      class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed"
                    >
                      <p><strong>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</strong></p>  
                      <p>
                        Cumque quasi esse aperiam voluptatem laboriosam quidem enim placeat dicta nemo, distinctio, 
                        laudantium ratione velit, facilis commodi minus ducimus et vel perferendis 
                        quibusdam! Fugit, inventore iusto. Molestias eaque illo nostrum perferendis voluptas, 
                        quidem harum consequuntur tenetur laborum quisquam. Laborum quidem cumque beatae 
                        deleniti expedita rerum. Quasi, rem. Maxime dignissimos vel similique in.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            </div>
            </div>
            </div>
            <!-- END: Feedback Accordian -->
          </div>
          </div>
          <div
            class="flex items-center text-gray-700 dark:text-gray-600 sm:ml-auto mt-5 sm:mt-0"
            style="position: absolute; left: 0; bottom: 5%; margin-left: 10px;"
          >
            <Tippy
              tag="a"
              class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
              content="Back to Application"
              @click="handleBackSection3"
            >
              <ArrowLeftIcon class="w-4 h-4 fill-current" />
            </Tippy>
            &nbsp;Back to Section 3
          </div>
        </div>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import useDocument from '@/composables/useDocument';
import getDocument from '@/composables/getDocument';

export default ({
  props: ['appID'],
  setup(props) {
    const router = useRouter();
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const data = computed(() => {
      return {
        datasets: [
          {
            data: [75, 25],
            backgroundColor: ['#71797e', '#cc3232'],
            borderWidth: 0,
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    })
    const options = computed(() => {
      return {
        cutoutPercentage: 80,
        tooltips: {enabled: false},
        hover: {mode: null},
      }
    })

    const {
      error: getDocumentError,
      getDocumentByID,
      document: application
    } = getDocument('applications', props.appID)

    const {
      error: useDocumentError,
      updateDoc,
      isPending
    } = useDocument('applications', props.appID)

    const handleBackSection3 = () => {
      router.push({ name: 'side-menu-7a-section-3' });
    }

    return {
      data,
      options,
      handleBackSection3
    }
  }
})
</script>