import { ref } from 'vue'
import { useStore } from '@/store'
import { useGlobalStaticData } from '@/composables/form-data';
const dataInfo = ref(null);
const store = useStore()

const { applicationPdfMapping, sbaForm1919PdfMapping, sbaFormPFSMapping } = useGlobalStaticData();

function standardProcessing(key) {
  let newObj = {};

  let buildKeyValueString = ""
  for (const element of applicationPdfMapping.value[key]) {
    let value = eval(element); // Value
    let typeofValue = typeof value;


    if (typeofValue === 'boolean') {
      if (key.substring(key.length - 3, key.length) == 'Yes') {
        // console.log(key + "[" + element + "]= " + value + " isTrue:" + (String(value).toLowerCase() === 'true') + " -- typeof - " + typeof typeofValue)
        if (String(value).toLowerCase() === 'true')
           buildKeyValueString = buildKeyValueString + "true"
        else
           buildKeyValueString = buildKeyValueString + "false"
      }

      if (key.substring(key.length - 2, key.length) == 'No') {
        // console.log(key + "[" + element + "]= " + value + " isTrue:" + (String(value).toLowerCase() === 'true') + " -- typeof - " + typeof typeofValue)
        if (String(value).toLowerCase() === 'true')
           buildKeyValueString = buildKeyValueString + "false"
        else
           buildKeyValueString = buildKeyValueString + "true"
      }


    } else {
      buildKeyValueString = buildKeyValueString + value + " "
    }
  }

  if (buildKeyValueString.trim() !== "undefined") {
    newObj[key] = buildKeyValueString;
  }

  return newObj
}

function standardProcess(key, pdfMapping) {
  let newObj = {};

  let buildKeyValueString = ""
  for (const element of pdfMapping.value[key]) {
    let value = eval(element); // Value
    let typeofValue = typeof value;


   // console.log("Key(" + JSON.stringify(key) + ")")

    if (typeofValue === 'boolean') {
      if (key.substring(key.length - 3, key.length) == 'Yes') {
        // console.log(key + "[" + element + "]= " + value + " isTrue:" + (String(value).toLowerCase() === 'true') + " -- typeof - " + typeof typeofValue)
        if (String(value).toLowerCase() === 'true')
          buildKeyValueString = buildKeyValueString + "true"
        else
          buildKeyValueString = buildKeyValueString + "false"
      }

      if (key.substring(key.length - 2, key.length) == 'No') {
        // console.log(key + "[" + element + "]= " + value + " isTrue:" + (String(value).toLowerCase() === 'true') + " -- typeof - " + typeof typeofValue)
        if (String(value).toLowerCase() === 'true')
          buildKeyValueString = buildKeyValueString + "false"
        else
          buildKeyValueString = buildKeyValueString + "true"
      }


    } else {
      buildKeyValueString = buildKeyValueString + value + " "
    }
  }

  if (buildKeyValueString.trim() !== "undefined") {
    newObj[key] = buildKeyValueString.trim();
  }

  return newObj
}

function businessOwnedByProcessing(key) {
  let newObj = {};

  let v = eval(applicationPdfMapping.value[key] + "")

  newObj["X401kCheckbox"] = "false"
  newObj["ESOPCheckbox"] = "false"
  newObj["TrustCheckbox"] = "false"
  newObj["CooperativeCheckbox"] = "false"


  switch (v) {
    case "401(k)":
      newObj["X401kCheckbox"] = "true"
      break;
    case "ESOP":
      newObj["ESOPCheckbox"] = "true"
      break;
    case "Trust":
      newObj["TrustCheckbox"] = "true"
      break;
    case "Cooperative":
      newObj["CooperativeCheckbox"] = "true"
      break;
    default:
      return null
  }

  return newObj
}

function demographicProcessing(key) {
  let newObj = {};

  let v = eval(applicationPdfMapping.value[key] + "");
  switch (key) {
    case "Veteran":
        if (v.trim() == "Non-Veteran") {
          newObj["Veteran"] = '1';
        } else if (v.trim() == "Veteran") {
          newObj["Veteran"] = '2'
        } else if (v.trim() == "Service Disable Veteran") {
          newObj["Veteran"] = '3'
        } else if (v.trim() == "Spouse of Veteran") {
          newObj["Veteran"] = '4'
        } else if (v.trim() == "Not Disclosed") {
          newObj["Veteran"] = 'x'
        }
      break;
    case "Gender":
      if (v.trim() == "Male") {
        newObj["Gender"] = 'M';
      } else if (v.trim() == "Female") {
        newObj["Gender"] = 'F'
      } else if (v.trim() == "Not Disclosed") {
        newObj["Gender"] = 'x'
      }
      break;
    case "Race":
      if (v.trim() == "American Indian or Alaska Native") {
        newObj["Race"] = '1';
      } else if (v.trim() == "Asian") {
        newObj["Race"] = '2'
      } else if (v.trim() == "Black or African American") {
        newObj["Race"] = '3'
      } else if (v.trim() == "Native Hawaiian or Pacific Islander") {
        newObj["Race"] = '4'
      } else if (v.trim() == "White") {
        newObj["Race"] = '5'
      } else if (v.trim() == "Not Disclosed") {
        newObj["Race"] = 'x'
      }
      break;
    case "Ethnicity":
      if (v.trim() == "Hispanic or Latino") {
        newObj["Ethnicity"] = 'H';
      } else if (v.trim() == "Not Hispanic or Latino") {
        newObj["Ethnicity"] = 'L'
      } else if (v.trim() == "Not Disclosed") {
        newObj["Ethnicity"] = 'x'
      }
      break;
    default:
      return null
  }


  return newObj

}

// 504
export async function pdfWebServices(email, orgName) {
  let newObj = {};
  let newObj2 = {};

  Object.keys(applicationPdfMapping.value).forEach(key => {
    if (["X401kCheckbox", "ESOPCheckbox", "TrustCheckbox", "CooperativeCheckbox"].includes(key)) {
      if (businessOwnedByProcessing(key) != null) {
        newObj = {
          ...newObj,
          ...businessOwnedByProcessing(key)
        }
      }
    } else if (["Veteran", "Gender", "Race", "Ethnicity"].includes(key)) {
      if (demographicProcessing(key) != null) {
        newObj = {
          ...newObj,
          ...demographicProcessing(key)
        }
      }
      // console.log (JSON.stringify(newObj));
    } else {
      newObj = {
        ...newObj,
        ...standardProcessing(key)
      }
    }
  });

  newObj["email"] = email
  newObj["orgName"] = orgName

  // Section 1
  newObj["OCTitleOrganizationType1"] = "OWNER"
  newObj["OCOwnershipPercentage1"] = "100%"
  // Section 2
  // Section 3
  newObj["EPCOC1"] = "OC"
  newObj["RequiredForms"] = { ...store.state.requiredForm };
  // console.log(JSON.stringify(newObj));
  newObj["RequiredForms"]

  Object.keys(sbaFormPFSMapping.value).forEach(key => {
    newObj2 = {
      ...newObj2,
      ...standardProcess(key, sbaFormPFSMapping)
    }
  });

  newObj["PFS"] = newObj2;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/pdf"
    },
    body: JSON.stringify(newObj)
  };

   // console.log(`get504LoanApplication URL set to https://pdfgen.archymedes.com:8090/get504LoanApplication`)

  const response = await fetch(`${process.env.VUE_APP_API_URL}/get504LoanApplication`, requestOptions)
  return response;

}

function demographic191Processing(key, pdfMapping) {
  let newObj = {};


  let v = eval(pdfMapping.value[key] + "");
  switch (key) {
    case "entityType":
      if (v.trim() == "Sole proprietor") {
        newObj["SoleProprietorcheckbox"] = 'true';
      } else if (v.trim() == "Partnership") {
        newObj["Partnershipcheckbox"] = 'true'
      } else if (v.trim() == "C-Corp") {
        newObj["CCorpcheckbox"] = 'true'
      } else if (v.trim() == "S-Corp") {
        newObj["SCorpcheckbox"] = 'true'
      } else if (v.trim() == "LLC") {
        newObj["LLCCheckBox"] = 'true'
      } else if (v.trim() == "Other") {
        newObj["OtherCheckbox"] = 'true'
      }
      break;
    case "Veteran":
      if (v.trim() == "Non-Veteran") {
        newObj["Nonveterancheckbox"] = 'true';
      } else if (v.trim() == "Veteran") {
        newObj["Veterancheckbox"] = 'true'
      } else if (v.trim() == "Service Disable Veteran") {
        newObj["ServiceDisableVeterancheckbox"] = 'true'
      } else if (v.trim() == "Spouse of Veteran") {
        newObj["Spouseofveterancheckbox"] = 'true'
      } else if (v.trim() == "Not Disclosed") {
        newObj["Notdisclosedcheckbox"] = 'true'
      }
      break;
    case "Gender":
      if (v.trim() === "Male") {
        newObj["maleCheckBox"] = 'true';
      } else if (v.trim() === "Female") {
        newObj["femaleCheckBox"] = 'true'
      } else if (v.trim() === "Not Disclosed") {
        newObj["Notdisclosedcheckbox"] = 'true'
      }
      break;
    case "Race":
      if (v.trim() == "American Indian or Alaska Native") {
        newObj["americanIndianAlaskaNativeCheckBox"] = 'true';
      } else if (v.trim() == "Asian") {
        newObj["asianCheckBox"] = 'true'
      } else if (v.trim() == "Black or African American") {
        newObj["blackAfricanAmericanCheckBox"] = 'true'
      } else if (v.trim() == "Native Hawaiian or Pacific Islander") {
        newObj["nativeHawaiianPacificIslanderCheckBox"] = 'true'
      } else if (v.trim() == "White") {
        newObj["whiteCheckBox"] = 'true'
      } else if (v.trim() == "Not Disclosed") {
        newObj["notdisclosedCheckBox"] = 'true'
      }
      break;
    case "Ethnicity":
      if (v.trim() == "Hispanic or Latino") {
        newObj["hispanicLatinoCheckBox"] = 'true';
      } else if (v.trim() == "Not Hispanic or Latino") {
        newObj["notHispanicLatinoCheckBox"] = 'true'
      } else if (v.trim() == "Not Disclosed") {
        newObj["notDisclosedCheckBox"] = 'true'
      }
      break;
    default:
      return null
  }


  return newObj

}

export async function accountNowPdfPackage(email, orgName) {
  let newObj = {};
  newObj["email"] = email
  newObj["orgName"] = orgName
  newObj["companyName"] = store.state.requiredForm.bizInfo.companyName;


  newObj["NowAccount"] = { ...store.state.accountNow };
  newObj["requiredForms"] = { "bizInfo": {...store.state.requiredForm.bizInfo},  "sourceIncome": { ...store.state.requiredForm.sourceIncome } , "contingentLiabilities": { ...store.state.requiredForm.contingentLiabilities} };

  console.log(newObj["NowAccount"]);
  console.log(JSON.stringify(newObj));

  console.log(`accountNowPdfPackage URL set to ${process.env.VUE_APP_API_URL}/now-account-pdfs`);

  return await fetch(`${process.env.VUE_APP_API_URL}/now-account-pdfs`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj),
  });

}

// 7a Form
export async function pdf1919SbaForm(email, orgName) {
  let newObj = {};
  let newObj2 = {};
  let newObj3 = {};

  Object.keys(applicationPdfMapping.value).forEach(key => {
    if (["X401kCheckbox", "ESOPCheckbox", "TrustCheckbox", "CooperativeCheckbox"].includes(key)) {
      if (businessOwnedByProcessing(key) != null) {
        newObj2 = {
          ...newObj2,
          ...businessOwnedByProcessing(key)
        }
      }
    } else if(["Veteran", "Gender", "Race", "Ethnicity"].includes(key)) {
      if (demographicProcessing(key) != null) {
        newObj2 = {
          ...newObj2,
          ...demographicProcessing(key)
        }
      }
      // console.log (JSON.stringify(newObj));
    } else {
      newObj2 = {
        ...newObj2,
        ...standardProcessing(key)
      }
    }

  });

  newObj2["OCTitleOrganizationType1"] = "OWNER"
  newObj2["OCOwnershipPercentage1"] = "100%"
  newObj2["EPCOC1"] = "OC"
  newObj["email"] = email
  newObj["orgName"] = orgName
  newObj["504Loan"] = newObj2
  newObj["RequiredForms"] = { ...store.state.requiredForm };

  console.log(JSON.stringify(newObj));

  Object.keys(sbaForm1919PdfMapping.value).forEach(key => {
    if(["entityType", "Veteran", "Gender", "Race", "Ethnicity"].includes(key)) {
        newObj3 = {
          ...newObj3,
          ...demographic191Processing(key, sbaForm1919PdfMapping),
        }
    } else {
        newObj3 = {
          ...newObj3,
          ...standardProcess(key, sbaForm1919PdfMapping)
        }
      }
  });

  newObj["7a"] = newObj3;

  Object.keys(sbaFormPFSMapping.value).forEach(key => {
    newObj2 = {
      ...newObj2,
      ...standardProcess(key, sbaFormPFSMapping)
    }
  });

  newObj["PFS"] = newObj2;

  console.log(JSON.stringify(newObj));

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/pdf"
    },
    body: JSON.stringify(newObj)
  };

  // console.log(`get504LoanApplication URL set to https://pdfgen.archymedes.com:8090/get504LoanApplication`)

  const response = await fetch(`${process.env.VUE_APP_API_URL}/get7aLoanApplication`, requestOptions)
  return response;
  // return null;

}

export async function pdfPackage(email, orgName) {
  let newObj = {};
  let newObj2 = {};

  Object.keys(applicationPdfMapping.value).forEach(key => {
    if (["X401kCheckbox", "ESOPCheckbox", "TrustCheckbox", "CooperativeCheckbox"].includes(key)) {
      if (businessOwnedByProcessing(key) != null) {
        newObj2 = {
          ...newObj2,
          ...businessOwnedByProcessing(key)
        }
      }
    } else if(["Veteran", "Gender", "Race", "Ethnicity"].includes(key)) {
      if (demographicProcessing(key) != null) {
        newObj2 = {
          ...newObj2,
          ...demographicProcessing(key)
        }
      }
      // console.log (JSON.stringify(newObj));
    } else {
      newObj2 = {
        ...newObj2,
        ...standardProcessing(key)
      }
    }

  });

  newObj2["OCTitleOrganizationType1"] = "OWNER"
  newObj2["OCOwnershipPercentage1"] = "100%"
  newObj2["EPCOC1"] = "OC"
  newObj["email"] = email
  newObj["orgName"] = orgName
  newObj["504Loan"] = newObj2
  newObj["RequiredForms"] = { ...store.state.requiredForm };
  newObj = {
    ...newObj,
  }

  console.log(JSON.stringify(newObj));
  console.log(`get504LoanApplication URL set to ${process.env.VUE_APP_API_URL}/merge-pdfs`)
  return await fetch(`${process.env.VUE_APP_API_URL}/merge-pdfs`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj),
  });
}

export async function pdfPFSSbaForm(email, orgName) {
  let newObj2 = {};

  Object.keys(sbaFormPFSMapping.value).forEach(key => {
    newObj2 = {
      ...newObj2,
      ...standardProcess(key, sbaFormPFSMapping)
    }
  });

  console.log(JSON.stringify(newObj2));
  console.log(`get504LoanApplication URL set to ${process.env.VUE_APP_API_URL}/getPFSLoanApplication`)

  return await fetch(`${process.env.VUE_APP_API_URL}/getPFSLoanApplication`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newObj2),
  });
}