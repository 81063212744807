import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'



const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('balance-sheet');


const state = () => {
  // TODO: must deal with date input type

  return {
      openInventory: 0,
      purchase: 0,
      factoryWages: 0,
      total: 0,
      lessClosingInventory: 0,
      equalsCostOfGoodsSold: 0,
      grossProfit: 0,

      salary: 0,
      wage: 0,
      rentOrMortgageInterest: 0,
      utilities: 0,
      insurance: 0,
      advertisingAndPromotion: 0,
      automobile: 0,

      otherTravel: 0,
      officeExpenses: 0,
      storage: 0,
      maintenance: 0,
      depreciation: 0,
      badDebts: 0,
      interest: 0,
      otherExpenses: 0,
      totalExpenses: 0,
      earning: 0,
      incomeTax: 0,
      netEarning: 0
    }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    const {
      user
    } = getUser();
    const application = await addDocWithGenId(this.state.balanceSheet, user._value.email);
  }
}



// mutations
const mutations = {
  setOpenInventory(state, value) {
    this.state.balanceSheet.openInventory = value;
  },

  setPurchase(state, value) {
    this.state.balanceSheet.purchase = value;
  },

  setFactoryWages(state, value) {
    this.state.balanceSheet.factoryWages = value;
  },

  setTotal(state, value) {
    this.state.balanceSheet.total = value;
  },
  setLessClosingInventory(state, value) {
    this.state.balanceSheet.lessClosingInventory = value;
  },
  setEqualsCostOfGoodsSold(state, value) {
    this.state.balanceSheet.equalsCostOfGoodsSold = value;
  },

  setGrossProfit(state, value) {
    this.state.balanceSheet.grossProfit = value;
  },

  setBSSalary(state, value) {
    this.state.balanceSheet.salary = value;
  },
  setBSWage(state, value) {
    this.state.balanceSheet.wage = value;
  },

  setRentOrMortgageInterest(state, value) {
    this.state.balanceSheet.rentOrMortgageInterest = value;
  },

  setBSUtilities(state, value) {
    this.state.balanceSheet.utilities = value;
  },

  setInsurance(state, value) {
    this.state.balanceSheet.insurance = value;
  },

  setAdvertisingAndPromotion(state, value) {
    this.state.balanceSheet.advertisingAndPromotion = value;
  },
  setAutomobile(state, value) {
    this.state.balanceSheet.automobile = value;
  },

  setOtherTravel(state, value) {
    this.state.balanceSheet.otherTravel = value;
  },

  setOfficeExpenses(state, value) {
    this.state.balanceSheet.officeExpenses = value;
  },

  setStorage(state, value) {
    this.state.balanceSheet.storage = value;
  },

  setMaintenance(state, value) {
    this.state.balanceSheet.maintenance = value;
  },

  setDepreciation(state, value) {
    this.state.balanceSheet.depreciation = value;
  },

  setBadDebts(state, value) {
    this.state.balanceSheet.badDebts = value;
  },

  setInterest(state, value) {
    this.state.balanceSheet.interest = value;
  },

  setOtherExpenses(state, value) {
    this.state.balanceSheet.otherExpenses = value;
  },

  setBSTotalExpenses(state, value) {
    this.state.balanceSheet.totalExpenses = value;
  },


  setEarning(state, value) {
    this.state.balanceSheet.earning = value;
  },

  setBSIncomeTax(state, value) {
    this.state.balanceSheet.incomeTax = value;
  },

  setNetEarning(state, value) {
    this.state.balanceSheet.netEarning = value;
  },


  setBalanceSheetObj(state, value) {
    this.state.balanceSheet = value;
  }



}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
