import { ref } from 'vue';
import { projectStorage } from '../firebase/config';
import getUser from './getUser';
import getCurrentProfileDetails from '@/composables/getCurrentUserProfileDetails';

const { user } = getUser();

const useStorage = () => {
    const error = ref(null);
    const url = ref(null);
    const filePath = ref(null);

    const {
      collect,
      getCurrentProfileDetailsData
    } = getCurrentProfileDetails();

    const uploadImage = async (file) => {
        filePath.value = 'arch-documents/${user.value.uid}/${file.name}';
        const storageRef = projectStorage.ref(filePath.value);

        try {
            const response = await storageRef.put(file);
            url.value = await response.ref.getDownloadURL();
        } catch (err) {
            console.log(err.message);
            error.value = err.message;
        }
    }

    async function uploadData(file, optDir){

      // optDir.forEach(item => console.log(item));
      const sorted = Object.keys(optDir)
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = optDir[key];

            return accumulator;
          }, {});


      let rmSpecialCharacters = user.value.email.replace(/[^A-Z0-9]/ig, "_");

      await getCurrentProfileDetailsData(user.value.email);

      //console.log("OnMount: elDropzoneMultipleRef event " + collect.value[0].orgName);
      //console.log("rmSpecialCharacters: " + rmSpecialCharacters);
      //console.log("filename: " + JSON.stringify(file.name));


      let partialDir = '';
      Object.values(sorted).forEach(
         function (val) {
           partialDir = partialDir + `/${val}`
         }
       );

     //console.log(partialDir);


      //console.log("Saving: " + `arch-documents/${collect.value[0].orgName}/${rmSpecialCharacters}/${partialDir}`);

      //
      // let file = upload;
      let metadata = {contentType: file.type};


      let storageRef = projectStorage.ref();

      let imageRef = storageRef.child(`arch-documents/${collect.value[0].orgName}/${rmSpecialCharacters}/${partialDir}`);
      await imageRef.put(file, metadata);
      let downloadURL = await imageRef.getDownloadURL();

    }
    return { url, filePath, error, uploadImage, uploadData };
}

export default useStorage;
