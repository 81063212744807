<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ pageTitle }}</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" aria-expanded="false" @click="addNewRecord">
          <PlusIcon class="w-4 h-4" />&nbsp;{{addRecordButtonProps.value}}
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start" v-show="showSearch">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto" >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Field</label>
            <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto">
              <option value="started">Started</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Type</label>
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like">like</option>
              <option value="=" selected>=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Value</label>
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </form>


        <div class="flex mt-5 sm:mt-0" >
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportHtml"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue';
import xlsx from 'xlsx';
import feather from 'feather-icons';
import Tabulator from 'tabulator-tables';
import { useRouter } from 'vue-router';
import getDocument from '@/composables/getDocument';

export default defineComponent({
  props: {
    pageTitle:{
      type: String,
      required: true
    },
    addRecordButtonProps: {
      type: Object,
      required: true
    },
    showSearch: {
      type: Boolean,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    organization: {
      type: String,
      required: false
    }
  },
  setup(props) {

    console.log("data-table/main.vue");

    const router = useRouter();
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'type',
      type: '=',
      value: ''
    })

    const {
      error: getDocumentError,
      getDocumentByID,
      getDocumentByEmail,
      getDocumentByOrgName,
      document: profileDetails
    } = getDocument();



    function queryRealm(url, config, params){

      console.log("queryRealm url:" + url);
      console.log("queryRealm config:" + JSON.stringify(config));
      console.log("queryRealm params:" + JSON.stringify(params));

      //return promise
      return new Promise(function(resolve, reject){
        console.log("queryRealm1");
        //do some async data retrieval then pass the array of row data back into Tabulator
        let data = [];
        let dataInfo = [];
        // await getDocumentByID('organizations', props.org);
         getDocumentByOrgName('profileDetails', props.organization).then(()=>{
           for(let i = 0;i < profileDetails.value.length; i++) {
             let isAdmin = false
             if (profileDetails.value[i].role == "ROLE_ADMIN") {
               isAdmin = true;
             }

          //   for (let x = 0; x < 20; x++) {
               var member = {
                 id: i,
                 uid: profileDetails.value[i].id,
                 name: profileDetails.value[i].displayName,
                 email: profileDetails.value[i].email,
                 orgName: profileDetails.value[i].orgName,
                 companyName: profileDetails.value[i].companyName,
                 admin: isAdmin,
                 disabled: false
               }
               // console.log(member);
               data.push(member);
            // }
           }

           dataInfo = {last_page:1, data: data};
           console.log("dataInfo object: " + JSON.stringify(dataInfo));
           resolve(dataInfo);

           });

      });
    }

   var initTabulator = async () => {

      var table = new Tabulator(tableRef.value, {
        printAsHtml: true,
        printStyled: true,
        reactiveData: true,
        pagination:"remote",
        paginationSize:10,
        paginationSizeSelector:[10, 20, 50],
        movableColumns:true,
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: props.columns,
        ajaxURL:"http://help", //ajax URL
        ajaxRequestFunc: queryRealm,
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })

      //table.setData(props.tableData)
      tabulator.value = table;
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'type'
      filter.type = '='
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    const addNewRecord = props.addRecordButtonProps.action;

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })

    return {

      tableRef,
      filter,
      router,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      addNewRecord

    }
  }
})
</script>
