<template>
  <div class="text-2xl">
    {{ $t('message.finance') }}
  </div>
<div class="flex flex-wrap">
  <div class="grid grid-cols-12 gap-6 p-10">
    <div class="col-span-6">
      <h1 class="text-lg font-medium truncate"> Report your Liabilities </h1>
      <small class="text-theme-25 intro-y">You can report your liabilities by updating your information in appropriate columns. </small>
    </div>
    <div class="col-span-6">
      <h3 class=""> Total amount of liabilities </h3>
      <h1 class="text-lg font-medium truncate"> ${{sumAmount}} </h1>
    </div>
  </div>
</div>
<div class="flex flex-wrap">
  <div class="w-full">
    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <a class="text-xs font-bold px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" :style="styleObjectOwner" v-bind:class="{'text-black': openTab !== 1, 'text-white': openTab === 1}">
          OWNER
        </a>
      </li>
<!--      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">-->
<!--        <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" :style="styleObjectBusiness" v-bind:class="{'text-black': openTab !== 2, 'text-white': openTab === 2}">-->
<!--          Business-->
<!--        </a>-->
<!--      </li>-->
    </ul>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded" style="background-color: #fafafa;">
      <div class="px-12 py-12 flex-auto">
        <div class="tab-content tab-space">
          <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
            <div class="intro-y">
              <!-- Info icon -->
              Click info icon for definitions and examples <span @click="showPopup = true" class="info-icon">ℹ️</span>

              <!-- Popup -->
              <div v-if="showPopup" class="popup">
                <p>This is the detailed information you wanted to display.</p>
                <button @click="closePopup">Close</button>
              </div>
            </div>


            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
              <table class="table table-report -mt-2">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap">Category</th>
                    <th class="text-center whitespace-nowrap">Amount</th>
                    <th class="text-center whitespace-nowrap">Action</th>
                    <!--    <th class="text-center whitespace-nowrap">ACTIONS</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(values , type, index) in this.$store.state.financials.owner.liabilities" class="intro-x">
                    <td class="w-40">
                      <a href="" class="uppercase font-medium whitespace-nowrap">{{ values.type }}</a>

                    </td>
                    <td class="w-40 text-center">
                      <h1 v-if="values.amount > 0">${{values.amount}}</h1>
                      <h1 v-if="values.amount === 0"></h1>
                    </td>
                    <td class="w-40 text-center">
                      <a href="javascript:;" data-toggle="modal" data-target="#edit-liabilities-modal" @click="setValuesFromRow(values.type, values.amount, type)">
                        <EditIcon class="w-4 h-4 mr-1" />
                      </a>
                    </td>
                    <!--  <td class="table-report__action w-56">
                                            <div class="flex justify-center items-center">
                                                <a class="flex items-center mr-3" href="javascript:;">
                                                    <CheckSquareIcon class="w-4 h-4 mr-1" /> Edit
                                                </a>
                                                <a class="flex items-center text-theme-24" href="javascript:;" data-toggle="modal" data-target="#delete-confirmation-modal">
                                                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                                                </a>
                                            </div>
                                        </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>










          <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">


            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
              <table class="table table-report -mt-2">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap">Category</th>
                    <th class="text-center whitespace-nowrap">Amount</th>
                    <th class="text-center whitespace-nowrap">Action</th>
                    <!--    <th class="text-center whitespace-nowrap">ACTIONS</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(values , type, index) in this.$store.state.financials.business.liabilities" class="intro-x">
                    <td class="w-40">
                      <a href="" class="uppercase font-medium whitespace-nowrap">{{ values.type }}</a>

                    </td>
                    <td class="w-40 text-center">
                      <h1 v-if="values.amount > 0">${{values.amount}}</h1>
                      <h1 v-if="values.amount == 0"></h1>
                    </td>
                    <td class="w-40 text-center">
                      <a href="javascript:;" data-toggle="modal" data-target="#edit-liabilities-modal" @click="setValuesFromRow(values.type, values.amount, type)">
                        <EditIcon class="w-4 h-4 mr-1" />
                      </a>
                    </td>
                    <!--  <td class="table-report__action w-56">
                                          <div class="flex justify-center items-center">
                                              <a class="flex items-center mr-3" href="javascript:;">
                                                  <CheckSquareIcon class="w-4 h-4 mr-1" /> Edit
                                              </a>
                                              <a class="flex items-center text-theme-24" href="javascript:;" data-toggle="modal" data-target="#delete-confirmation-modal">
                                                  <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                                              </a>
                                          </div>
                                      </td> -->
                  </tr>
                </tbody>
              </table>
            </div>









          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END: Pagination -->
<div id="edit-liabilities-modal" class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto" tabindex="-1" aria-hidden="true" @click.self="resetDeleteID()">
  <div class="modal-dialog modal-fullscreen relative w-auto pointer-events-none" style="width: 90%;">
    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">Edit {{modalTitle}}</h5>
        <button type="button" class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-dismiss="modal"
          aria-label="Close">X</button>
      </div>
      <div class="modal-body p-0">

        <div class="
                    px-5
                    sm:px-20
                    mt-1
                    pt-1
                    dark:border-dark-5
                  ">

          <div class="grid grid-cols-2 gap-4 gap-y-5 mt-5">
            <div class="">
              <label for="input-wizard-9" class="form-label">Category</label><input id="input-wizard-9" type="text" class="form-control" v-model="Category" disabled />
            </div>
            <div class="">
              <label for="input-wizard-9" class="form-label">Amount</label><CurrencyInput id="input-wizard-9" type="text" class="form-control" v-model="editModalAmount" :options="opt" />
            </div>
          </div>

          <div class="text-right mt-5">
            <button class="btn btn-outline-secondary w-24 mr-1" data-dismiss="modal" aria-label="Cancel">
              Cancel
            </button>
            <button class="btn btn-primary w-24 mr-1" data-dismiss="modal" aria-label="Save" @click="saveEditValues(currentCategory, editModalAmount, openTab)">
              Save
            </button>

          </div>
        </div>




      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  defineComponent,
  computed
} from 'vue'
import {
  useStore
} from '@/store'



import CurrencyInput from "@/components/currency-input/Main.vue";


export default {
  name: "liabilities",
  components: {
   CurrencyInput,
 },
  data() {
    const store = useStore();
    return {
      openTab: 1,
      showPopup: false,
      store,
      totalAmount: 0,
      Category: '',
      modalTitle: '',
      editModalAmount: 0,
      currentCategory: '',
      opt: {currency: 'USD',currencyDisplay: 'symbol', hideCurrencySymbolOnFocus: false,  hideGroupingSeparatorOnFocus: false, precision: 0 },
    }
  },
  computed: {
    styleObjectBusiness: function() {
      if (this.openTab == 2) {
        return 'background-color:rgba(28, 63, 170, var(--tw-bg-opacity));'
      }
      return 'background-color:white;'
    },
    styleObjectOwner: function() {
      if (this.openTab == 1) {
        return 'background-color:rgba(28, 63, 170, var(--tw-bg-opacity));'
      }
      return 'background-color:white;'
    },
    sumAmount: function() {
      let liabilitiesList = 0;

      if (this.openTab === 1) {
        liabilitiesList = this.$store.state.financials.owner.liabilities;
      } else {
        liabilitiesList = this.$store.state.financials.business.liabilities;
      }

      let total = 0;
      for (const key in liabilitiesList) {
        total = total + (isNaN(parseInt(liabilitiesList[key].amount)) ? 0 : parseInt(liabilitiesList[key].amount));
      }

      return total;
    },
  },

  methods: {
    toggleTabs: function(tabNumber) {
      console.log(tabNumber);
      this.openTab = tabNumber
    },

    closePopup() {
      this.showPopup = false;
    },

    setValuesFromRow: function(Category, amount, currentCategory) {
      this.modalTitle = Category;
      this.Category = Category;
      this.editModalAmount = amount;
      this.currentCategory = currentCategory;
    },
    getCurrentDateStr() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      return mm + '/' + dd + '/' + yyyy;
    },
    resetDeleteID(){},
    saveEditValues: function(currentCategory, editModalAmount, tabNumber) {
      let tab = 'Owner';
      if (tabNumber == 2) {
        tab = 'Business';
      }

      let captializeCategory = currentCategory.substring(0, 1).toUpperCase() + currentCategory.substring(1, currentCategory.length);
      let setCategoryAmount = 'set' + tab + 'Liabilities' + captializeCategory + 'Amount'
      let setCategoryUpdatedDate = 'set' + tab + 'Liabilities' + captializeCategory + 'UpdatedDate'

      // console.log("setCategoryAmount:xxxxxx***"  + setCategoryAmount);
      // console.log("setCategoryUpdatedDate:"  + setCategoryUpdatedDate);

      this.store.commit('financials/' + setCategoryAmount, editModalAmount);
      this.store.commit('financials/' + setCategoryUpdatedDate, this.getCurrentDateStr());



      this.store.dispatch('financials/submitFirebase');
    },
  },


}
</script>
<style scoped>

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.info-icon {
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.popup {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
</style>