<template>
  <div
    class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    "
  >
    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-1" class="form-label">{{ $t('message.sourceOfIncomeSalary') }}*</label>
        <CurrencyInput id="input-wizard-1" class="form-control" v-model="salary" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-2" class="form-label">{{ $t('message.sourceOfIncomeNetInvestmentIncome') }}*</label>
        <CurrencyInput id="input-wizard-2" class="form-control" v-model="netInvestIncome" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-3" class="form-label">{{ $t('message.sourceOfIncomeRealEstateIncome') }}*</label>
        <CurrencyInput id="input-wizard-3" class="form-control" v-model="realEstateIncome" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-4"
               class="form-label">{{ $t('message.sourceOfIncomeLifeInsuranceLoanAgainstLifeInsurance') }}*</label>
        <CurrencyInput id="input-wizard-4" class="form-control" v-model="lifeInsuranceLoan" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-52" class="form-label">{{ $t('message.sourceOfIncomeTotalNetIncome') }}*</label>
        <CurrencyInput id="input-wizard-52" class="form-control" v-model="totalNetIncome" :options="opt" />
        <template v-if="errorValidation.totalNetIncome !== ''">
          <small class="text-theme-21 mt-2">
           <div v-if="errorValidation.totalNetIncome !== 0"> Total Net Income should not be blank </div><br>
          </small>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from '@/store'
import CurrencyInput from '@/components/currency-input/Main.vue'
import { object, string, number, bool } from 'yup'

export default {
  components: {
    CurrencyInput
  },
  setup() {
    const errorValidation = reactive({
      totalNetIncome:0
    });

    const sourceIncomeSchema = object().shape({
      totalNetIncome: number().required('Total Net Income is required'),
    });

    const setErrors = function (e) {
      for (var i in e.inner) {
        eval("errorValidation." + e.inner[i].path + " = '" + e.inner[i].errors + "'");
      }
    }

    const convertFirstLetterUpper = (str) => {
      return str.charAt(0).toUpperCase() + str.substring(1, str.length)
    }

    const validate = async (field, value) => {
      console.log('in validate')

      let obj = {};
      obj[field] = value;

      try {
        await sourceIncomeSchema
          .validateAt(field, obj).then(() => {
            eval("errorValidation." + field + " = ''");
            console.log("Validate: requiredForm/set" + convertFirstLetterUpper(field));
            console.log("Validate: value:" + value);
            console.log("Validate: field:" + field);
            store.commit("requiredForm/set" + convertFirstLetterUpper(field), value);
          }).catch(err => {
            eval("errorValidation." + field + " = '" + err.message + "'");
            store.commit("requiredForm/set" + convertFirstLetterUpper(field), value);
          });
      } catch (error) {
        console.log("Error:" + error);
        store.commit("requiredForm/set" + convertFirstLetterUpper(field), value);
      }


    }

    const commitStore = async function () {
      await sourceIncomeSchema.validate(store.state.requiredForm.sourceIncome, {
        abortEarly: false
      });
    }
    const store = useStore()

    const opt = {
      currency: 'USD',
      currencyDisplay: 'symbol',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      precision: 0
    }
    const salary = computed({
      get() {
        return store.state.requiredForm.sourceIncome.salary
      },
      set(value) {
        store.commit('requiredForm/setSalary', value)
      }
    })

    const netInvestIncome = computed({
      get() {
        return store.state.requiredForm.sourceIncome.netInvestIncome
      },
      set(value) {
        store.commit('requiredForm/setNetInvestIncome', value)
      }
    })

    const realEstateIncome = computed({
      get() {
        return store.state.requiredForm.sourceIncome.realEstateIncome
      },
      set(value) {
        store.commit('requiredForm/setRealEstateIncome', value)
      }
    })

    const lifeInsuranceLoan = computed({
      get() {
        return store.state.requiredForm.sourceIncome.lifeInsuranceLoan
      },
      set(value) {
        store.commit('requiredForm/setLifeInsuranceLoan', value)
      }
    })

    const totalNetIncome = computed({
      get() {
        return store.state.requiredForm.sourceIncome.totalNetIncome
      },
      set(value) {
        // store.commit('requiredForm/setTotalNetIncome', value)
        validate('totalNetIncome', value);
      }
    })


    return {
      // access a state in computed function
      salary,
      netInvestIncome,
      realEstateIncome,
      lifeInsuranceLoan,
      totalNetIncome,
      commitStore,
      errorValidation,
      store,
      setErrors,
      opt
    }
  }
}

</script>

<style>
</style>