import { ref } from 'vue';
import { projectAuth, projectFirestore } from '../firebase/config';

const error = ref(null);
const isPending = ref(false);
const newID = ref(null);

const signup = async (email, password, displayName, orgName, roleType) => {
  error.value = null;
  isPending.value = true;

    await projectAuth.createUserWithEmailAndPassword(email, password).then(async (res) => {
        const profileDetails = {
          displayName: displayName,
          role: roleType,
          orgName: orgName,
          email:email,
          companyName: orgName
        }


        console.log("profileDetails: " + JSON.stringify(profileDetails));

        await projectFirestore.collection('profileDetails').doc(res.user.uid).set(profileDetails);

        error.value = null;
        isPending.value = false;
        newID.value = res.user.uid;

        return res;
      }
    ).catch((error) => {
      console.log(error.message);
      error.value = error.message;
      isPending.value = false;
    });
}

const useSignup = () => {
  return { error, newID, signup, isPending };
};

export default useSignup;
