<template>
  <div class="text-2xl mb-10">
    Performance
  </div>

  <div class="col-span-12 mt-8 mb-10">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
        <div class="report-box zoom-in">
          <div class="box p-5">
            <div class="flex">

              <div class="ml-auto">
                <div class="report-box__indicator bg-theme-20 tooltip cursor-pointer">&nbsp;GOOD&nbsp;&nbsp;&nbsp;</div>
              </div>
            </div>
            <div class="text-3xl font-medium leading-8 mt-6">2.3</div>
            <div class="text-base text-gray-600 mt-1">Leverageable</div>
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
        <div class="report-box zoom-in">
          <div class="box p-5">
            <div class="flex">
              <div class="ml-auto">
                <div class="report-box__indicator bg-theme-15 tooltip cursor-pointer">&nbsp;AVERAGE&nbsp;&nbsp;&nbsp; </div>
              </div>
            </div>
            <div class="text-3xl font-medium leading-8 mt-6">35%</div>
            <div class="text-base text-gray-600 mt-1">Profitability</div>
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
        <div class="report-box zoom-in">
          <div class="box p-5">
            <div class="flex">
              <div class="ml-auto">
                <div class="report-box__indicator bg-theme-21 tooltip cursor-pointer">&nbsp;POOR&nbsp;&nbsp;&nbsp;</div>
              </div>
            </div>
            <div class="text-3xl font-medium leading-8 mt-6">.45</div>
            <div class="text-base text-gray-600 mt-1">Liquidity</div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="grid grid-cols-12 gap-6 mt-5">
    <!-- BEGIN: Profile Menu -->
    <div class="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
      <div class="intro-y box mt-5 lg:mt-0">
        <div class="relative flex items-center p-5">

          <div class="ml-4 mr-auto">
            <div class="font-medium text-base">Business Financial Form</div>
          </div>

        </div>

        <div class="p-5 border-t border-gray-200 dark:border-dark-5">
          <a class="flex items-center" href="/performance/balance-sheet">Balance Sheet &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<CheckIcon class="block mx-auto" stroke="green" /> </a>
          <a class="flex items-center mt-5" href="performance/income-statement">Income Statement <XIcon class="block mx-auto" stroke="red" /> </a>
          <a class="flex items-center mt-5" href="performance/cash-flow">Cash Flow Statement&nbsp; <CheckIcon class="block mx-auto" stroke="green" />  </a>
        </div>

      </div>

    </div>
  </div>


</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  reactive,
  provide,
  onMounted,
  toRefs
} from 'vue';
import {
  useRouter
} from 'vue-router';
import { useStore } from 'vuex'
import InfoBox from '@/components/info-box/Main.vue';
import Toastify from "toastify-js";



export default {
  components: {
    InfoBox
  },
  setup() {
    const store = useStore();

    const handleSubmitIncome = async () => {
      this.$router.push('income-statement');
    }
    const handleSubmitBalance = async () => {
      this.$router.push('balance-sheet');
    }

    const handleSubmitCash = async () => {
      this.$router.push('cash-flow');
    }

    const s = ref('Excellent');
    const rawHtml = ref(
      'const debitCoverageRatio = computed(function() {'+
      'let dcrValue = store.state.requiredForm.sourceIncome.totalNetIncome / totalDebtService();'+
      'Red < 1.15'+
      'Yellow 1.15 - 1.24'+
      '﻿Green 1.25  >'+
      'let dcr = "";'+
      'if (dcrValue <= 1.15 ) {'+
      '  dcr = alert-danger'+
      '} else if (dcrValue >= 1.15 && dcrValue <= 1.24) {'+
      '  dcr = alert-warning'+
      '} else if (dcrValue >= 1.25) {'+
      '  dcr = alert-success'+
      '}');

    const rawHtml2 = ref(
      `const businessStrength = computed(function() {
        let color = '';
        if (businessStrengthRating() <= 155.999) {
          color = 'alert-secondary text-red';
        } else if (businessStrengthRating() <= 181.194) {
          color = 'alert-secondary  text-yellow';
        } else  {
          color = 'alert-secondary text-green';
        }

        return color
      });`);


    const rawHtml3 = ref(`
      const businessStrengthRating = function() {
        let businessStrengthValue = 0
        businessStrengthValue =
        (totalDebtService() * .6)
        + (store.state.requiredForm.bizInfo.yrsInBusiness * .15)
        + (store.state.requiredForm.ownerInfo.creditScore * .25)

        return businessStrengthValue;
      };`);


    const creditScore = computed(function() {
      let cs = "";
      if (store.state.requiredForm.ownerInfo.creditScore <= 620 ) {
        cs = 'alert-danger'
      } else if (store.state.requiredForm.ownerInfo.creditScore >= 621 && store.state.requiredForm.ownerInfo.creditScore <= 720) {
        cs = 'alert-warning'
      } else if (store.state.requiredForm.ownerInfo.creditScore >= 721) {
        cs = 'alert-success'
      }

      return cs;
    });

    const yearsInBusiness = computed(function() {
      console.log("store.state.requiredForm.ownerInfo.yrsInBusiness:" + store.state.requiredForm.bizInfo.yrsInBusiness);
      let yib = "";
      if (store.state.requiredForm.bizInfo.yrsInBusiness <= 2 ) {
        yib = 'alert-danger'
      } else if (store.state.requiredForm.bizInfo.yrsInBusiness == 3) {
        yib = 'alert-warning'
      } else if (store.state.requiredForm.bizInfo.yrsInBusiness > 3) {
        yib = 'alert-success'
      }

      return yib;
    });

    const totalDebtService = function() {
      let totalDebtService = 0
      let sumDebtService = 0

      store.state.financials.notespayable.forEach(function(item) {
        totalDebtService = 0
        if (!isNaN(item.paymentAmount)) {
          totalDebtService = totalDebtService + parseInt(item.paymentAmount) * 12;
        }
        sumDebtService = sumDebtService + totalDebtService
      });

      return sumDebtService;
    };


    const businessStrengthRating = computed(function() {
      let businessStrengthValue = 0
      businessStrengthValue =
        ((store.state.requiredForm.sourceIncome.totalNetIncome/totalDebtService()) * .6)
        + (store.state.requiredForm.bizInfo.yrsInBusiness * .15)
        + (store.state.requiredForm.ownerInfo.creditScore * .25)

      return businessStrengthValue.toFixed(1);
    });



    const debitCoverageRatio = computed(function() {
      let dcrValue = store.state.requiredForm.sourceIncome.totalNetIncome / totalDebtService();
      /*
      Red < 1.15
      ﻿Yellow 1.15 - 1.24
      ﻿Green 1.25  >
      */
      let dcr = "";
      if (dcrValue <= 1.15 ) {
        dcr = 'alert-danger'
      } else if (dcrValue >= 1.15 && dcrValue <= 1.24) {
        dcr = 'alert-warning'
      } else if (dcrValue >= 1.25) {
        dcr = 'alert-success'
      }

      return dcr
    });

    const businessStrength = computed(function() {
      let color = '';
      if (businessStrengthRating <= 155.999) {
        color = 'alert-secondary text-red';
      } else if (businessStrengthRating <= 181.194) {
        color = 'alert-secondary  text-yellow';
      } else  {
        color = 'alert-secondary text-green';
      }

      return color
    });

    const businessStrengthName = computed(function() {
      let name = '';
      if (businessStrengthRating <= 155.999) {
        name = 'Low ';
      } else if (businessStrengthRating <= 181.194) {
        name = 'Acceptable ';
      } else  {
        name = 'Outstanding ';
      }

      return name
    });


    return {
      creditScore,
      yearsInBusiness,
      debitCoverageRatio,
      businessStrength,
      totalDebtService,
      businessStrengthRating,
      businessStrengthName,
      store,
      rawHtml3,
      rawHtml2,
      rawHtml,
      handleSubmitIncome,
      handleSubmitBalance,
      handleSubmitCash
    }
  }
}
</script>
