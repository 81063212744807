<template>
<div class="flex flex-wrap">
  <div class="grid grid-cols-12 gap-6 p-10">
    <div class="col-span-12">
      <h1 class="text-lg font-medium truncate"> Report your Stocks and Bonds </h1>
      <small class="text-theme-25 intro-y">You can report your stocks and bonds by updating your information in appropperiate colums. </small>
    </div>
  </div>
</div>
<div class="col-span-6">




</div>
<div class="flex flex-wrap">
  <div class="w-full">
    <a class="btn btn-primary mb-4  text-blue-600 hover:text-blue-800 visited:text-purple-600" href="javascript:;" data-toggle="modal" data-target="#exampleModalLabel" @click="setClearValuesFromRow()">
      <PlusIcon class="w-4 h-4 mr-1" /> New Stocks and Bonds
    </a>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded" style="background-color: #fafafa;">
      <div class="px-12 py-12 flex-auto">
        <div class="tab-content tab-space">
          <div v-bind:class="{'hidden': countStocksBonds !== 0 }">
            <div class="text-center">
              No Stocks and Bonds Records
            </div>
          </div>
          <div v-bind:class="{'hidden': countStocksBonds === 0}">
            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
              <table class="table table-report -mt-2">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap">Security</th>
                    <th class="text-center whitespace-nowrap">Updated Date</th>
                    <th class="text-center whitespace-nowrap">Total Market Value</th>
                    <th class="text-center whitespace-nowrap">Action</th>
                    <!--    <th class="text-center whitespace-nowrap">ACTIONS</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(values , index) in this.$store.state.financials.stocksbonds" class="intro-x">
                    <td class="w-40 uppercase">
                      {{ values.security }}
                    </td>
                    <td class="w-40 text-center">
                      {{values.updatedDate}}
                    </td>
                    <td class="w-40 text-center">
                      ${{values.totalMarketValue}}
                    </td>
                    <td class="w-40 text-center">
                      <a href="javascript:;" data-toggle="modal" data-target="#exampleModalLabel" @click="setValuesFromRow(index)">
                        <EditIcon class="w-4 h-4 mr-1" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- END: Pagination -->
<div id="exampleModalLabel" class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto" tabindex="-1" aria-hidden="true" @click.self="resetDeleteID()">
  <div class="modal-dialog modal-fullscreen relative w-auto pointer-events-none" style="width: 60%;">
    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 class="text-xl font-medium leading-normal text-gray-800">Edit Stocks and Bonds</h5>
        <button type="button" class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-dismiss="modal"
          aria-label="Close">X</button>
      </div>
      <div class="modal-body p-0">
        <div class="
                    px-5
                    sm:px-20
                    mt-1
                    pt-1
                    dark:border-dark-5
                  ">
          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-12 sm:col-span-6">
              <label for="input-wizard-9" class="form-label">Name Security</label>
              <input id="input-wizard-9" type="text" class="form-control" v-model="security" />
              <template v-if="errorValidation.security !== ''">
                <small class="text-theme-21 mt-2">
                  {{errorValidation.security}} <br>
                </small>
              </template>
            </div>
          </div>



          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-4 sm:col-span-3">
            <div id="input-group-datepicker">
              <label for="input-wizard-9444" class="form-label">Exchange Date</label>
              <div class="relative">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                >
                  <CalendarIcon class="w-4 h-4" />
                </div>
                <Litepicker
                  id="input-wizard-9444"
                  v-model="exchangeDate"
                  :options="{
                    format: 'MM-DD-YYYY',
                    autoApply: true,
                    showWeekNumbers: false,
                    dropdowns: {
                      minYear: 1930,
                      maxYear: null,
                      months: true,
                      years: true
                    }
                  }"
                  class="form-control pl-12"
                />
              </div>
            </div>
         </div>

            <div class="intro-y col-span-8 sm:col-span-3">
              <label for="input-wizard-92" class="form-label">Number of Shares</label>
             <input id="input-wizard-92" type="number" class="form-control" v-model="numberShares"  />
            </div>

          </div>


          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-3 sm:col-span-6">
              <label for="input-wizard-93" class="form-label">Purchase Price Per Share</label>
               <CurrencyInput id="input-wizard-93" type="text" class="form-control" v-model="purchasePricePerShare" :options="opt" />
            </div>
            <div class="intro-y col-span-3 sm:col-span-6">
              <label for="input-wizard-94" class="form-label">Total Purchase Price</label>
              <CurrencyInput  id="input-wizard-94" type="text" class="form-control" v-model="totalPurchasePrice" :options="opt" />
            </div>
          </div>

          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-6 sm:col-span-6">
              <label for="input-wizard-93" class="form-label">Market Value Per Share</label>
               <CurrencyInput id="input-wizard-93" type="text" class="form-control" v-model="marketValuePerShare" :options="opt" />
            </div>
            <div class="intro-y col-span-6 sm:col-span-6">
              <label for="input-wizard-94" class="form-label">Total Market Value</label>
              <CurrencyInput  id="input-wizard-94" type="text" class="form-control"  v-model="totalMarketValue"  :options="opt" />
            </div>
          </div>

          <div class="text-right mt-10">
            <button class="btn btn-outline-secondary w-24 mr-1" data-dismiss="modal" aria-label="Cancel">
              Cancel
            </button>
            <button class="btn btn-primary w-24 mr-1" data-dismiss="modal" aria-label="Save" @click="saveValues(index)">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  defineComponent,
  computed
} from 'vue'
import {
  useStore
} from '@/store'


import {
  object,
  string,
  number,
  bool
} from "yup";
import * as yup from 'yup';
import {
  mask
} from 'vue-the-mask';

import CurrencyInput from "@/components/currency-input/Main.vue";



const dateRegExp =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/


const notesPayableSchema = object().shape({
  accountTitle: string().required('Account Title is required'),
  address1: string().required('Address name is a required field'),
  city: string().required('City is a required field'),
  state: string().required('State is a required field'),
  zipcode: string().required('Zipcode is a required field').matches(/^[0-9]+$/, "Must be only digits").min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
});

export default {
  name: "stocks-bonds",
    directives: { mask },
    components: {
     CurrencyInput,
   },
  data() {

    let errorValidation = {
      accountTitle: '',
      address1: '',
      city: '',
      state: '',
      zipcode: ''
    };

    const opt = {currency: 'USD',currencyDisplay: 'symbol',hideCurrencySymbolOnFocus: false,  hideGroupingSeparatorOnFocus: false, precision: 0 }

    const store = useStore();
    return {
      store,
      opt,
      editModalAmount: 0,
      currentSaveIndex: -1,
      errorValidation,
      notespayable: {
        updatedDate: '',
        accountTitle: '',
        currentBalance: 0,
        originalBalance: 0,
        paymentAmount: 0,
        interestRate: 0,
        repaymentDate: 0,
        frequency: 'Daily',
        address1: '',
        address2: '',
        city: '',
        state: 'AL',
        zipcode: 0,
      },
      stocksbonds: {
        security: '',
        exchangeDate: "0",
        numberShares: 0,
        purchasePricePerShare: 0,
        totalPurchasePrice: 0,
        marketValuePerShare: 0,
        totalMarketValue: 0,
        updatedDate: '',
      }
    }
  },



  computed: {
    countStocksBonds: function() {
      return this.$store.state.financials.stocksbonds.length;
    },
    security: {
      get() {
        return this.stocksbonds.security;
      },
      set(value) {
        //this.validate('security', value)
        this.stocksbonds.security = value;
      }
    },
    exchangeDate: {
      get() {
        return this.stocksbonds.exchangeDate;
      },
      set(value) {
        this.stocksbonds.exchangeDate = value;
      }
    },
    numberShares: {
      get() {
        return this.stocksbonds.numberShares;
      },
      set(value) {
        this.stocksbonds.numberShares = value;
      }
    },
    purchasePricePerShare: {
      get() {
        return this.stocksbonds.purchasePricePerShare;
      },
      set(value) {
        this.stocksbonds.purchasePricePerShare = value;
      }
    },
    totalPurchasePrice: {
      get() {
        return this.stocksbonds.totalPurchasePrice;
      },
      set(value) {
        this.stocksbonds.totalPurchasePrice = value;
      }
    },
    marketValuePerShare: {
      get() {
        return this.stocksbonds.marketValuePerShare;
      },
      set(value) {
        this.stocksbonds.marketValuePerShare = value;
      }
    },
    totalMarketValue: {
      get() {
        return this.stocksbonds.totalMarketValue;
      },
      set(value) {
        this.stocksbonds.totalMarketValue = value;
      }
    },

  },

  methods: {

    // async validate(field, value) {
    //   let obj = {};
    //   obj[field] = value;
    //
    //   await notesPayableSchema
    //     .validateAt(field, obj).then(() => {
    //       this.errorValidation[field] = '';
    //       this.notespayable[field] = value;
    //       //this.notespayable.accountTitle = value;
    //     }).catch(err => {
    //       console.log(Object.keys(err).length);
    //       if (Object.keys(err).length !== 0) {
    //         // this.notespayable[field] = '';
    //         this.errorValidation[err.path] = err.message;
    //         console.log(err.message);
    //       }
    //       this.notespayable[field] = value;
    //     });
    // },

    setValuesFromRow: function(index) {
      this.currentSaveIndex = index;
      console.log("Setting currentSaveIndex: " + JSON.stringify(this.currentSaveIndex));
      this.stocksbonds.security = this.$store.state.financials.stocksbonds[index].security;
      this.stocksbonds.exchangeDate = this.$store.state.financials.stocksbonds[index].exchangeDate;
      this.stocksbonds.numberShares = this.$store.state.financials.stocksbonds[index].numberShares;
      this.stocksbonds.purchasePricePerShare = this.$store.state.financials.stocksbonds[index].purchasePricePerShare;
      this.stocksbonds.totalPurchasePrice = this.$store.state.financials.stocksbonds[index].totalPurchasePrice;
      this.stocksbonds.marketValuePerShare = this.$store.state.financials.stocksbonds[index].marketValuePerShare;
      this.stocksbonds.totalMarketValue = this.$store.state.financials.stocksbonds[index].totalMarketValue;
    },
    setClearValuesFromRow: function() {
      console.log("setClearValueFromRow");
      this.currentSaveIndex = -1;
      this.stocksbonds.security = "";
      this.stocksbonds.exchangeDate = "0";
      this.stocksbonds.numberShares = 0;
      this.stocksbonds.purchasePricePerShare = 0;
      this.stocksbonds.totalPurchasePrice = 0;
      this.stocksbonds.marketValuePerShare = 0;
      this.stocksbonds.totalMarketValue = 0;
    },
    resetDeleteID() {},
    getCurrentDateStr() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      return mm + '/' + dd + '/' + yyyy;
    },
    saveValues: function(index) {
      console.log("saveEditValues.currentIndex: " + this.currentSaveIndex);
      this.store.commit('financials/setStocksBonds', {
        index: this.currentSaveIndex,
        stocksbonds: {
          security: this.stocksbonds.security,
          exchangeDate: this.stocksbonds.exchangeDate,
          numberShares: this.stocksbonds.numberShares,
          purchasePricePerShare: this.stocksbonds.purchasePricePerShare,
          totalPurchasePrice: this.stocksbonds.totalPurchasePrice,
          marketValuePerShare: this.stocksbonds.marketValuePerShare,
          totalMarketValue: this.stocksbonds.totalMarketValue,
          updatedDate:this.getCurrentDateStr(),
        }
      });

      this.store.dispatch('financials/submitFirebase');
    },
  },


}
</script>