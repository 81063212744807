<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Application Submission</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20">
        <div class="intro-x lg:text-center flex items-center lg:block flex-1 z-10" v-if="processing">
          <button class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-500 dark:bg-dark-1">
            <CheckIcon class="block mx-auto" />
          </button>
          <div class="lg:w-32 font-medium text-theme-20 lg:mt-3 ml-3 lg:mx-auto">
            Application Submitted
          </div>
        </div>
        <div class="intro-x lg:text-center flex items-center lg:block flex-1 z-10" v-else>
          <button class="w-10 h-10 rounded-full btn btn-primary">
            <LoadingIcon icon="three-dots" color="white" class="w-10 h-10" />
          </button>
          <div class="lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto">
            <strong>1</strong>: Application Submitted
          </div>
        </div>
        <div class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10" v-if="processing">
          <button class="w-10 h-10 rounded-full btn btn-primary">
            <LoadingIcon icon="three-dots" color="white" class="w-10 h-10" />
          </button>
          <div class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600">
            <strong>2</strong>: Processing Application
          </div>
        </div>
        <div class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10" v-else>
          <button class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1">
            2
          </button>
          <div class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600">
            Processing Application
          </div>
        </div>
        <div class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10">
          <button class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1">
            3
          </button>
          <div class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600">
            Results
          </div>
        </div>
        <!-- <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button
            class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1"
          >
            4
          </button>
          <div
            class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
          >
            Setup Billing Account
          </div>
        </div> -->
        <!-- <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button
            class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1"
          >
            5
          </button>
          <div
            class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
          >
            Finalize your purchase
          </div>
        </div> -->
        <div
          class="wizard__line hidden lg:block w-2/3 bg-gray-200 dark:bg-dark-1 absolute mt-5"
        ></div>
      </div>
      <div
        class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base"><strong><i>Application Status</i></strong></div>
          <div class="alert alert-primary-soft show mb-2" role="alert" v-if="processing">
            <div class="flex items-center">
                  <div class="font-medium text-lg">
                    <AlertCircleIcon class="w-6 h-6 mr-2" /> Your application is being processed by the bank!
                  </div>
                  <div
                    class="ml-auto"
                  >
                    <LoadingIcon icon="three-dots" color="1a202c" class="w-4 h-4" />
                  </div>
                </div>
                <div class="mt-3">
                  <p>The bank is now processing your application.</p>
                  <p>We'll notify you when the bank has finished processing your application and has made a decision.</p>
                </div>
          </div>
        <div class="alert alert-primary-soft show mb-2" role="alert" v-else>
            <div class="flex items-center">
                  <div class="font-medium text-lg">
                    <AlertCircleIcon class="w-6 h-6 mr-2" /> Your application has been submitted to the bank!
                  </div>
                  <div
                    class="ml-auto"
                  >
                    <LoadingIcon icon="three-dots" color="1a202c" class="w-4 h-4" />
                  </div>
                </div>
                <div class="mt-3">
                  <p>Your application has been submitted successfully and the bank will begin processing it at their earliest convenience.</p>
                  <p>We'll notify you when the bank begins processing your application.</p>
                </div>
          </div>
      </div>
      <div class="intro-y flex text-xs sm:text-sm flex-col sm:flex-row items-center mt-5 pt-5 border-t border-gray-200 dark:border-dark-5" v-if="processing" style="position: absolute; right: 0; bottom: 5%; margin-right: 10px;">
              <div class="flex items-center">
                <!-- <div class="w-12 h-12 flex-none image-fit">
              <img
              alt="Icewall Tailwind HTML Admin Template"
              class="rounded-full"
              :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
              />
              </div>
              <div class="ml-3 mr-auto">
                <a href="" class="font-medium">{{ $f()[0].users[0].name }}</a>, Author
                <div class="text-gray-600">Senior Frontend Engineer</div>
              </div> -->
              </div>
              <div class="flex items-center text-gray-700 dark:text-gray-600 sm:ml-auto mt-10 sm:mt-0">
                Go to Complete (ctrl+click for Denied Application):
                <Tippy
                  tag="a"
                  class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
                  content="Next: Application Results"
                  @click="handleApplicationSuccess"
                  @click.ctrl.exact="handleApplicationFailure"
                >
                  <ArrowRightIcon class="w-4 h-4 fill-current" />
                </Tippy>
              </div>
        </div>
      <div class="intro-y flex text-xs sm:text-sm flex-col sm:flex-row items-center mt-5 pt-5 border-t border-gray-200 dark:border-dark-5" v-else style="position: absolute; right: 0; bottom: 5%; margin-right: 10px;">
              <div class="flex items-center">
                <!-- <div class="w-12 h-12 flex-none image-fit">
              <img
              alt="Icewall Tailwind HTML Admin Template"
              class="rounded-full"
              :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
              />
              </div>
              <div class="ml-3 mr-auto">
                <a href="" class="font-medium">{{ $f()[0].users[0].name }}</a>, Author
                <div class="text-gray-600">Senior Frontend Engineer</div>
              </div> -->
              </div>
              <div class="flex items-center text-gray-700 dark:text-gray-600 sm:ml-auto mt-10 sm:mt-0">
                Go to Processing Application:
                <Tippy
                  tag="a"
                  class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
                  content="Next: Processing Application"
                  @click="handleNext"
                >
                  <ArrowRightIcon class="w-4 h-4 fill-current" />
                </Tippy>
              </div>
        </div>

        <div
          class="flex items-center text-gray-700 dark:text-gray-600 sm:ml-auto mt-5 sm:mt-0"
          v-if="processing"
          style="position: absolute; left: 0; bottom: 5%; margin-left: 10px;"
        >
          <Tippy
            tag="a"
            class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
            content="Back to Application Submitted"
            @click="handleBack"
          >
            <ArrowLeftIcon class="w-4 h-4 fill-current" />
          </Tippy>
          &nbsp;Back to Application Submitted
        </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref } from 'vue';

export default {
  setup() {
    const router = useRouter();
    const processing = ref(null);

    const handleApplicationSuccess = () => {
      router.push({ name: 'side-menu-application-success' });
    }

    const handleApplicationFailure = () => {
      router.push({ name: 'side-menu-application-failure' });
    }

    const handleNext = () => {
      processing.value = true;
    }

    const handleBack = () => {
      processing.value = false;
    }


    return { handleNext, handleBack, handleApplicationSuccess, handleApplicationFailure, processing };
  },
}
</script>
