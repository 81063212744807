import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCLhr9Qjw1z5BpMTmCRFzHdlWT70Yk6LpI",
  authDomain: "udemy-vue-firebase-site-f35dc.firebaseapp.com",
  projectId: "udemy-vue-firebase-site-f35dc",
  storageBucket: "udemy-vue-firebase-site-f35dc.appspot.com",
  messagingSenderId: "971111492642",
  appId: "1:971111492642:web:1fdf720fb8552d5f4bee7b"
};

firebase.initializeApp(firebaseConfig);


const projectAuth = firebase.auth();
// projectAuth.useEmulator("http://localhost:9099");



const projectFirestore = firebase.firestore();
// if (location.hostname === "localhost") {
//   projectFirestore.useEmulator("localhost", 8080);
// }


projectAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
        console.log("Changed the Session Persistence to Session! You'll have to log in again if you leave this tab/window!");
    })

const projectStorage = firebase.storage();

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, projectAuth, projectStorage, timestamp, firebaseConfig };