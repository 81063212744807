<template>
  <Suspense>
    <template #default>
      <Profile />
    </template>

    <template #fallback>
      <div
        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
      >
        <LoadingIcon icon="grid" class="w-8 h-8" />
      </div>
    </template>
  </Suspense>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount } from 'vue';
import Profile from './Profile.vue';

export default ({
  components: {
    Profile
  },
  setup() {}
})
</script>
