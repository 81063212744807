import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'



const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('cash-flow');


const state = () => {
  // TODO: must deal with date input type

  return {
      netEarnings: 0,
      depreciations: 0,
      decreaseInAccountsReceivable: 0,
      increaseInAccountsPayable: 0,
      increaseInTaxesPayable: 0,
      increaseInInventory: 0,
      netCashFromOperations: 0,
      equipment: 0,
      notesPayable: 0,
      cashFlowForPeriod: 0,
    }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    const {
      user
    } = getUser();
    const application = await addDocWithGenId(this.state.cashFlow, user._value.email);
  }
}



// mutations
const mutations = {
  setNetEarnings(state, value) {
    this.state.cashFlow.netEarnings = value;
  },

  setDepreciations(state, value) {
    this.state.cashFlow.depreciations = value;
  },


  setDecreaseInAccountsReceivable(state, value) {
    this.state.cashFlow.decreaseInAccountsReceivable = value;
  },

  setIncreaseInAccountsPayable(state, value) {
    this.state.cashFlow.increaseInAccountsPayable = value;
  },

  setIncreaseInTaxesPayable(state, value) {
    this.state.cashFlow.increaseInTaxesPayable = value;
  },

  setIncreaseInInventory(state, value) {
    this.state.cashFlow.increaseInInventory = value;
  },

  setNetCashFromOperations(state, value) {
    this.state.cashFlow.netCashFromOperations = value;
  },

  setEquipment(state, value) {
    this.state.cashFlow.equipment = value;
  },

  setCNotesPayable(state, value) {
    this.state.cashFlow.notesPayable = value;
  },

  setCashFlowForPeriod(state, value) {
    this.state.cashFlow.cashFlowForPeriod = value;
  },


  setCashFlowObj(state, value) {
    this.state.cashFlow = value;
  }



}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
