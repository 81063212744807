<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar -mx-4 px-4 md:mx-0 md:px-0">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">

    </div>
    <!-- END: Breadcrumb -->

    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="false"
      >
        <img
          alt="Tinker Tailwind HTML Admin Template"
          :src="require(`@/assets/images/${$f()[9].photos[0]}`)"
        />
      </div>
      <div class="dropdown-menu w-56">
        <div class="dropdown-menu__content box dark:bg-dark-6">
          <div
            class="p-4 border-b border-black border-opacity-5 dark:border-dark-3"
          >
            <div class="font-medium">{{ user.displayName }}</div>
            <div class="text-xs text-theme-25" v-if="user.admin">
              Admin
            </div>
            <div class="text-xs text-gray-600 mt-0.5 dark:text-gray-600" v-else>
              Standard
            </div>
          </div>
          <div class="p-2">
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md"
              @click.prevent="handleProfileClick"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Profile
            </a>
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md"
              @click.prevent="handleBlogsClick"
            >
              <LayersIcon class="w-4 h-4 mr-2" /> Blogs
            </a>
          </div>
          <div
            class="p-2 border-t border-black border-opacity-5 dark:border-dark-3"
          >
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md"
              @click.prevent="handleLogout"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import useLogout from '@/composables/useLogout';
import getUser from '@/composables/getUser';

export default defineComponent({
  setup() {
    const { logout, error, isPending } = useLogout();
    const router = useRouter();

    const { user } = getUser();
    const admin = user.value.admin;

    const handleLogout = async () => {
      await logout();
      console.log('User logged out!');
      router.go(0);
    }

    const handleProfileClick = async () => {
      router.push({ name: 'user-profile' });
    }

    const handleBlogsClick = async () => {
      router.push({ name: 'blog' });
    }

    return {
      handleLogout,
      handleProfileClick,
      handleBlogsClick,
      user
    }
  }
})
</script>
