<template>
  <select v-model="selectedOption">
    <option v-for="option in options" :key="option" :value="option">{{ option }}</option>
  </select>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: ['options', 'selectedOption'],

  setup(props, context) {
    const selectedOption = ref(props.selectedOption);
    const options = ref(props.options);

    watch(selectedOption, (newVal) => {
      if (newVal !== props.selectedOption) {
        context.emit('language-selected', newVal);
      }
    });

    return {
      selectedOption,
      options,
    };
  },
};
</script>