<template>
  <div class="flex flex-wrap">
      <div class="grid grid-cols-12 gap-6 p-10">
          <div class="col-span-6">
              <h1 class="text-lg font-medium truncate"> Report your Notes Payable Balance</h1>
              <small class="text-theme-25 intro-y">You can report your bills to be paid by creating new or by updating your information in appropperiate columns.</small>
          </div>
      </div>
  </div>

  <div
    class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    "
  >

    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-12 sm:col-span-12">
        <label for="input-wizard-1" class="form-label">Title Account*</label
        ><input
          id="input-wizard-1"
          type="text"
          class="form-control"
          placeholder="Company Name"
          v-model="companyName"
        />
      </div>

      <div class="intro-y col-span-2 sm:col-span-2">
        <label for="input-wizard-3" class="form-label">Current Balance</label
        ><input
          id="input-wizard-3"
          type="text"
          class="form-control"
          placeholder="DBA Name"
          v-model="dbaName"
        />
      </div>


    <div class="intro-y col-span-2 sm:col-span-2">
      <label for="input-wizard-5" class="form-label">Original Balance*</label
      ><input
        id="input-wizard-5"
        type="text"
        class="form-control"
        placeholder="1 Main St"
        v-model="address1"
      />
    </div>
    <div class="intro-y col-span-2 sm:col-span-2">
      <label for="input-wizard-6" class="form-label">Payment Amount</label
      ><input
        id="input-wizard-6"
        type="text"
        class="form-control"
        placeholder="Suite 100 Bld 200"
        v-model="address2"
      />
    </div>
    <div class="intro-y col-span-2 sm:col-span-2">
      <label for="input-wizard-7" class="form-label">Frequence</label
      ><input
        id="input-wizard-7"
        type="text"
        class="form-control"
        placeholder="City"
        v-model="city"
      />
    </div>
    <div class="intro-y col-span-12 sm:col-span-3">
      <label for="input-wizard-8" class="form-label">Repayment Date</label
      ><input
        id="input-wizard-8"
        type="text"
        class="form-control"
        placeholder="State"
        v-model="state"
      />
    </div>

    <div class="intro-y col-span-12 sm:col-span-12">
      <label for="input-wizard-5" class="form-label">Address Line 1*</label
      ><input
        id="input-wizard-5"
        type="text"
        class="form-control"
        placeholder="1 Main St"
        v-model="address1"
      />
    </div>
    <div class="intro-y col-span-12 sm:col-span-12">
      <label for="input-wizard-6" class="form-label">Address Line 2</label
      ><input
        id="input-wizard-6"
        type="text"
        class="form-control"
        placeholder="Suite 100 Bld 200"
        v-model="address2"
      />
    </div>
    <div class="intro-y col-span-12 sm:col-span-7">
      <label for="input-wizard-7" class="form-label">City</label
      ><input
        id="input-wizard-7"
        type="text"
        class="form-control"
        placeholder="City"
        v-model="city"
      />
    </div>
    <div class="intro-y col-span-12 sm:col-span-3">
      <label for="input-wizard-8" class="form-label">State</label
      ><input
        id="input-wizard-8"
        type="text"
        class="form-control"
        placeholder="State"
        v-model="state"
      />
    </div>
  <div class="intro-y col-span-12 sm:col-span-2">
    <label for="input-wizard-9" class="form-label">Zip Code</label
    ><input
      id="input-wizard-9"
      type="text"
      class="form-control"
      placeholder="Zip Code"
      v-model="zipcode"
    />
  </div>



  </div>

  <div class="text-right mt-5">
    <button type="button" class="btn btn-outline-secondary w-24 mr-1" @click="handleCancel">
      Cancel
    </button>
    <button type="button" class="btn btn-primary w-24 mr-1" @click="handleSaveEdit">
      Save
    </button>

  </div>
    </div>

</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router';
import Toastify from 'toastify-js';

export default {
    data() {
      const store = useStore();


      return {
        store
      }

    },
    computed: {
       companyName: {
         get () { return this.$store.state.requiredForm.bizInfo.companyName; },
         set(value) { this.$store.commit('requiredForm/setCompanyName', value); }
       },
       dbaName: {
         get () { return this.$store.state.requiredForm.bizInfo.dbaName; },
         set(value) { this.$store.commit('requiredForm/setDbaName', value) }
       },
       address1: {
         get () { return this.$store.state.requiredForm.bizInfo.address1; },
         set(value) { this.$store.commit('requiredForm/setAddress1', value); }
       },
       address2: {
         get () { return this.$store.state.requiredForm.bizInfo.address2; },
         set(value) { this.$store.commit('requiredForm/setAddress2', value) }
       },
       city: {
         get () { return this.$store.state.requiredForm.bizInfo.city; },
         set(value) { this.$store.commit('requiredForm/setCity', value); }
       },
       state: {
         get () { return this.$store.state.requiredForm.bizInfo.state; },
         set(value) { this.$store.commit('requiredForm/setState', value) }
       },
       zipcode: {
         get () { return this.$store.state.requiredForm.bizInfo.zipcode; },
         set(value) { this.$store.commit('requiredForm/setZipecode', value) }
       },
       taxId: {
         get () { return this.$store.state.requiredForm.bizInfo.taxId; },
         set(value) { this.$store.commit('requiredForm/setTaxId', value); }
       },
       duns: {
         get () { return this.$store.state.requiredForm.bizInfo.duns; },
         set(value) { this.$store.commit('requiredForm/setDuns', value) }
       },
       businessContactNumber: {
         get () { return this.$store.state.requiredForm.bizInfo.businessContactNumber; },
         set(value) { this.$store.commit('requiredForm/setBusinessContactNumber', value) }
       },
       email: {
         get () { return this.$store.state.requiredForm.bizInfo.email; },
         set(value) { this.$store.commit('requiredForm/setEmail', value) }
       },
       website: {
         get () { return this.$store.state.requiredForm.bizInfo.website; },
         set(value) { this.$store.commit('requiredForm/setWebsite', value) }
       },
       entityType: {
         get () { return this.$store.state.requiredForm.bizInfo.entityType; },
         set(value) { this.$store.commit('requiredForm/setEntityType', value) }
       }
     }
}

</script>

<style>
</style>
