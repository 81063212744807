const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'portal-page',
        title: 'Dashboard'
      },
      // 'devider',
      // {
      //   icon: 'ThumbsUpIcon',
      //   pageName: 'side-menu-eight-a-evaluation2',
      //   title: 'Fitness'
      // },
      // 'devider',
      // {
      //   icon: 'FileTextIcon',
      //   pageName: 'side-menu-eight-a-evaluation',
      //   title: '8(a) Evaluation'
      // },
      'devider',
      {
        icon: 'FileTextIcon',
        pageName: 'side-menu-required-forms',
        title: 'Required Forms'
      },
      'devider',
      {
        icon: 'PieChartIcon',
        pageName: 'financial-statement',
        title: 'Financial',
        subMenu: [
          {
            icon: 'TagIcon',
            pageName: 'assets',
            title: 'Assets'
          },
          {
            icon: 'CreditCardIcon',
            pageName: 'liabilities',
            title: 'Liabilities'
          },
          {
            icon: 'FileIcon',
            pageName: 'notes-payable',
            title: 'Notes Payable'
          },
          // {
          //   icon: 'PieChartIcon',
          //   pageName: 'stocks-bonds',
          //   title: 'Stocks Bonds'
          // },
          // {
          //   icon: 'PenToolIcon',
          //   pageName: 'real-estate',
          //   title: 'Real Estate'
          // }

        ]
      },
      'devider',
      {
        icon: 'HardDriveIcon',
        pageName: 'side-menu-file-manager',
        title: 'File Manager'
      },
      // 'devider',
      // {
      //   icon: 'PieChartIcon',
      //   pageName: 'performance',
      //   title: 'Performance',
      //   subMenu: [
      //     {
      //       icon: 'TagIcon',
      //       pageName: 'income-statement',
      //       title: 'Income Statement',
      //     },
      //     {
      //       icon: 'CreditCardIcon',
      //       pageName: 'balance-sheet',
      //       title: 'Balance Sheet',
      //     },
      //     {
      //       icon: 'CreditCardIcon',
      //       pageName: 'cash-flow',
      //       title: 'Cash Flow',
      //     }
      //   ]
      // },
      'devider',
      {
        icon: 'DollarSignIcon',
        pageName: '508-loan',
        title: 'Application',
        subMenu: [
          {
            icon: 'FileIcon',
            pageName: '7a-loan',
            title: '7a Loan'
          },
          {
            icon: 'FileIcon',
            pageName: '508-loan',
            title: '504 Loan'
          },
          {
            icon: 'FileIcon',
            pageName: 'now-account',
            title: 'Account Now'
          }
          ]
      },
      'devider',
      {
        icon: 'PackageIcon',
        pageName: 'loan-packages',
        title: 'Packets'
      },
      // {
      //   icon: 'DollarSignIcon',
      //   pageName: 'your-loan-list',
      //   title: 'Your Loans',
      // },
      // 'devider',
      // {
      //   icon: 'EditIcon',
      //   pageName: 'side-menu-crud-data-list',
      //   title: 'User Management'
      // },
      // {
      //   icon: 'UsersIcon',
      //   pageName: 'member-list',
      //   title: 'Your Members'
      // }

    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}