<template>
  <d-stepper :steps="steps" @confirm="submitChanges" @nextStepChange="nextStepChangeUpdate" >
    <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
  </d-stepper>

  <div
    id="success-notification-content_toast"
    class="toastify-content hidden flex"
  >
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">CONGRATULATION - Required Forms Completed</div>
      <div class="text-gray-600 mt-1">
        You have successfully completed the required form for Archymedes.
      </div>
    </div>
    </div>
</template>


<script>
import { reactive, shallowRef,  ref, computed } from 'vue'
import DStepper from "../../components/stepper/Step"
import Review from "./Steps/Review"
import OwnerInformation from "./Steps/OwnerInformation"
import BusinessInformation from "./Steps/BusinessInformation"
import SourceIncome from "./Steps/SourceIncome"
import ContingentLiabilities from "./Steps/ContingentLiabilities"
import { useStore } from '@/store'
import { useRouter } from 'vue-router';
import Toastify from 'toastify-js';



export default {
  name: "FormStepper",
  components: {
    DStepper
  },
  methods: {
    nextStepChangeUpdate(){
      console.log("nextStepChangeUpdate");
      this.$store.dispatch('requiredForm/submitFirebase');
    },
     submitChanges () {
       this.$store.commit('requiredForm/setLastUpdated', new Date());
       this.$store.commit('requiredForm/setUpdatedRecently', true);
       this.$store.commit('requiredForm/setWatsonBusinessStrength', 'Pending');
       this.$store.dispatch('requiredForm/submitFirebase');
       this.$store.dispatch('requiredForm/fetchItems');

        Toastify({
          node: cash('#success-notification-content_toast')
            .clone()
            .removeClass('hidden')[0],
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();

        this.$router.push('portal');
       //console.log('dispatching to vuex to save in firebse:' + JSON.stringify(this.$store));
     }
   },
  data() {
    let a = 0;
    const store = useStore();
    const router = useRouter();
    const stepEn = reactive([
      {
        name: "Owner Information",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(OwnerInformation)
      },
      {
        name: "Business Information",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(BusinessInformation)
      },
      {
        name: "Source of Income ",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(SourceIncome)
      },
      {
        name: " Contingent Liabilities",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(ContingentLiabilities)
      },
      {
        name: "Review",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(Review),
        confirm:"Submit"
      }
      ]);


    const stepEs = reactive([
      {
        name: "Información del propietario",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(OwnerInformation)
      },
      {
        name: "Información de la empresa",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(BusinessInformation)
      },
      {
        name: "stepEs Source of Income ",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(SourceIncome)
      },
      {
        name: "Fuente de ingresos",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(ContingentLiabilities)
      },
      {
        name: "Revisión",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(Review),
        confirm:"Submit"
      }
    ])

    const steps = this.$i18n.locale === 'en' ? stepEn : stepEs;


    return {
      store,
      steps,
      watsonAssistantChatOptions: {
        integrationID: "b90cc680-c495-455c-ae9a-58657d022100", // The ID of this integration.
        region: "us-east", // The region your integration is hosted in.
        serviceInstanceID: "c8599dda-cef1-4bc0-a270-161a8a53dfd2", // The ID of your service instance.
        onLoad: async (instance) => { await instance.render(); }
      }
    }
  },
  mounted() {
    window.watsonAssistantChatOptions = this.watsonAssistantChatOptions;
    setTimeout(() => {
      const t = document.createElement('script');
      t.src = "https://web-chat.global.assistant.watson.appdomain.cloud/versions/" + (window.watsonAssistantChatOptions.clientVersion || 'latest') + "/WatsonAssistantChatEntry.js";
      document.head.appendChild(t);
    }, 0);
  }
};

</script>