<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Your Assessment</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-5 sm:py-20 mt-5">
      <!-- <div class="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20"> -->
        <!-- <div
          class="intro-x lg:text-center flex items-center lg:block flex-1 z-10"
        >
          <button class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-500 dark:bg-dark-1">
            <CheckIcon class="block mx-auto" />
          </button>
          <div class="lg:w-32 font-medium text-theme-20 lg:mt-3 ml-3 lg:mx-auto">
            Assessment Submitted
          </div>
        </div>
        <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-500 dark:bg-dark-1">
            <CheckIcon class="block mx-auto" />
          </button>
          <div class="lg:w-32 font-medium text-theme-20 lg:mt-3 ml-3 lg:mx-auto">
            Processing Assessment
          </div>
        </div>
        <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button class="w-10 h-10 rounded-full btn btn-success">
            <CheckIcon class="block mx-auto" />
          </button>
          <div class="lg:w-32 font-medium text-theme-20 lg:mt-3 ml-3 lg:mx-auto">
            <strong>Complete</strong>
          </div>
        </div> -->
        <!-- <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button
            class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1"
          >
            4
          </button>
          <div
            class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
          >
            Setup Billing Account
          </div>
        </div> -->
        <!-- <div
          class="intro-x lg:text-center flex items-center mt-5 lg:mt-0 lg:block flex-1 z-10"
        >
          <button
            class="w-10 h-10 rounded-full btn text-gray-600 bg-gray-200 dark:bg-dark-1"
          >
            5
          </button>
          <div
            class="lg:w-32 text-base lg:mt-3 ml-3 lg:mx-auto text-gray-700 dark:text-gray-600"
          >
            Finalize your purchase
          </div>
        </div> -->
        <!-- <div
          class="wizard__line hidden lg:block w-2/3 bg-gray-200 dark:bg-dark-1 absolute mt-5"
        ></div> -->
      <!-- </div> -->
      <div class="px-5 sm:px-20">
        <div class="font-medium text-base"><strong><i>Assessment Results</i></strong></div>
        <!-- <div class="alert alert-success-soft show mb-2" role="alert">
            <div class="flex items-center">
                  <div class="font-medium text-lg">
                    <CheckCircleIcon class="w-6 h-6 mr-2" /> We've finished processing your assessment!
                  </div>
                  <div
                    class="ml-auto"
                  >
                    <CheckIcon class="block mx-auto" />
                  </div>
                </div>
                <div class="mt-3">
                  Check your results below!
                </div>
        </div> -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
          <div class="intro-y box col-span-12 sm:col-span-6">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto">Your chance of approval is <strong class="text-theme-20">Excellent</strong>!</h2>
            </div>
            <div id="donut-chart" class="p-5">
              <div class="preview">
                <div class="alert alert-success-soft show mb-2" role="alert">
                  <div class="flex items-center">
                  <div class="font-medium text-lg">
                    <CheckCircleIcon class="w-6 h-6 mr-2" /> You're good to go!
                  </div>
                  <div
                    class="ml-auto"
                  >
                    <CheckIcon class="block mx-auto" />
                  </div>
                </div>
                <div class="mt-3">
                  After reviewing your information, we believe you're ready to submit your application to the bank!
                </div>
            </div>
              </div>
              <!-- <div class="source-code hidden">
              <button
                data-target="#copy-donut-chart"
                class="copy-code btn py-1 px-2 btn-outline-secondary"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto mt-3 rounded-md">
                <Highlight id="copy-donut-chart" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <DonutChart :height="200" />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div> -->
            </div>
          </div>
          <div class="intro-y box col-span- sm:col-span-6" style="position: relative;">
            <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto"><strong>Next Steps</strong></h2>
            </div>
            <div class="intro-y flex text-xs sm:text-sm flex-col sm:flex-row items-center mt-5 pt-5" style="position: absolute; right: 0; bottom: 5%; margin-right: 10px;">
              <div class="flex items-center">
                <!-- <div class="w-12 h-12 flex-none image-fit">
              <img
              alt="Icewall Tailwind HTML Admin Template"
              class="rounded-full"
              :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
              />
              </div>
              <div class="ml-3 mr-auto">
                <a href="" class="font-medium">{{ $f()[0].users[0].name }}</a>, Author
                <div class="text-gray-600">Senior Frontend Engineer</div>
              </div> -->
              </div>
              <div class="flex items-center text-gray-700 dark:text-gray-600 sm:ml-auto mt-10 sm:mt-0">
                <Tippy
                  tag="button"
                  class="btn btn-success mr-2 ml-2 mb-2 zoom-in"
                  content="View Application"
                  @click="handleSubmit"
                >
                  <FileTextIcon class="w-4 h-4 fill-current" />&nbsp;View your application
                </Tippy>
              </div>
            </div>
          </div>
          <div
            class="flex items-center text-gray-700 dark:text-gray-600 sm:ml-auto mt-5 sm:mt-0"
            style="position: absolute; left: 0; bottom: 5%; margin-left: 10px;"
          >
            <Tippy
              tag="a"
              class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
              content="Back to Application"
              @click="handleBackSection3"
            >
              <ArrowLeftIcon class="w-4 h-4 fill-current" />
            </Tippy>
            &nbsp;Back to Section 3
          </div>
        </div>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import useDocument from '@/composables/useDocument';
import { pdfWebServices } from '@/composables/pdfWebServices';
import getDocument from '@/composables/getDocument';

export default ({
  props: ['appID'],
  setup(props) {
    const router = useRouter();
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)


    const data = computed(() => {
      return {
        datasets: [
          {
            data: [20, 80],
            backgroundColor: ['#71797e', '#2dc937'],
            borderWidth: 0,
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    });



    const options = computed(() => {
      return {
        cutoutPercentage: 80,
        tooltips: {enabled: false},
        hover: {mode: null},
      }
    });

    const {
      error: getDocumentError,
      getDocumentByID,
      document: application
    } = getDocument('applications', props.appID)



    const {
      error: useDocumentError,
      updateDoc,
      isPending
    } = useDocument('applications', props.appID)

    const handleSubmit = async () => {
      // router.push({ name: 'view-loan-application' });
      // Display popup of a pdf after calling PDFWebServices
      console.log(0);

      const response = await pdfWebServices();
      const content = await response.blob();

        console.log("content size: " + content.size);
        let x = URL.createObjectURL(content);
        console.log(x);
        let html = '<!doctype html><html><head><title>BOL PDF</title></head>' +
          '<body>' +
          '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
          x +
          '"></iframe>' +
          '</style></body></html>';

        let popup = window.open("", "_blank");



        popup.document.write(html);

        console.log(1);

        console.log(2);






        // const blobUrl = new Blob(dataInfo,  { type: 'application/pdf;charset-UTF-8' }) ;
        // let blob = new Blob(dataInfo.data, {
        //   type: 'application/pdf;charset-UTF-8'
        // });

        // console.log(blob);
        // var getFile = new Blob(dataInfo, { type: data.type });


        // let x = URL.createObjectURL(blobUrl);






    }

    const handleBackSection3 = () => {
      router.push({ name: 'side-menu-7a-section-3' });
    }

    return {
      data,
      options,
      handleSubmit,
      handleBackSection3
    }
  }
})
</script>
