<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ pageTitle }}</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
     <div class="flex flex-col sm:flex-row sm:items-end xl:items-start" v-show="showSearch">
        <!-- <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto" >
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Field</label>
            <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto">
              <option value="started">Started</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Type</label>
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like">like</option>
              <option value="=" selected>=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Value</label>
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </form>
-->

        <div class="flex mt-5 sm:mt-0" >
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportHtml"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

  <div id="modal-finance" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body p-10 text-center">
          <div class="box p-5 rounded-md">
            <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
              <div class="font-medium text-base truncate">Company Details</div>
            </div>
            <VerticalBarChart :dataInfo=dataChart :height="200" />
          </div>
      </div>
    </div>
  </div>
  </div>
  <div id="superlarge-modal-size-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body p-10 text-center">
          <div class="box p-5 rounded-md">
            <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
              <div class="font-medium text-base truncate">Company Details</div>
            </div>
            <div class="flex items-center"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="clipboard" data-lucide="clipboard" class="lucide lucide-clipboard w-4 h-4 text-slate-500 mr-2"><path d="M16 4h2a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg> Email: {{dialogDuns}} </div>
            <div class="flex items-center mt-3"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="user" data-lucide="user" class="lucide lucide-user w-4 h-4 text-slate-500 mr-2"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> Name: {{dialogCompanyName}}</div>
            <div class="flex items-center mt-3"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="calendar" data-lucide="calendar" class="lucide lucide-calendar w-4 h-4 text-slate-500 mr-2"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg> Phone Number: {{dialogCompanyPhone}} </div>
            <div class="flex items-center mt-3"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="map-pin" data-lucide="map-pin" class="lucide lucide-map-pin w-4 h-4 text-slate-500 mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z"></path><circle cx="12" cy="10" r="3"></circle></svg> {{dialogCompanyAddress}} </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from 'vue';
import xlsx from 'xlsx';
import feather from 'feather-icons';
import Tabulator from 'tabulator-tables';
import {useRouter} from 'vue-router';
import VerticalBarChart from '@/components/vertical-bar-chart/Main.vue'

/*
Used to create the aggregated table of summed contract awards

create table
curr_comp_ctrt_tot as
select exit_date_8_c
, a.companylegalname
, b.recipient_name
, a.entrance_date_8_b
, a.dunsnumber
, SUM(b.base_and_exercised_options_value_num) as total
 from govgenie_companies_8a_20230623 a, govgenie_awards_8a_20230623 b
where TO_DATE(exit_date_8_c,'MM-DD-YYYY')  >= now()
and a.dunsnumber = b.recipient_duns
and upper(a.companylegalname) = upper(recipient_name)
group by
exit_date_8_c
, a.companylegalname
, b.recipient_name
, a.entrance_date_8_b
, a.dunsnumber

*/


export default defineComponent({
  components: {
    VerticalBarChart
  },
  props: {
    pageTitle:{
      type: String,
      required: true
    },
    addRecordButtonProps: {
      type: Object,
      required: true
    },
    showSearch: {
      type: Boolean,
      required: true
    },
    tableData: {
      type: Array,
      required: false
    },
    organization: {
      type: String,
      required: false
    },
  },
  setup(props)  {



    const dialogDuns = ref();
    const dialogCompanyName = ref();
    const dialogCompanyPhone = ref();
    const dialogCompanyAddress = ref();

    const dataChart = ref([]);


    const doSomething = () => {
      console.log('Im batman')
    }

    console.log("data-table/main.vue");

    const router = useRouter();
    const tableRef = ref()


    const tabulator = ref()
    const filter = reactive({
      field: 'type',
      type: '=',
      value: ''
    })

    const  actionsFormatter = (e, cell) => {
      let a = cash(`<div class="flex lg:justify-center items-center">
                            <a href="javascript:;" data-toggle="modal" data-target="#modal-finance" id='${e.getRow().getData().dunsnumber}' name='${e.getRow().getData().companylegalname}' class=" financialInfo btn btn-primary mr-1 mb-2">Financial</a>
                            <a href="javascript:;" data-toggle="modal" id=${e.getRow().getData().dunsnumber} data-target="#superlarge-modal-size-preview" class=" contractInfo btn btn-success mr-1 mb-2">Contact</a>
                        </div>`);


      // console.log("sadfasdf:" + JSON.stringify(e.getRow().getData()));
      // console.log(cell);

      // console.log(rowDatax);

   //  var rowData = JSON.parse(cell.target.getAttribute("data-row"));
      //console.log(rowData);




      cash(a)
        .find(".financialInfo")
        .on("click", async function (e) {
         // console.log("start: " + e.target.getAttribute('id') + " - " + e.target.getAttribute('name'));
            dataChart.value = await fetchCompanyStatFromEndpoint(e.target.getAttribute('id'), e.target.getAttribute('name'));

        });

      cash(a)
        .find(".contractInfo")
        .on("click", async function  (e)  {
          let data = await fetchCompanyContactDataFromEndpoint(e.target.getAttribute('id'));

          dialogCompanyName.value = data[0].company_name;
          dialogDuns.value = data[0].emailaddress
          dialogCompanyPhone.value = data[0].phonenumber;
          dialogCompanyAddress.value = data[0].addressline1 + " " + data[0].city + " " +  data[0].state + " " +  data[0].zip;

        });

      return a[0];
    }


    async function fetchCompaniesDataFromEndpoint() {
      try {
        const response = await
          fetch("https://pdfgen.archymedes.com:8090/api/companies");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching data:", error);
        return []; // Return empty array if an error occurs
      }
    }

    async function fetchCompanyContactDataFromEndpoint(duns) {
      try {
        const response = await
          fetch("https://pdfgen.archymedes.com:8090/api/company-contact?dunsnumber="+duns);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching data:", error);
        return []; // Return empty array if an error occurs
      }
    }

    // https://10.1.1.30:8089/api/company-stats?dunsnumber=961829129&companyname=WEEMS%20DESIGN%20STUDIO,%20INC.

    async function fetchCompanyStatFromEndpoint(duns, companyName) {
      try {
        // console.log(`process: ${process.env.VUE_APP_API_URL}`);

        const response = await
          fetch("https://pdfgen.archymedes.com:8090/api/company-stats?dunsnumber=" + duns + "&companyname=" + companyName);
        // const response = await fetch("https://10.1.1.30:8089/api/company-stats?dunsnumber=" + duns + "&companyname=" + companyName);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching data:", error);
        return []; // Return empty array if an error occurs
      }
    }



    var columns = [{
      formatter: 'responsiveCollapse',
      width: 40,
      minWidth: 30,
      hozAlign: 'center',
      resizable: false,
      headerSort: false
    },
      {
        title: "dunsnumber",
        field: "dunsnumber",
        sorter: "string",
        width: 200
      },
      {
        title: "name",
        field: "companylegalname",
        sorter: "string",
        width: 200
      },
      {
        title: "recipient_name",
        field: "recipient_name",
        sorter: "string",
        width: 200
      },
      {
        title: "entrance_date_8_b",
        field: "entrance_date_8_b",
        sorter: "string",
        width: 200
      },
      {
        title: "total",
        field: "total",
        sorter: "string",
        width: 200
      },
      {
        title: "ACTIONS",
        field: "actions",
        formatter: actionsFormatter
      },
    ];


    var initTabulator =  async () => {
      tabulator.value = new Tabulator(tableRef.value, {
        data: await fetchCompaniesDataFromEndpoint(),
          paginationSize:10,
          paginationSizeSelector:[10, 20, 50],
          movableColumns:true,
          layout: 'fitColumns',
          responsiveLayout: 'collapse',
          placeholder: 'No matching records found', //ajax URL
          columns:columns
      })

      // console.log("onMounted main: " + JSON.stringify(await fetchCompaniesDataFromEndpoint(), null, 3));

      // const data = await fetchDataFromEndpoint();
      // let dataInfo = {last_page: 1, data: tabledata};
      // table.setData();

      // table.setData(props.tableData);

    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'type'
      filter.type = '='
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    const addNewRecord = props.addRecordButtonProps.action;


    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })

    return {
      actionsFormatter,
      tableRef,
      filter,
      router,
      onFilter,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      addNewRecord,
      doSomething,
      dialogCompanyName,
      dialogDuns,
      dialogCompanyPhone,
      dialogCompanyAddress,
      tabulator,
      dataChart
    }
  }
})
</script>
