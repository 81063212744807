<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Complete Your Profile</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="flex justify-center">
        <button 
          v-bind:class= "[page == 1 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          1
        </button>
        <button
          v-bind:class= "[page == 2 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          2
        </button>
        <button
          v-bind:class= "[page == 3 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          3
        </button>
      </div>
      <div class="px-5 mt-10">
        <div class="font-medium text-center text-lg"><strong>About You</strong></div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 1">
          Personal Information
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 2">
          Citizenship
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 3">
          Criminal History
        </div>
      </div>
      <div
        class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <!-- <div class="font-medium text-base">[Insert Title Here]</div> -->
        <div class="text-xs">
          <span class="text-theme-21">*</span> ALL fields are required
        </div>
        
        <!-- BEGIN: Page 1 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 1">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Phone Number</label>
            <input
              id="input-wizard-1"
              type="text"
              class="form-control"
              :class="{ 'border-theme-21': personalInfoValidate.phoneNumber.$error }"
              placeholder="(XXX) XXX-XXXX"
              v-model.trim="personalInfoValidate.phoneNumber.$model"
            />
            <template v-if="personalInfoValidate.phoneNumber.$error">
              <small
                v-for="(error, index) in personalInfoValidate.phoneNumber.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Current Home Address</label>
            <input
              id="input-wizard-2"
              type="text"
              class="form-control"
              :class="{ 'border-theme-21': personalInfoValidate.address.$error }"
              placeholder="1111 Placeholder Circle, Testland, Testing 00000"
              v-model.trim="personalInfoValidate.address.$model"
            />
            <template v-if="personalInfoValidate.address.$error">
              <small
                v-for="(error, index) in personalInfoValidate.address.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">SSN</label>
            <input
              id="input-wizard-3"
              type="password"
              class="form-control"
              :class="{ 'border-theme-21': personalInfoValidate.ssn.$error }"
              placeholder="XXX-XX-XXXX"
              v-model.trim="personalInfoValidate.ssn.$model"
            />
            <template v-if="personalInfoValidate.ssn.$error">
              <small
                v-for="(error, index) in personalInfoValidate.ssn.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">Personal Credit Score</label>
            <input
              id="input-wizard-3"
              type="number"
              class="form-control"
              :class="{ 'border-theme-21': personalInfoValidate.creditScore.$error }"
              placeholder="600"
              min="300"
              v-model="personalInfoValidate.creditScore.$model"
            />
            <template v-if="personalInfoValidate.creditScore.$error">
              <small
                v-for="(error, index) in personalInfoValidate.creditScore.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Date of Birth</label>
            <div id="input-group-datepicker">
              <div class="relative w-80">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                >
                  <CalendarIcon class="w-4 h-4" />
                </div>
                <Litepicker
                  v-model="personalInfoValidate.dob.$model"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1970,
                      maxYear: null,
                      months: true,
                      years: true
                    }
                  }"
                  class="form-control pl-12"
                  :class="{ 'border-theme-21': personalInfoValidate.dob.$error }"
                />
              </div>
            </div>
            <template v-if="personalInfoValidate.dob.$error">
              <small
                v-for="(error, index) in personalInfoValidate.dob.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>

          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >

            <button class="btn btn-primary w-24 ml-2" @click="handleNext" v-if="!isPending">Next</button>
            <button class="btn btn-dark mr-1 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>

            <button class="btn btn-linkedin ml-2" @click="handleSaveandQuit" v-if="!isPending">Save & Exit</button>
            <button class="btn btn-dark mr-1 ml-2 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
        <!-- END: Page 1 -->
        
        <!-- BEGIN: Page 2 -->
        <div id="radio" class="intro-y p-5" v-else-if="page == 2">
          <div class="preview">
            <div>
              <label>Are you a US Citizen?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="usCitizen-choice"
                  v-bind:value="true"
                  v-model="citizenshipValidate.usCitizen.$model"
                  :style="citizenshipValidate.usCitizen.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="usCitizen-choice"
                  v-bind:value="false"
                  v-model="citizenshipValidate.usCitizen.$model"
                  :style="citizenshipValidate.usCitizen.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
              <template v-if="citizenshipValidate.usCitizen.$error">
                <small
                  v-for="(error, index) in citizenshipValidate.usCitizen.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>
            </div>

            <div class="intro-y mt-5" v-if="usCitizen == false">
              <label>Are you a Lawful Permanent Resident Alien?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="lawfulAlien-choice"
                  v-bind:value="true"
                  v-model="citizenshipValidate.lawfulAlien.$model"
                  :style="citizenshipValidate.lawfulAlien.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="lawfulAlien-choice"
                  v-bind:value="false"
                  v-model="citizenshipValidate.lawfulAlien.$model"
                  :style="citizenshipValidate.lawfulAlien.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
              <template v-if="citizenshipValidate.lawfulAlien.$error">
                <small
                  v-for="(error, index) in citizenshipValidate.lawfulAlien.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>
            </div>
            
            <div class="intro-y col-span-12 sm:col-span-6 mt-5" v-if="usCitizen == false && lawfulAlien == true">
              <label for="input-wizard-1" class="form-label">Alien Registration #</label>
              <div class="grid grid-cols-12 gap-2">
                <input
                  id="input-wizard-1"
                  type="text"
                  class="form-control col-span-4"
                  :class="{ 'border-theme-21': citizenshipValidate.alienRegNo.$error }"
                  placeholder="XXX-XXX-XXX"
                  v-model.trim="citizenshipValidate.alienRegNo.$model"
                />
              </div>
              <template v-if="citizenshipValidate.alienRegNo.$error">
                <small
                  v-for="(error, index) in citizenshipValidate.alienRegNo.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>
            </div>

            <div class="intro-y col-span-12 sm:col-span-6 mt-5" v-if="usCitizen == true">
              <label for="input-wizard-4" class="form-label">Place of Birth</label>
              <div class="grid grid-cols-12 gap-2">
                <input
                  type="text"
                  class="form-control col-span-4"
                  :class="{ 'border-theme-21': citizenshipValidate.pob_city.$error }"
                  placeholder="City"
                  aria-label="default input inline 2"
                  v-model.trim="citizenshipValidate.pob_city.$model"
                />
                <template v-if="citizenshipValidate.pob_city.$error">
                  <small
                    v-for="(error, index) in citizenshipValidate.pob_city.$errors"
                    :key="index"
                    class="text-theme-21 mt-2"
                  >
                    {{ error.$message }} <br>
                  </small>
                </template>

                <input
                  type="text"
                  class="form-control col-span-4"
                  :class="{ 'border-theme-21': citizenshipValidate.pob_state.$error }"
                  placeholder="State"
                  aria-label="default input inline 3"
                  v-model.trim="citizenshipValidate.pob_state.$model"
                />
                <template v-if="citizenshipValidate.pob_state.$error">
                  <small
                    v-for="(error, index) in citizenshipValidate.pob_state.$errors"
                    :key="index"
                    class="text-theme-21 mt-2"
                  >
                    {{ error.$message }}
                  </small>
                </template>
              </div>
            </div>
            
            <div class="intro-y col-span-12 sm:col-span-6 mt-5" v-if="usCitizen == false && lawfulAlien != null">
              <label for="input-wizard-4" class="form-label">Country of Citizenship</label>
              <div class="grid grid-cols-12 gap-2">
                <select
                  class="form-select col-span-4"
                  :class="{ 'border-theme-21': citizenshipValidate.pob_country.$error }"
                  v-model="citizenshipValidate.pob_country.$model"
                >
                    <option disabled value="">Please select a country</option>
                    <option disabled>--------------------------------</option>
                    <option>Afghanistan</option>
                    <option>Åland Islands</option>
                    <option>Albania</option>
                    <option>Algeria</option>
                    <option>American Samoa</option>
                    <option>Andorra</option>
                    <option>Angola</option>
                    <option>Anguilla</option>
                    <option>Antarctica</option>
                    <option>Antigua and Barbuda</option>
                    <option>Argentina</option>
                    <option>Armenia</option>
                    <option>Aruba</option>
                    <option>Australia</option>
                    <option>Austria</option>
                    <option>Azerbaijan</option>
                    <option>Bahamas (the)</option>
                    <option>Bahrain</option>
                    <option>Bangladesh</option>
                    <option>Barbados</option>
                    <option>Belarus</option>
                    <option>Belgium</option>
                    <option>Belize</option>
                    <option>Benin</option>
                    <option>Bermuda</option>
                    <option>Bhutan</option>
                    <option>Bolivia (Plurinational State of)</option>
                    <option>Bonaire, Sint Eustatius and Saba</option>
                    <option>Bosnia and Herzegovina</option>
                    <option>Botswana</option>
                    <option>Bouvet Island</option>
                    <option>Brazil</option>
                    <option>British Indian Ocean Territory (the)</option>
                    <option>Brunei Darussalam</option>
                    <option>Bulgaria</option>
                    <option>Burkina Faso</option>
                    <option>Burundi</option>
                    <option>Cabo Verde</option>
                    <option>Cambodia</option>
                    <option>Cameroon</option>
                    <option>Canada</option>
                    <option>Cayman Islands (the)</option>
                    <option>Central African Republic (the)</option>
                    <option>Chad</option>
                    <option>Chile</option>
                    <option>China</option>
                    <option>Christmas Island</option>
                    <option>Cocos (Keeling) Islands (the)</option>
                    <option>Colombia</option>
                    <option>Comoros (the)</option>
                    <option>Congo (the Democratic Republic of the)</option>
                    <option>Congo (the)</option>
                    <option>Cook Islands (the)</option>
                    <option>Costa Rica</option>
                    <option>Croatia</option>
                    <option>Cuba</option>
                    <option>Curaçao</option>
                    <option>Cyprus</option>
                    <option>Czechia</option>
                    <option>Côte d'Ivoire</option>
                    <option>Denmark</option>
                    <option>Djibouti</option>
                    <option>Dominica</option>
                    <option>Dominican Republic (the)</option>
                    <option>Ecuador</option>
                    <option>Egypt</option>
                    <option>El Salvador</option>
                    <option>Equatorial Guinea</option>
                    <option>Eritrea</option>
                    <option>Estonia</option>
                    <option>Eswatini</option>
                    <option>Ethiopia</option>
                    <option>Falkland Islands (the) [Malvinas]</option>
                    <option>Faroe Islands (the)</option>
                    <option>Fiji</option>
                    <option>Finland</option>
                    <option>France</option>
                    <option>French Guiana</option>
                    <option>French Polynesia</option>
                    <option>French Southern Territories (the)</option>
                    <option>Gabon</option>
                    <option>Gambia (the)</option>
                    <option>Georgia</option>
                    <option>Germany</option>
                    <option>Ghana</option>
                    <option>Gibraltar</option>
                    <option>Greece</option>
                    <option>Greenland</option>
                    <option>Grenada</option>
                    <option>Guadeloupe</option>
                    <option>Guam</option>
                    <option>Guatemala</option>
                    <option>Guernsey</option>
                    <option>Guinea</option>
                    <option>Guinea-Bissau</option>
                    <option>Guyana</option>
                    <option>Haiti</option>
                    <option>Heard Island and McDonald Islands</option>
                    <option>Holy See (the)</option>
                    <option>Honduras</option>
                    <option>Hong Kong</option>
                    <option>Hungary</option>
                    <option>Iceland</option>
                    <option>India</option>
                    <option>Indonesia</option>
                    <option>Iran (Islamic Republic of)</option>
                    <option>Iraq</option>
                    <option>Ireland</option>
                    <option>Isle of Man</option>
                    <option>Israel</option>
                    <option>Italy</option>
                    <option>Jamaica</option>
                    <option>Japan</option>
                    <option>Jersey</option>
                    <option>Jordan</option>
                    <option>Kazakhstan</option>
                    <option>Kenya</option>
                    <option>Kiribati</option>
                    <option>Korea (the Democratic People's Republic of)</option>
                    <option>Korea (the Republic of)</option>
                    <option>Kuwait</option>
                    <option>Kyrgyzstan</option>
                    <option>Lao People's Democratic Republic (the)</option>
                    <option>Latvia</option>
                    <option>Lebanon</option>
                    <option>Lesotho</option>
                    <option>Liberia</option>
                    <option>Libya</option>
                    <option>Liechtenstein</option>
                    <option>Lithuania</option>
                    <option>Luxembourg</option>
                    <option>Macao</option>
                    <option>Madagascar</option>
                    <option>Malawi</option>
                    <option>Malaysia</option>
                    <option>Maldives</option>
                    <option>Mali</option>
                    <option>Malta</option>
                    <option>Marshall Islands (the)</option>
                    <option>Martinique</option>
                    <option>Mauritania</option>
                    <option>Mauritius</option>
                    <option>Mayotte</option>
                    <option>Mexico</option>
                    <option>Micronesia (Federated States of)</option>
                    <option>Moldova (the Republic of)</option>
                    <option>Monaco</option>
                    <option>Mongolia</option>
                    <option>Montenegro</option>
                    <option>Montserrat</option>
                    <option>Morocco</option>
                    <option>Mozambique</option>
                    <option>Myanmar</option>
                    <option>Namibia</option>
                    <option>Nauru</option>
                    <option>Nepal</option>
                    <option>Netherlands (the)</option>
                    <option>New Caledonia</option>
                    <option>New Zealand</option>
                    <option>Nicaragua</option>
                    <option>Niger (the)</option>
                    <option>Nigeria</option>
                    <option>Niue</option>
                    <option>Norfolk Island</option>
                    <option>Northern Mariana Islands (the)</option>
                    <option>Norway</option>
                    <option>Oman</option>
                    <option>Pakistan</option>
                    <option>Palau</option>
                    <option>Palestine, State of</option>
                    <option>Panama</option>
                    <option>Papua New Guinea</option>
                    <option>Paraguay</option>
                    <option>Peru</option>
                    <option>Philippines (the)</option>
                    <option>Pitcairn</option>
                    <option>Poland</option>
                    <option>Portugal</option>
                    <option>Puerto Rico</option>
                    <option>Qatar</option>
                    <option>Republic of North Macedonia</option>
                    <option>Romania</option>
                    <option>Russian Federation (the)</option>
                    <option>Rwanda</option>
                    <option>Réunion</option>
                    <option>Saint Barthélemy</option>
                    <option>Saint Helena, Ascension and Tristan da Cunha</option>
                    <option>Saint Kitts and Nevis</option>
                    <option>Saint Lucia</option>
                    <option>Saint Martin (French part)</option>
                    <option>Saint Pierre and Miquelon</option>
                    <option>Saint Vincent and the Grenadines</option>
                    <option>Samoa</option>
                    <option>San Marino</option>
                    <option>Sao Tome and Principe</option>
                    <option>Saudi Arabia</option>
                    <option>Senegal</option>
                    <option>Serbia</option>
                    <option>Seychelles</option>
                    <option>Sierra Leone</option>
                    <option>Singapore</option>
                    <option>Sint Maarten (Dutch part)</option>
                    <option>Slovakia</option>
                    <option>Slovenia</option>
                    <option>Solomon Islands</option>
                    <option>Somalia</option>
                    <option>South Africa</option>
                    <option>South Georgia and the South Sandwich Islands</option>
                    <option>South Sudan</option>
                    <option>Spain</option>
                    <option>Sri Lanka</option>
                    <option>Sudan (the)</option>
                    <option>Suriname</option>
                    <option>Svalbard and Jan Mayen</option>
                    <option>Sweden</option>
                    <option>Switzerland</option>
                    <option>Syrian Arab Republic</option>
                    <option>Taiwan (Province of China)</option>
                    <option>Tajikistan</option>
                    <option>Tanzania, United Republic of</option>
                    <option>Thailand</option>
                    <option>Timor-Leste</option>
                    <option>Togo</option>
                    <option>Tokelau</option>
                    <option>Tonga</option>
                    <option>Trinidad and Tobago</option>
                    <option>Tunisia</option>
                    <option>Turkey</option>
                    <option>Turkmenistan</option>
                    <option>Turks and Caicos Islands (the)</option>
                    <option>Tuvalu</option>
                    <option>Uganda</option>
                    <option>Ukraine</option>
                    <option>United Arab Emirates (the)</option>
                    <option>United Kingdom of Great Britain and Northern Ireland (the)</option>
                    <option>United States Minor Outlying Islands (the)</option>
                    <option>United States of America (the)</option>
                    <option>Uruguay</option>
                    <option>Uzbekistan</option>
                    <option>Vanuatu</option>
                    <option>Venezuela (Bolivarian Republic of)</option>
                    <option>Viet Nam</option>
                    <option>Virgin Islands (British)</option>
                    <option>Virgin Islands (U.S.)</option>
                    <option>Wallis and Futuna</option>
                    <option>Western Sahara</option>
                    <option>Yemen</option>
                    <option>Zambia</option>
                    <option>Zimbabwe</option>
                </select>
              </div>
              <template v-if="citizenshipValidate.pob_country.$error">
                <small
                  v-for="(error, index) in citizenshipValidate.pob_country.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>
            </div>

            <div
              class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
            >

              <button class="btn btn-secondary w-24" @click="handlePrev" v-if="!isPending">Previous</button>
              <button class="btn btn-dark mr-1 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button class="btn btn-primary w-24 ml-2" @click="handleNext" v-if="!isPending">Next</button>
              <button class="btn btn-dark mr-1 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button class="btn btn-linkedin ml-2" @click="handleSaveandQuit" v-if="!isPending">Save & Exit</button>
              <button class="btn btn-dark mr-1 ml-2 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>

          </div>
        </div>
        <!-- END: Page 2 -->
        
        <!-- BEGIN: Page 3 -->
        <div id="radio" class="intro-y p-5" v-else>
          <div class="preview">

            <div>
              <label>Are you presently subject to an indictment, criminal info, arraignment or other means by which formal criminal charges are brought in any jurisdiction?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="presentlySubject-choice"
                  v-bind:value="true"
                  v-model="criminalInfoValidate.presentlySubject.$model"
                  :style="criminalInfoValidate.presentlySubject.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="presentlySubject-choice"
                  v-bind:value="false"
                  v-model="criminalInfoValidate.presentlySubject.$model"
                  :style="criminalInfoValidate.presentlySubject.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
              <template v-if="criminalInfoValidate.presentlySubject.$error">
                <small
                  v-for="(error, index) in criminalInfoValidate.presentlySubject.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>
            </div>

            <div class="intro-y mt-5">
              <label>Have you been arrested in the past 6 months?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="past6Months-choice"
                  v-bind:value="true"
                  v-model="criminalInfoValidate.past6Months.$model"
                  :style="criminalInfoValidate.past6Months.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="past6Months-choice"
                  v-bind:value="false"
                  v-model="criminalInfoValidate.past6Months.$model"
                  :style="criminalInfoValidate.past6Months.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
              <template v-if="criminalInfoValidate.past6Months.$error">
                <small
                  v-for="(error, index) in criminalInfoValidate.past6Months.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>
            </div>

            <div class="intro-y mt-5">
              <label>For any criminal offense (other than a minor vehicle violation), have you ever been convicted, pleaded guilty, pleaded nolo contendere, been placed on pretrial diversion or been placed on any form of parole or probation?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="anyPunishment-choice"
                  v-bind:value="true"
                  v-model="criminalInfoValidate.anyPunishment.$model"
                  :style="criminalInfoValidate.anyPunishment.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="anyPunishment-choice"
                  v-bind:value="false"
                  v-model="criminalInfoValidate.anyPunishment.$model"
                  :style="criminalInfoValidate.anyPunishment.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
              <template v-if="criminalInfoValidate.anyPunishment.$error">
                <small
                  v-for="(error, index) in criminalInfoValidate.anyPunishment.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>
            </div>

            <div
              class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
            >

              <button class="btn btn-secondary w-24" @click="handlePrev" v-if="!isPending">Previous</button>
              <button class="btn btn-dark mr-1 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button class="btn btn-success w-24 ml-2" @click="handleFinish" v-if="!isPending">Continue to Business Info</button>
              <button class="btn btn-dark mr-1 mb-2" v-else-if="isPending">
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button class="btn btn-linkedin ml-2" @click="handleSaveandQuit" v-if="!isPending">Save & Exit</button>
              <button class="btn btn-dark mr-1 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>

          </div>
        </div>
        <!-- END: Page 3 -->

        <div class="w-full border-t border-gray-200 dark:border-dark-5 mt-5"></div>

      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRef, toRefs } from 'vue';
import getUser from '@/composables/getUser';
import useDocument from '@/composables/useDocument';
import getDocument from '@/composables/getDocument';
import { useRouter } from 'vue-router';
import {
  required,
  minLength,
  helpers,
  numeric,
  requiredIf,
  requiredUnless
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  setup() {
    const router = useRouter();
    const {user} = getUser();
    const { error,
            getDocumentByID, document: profileDetails } = getDocument('profileDetails', user.value.uid);
    const { error: useDocumentError, isPending, deleteDoc, updateDoc } = useDocument('profileDetails', user.value.uid);

    const page = ref(1);

    // Personal Info Refs & Validation
    const personalInfo = reactive({
      phoneNumber: null,
      address: null,
      ssn: null,
      creditScore: null,
      dob: '',
    });
    
    const personalInfoRules = {
      phoneNumber: {
        required,
        numeric: helpers.withMessage('Only numbers (0-9) are allowed for this field.', numeric),
        minLength: minLength(10)
      },
      address: {
        required
      },
      ssn: {
        required,
        numeric: helpers.withMessage('Only numbers (0-9) are allowed for this field.', numeric),
        minLength: minLength(9)
      },
      creditScore: {
        required,
      },
      dob: {
        required
      },
    }
    const personalInfoValidate = useVuelidate(personalInfoRules, toRefs(personalInfo));

    // Citizenship Refs & Validation
    const citizenship = reactive({
      usCitizen: null,
      lawfulAlien: null,
      alienRegNo: null,
      pob_country: '',
      pob_state: null,
      pob_city: null,
    });

    const citizenshipRules = {
      usCitizen: {
        required
      },
      lawfulAlien: {
        requiredIfNotUSCitizen: requiredUnless(citizenship.usCitizen)
      },
      alienRegNo: {
        requiredIfLawfulAlien: requiredIf(() => citizenship.lawfulAlien == true),
        numeric: helpers.withMessage('Only numbers (0-9) are allowed for this field.', numeric),
        minLength: minLength(9)
      },
      pob_country: {
        requiredIfNotUSCitizen: requiredIf(() => citizenship.usCitizen != true),
      },
      pob_state: {
        requiredIfUSCitizen: requiredIf(() => citizenship.usCitizen == true)
      },
      pob_city: {
        requiredIfUSCitizen: requiredIf(() => citizenship.usCitizen == true)
      },
    }
    const citizenshipValidate = useVuelidate(citizenshipRules, toRefs(citizenship));

    // Criminal History Refs & Validation
    const criminalInfo = reactive({
      presentlySubject: null,
      past6Months: null,
      anyPunishment: null,
    });
    
    const criminalInfoRules = {
      presentlySubject: {
        required
      },
      past6Months: {
        required
      },
      anyPunishment: {
        required
      },
    }
    const criminalInfoValidate = useVuelidate(criminalInfoRules, toRefs(criminalInfo));

    const handleNext = () => {
      switch(page.value) {
        case 1: {
          personalInfoValidate.value.$touch();
          if(!personalInfoValidate.value.$invalid) {
            page.value++;
          }
          break;
        }
        case 2: {
          citizenshipValidate.value.$touch();
          if(!citizenshipValidate.value.$invalid) {
            page.value++;
          }
          break;
        }
        default:
          break;
      }
    };
    
    const handlePrev = () => {
        page.value--;
    };

    const handleSaveandQuit = async () => {
      switch(page.value) {
        case 1: {
          const updates = {
            ...personalInfo,
            phoneNumber: personalInfo.phoneNumber == '' ? null : personalInfo.phoneNumber,
            address: personalInfo.address == '' ? null : personalInfo.address,
            ssn: personalInfo.ssn == '' ? null : personalInfo.ssn,
            creditScore: personalInfo.creditScore == '' ? null : personalInfo.creditScore,
            page: 1
          };
    
          await updateDoc(updates);
    
          router.push({ name: 'portal-page' });
          break;
        }
        case 2: {
          const updates = {
            ...personalInfo,
            citizenship: {
              ...citizenship,
              alienRegNo: citizenship.alienRegNo == '' ? null : citizenship.alienRegNo,
              pob_state: citizenship.pob_state == '' ? null : citizenship.pob_state,
              pob_city: citizenship.pob_city == '' ? null : citizenship.pob_city,
            },
            page: 2
          };
    
          await updateDoc(updates);
    
          router.push({ name: 'portal-page' });
          break;
        }
        case 3: {
          const updates = {
            ...personalInfo,
            citizenship: {
              ...citizenship,
            },
            criminalInfo: {
              ...criminalInfo,
            },
            page: 3
          };
    
          await updateDoc(updates);
    
          router.push({ name: 'portal-page' });
          break;
        }
        default:
          break;
      }
    };

    const handleFinish = async () => {
      criminalInfoValidate.value.$touch();
      if(!criminalInfoValidate.value.$invalid) {
        const updates = {
          ...personalInfo,
          citizenship: {
            ...citizenship,
            lawfulAlien: citizenship.usCitizen == false ? citizenship.lawfulAlien : false,
            alienRegNo: citizenship.lawfulAlien == true ? citizenship.alienRegNo : '',
            pob_country: citizenship.usCitizen == false ? citizenship.pob_country : '',
            pob_city: citizenship.usCitizen == true ? citizenship.pob_city : '',
            pob_state: citizenship.usCitizen == true ? citizenship.pob_state : '',
          },
          criminalInfo: {
            ...criminalInfo,
          }
        };
  
        await updateDoc(updates);
  
        router.push({ name: 'business-info' });
      }
    };

    onMounted(async () => {
      await getDocumentByID('profileDetails', user.value.uid);
      
      // If values exist in the DB, populate them in the form
      if (profileDetails.value != null && profileDetails.value.citizenship != null && profileDetails.value.criminalInfo != null) {
        let profilePersonalInfo = profileDetails.value;
        let profileCitizenship = profileDetails.value.citizenship;
        let profileCriminalInfo = profileDetails.value.criminalInfo;

        Object.keys(profilePersonalInfo).forEach(function(key) {
          if(key in personalInfo) {
            personalInfo[key] = profilePersonalInfo[key];
          }
        });

        Object.keys(profileCitizenship).forEach(function(key) {
          if(key in citizenship) {
            citizenship[key] = profileCitizenship[key];
          }
        });

        Object.keys(profileCriminalInfo).forEach(function(key) {
          if(key in criminalInfo) {
            criminalInfo[key] = profileCriminalInfo[key];
          }
        });
      }

      if(profileDetails.value.page != null) {
        page.value = profileDetails.value.page
      }
    })

    return { ...toRefs(personalInfo), ...toRefs(citizenship), ...toRefs(criminalInfo),
             personalInfoValidate, citizenshipValidate, criminalInfoValidate,
             page, handleNext, handlePrev, handleFinish, isPending, handleSaveandQuit };
  },
}
</script>
