<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">User Management</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <button class="btn btn-primary shadow-md mr-2" @click="handleInviteClick">Invite a Member</button>
        <!-- <div class="dropdown">
          <button
            class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"
            aria-expanded="false"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <PrinterIcon class="w-4 h-4 mr-2" /> Print
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export to Excel
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export to PDF
              </a>
            </div>
          </div>
        </div> -->
        <div class="hidden md:block mx-auto text-gray-600">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <!-- <th class="whitespace-nowrap">IMAGES</th> -->
              <th class="whitespace-nowrap">DISPLAY NAME</th>
              <!-- <th class="text-center whitespace-nowrap">STOCK</th> -->
              <!-- <th class="text-center whitespace-nowrap">STATUS</th> -->
              <th class="text-center whitespace-nowrap">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in userList"
              :key="user.uid"
              class="intro-x"
            >
              <!-- <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <Tippy
                      tag="img"
                      alt="Icewall Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="require(`@/assets/images/${faker.images[0]}`)"
                      :content="`Uploaded at ${faker.dates[0]}`"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <Tippy
                      tag="img"
                      alt="Icewall Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="require(`@/assets/images/${faker.images[1]}`)"
                      :content="`Uploaded at ${faker.dates[0]}`"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <Tippy
                      tag="img"
                      alt="Icewall Tailwind HTML Admin Template"
                      class="rounded-full"
                      :src="require(`@/assets/images/${faker.images[2]}`)"
                      :content="`Uploaded at ${faker.dates[0]}`"
                    />
                  </div>
                </div>
              </td> -->
              <td>
                <a href="" class="font-medium whitespace-nowrap">{{
                  user.displayName
                }}</a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  {{ user.email }}
                </div>
                <div
                class="alert alert-primary-soft show flex items-center mb-0.5"
                role="alert"
                v-if="promote && adminEmail == user.email"
                >
                <LoadingIcon icon="grid" class="w-4 h-4" /> &nbsp;Promoting to Admin... Please wait...
              </div>
              <div
                class="alert alert-primary-soft show flex items-center mb-0.5"
                role="alert"
                v-if="!promote && adminEmail == user.email"
                >
                <LoadingIcon icon="grid" class="w-4 h-4" /> &nbsp;Demoting Admin... Please wait...
              </div>
              </td>
              <!-- <td class="text-center">{{ faker.stocks[0] }}</td> -->
              <!-- <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-20': faker.trueFalse[0],
                    'text-theme-21': !faker.trueFalse[0]
                  }"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-2" />
                  {{ faker.trueFalse[0] ? "Active" : "Inactive" }}
                </div>
              </td> -->
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;" @click="handleEditClick(user.uid)">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-21"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                    @click="setDeleteID(user.uid)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                  </a>
                  &nbsp;
                  <a class="flex items-center" href="javascript:;" @click="handleAdminPromoteClick(user.email)" v-if="!user.customClaims">
                    <ChevronsUpIcon class="w-4 h-4 mr-1" />
                    Promote
                  </a>
                  <a class="flex items-center" href="javascript:;" @click="handleAdminPromoteClick(user.email)" v-else-if="!user.customClaims.admin">
                    <ChevronsUpIcon class="w-4 h-4 mr-1" />
                    Promote
                  </a>
                  <a class="flex items-center" href="javascript:;" @click="handleAdminDemoteClick(user.email)" v-else>
                    <ChevronsDownIcon class="w-4 h-4 mr-1" />
                    Demote
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">1</a>
          </li>
          <li>
            <a class="pagination__link pagination__link--active" href="">2</a>
          </li>
          <li>
            <a class="pagination__link" href="">3</a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select class="w-20 form-select box mt-3 sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      @click.self="resetDeleteID()"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-21 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete this user? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
                @click="resetDeleteID()"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
import { ref } from 'vue';
import userCRUD from '@/composables/userCRUD';
import { useRouter } from 'vue-router';
import getUser from '@/composables/getUser';

export default {
  async setup() {
    const router = useRouter();
    const {user} = getUser();

    const { allUsers, getAllUsers, promoteToAdmin, demoteAdmin } = userCRUD();
    const deleteID = ref('');
    const adminEmail = ref('');
    const promote = ref(null);

    await getAllUsers();
    console.log(allUsers.value);
    const userList = allUsers.value;
    console.log('User List: ', userList);
    console.log(location.host)

    const setDeleteID = (id) => {
      deleteID.value = id;
      console.log(deleteID.value);
    }

    const resetDeleteID = () => {
      deleteID.value = '';
      console.log(deleteID.value);
    }

    const handleEditClick = (id) => {
      router.push({ path: `/edit-user/${id}` });
    }

    const handleInviteClick = () => {
      router.push({ path: '/invite-user' });
    }

    const handleAdminPromoteClick = async (email) => {
      // router.push({ path: `/edit-user/${id}` });
      console.log(email);
      adminEmail.value = email;
      promote.value = true;
      promoteToAdmin(email);
    }

    const handleAdminDemoteClick = async (email) => {
      // router.push({ path: `/edit-user/${id}` });
      console.log(email);
      adminEmail.value = email;
      promote.value = false;
      demoteAdmin(email);
    }


    return { userList, setDeleteID, resetDeleteID, handleEditClick, handleInviteClick, handleAdminPromoteClick, handleAdminDemoteClick, adminEmail, promote };
  }
}
</script>
