<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">
        Apply for a Loan: SBA 7(a)
      </h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="flex justify-center">
        <button
          v-bind:class="[
            page == 1
              ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2'
              : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2'
          ]"
        >
          1
        </button>
        <button
          v-bind:class="[
            page == 2
              ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2'
              : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2'
          ]"
        >
          2
        </button>
        <button
          v-bind:class="[
            page == 3
              ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2'
              : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2'
          ]"
        >
          3
        </button>
      </div>
      <div class="px-5 mt-10">
        <div class="font-medium text-center text-lg">
          <strong>Business Info</strong>
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 1">
          <a href="javascript:;" class="text-theme-25">
            <u>General Information</u>
          </a>
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 2">
          Questions about your business
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 3">
          Conflict of Interest Questions
        </div>
      </div>
      <div
        class="
          px-5
          sm:px-20
          mt-10
          pt-10
          border-t border-gray-200
          dark:border-dark-5
        "
      >
        <!-- <div class="font-medium text-base">[Insert Title Here]</div> -->

        <!-- BEGIN: Page 1 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 1">
          <!-- BEGIN: Page 1, Subpage 1 -->
          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 1">
            <label for="input-wizard-2" class="form-label">
              Is your company an <strong>Operating Company (OC)</strong> or an
              <strong>Eligible Passive Company (EPC)</strong>?
              <small>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#basic-slide-over-preview"
                  class="text-theme-25 font-normal"
                  @click="toggleOCEPC"
                  >More Info</a
                >
              </small>
            </label>
            <div class="flex flex-col sm:flex-row">
              <div class="form-check mr-2">
                <input
                  id="radio-switch-4"
                  class="form-check-input"
                  type="radio"
                  name="ocEPC_choice"
                  v-bind:value="true"
                  v-model="oc"
                />
                <label class="form-check-label" for="radio-switch-4">OC</label>
              </div>
              <div class="form-check mr-2 mt-2 sm:mt-0">
                <input
                  id="radio-switch-5"
                  class="form-check-input"
                  type="radio"
                  name="ocEPC_choice"
                  v-bind:value="false"
                  v-model="oc"
                />
                <label class="form-check-label" for="radio-switch-5">EPC</label>
              </div>
            </div>

            <label
              for="input-wizard-4"
              class="form-label mt-1"
              v-if="oc == true"
              >Legal Business Name</label
            >
            <label
              for="input-wizard-4"
              class="form-label mt-1"
              v-else-if="oc == false"
              >Legal Business Names</label
            >
            <div class="grid grid-cols-12 gap-2" v-if="oc != null">
              <input
                type="text"
                class="form-control col-span-6"
                placeholder="Legal Business Name"
                aria-label="default input inline 1"
                v-model="businessName"
              />
              <input
                type="text"
                class="form-control col-span-6"
                placeholder="Operating Company Legal Business Name"
                aria-label="default input inline 2"
                v-if="oc == false"
                v-model="ocBusinessName"
              />
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 1">
            <label>Your business is a:&nbsp;</label>
            <small>
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#basic-slide-over-preview"
                class="text-theme-25 font-normal"
                @click="toggleCooperative"
                >More Info</a
              >
            </small>
            <div class="flex flex-col sm:flex-row mt-2">
              <div class="form-check mr-2">
                <input
                  id="radio-switch-4"
                  class="form-check-input"
                  type="radio"
                  name="applicantOwnershipType"
                  value="Cooperative"
                  v-model="ownershipType"
                />
                <label class="form-check-label" for="radio-switch-4"
                  >Cooperative</label
                >
              </div>
              <div class="form-check mr-2 mt-2 sm:mt-0">
                <input
                  id="radio-switch-5"
                  class="form-check-input"
                  type="radio"
                  name="applicantOwnershipType"
                  value="ESOP"
                  v-model="ownershipType"
                />
                <label class="form-check-label" for="radio-switch-5"
                  >ESOP</label
                >
              </div>
              <div class="form-check mr-2 mt-2 sm:mt-0">
                <input
                  id="radio-switch-6"
                  class="form-check-input"
                  type="radio"
                  name="applicantOwnershipType"
                  value="401(k)"
                  v-model="ownershipType"
                />
                <label class="form-check-label" for="radio-switch-6"
                  >401(k) Plan</label
                >
              </div>
              <div class="form-check mr-2 mt-2 sm:mt-0">
                <input
                  id="radio-switch-7"
                  class="form-check-input"
                  type="radio"
                  name="applicantOwnershipType"
                  value="Trust"
                  v-model="ownershipType"
                />
                <label class="form-check-label" for="radio-switch-7"
                  >Trust</label
                >
              </div>
              <div class="form-check mr-2 mt-2 sm:mt-0">
                <input
                  id="radio-switch-8"
                  class="form-check-input"
                  type="radio"
                  name="applicantOwnershipType"
                  value="Other"
                  v-model="ownershipType"
                />
                <label class="form-check-label" for="radio-switch-8"
                  >Other</label
                >
              </div>
              <div class="form-check mr-2 mt-2 sm:mt-0">
                <input
                  id="radio-switch-9"
                  class="form-check-input"
                  type="radio"
                  name="applicantOwnershipType"
                  value=""
                  v-model="ownershipType"
                />
                <label class="form-check-label" for="radio-switch-9">N/A</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 1">
            <div>
              <label>Are you planning on using a 401(k) plan for equity?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="equity401k-choice"
                  v-bind:value="true"
                  v-model="equity401k"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="equity401k-choice"
                  v-bind:value="false"
                  v-model="equity401k"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 1">
            <div>
              <label
                >Will a Management Company be hired to manage your business's
                day-to-day operations?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="managementCompany-choice"
                  v-bind:value="true"
                  v-model="managementCompany"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="managementCompany-choice"
                  v-bind:value="false"
                  v-model="managementCompany"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>
          <!-- END: Page 1, Subpage 1 -->

          <!-- BEGIN: Page 1, Subpage 2 -->
          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 2">
            <label for="input-wizard-3" class="form-label"
              >Primary Business Address</label
            >
            <input
              id="input-wizard-3"
              type="text"
              class="form-control"
              placeholder="Corporation, Partnership, etc."
              v-model="address"
            />
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 2">
            <label for="input-wizard-4" class="form-label"
              >Business Tax ID</label
            >
            <input
              id="input-wizard-4"
              type="text"
              class="form-control"
              placeholder="xx-xxxxxxx"
              aria-label="Percentage"
              aria-describedby="input-group-price"
              v-model="taxID"
            />
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 2">
            <label for="input-wizard-3" class="form-label"
              >Business Phone Number</label
            >
            <input
              id="input-wizard-3"
              type="text"
              class="form-control"
              placeholder="(222) 222-2222"
              v-model="phoneNumber"
            />
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 2">
            <label for="input-wizard-4" class="form-label"
              >Project Address</label
            >
            <input
              id="input-wizard-4"
              type="text"
              class="form-control"
              placeholder="2222 Placeholder Avenue, Test, Testing 00000"
              aria-label="Percentage"
              aria-describedby="input-group-price"
              v-model="projectAddress"
            />
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 2">
            <label for="input-wizard-3" class="form-label"
              >Primary Contact Name</label
            >
            <input
              id="input-wizard-3"
              type="text"
              class="form-control"
              placeholder="Your Name Here - First and Last"
              v-model="name"
            />
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 2">
            <label for="input-wizard-4" class="form-label">Email Address</label>
            <input
              id="input-wizard-4"
              type="text"
              class="form-control"
              placeholder="test@test.com"
              aria-label="Percentage"
              aria-describedby="input-group-price"
              v-model="email"
            />
          </div>
          <!-- END: Page 1, Subpage 2 -->

          <!-- BEGIN: Page 1, Subpage 3 -->
          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 3">
            <label for="input-wizard-4" class="form-label"
              >How much are you requesting for this loan?</label
            >
            <div class="input-group">
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="loanRequest"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 3">
            <label for="input-wizard-1" class="form-label"
              >How many existing employees does your business have (including
              owners)?</label
            >
            <input
              id="input-wizard-1"
              type="number"
              class="form-control"
              placeholder="51"
              aria-label="Percentage"
              aria-describedby="input-group-price"
              min="1"
              v-model="existingEmployees"
            />
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 3">
            <label for="input-wizard-1" class="form-label"
              >How many jobs will be created because of this loan (including
              owners)?</label
            >
            <input
              id="input-wizard-1"
              type="number"
              class="form-control"
              placeholder="51"
              aria-label="Percentage"
              aria-describedby="input-group-price"
              min="1"
              v-model="jobsCreated"
            />
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 3">
            <label for="input-wizard-1" class="form-label"
              >How many jobs won't be lost because of this loan (including
              owners)?</label
            >
            <input
              id="input-wizard-1"
              type="number"
              class="form-control"
              placeholder="51"
              aria-label="Percentage"
              aria-describedby="input-group-price"
              min="1"
              v-model="jobsRetained"
            />
          </div>

          <!-- <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 3">
            <label for="input-wizard-4" class="form-label">How much of this amount are you planning on using on what parts?</label>
            <div class="grid grid-cols-12 gap-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="$"
                  aria-label="default input inline 1"
                  min="1"
                />
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="For what?"
                  aria-label="default input inline 2"
                />
            </div>
            <div class="grid grid-cols-12 gap-2 mt-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="$"
                  aria-label="default input inline 1"
                  min="1"
                />
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="For what?"
                  aria-label="default input inline 2"
                />
            </div>
            <div class="grid grid-cols-12 gap-2 mt-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="$"
                  aria-label="default input inline 1"
                  min="1"
                />
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="For what?"
                  aria-label="default input inline 2"
                />
            </div>
            <div class="grid grid-cols-12 gap-2 mt-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="$"
                  aria-label="default input inline 1"
                  min="1"
                />
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="For what?"
                  aria-label="default input inline 2"
                />
            </div>
            <div class="grid grid-cols-12 gap-2 mt-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="$"
                  aria-label="default input inline 1"
                  min="1"
                />
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="For what?"
                  aria-label="default input inline 2"
                />
            </div>
            <div class="grid grid-cols-12 gap-2 mt-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="$"
                  aria-label="default input inline 1"
                  min="1"
                />
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="For what?"
                  aria-label="default input inline 2"
                />
            </div>
          </div> -->

          <!-- <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 3">
            <label for="input-wizard-4" class="form-label">Date of Birth</label>
            <div class="grid grid-cols-6 gap-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Month"
                  aria-label="default input inline 1"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Day"
                  aria-label="default input inline 2"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Year"
                  aria-label="default input inline 3"
                />
              </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 3">
            <label for="input-wizard-4" class="form-label">Date of Birth</label>
            <div class="grid grid-cols-12 gap-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Month"
                  aria-label="default input inline 1"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Day"
                  aria-label="default input inline 2"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Year"
                  aria-label="default input inline 3"
                />
              </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6" v-if="subPage == 3">
            <label for="input-wizard-4" class="form-label">Date of Birth</label>
            <div class="grid grid-cols-12 gap-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Month"
                  aria-label="default input inline 1"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Day"
                  aria-label="default input inline 2"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Year"
                  aria-label="default input inline 3"
                />
              </div>
          </div> -->
          <!-- END: Page 1, Subpage 3 -->

          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-5" class="form-label">Doesn't Have</label>
            <input
              id="input-wizard-5"
              type="text"
              class="form-control"
              placeholder="Job, Work, Documentation"
            />
          </div> -->
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-6" class="form-label">Size</label>
            <select id="input-wizard-6" class="form-select">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div> -->

          <div
            class="
              intro-y
              col-span-12
              flex
              items-center
              justify-center
              sm:justify-end
              mt-5
            "
          >
            <!-- <button class="btn btn-secondary w-24">Previous</button> -->
            <button
              class="btn btn-secondary w-24"
              @click="handlePrevSubpage"
              v-if="subPage > 1"
            >
              Back
            </button>
            <button
              class="btn btn-primary w-24 ml-2"
              @click="handleNext"
              v-if="subPage == 3"
            >
              Next: Business Specific Questions
            </button>
            <button
              class="btn btn-primary w-24 ml-2"
              @click="handleNextSubpage"
              v-if="subPage != 3"
            >
              Continue
            </button>
          </div>
        </div>
        <!-- END: Page 1 -->

        <!-- BEGIN: Page 2 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 2">
          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label>Are there any co-applicants?&nbsp;</label>
              <small>
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#basic-slide-over-preview"
                  class="text-theme-25 font-normal"
                  @click="toggleCoApplicant"
                  >More Info</a
                >
              </small>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="coApplicants-choice"
                  v-bind:value="true"
                  v-model="coApplicants"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="coApplicants-choice"
                  v-bind:value="false"
                  v-model="coApplicants"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label>Have you ever applied for this 7(a) loan before?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="appliedBefore-choice"
                  v-bind:value="true"
                  v-model="appliedBefore"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="appliedBefore-choice"
                  v-bind:value="false"
                  v-model="appliedBefore"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <!-- returnApplicant & ineligible questions here -->
          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Have your business ever gotten, guaranteed or applied for an
                SBA/Federal loan?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="returnApplicant-choice"
                  v-bind:value="true"
                  v-model="returnApplicant"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="returnApplicant-choice"
                  v-bind:value="false"
                  v-model="returnApplicant"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 sm:col-span-6"
            v-if="returnApplicant == true"
          >
            <div>
              <label
                >Are any of the loan/loans presently considered
                delinquent?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="presentlyDelinquent-choice"
                  v-bind:value="true"
                  v-model="presentlyDelinquent"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="presentlyDelinquent-choice"
                  v-bind:value="false"
                  v-model="presentlyDelinquent"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 sm:col-span-6"
            v-if="returnApplicant == true"
          >
            <div>
              <label>Has the entity ever defaulted on the loan/loans?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="defaulted-choice"
                  v-bind:value="true"
                  v-model="defaulted"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="defaulted-choice"
                  v-bind:value="false"
                  v-model="defaulted"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Is your business currently suspended, debarred or declared
                unable to apply for this loan by the Federal government?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="ineligible-choice"
                  v-bind:value="true"
                  v-model="ineligible"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="ineligible-choice"
                  v-bind:value="false"
                  v-model="ineligible"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Does your business operate under a franchise model or other
                type of agreement?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="franchise-choice"
                  v-bind:value="true"
                  v-model="franchise"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="franchise-choice"
                  v-bind:value="false"
                  v-model="franchise"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Have you or any business you've owned ever filed for bankruptcy
                protection?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="bProtection-choice"
                  v-bind:value="true"
                  v-model="bProtection"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="bProtection-choice"
                  v-bind:value="false"
                  v-model="bProtection"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Are you or any business you own presently involved in any
                pending legal action?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="pendingLegal-choice"
                  v-bind:value="true"
                  v-model="pendingLegal"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="pendingLegal-choice"
                  v-bind:value="false"
                  v-model="pendingLegal"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Are any of your business's products or services
                exported?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="exported-choice"
                  v-bind:value="true"
                  v-model="exported"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="exported-choice"
                  v-bind:value="false"
                  v-model="exported"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 sm:col-span-6"
            v-if="exported == true"
          >
            <div>
              <label
                >How much of the loan you're requesting will support export
                sales?</label
              >
              <div class="input-group">
                <input
                  id="input-wizard-1"
                  type="number"
                  class="form-control"
                  placeholder="51"
                  aria-label="Percentage"
                  aria-describedby="input-group-price"
                  min="0"
                  v-model="exportSales"
                />
                <div id="input-group-price" class="input-group-text">$</div>
              </div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 sm:col-span-6"
            v-if="exported == true"
          >
            <div>
              <label
                >What are the principal country/countries you'll be exporting
                to? (at least 1)</label
              >
              <div class="grid grid-cols-12 gap-2">
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="Export Country #1"
                  aria-label="default input inline 1"
                  v-model="exportCountry1"
                />
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="Export Country #2"
                  aria-label="default input inline 2"
                  v-model="exportCountry2"
                />
                <input
                  type="text"
                  class="form-control col-span-4"
                  placeholder="Export Country #3"
                  aria-label="default input inline 2"
                  v-model="exportCountry3"
                />
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Are you using a third-party to help with your SBA
                application?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="thirdParty-choice"
                  v-bind:value="true"
                  v-model="thirdParty"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="thirdParty-choice"
                  v-bind:value="false"
                  v-model="thirdParty"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Does your business make any money from gambling, loan packaging
                or anything sexual in nature?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="illegalActivity-choice"
                  v-bind:value="true"
                  v-model="illegalActivity"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="illegalActivity-choice"
                  v-bind:value="false"
                  v-model="illegalActivity"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div
            class="
              intro-y
              col-span-12
              flex
              items-center
              justify-center
              sm:justify-end
              mt-5
            "
          >
            <button
              class="btn btn-secondary w-24"
              @click="handlePrev"
              v-if="!isPending"
            >
              Previous
            </button>
            <!-- <button class="btn btn-dark mr-1 mb-2" v-else>
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button> -->

            <button
              class="btn btn-primary w-24 ml-2 w-24 ml-2"
              @click="handleNext"
              v-if="!isPending"
            >
              Next
            </button>
            <!-- <button class="btn btn-dark mr-1 mb-2" v-else-if="isPending">
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button> -->
          </div>
        </div>
        <!-- END: Page 2 -->

        <!-- BEGIN: Page 3 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 3">
          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Is anyone owning at least 10% of this business living with and
                related to an SBA employee or IS an SBA employee?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="sbaEmployee-choice"
                  v-bind:value="true"
                  v-model="sbaEmployee"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="sbaEmployee-choice"
                  v-bind:value="false"
                  v-model="sbaEmployee"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Is anyone directly associated with the business a former SBA
                employee who's been separated from the SBA for less than 1
                year?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="formerEmployee-choice"
                  v-bind:value="true"
                  v-model="formerEmployee"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="formerEmployee-choice"
                  v-bind:value="false"
                  v-model="formerEmployee"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Is anyone owning at least 10% of this business a member of
                Congress or an employee of the Federal Government's legislative
                or judicial branch?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="fedGovEmployee-choice"
                  v-bind:value="true"
                  v-model="fedGovEmployee"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="fedGovEmployee-choice"
                  v-bind:value="false"
                  v-model="fedGovEmployee"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Is anyone owning at least 10% of this business an employee of
                the Federal Government or Member of the Military with a rank of
                Major and above (or grade GS-13+)?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="militaryEmployee-choice"
                  v-bind:value="true"
                  v-model="militaryEmployee"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="militaryEmployee-choice"
                  v-bind:value="false"
                  v-model="militaryEmployee"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label
                >Is anyone owning at least 10% of this business a member of a
                Small Business Advisory Council or a SCORE volunteer?</label
              >
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="sbacScore-choice"
                  v-bind:value="true"
                  v-model="sbacScore"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="sbacScore-choice"
                  v-bind:value="false"
                  v-model="sbacScore"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
            </div>
          </div>

          <div
            class="
              intro-y
              col-span-12
              flex
              items-center
              justify-center
              sm:justify-end
              mt-5
            "
          >
            <button
              class="btn btn-secondary w-24"
              @click="handlePrev"
              v-if="!isPending"
            >
              Previous
            </button>
            <button class="btn btn-dark mr-1 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>

            <button
              class="btn btn-primary w-24 ml-2"
              @click="handleFinish"
              v-if="!isPending"
            >
              Save & Continue
            </button>
            <button class="btn btn-dark mr-1 mb-2" v-else-if="isPending">
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
            <!-- <button class="btn btn-success w-24 ml-2" @click="handleFinishandQuit" v-if="!isPending">Save & Quit</button>
                <button class="btn btn-dark mr-1 mb-2" v-else-if="isPending">
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button> -->
          </div>
        </div>
        <!-- END: Page 3 -->

        <div
          class="w-full border-t border-gray-200 dark:border-dark-5 mt-5"
        ></div>

        <!-- START: More Info Slide Overs -->
        <div id="ocEPC-slide-over" class="p-5">
          <div class="preview">
            <!-- BEGIN: Slide Over Content -->
            <div
              id="basic-slide-over-preview"
              class="modal modal-slide-over"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header p-5">
                    <h2
                      class="font-medium text-base mr-auto"
                      v-if="ocEPC == true"
                    >
                      Operating Companies (OC) vs. Eligible Passive Companies
                      (EPC)
                    </h2>
                    <h2
                      class="font-medium text-base mr-auto"
                      v-if="cooperative == true"
                    >
                      Business Ownership Options
                    </h2>
                    <h2
                      class="font-medium text-base mr-auto"
                      v-if="coApplicant == true"
                    >
                      A reminder about 7(a) Loan Co-Applicants
                    </h2>
                  </div>
                  <div class="modal-body" v-if="ocEPC == true">
                    <p>
                      A <strong>cooperative</strong> are companies that makes or
                      does something and sells it to a customer or client.
                    </p>
                    <br />
                    <p>
                      <strong>Eligible Passive Company (EPC)</strong> is a
                      company that lends property to operating companies.
                    </p>
                  </div>
                  <div class="modal-body" v-if="cooperative == true">
                    <p>
                      A <strong>cooperative or co-op</strong> is a business
                      where each employee owns an equal part of the business.
                    </p>
                    <br />
                    <p>
                      An
                      <strong>Employee Stock Ownership Plan or ESOP</strong> is
                      a retirement plan that gives employees parts of the
                      company as a retirement benefit
                    </p>
                    <br />
                    <p>
                      A <strong>401(k) Plan</strong> lets employees set aside
                      money from their paychecks for the future
                    </p>
                    <br />
                    <p>
                      A <strong>Trust</strong> are companies that makes or does
                      something and sells it to a customer or client.
                    </p>
                  </div>
                  <div class="modal-body" v-if="coApplicant == true">
                    <p>
                      If you have
                      <strong
                        >co-applicants, or people that would share the
                        responsibility of repaying this 7a loan</strong
                      >, then
                      <strong
                        ><i
                          >each of them will have to complete the Business
                          Information section of the application separately</i
                        ></strong
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- END: More Info Slide Overs -->
          </div>
        </div>
        <!-- END: OC vs EPC Slide Over -->
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import getUser from '@/composables/getUser'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import getDocument from '@/composables/getDocument'
import { useRouter } from 'vue-router'

export default {
  props: ['appID'],
  setup(props) {
    const router = useRouter()
    const { user } = getUser()
    const { error: useCollectionError, addDoc } = useCollection('applications')
    const {
      error: getDocumentError,
      getDocumentByID,
      document: application
    } = getDocument('applications', props.appID)

    const {
      error: useDocumentError,
      updateDoc,
      isPending
    } = useDocument('applications', props.appID)

    const page = ref(router.currentRoute.value.query.page != null ? router.currentRoute.value.query.page : 1)
    const subPage = ref(1);

    // General Business Info
    const generalBusinessInfo = reactive({
      page1: {
        oc: null,
        businessName: '',
        ocBusinessName: '',
        ownershipType: null,
        equity401k: null,
        managementCompany: null
      },
      page2: {
        address: '',
        taxID: '',
        phoneNumber: '',
        projectAddress: '',
        name: '',
        email: ''
      },
      page3: {
        loanRequest: 0,
        existingEmployees: 0,
        jobsCreated: 0,
        jobsRetained: 0
      }
    });

    // Questions
    const questions = reactive({
      coApplicants: null,
      appliedBefore: null,
      returnApplicant: null,
      presentlyDelinquent: null,
      defaulted: null,
      ineligible: null,
      franchise: null,
      bProtection: null,
      pendingLegal: null,
      exported: null,
      exportSales: 0,
      exportCountry1: '',
      exportCountry2: '',
      exportCountry3: '',
      thirdParty: null,
      illegalActivity: null
    });

    // Conflict of Interest Questions
    const conflictOfInterest = reactive({
      sbaEmployee: null,
      formerEmployee: null,
      fedGovEmployee: null,
      militaryEmployee: null,
      sbacScore: null
    });

    //  More Info Refs
    const ocEPC = ref(null);
    const cooperative = ref(null);
    const coApplicant = ref(null);

    const handleNext = () => {
      page.value++
      subPage.value = 1
    }

    const handleNextSubpage = () => {
      subPage.value++
    }

    const handlePrevSubpage = () => {
      subPage.value--
    }

    const handlePrev = () => {
      if (page.value == 2) {
        subPage.value = 3
        page.value--
      } else {
        page.value--
      }
    }

    const handleFinish = async () => {
      if (generalBusinessInfo.page2.projectAddress == '') {
        generalBusinessInfo.page2.projectAddress =
          generalBusinessInfo.page2.address
      }

      const application = {
        businessInfo: {
          generalBusinessInfo: {
            ...generalBusinessInfo.page1,
            ...generalBusinessInfo.page2,
            ...generalBusinessInfo.page3
          },
          questions: {
            ...questions
          },
          conflictOfInterest: {
            ...conflictOfInterest
          }
        },
        currentSection: 2,
      }

      await updateDoc(application)

      router.push({ name: 'side-menu-7a-section-2' })
    }

    // const handleFinishandQuit = async () => {
    //   const application = {
    //     section1: {
    //       status: 'In Progress',
    //       business: {
    //         name: businessName.value,
    //         ocName: oc.value == false ? ocBusinessName.value : '',
    //         type: ownershipType.value,
    //       },
    //       dbaName: '',
    //       equity401k: equity401k.value,
    //       managementCompany: managementCompany.value,

    //       primaryAddress: address.value,
    //       taxID: taxID.value,
    //       primaryPhone: phoneNumber.value,
    //       projectAddress: projectAddress.value,
    //       primaryContact: {
    //         name: name.value,
    //         email: email.value,
    //       },

    //       loanRequest: loanRequest.value,
    //       existingEmployees: existingEmployees.value,
    //       jobsCreated: jobsCreated.value,
    //       jobsRetained: jobsRetained.value,

    //       questions: {
    //         coApplicants: coApplicants.value,
    //         appliedBefore: appliedBefore.value,

    //         returnApplicant: returnApplicant.value,
    //         presentlyDelinquent: returnApplicant.value == true ? presentlyDelinquent.value : false,
    //         defaulted: returnApplicant.value == true ? defaulted.value : false,

    //         ineligible: ineligible.value,
    //         franchise: franchise.value,
    //         bProtection: bProtection.value,
    //         pendingLegal: pendingLegal.value,

    //         exported: exported.value,
    //         exportCountry1: exportCountry1.value,
    //         exportCountry2: exportCountry2.value ? exportCountry2.value : '',
    //         exportCountry3: exportCountry3.value ? exportCountry3.value : '',

    //         thirdParty: thirdParty.value,
    //         illegalActivity: illegalActivity.value,
    //         conflict: {
    //           sbaEmployee: sbaEmployee.value,
    //           formerEmployee: formerEmployee.value,
    //           fedGovEmployee: fedGovEmployee.value,
    //           militaryEmployee: militaryEmployee.value,
    //           sbacScore: sbacScore.value,
    //         }
    //       }
    //     },
    //   };

    //   await addDoc(application);

    //   router.push({ name: 'portal-page' });
    // }

    const toggleOCEPC = () => {
      ocEPC.value = true
      cooperative.value = false
      coApplicant.value = false
    }

    const toggleCooperative = () => {
      ocEPC.value = false
      cooperative.value = true
      coApplicant.value = false
    }

    const toggleCoApplicant = () => {
      ocEPC.value = false
      cooperative.value = false
      coApplicant.value = true
    }

    onMounted(async () => {
      await getDocumentByID('applications', props.appID);
      
      // If values exist in the DB, populate them in the form
      if (application.value.businessInfo != null) {
        let appBusinessInfo = application.value.businessInfo.generalBusinessInfo;
        let appQuestions = application.value.businessInfo.questions;
        let appCOI = application.value.businessInfo.conflictOfInterest;

        Object.keys(appBusinessInfo).forEach(function(key) {
          if(key in generalBusinessInfo.page1) {
            generalBusinessInfo.page1[key] = appBusinessInfo[key];
          } else if (key in generalBusinessInfo.page2) {
            generalBusinessInfo.page2[key] = appBusinessInfo[key];
          } else if (key in generalBusinessInfo.page3) {
            generalBusinessInfo.page3[key] = appBusinessInfo[key];
          }
        })

        Object.keys(appQuestions).forEach(function(key) {
          if(key in questions) {
            questions[key] = appQuestions[key];
          }
        })

        Object.keys(appCOI).forEach(function(key) {
          if(key in conflictOfInterest) {
            conflictOfInterest[key] = appCOI[key];
          }
        })
      }
    })

    return {
      ...toRefs(generalBusinessInfo.page1), ...toRefs(generalBusinessInfo.page2), ...toRefs(generalBusinessInfo.page3),
      ...toRefs(questions), ...toRefs(conflictOfInterest),
      page, subPage, handleNext, handlePrev, handleFinish, handleNextSubpage, handlePrevSubpage, isPending,
      ocEPC, cooperative, coApplicant, toggleOCEPC, toggleCooperative, toggleCoApplicant
    }
  }
}
</script>
