<template>
  <div class="flex flex-wrap">
    <div class="grid grid-cols-1 gap-6 p-10">
      <div class="col-span-1">
        <h1 class="text-lg font-medium truncate"> Report your Real Estate</h1>
        <small class="text-theme-25 intro-y">You can report your real estate by updating your information in
          appropriate columns. </small>
      </div>
    </div>
  </div>
  <div class="col-span-6">
  </div>
  <div class="flex flex-wrap">
    <div class="w-full">
      <a class="btn btn-primary mb-4  text-blue-600 hover:text-blue-800 visited:text-purple-600" href="javascript:;"
         data-toggle="modal" data-target="#exampleModalLabel" @click="setClearValuesFromRow()">
        <PlusIcon class="w-4 h-4 mr-1"/>
        New Real Estate
      </a>
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
           style="background-color: #fafafa;">
        <div class="px-12 py-12 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': countRealEstate !== 0 }">
              <div class="text-center">
                No Real Estate Records
              </div>
            </div>
            <div v-bind:class="{'hidden': countRealEstate === 0}">
              <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <table class="table table-report -mt-2">
                  <thead>
                  <tr>
                    <th class="whitespace-nowrap">Category</th>
                    <th class="text-center whitespace-nowrap">Updated Date</th>
                    <th class="text-center whitespace-nowrap">Property Address</th>
                    <th class="text-center whitespace-nowrap">Mortgage Balance</th>
                    <th class="text-center whitespace-nowrap">Action</th>
                    <!--    <th class="text-center whitespace-nowrap">ACTIONS</th> -->
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(values , index) in this.$store.state.financials.realestate" class="intro-x">
                    <td class="w-40 uppercase">
                      {{ values.typeRealEstate }}
                    </td>
                    <td class="w-40 text-center">
                      {{ values.updatedDate }}
                    </td>
                    <td class="w-40 text-center">
                      {{ values.propertyAddress1 }}
                    </td>
                    <td class="w-40 text-center">
                      ${{ values.mortgageBalance }}
                    </td>
                    <td class="w-40 text-center">
                      <a href="javascript:;" data-toggle="modal" data-target="#exampleModalLabel"
                         @click="setValuesFromRow(index)">
                        <EditIcon class="w-4 h-4 mr-1"/>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- END: Pagination -->
  <div id="exampleModalLabel"
       class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto" tabindex="-1"
       aria-hidden="true" @click.self="resetDeleteID()">
    <div class="modal-dialog modal-fullscreen relative w-auto pointer-events-none" style="width: 60%;">
      <div
        class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
        <div
          class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
          <h5 class="text-xl font-medium leading-normal text-gray-800">Edit Real Estate</h5>
          <button type="button"
                  class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-dismiss="modal"
                  aria-label="Close">X
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="
                    px-5
                    sm:px-20
                    mt-1
                    pt-1
                    dark:border-dark-5
                  ">


            <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
              <div class="intro-y col-span-12 sm:col-span-6">
                <label for="input-wizard-12" class="form-label">Select Real Estate Type</label><select
                id="input-wizard-12" class="form-select" v-model="typeRealEstate">
                <option>Primary</option>
                <option>Rental</option>
                <option>Land</option>
                <option>Other</option>
              </select>
              </div>
            </div>

            <div class="grid grid-cols-6 gap-4 gap-y-5 mt-5">
              <div class="intro-y col-span-3 sm:col-span-3">
                <label for="input-wizard-9" class="form-label">Property Address 1</label>
                <input id="input-wizard-9" type="text" class="form-control" v-model="propertyAddress1"/>
                <template v-if="errorValidation.address1 !== ''">
                  <small class="text-theme-21 mt-2">
                    {{ errorValidation.address1 }} <br>
                  </small>
                </template>
              </div>
              <div class="intro-y col-span-3 sm:col-span-3">
                <label for="input-wizard-9" class="form-label">Holder Address 1</label>
                <input id="input-wizard-9" type="text" class="form-control" v-model="holderAddress1"/>
                <template v-if="errorValidation.holderAddress1 !== ''">
                  <small class="text-theme-21 mt-2">
                    {{ errorValidation.holderAddress1 }} <br>
                  </small>
                </template>
              </div>
              <div class="intro-y col-span-3">
                <label for="input-wizard-9" class="form-label">Property Address 2</label>
                <input id="input-wizard-9" type="text" class="form-control" v-model="propertyAddress2"/>
                <template v-if="errorValidation.propertyAddress2 !== ''">
                  <small class="text-theme-21 mt-2">
                    {{ errorValidation.propertyAddress2 }} <br>
                  </small>
                </template>
              </div>
              <div class="intro-y col-span-3">
                <label for="input-wizard-9" class="form-label">Holder Address 2</label>
                <input id="input-wizard-9" type="text" class="form-control" v-model="holderAddress2"/>
                <template v-if="errorValidation.holderAddress2 !== ''">
                  <small class="text-theme-21 mt-2">
                    {{ errorValidation.holderAddress2 }} <br>
                  </small>
                </template>
              </div>

            </div>


            <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
              <div class="intro-y col-span-2">
                <label for="input-wizard-9" class="form-label">Property City</label>
                <input id="input-wizard-9" type="text" class="form-control" v-model="propertyCity"/>
                <template v-if="errorValidation.propertyCity !== ''">
                  <small class="text-theme-21 mt-2">
                    {{ errorValidation.propertyCity }} <br>
                  </small>
                </template>
              </div>
              <div class="intro-y col-span-2">
                <label for="input-wizard-12" class="form-label">Property State</label>
                <select id="input-wizard-12" class="form-select" v-model="propertyState">
                  <option selected value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>


              </div>
              <div class="intro-y col-span-2">
                <label for="input-wizard-9" class="form-label">Property Zip</label>
                <input id="input-wizard-9" type="text" class="form-control" v-mask="'#####'" v-model="propertyZipcode"/>
                <template v-if="errorValidation.zipcode !== ''">
                  <small class="text-theme-21 mt-2">
                    {{ errorValidation.propertyZipcode }} <br>
                  </small>
                </template>
              </div>
              <div class="intro-y col-span-2">
                <label for="input-wizard-9" class="form-label">Holder City</label>
                <input id="input-wizard-9" type="text" class="form-control" v-model="holderCity"/>
                <template v-if="errorValidation.holderCity !== ''">
                  <small class="text-theme-21 mt-2">
                    {{ errorValidation.holderCity }} <br>
                  </small>
                </template>
              </div>
              <div class="intro-y col-span-2">
                <label for="input-wizard-12" class="form-label">Holder State</label>
                <select id="input-wizard-12" class="form-select" v-model="holderState">
                  <option selected value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>


              </div>
              <div class="intro-y col-span-2">
                <label for="input-wizard-9" class="form-label">Holder Zip</label>
                <input id="input-wizard-9" type="text" class="form-control" v-mask="'#####'" v-model="holderZipcode"/>
                <template v-if="errorValidation.holderZipcode !== ''">
                  <small class="text-theme-21 mt-2">
                    {{ errorValidation.zipcode }} <br>
                  </small>
                </template>
              </div>
            </div>


            <div class="mt-10 border-t border-gray-200">

              <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div class="intro-y col-span-4 sm:col-span-3">
                  <label for="input-wizard-9" class="form-label">Purchase Date</label>

                  <div id="input-group-datepicker">
                    <div class="relative w-30">
                      <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4">
                        <CalendarIcon class="w-4 h-4"/>
                      </div>
                      <Litepicker
                        v-model="mortgagePurchaseDate"
                        :options="{
                        format: 'MM-DD-YYYY',
                        autoApply: true,
                        showWeekNumbers: false,
                        dropdowns: {
                          minYear: 1930,
                          maxYear: null,
                          months: true,
                          years: true
                        }
                      }"
                        class="form-control pl-12"
                      />
                    </div>
                  </div>
                </div>
                <div class="intro-y col-span-3 sm:col-span-3">
                  <label for="input-wizard-92" class="form-label">Mortgage Account Number</label>
                  <input id="input-wizard-9" type="text" class="form-control" v-model="mortgageAccountNumber"/>
                </div>
                <div class="intro-y col-span-3 sm:col-span-3">
                  <label for="input-wizard-93" class="form-label">Original Cost</label>
                  <CurrencyInput id="input-wizard-93" type="text" class="form-control" v-model="mortgageOriginalCost"
                                 :options="opt"/>
                </div>
                <div class="intro-y col-span-3 sm:col-span-3">
                  <label for="input-wizard-94" class="form-label">Present Market Value</label>
                  <CurrencyInput id="input-wizard-94" type="text" class="form-control"
                                 v-model="mortgagePresentMarketValue" :options="opt"/>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
                <div class="intro-y col-span-4 sm:col-span-3">
                  <label for="input-wizard-9" class="form-label">Mortgage Balance</label>
                  <CurrencyInput id="input-wizard-9" type="text" class="form-control" v-model="mortgageBalance"
                                 :options="opt"/>
                </div>

                <div class="intro-y col-span-4 sm:col-span-3">
                  <label for="input-wizard-911" class="form-label">Payment Per Month</label>
                  <CurrencyInput id="input-wizard-911" type="text" class="form-control"
                                 v-model="mortgagePaymentPerMonth" :options="opt"/>
                </div>


                <div class="intro-y col-span-8 sm:col-span-3">
                  <label for="input-wizard-12" class="form-label">Status of Mortgage</label><select id="input-wizard-12"
                                                                                                    class="form-select"
                                                                                                    v-model="mortgageStatus">
                  <option>Current</option>
                  <option>Delinquent</option>
                </select>
                </div>


              </div>
            </div>


            <div class="text-right mt-5">
              <button class="btn btn-outline-secondary w-24 mr-1" data-dismiss="modal" aria-label="Cancel">
                Cancel
              </button>
              <button class="btn btn-primary w-24 mr-1" data-dismiss="modal" aria-label="Save" @click="saveValues()">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed
} from 'vue'
import {
  useStore
} from '@/store'


import {
  object,
  string,
  number,
  bool
} from "yup";
import * as yup from 'yup';
import {
  mask
} from 'vue-the-mask';

import CurrencyInput from "@/components/currency-input/Main.vue";


const dateRegExp =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/


const notesPayableSchema = object().shape({
  propertyAddress1: string().required('Property address1 required'),
  propertyAddress2: string().required('Property address2 field'),
  holderAddress1: string().required('Holder address1 required field'),
  holderAddress2: string().required('Holder address1 required field'),
  zipcode: string().required('Zipcode is a required field').matches(/^[0-9]+$/, "Must be only digits").min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
});

export default {
  name: "real-estate",
  directives: {
    mask
  },
  components: {
    CurrencyInput,
  },
  data() {

    let errorValidation = {
      propertyAddress1: '',
      propertyAddress2: '',
      holderAddress1: '',
      holderAddress2: '',
      zipcode: ''
    };

    const opt = {
      currency: 'USD',
      currencyDisplay: 'symbol',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      precision: 0
    }

    const store = useStore();
    return {
      store,
      opt,
      currentSaveIndex: 0,
      errorValidation,
      realestate: {
        updatedDate: '',
        typeRealEstate: 'Primary',
        propertyAddress1: '',
        propertyAddress2: '',
        propertyCity: '',
        propertyState: 'AL',
        propertyZipcode: 0,
        holderAddress1: '',
        holderAddress2: '',
        holderCity: '',
        holderState: 'AL',
        holderZipcode: 0,
        mortgagePurchaseDate: '',
        mortgageAccountNumber: '',
        mortgageOriginalCost: 0,
        mortgagePresentMarketValue: 0,
        mortgageBalance: 0,
        mortgagePaymentPerMonth: 0,
        mortgageStatus: 'Current',
      }
    }
  },


  computed: {
    countRealEstate: function () {
      return this.$store.state.financials.realestate.length;
    },

    typeRealEstate: {
      get() {
        return this.realestate.typeRealEstate;
      },
      set(value) {
        this.realestate.typeRealEstate = value;
      }
    },
    propertyAddress1: {
      get() {
        return this.realestate.propertyAddress1;
      },
      set(value) {
        this.validate('propertyAddress1', value);
      }
    },
    propertyAddress2: {
      get() {
        return this.realestate.propertyAddress2;
      },
      set(value) {
        this.validate('propertyAddress2', value);
      }
    },
    propertyCity: {
      get() {
        return this.realestate.propertyCity;
      },
      set(value) {
        this.realestate.propertyCity = value;
      }
    },
    propertyState: {
      get() {
        return this.realestate.propertyState;
      },
      set(value) {
        this.realestate.propertyState = value;
      }
    },
    propertyZipcode: {
      get() {
        return this.realestate.propertyZipcode;
      },
      set(value) {
        this.realestate.propertyZipcode = value;
      }
    },
    holderAddress1: {
      get() {
        return this.realestate.holderAddress1;
      },
      set(value) {
        this.validate('holderAddress1', value);
      }
    },
    holderAddress2: {
      get() {
        return this.realestate.holderAddress2;
      },
      set(value) {
        this.validate('holderAddress2', value);
      }
    },
    holderCity: {
      get() {
        return this.realestate.holderCity;
      },
      set(value) {
        this.realestate.holderCity = value;
      }
    },
    holderState: {
      get() {
        return this.realestate.holderState;
      },
      set(value) {
        this.realestate.holderState = value;
      }
    },
    holderZipcode: {
      get() {
        return this.realestate.holderZipcode;
      },
      set(value) {
        this.realestate.holderZipcode = value;
      }
    },

    mortgagePurchaseDate: {
      get() {
        return this.realestate.mortgagePurchaseDate;
      },
      set(value) {
        this.realestate.mortgagePurchaseDate = value;
      }
    },
    mortgageAccountNumber: {
      get() {
        return this.realestate.mortgageAccountNumber;
      },
      set(value) {
        this.realestate.mortgageAccountNumber = value;
      }
    },
    mortgageOriginalCost: {
      get() {
        return this.realestate.mortgageOriginalCost;
      },
      set(value) {
        this.realestate.mortgageOriginalCost = value;
      }
    },
    mortgagePresentMarketValue: {
      get() {
        return this.realestate.mortgagePresentMarketValue;
      },
      set(value) {
        this.realestate.mortgagePresentMarketValue = value;
      }
    },
    mortgageBalance: {
      get() {
        return this.realestate.mortgageBalance;
      },
      set(value) {
        this.realestate.mortgageBalance = value;
      }
    },
    mortgagePaymentPerMonth: {
      get() {
        return this.realestate.mortgagePaymentPerMonth;
      },
      set(value) {
        this.realestate.mortgagePaymentPerMonth = value;
      }
    },
    mortgageStatus: {
      get() {
        return this.realestate.mortgageStatus;
      },
      set(value) {
        this.realestate.mortgageStatus = value;
      }
    },
  },

  methods: {

    async validate(field, value) {
      let obj = {};
      obj[field] = value;

      await notesPayableSchema
        .validateAt(field, obj).then(() => {
          this.errorValidation[field] = '';
          this.realestate[field] = value;

        }).catch(err => {
          console.log(Object.keys(err).length);
          if (Object.keys(err).length !== 0) {
            // this.notespayable[field] = '';
            this.errorValidation[err.path] = err.message;
            console.log(err.message);
          }
          this.realestate[field] = value;
        });
    },

    setValuesFromRow: function (index) {
      this.currentSaveIndex = index;
      console.log("Setting currentSaveIndex: " + JSON.stringify(this.currentSaveIndex));
      this.realestate.typeRealEstate = this.$store.state.financials.realestate[index].typeRealEstate;
      this.realestate.propertyAddress1 = this.$store.state.financials.realestate[index].propertyAddress1;
      this.realestate.propertyAddress2 = this.$store.state.financials.realestate[index].propertyAddress2;
      this.realestate.propertyCity = this.$store.state.financials.realestate[index].propertyCity;
      this.realestate.propertyState = this.$store.state.financials.realestate[index].propertyState;
      this.realestate.propertyZip = this.$store.state.financials.realestate[index].propertyZip;

      this.realestate.holderAddress1 = this.$store.state.financials.realestate[index].holderAddress1;
      this.realestate.holderAddress2 = this.$store.state.financials.realestate[index].holderAddress2;
      this.realestate.holderCity = this.$store.state.financials.realestate[index].holderCity;
      this.realestate.holderState = this.$store.state.financials.realestate[index].holderState;
      this.realestate.holderZip = this.$store.state.financials.realestate[index].holderZip;


      this.realestate.mortgagePurchaseDate = this.$store.state.financials.realestate[index].mortgagePurchaseDate;
      this.realestate.mortgageAccountNumber = this.$store.state.financials.realestate[index].mortgageAccountNumber;
      this.realestate.mortgageOriginalCost = this.$store.state.financials.realestate[index].mortgageOriginalCost;
      this.realestate.mortgagePresentMarketValue = this.$store.state.financials.realestate[index].mortgagePresentMarketValue;
      this.realestate.mortgageBalance = this.$store.state.financials.realestate[index].mortgageBalance;
      this.realestate.mortgagePaymentPerMonth = this.$store.state.financials.realestate[index].mortgagePaymentPerMonth;
      this.realestate.mortgageStatus = this.$store.state.financials.realestate[index].mortgageStatus;

      // this.modalTitle = Category;
      // this.Category = Category;
      // this.editModalAmount = amount;
      // this.currentCategory = currentCategory;
    },
    setClearValuesFromRow: function () {
      console.log("setClearValueFromRow");
      this.currentSaveIndex = 0;
      this.realestate.updatedDate = '';
      this.realestate.typeRealEstate = 'Primary';
      this.realestate.propertyAddress1 = '';
      this.realestate.propertyAddress2 = '';
      this.realestate.propertyCity = '';
      this.realestate.propertyState = 'AL';
      this.realestate.propertyZipcode = '';
      this.realestate.holderAddress1 = '';
      this.realestate.holderAddress2 = '';
      this.realestate.holderCity = '';
      this.realestate.holderState = 'AL';
      this.realestate.holderZipcode = '';
      this.realestate.mortgagePurchaseDate = '';
      this.realestate.mortgageAccountNumber = '';
      this.realestate.mortgageOriginalCost = 0;
      this.realestate.mortgagePresentMarketValue = 0;
      this.realestate.mortgageBalance = 0;
      this.realestate.mortgagePaymentPerMonth = 0;
      this.realestate.mortgageStatus = 'Current';
    },

    resetDeleteID() {
    },
    getCurrentDateStr() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      return mm + '/' + dd + '/' + yyyy;
    },
    saveValues: function (indexNotesPayable) {
      console.log("saveEditValues.currentIndex: " + this.currentSaveIndex);

      let x = {
        typeRealEstate: this.realestate.typeRealEstate,
        updatedDate: this.getCurrentDateStr(),
        propertyAddress1: this.realestate.propertyAddress1,
        propertyAddress2: this.realestate.propertyAddress2,
        propertyCity: this.realestate.propertyCity,
        propertyState: this.realestate.propertyState,
        propertyZipcode: this.realestate.propertyZipcode,
        holderAddress1: this.realestate.holderAddress1,
        holderAddress2: this.realestate.holderAddress2,
        holderCity: this.realestate.holderCity,
        holderState: this.realestate.holderState,
        holderZipcode: this.realestate.holderZipcode,
        mortgagePurchaseDate: this.realestate.mortgagePurchaseDate,
        mortgageAccountNumber: this.realestate.mortgageAccountNumber,
        mortgageOriginalCost: this.realestate.mortgageOriginalCost,
        mortgagePresentMarketValue: this.realestate.mortgagePresentMarketValue,
        mortgageBalance: this.realestate.mortgageBalance,
        mortgagePaymentPerMonth: this.realestate.mortgagePaymentPerMonth,
        mortgageStatus: this.realestate.mortgageStatus,
      }

      console.log(x);

      this.store.commit('financials/setRealEstate', {
        index: this.currentSaveIndex,
        realestate: {
          typeRealEstate: this.realestate.typeRealEstate,
          updatedDate: this.getCurrentDateStr(),
          propertyAddress1: this.realestate.propertyAddress1,
          propertyAddress2: this.realestate.propertyAddress2,
          propertyCity: this.realestate.propertyCity,
          propertyState: this.realestate.propertyState,
          propertyZipcode: this.realestate.propertyZipcode,
          holderAddress1: this.realestate.holderAddress1,
          holderAddress2: this.realestate.holderAddress2,
          holderCity: this.realestate.holderCity,
          holderState: this.realestate.holderState,
          holderZipcode: this.realestate.holderZipcode,
          mortgagePurchaseDate: this.realestate.mortgagePurchaseDate,
          mortgageAccountNumber: this.realestate.mortgageAccountNumber,
          mortgageOriginalCost: this.realestate.mortgageOriginalCost,
          mortgagePresentMarketValue: this.realestate.mortgagePresentMarketValue,
          mortgageBalance: this.realestate.mortgageBalance,
          mortgagePaymentPerMonth: this.realestate.mortgagePaymentPerMonth,
          mortgageStatus: this.realestate.mortgageStatus,
        }
      });


      this.store.dispatch('financials/' +
        'submitFirebase');
    },
  },


}
</script>