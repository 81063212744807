<template>
<DataTable :pageTitle="pageTitle" :showSearch="showSearch" :addRecordButtonProps="addRecordButtonProps" :columns="columns" :tableData="tableData" :organization="organization"/>
<div id="edit-assets-modal" class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto" tabindex="-1" aria-hidden="true" @click.self="resetDeleteID()">
  <div class="modal-dialog modal-fullscreen relative w-auto pointer-events-none" style="width: 60%;">
    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">{{ displayName }} </h5>
        <button type="button" class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-dismiss="modal"
          aria-label="Close">X</button>
      </div>
      <div class="modal-body p-5">

        <div class="grid grid-cols-4 gap-4 p-4">
          <InfoBox :class=debitCoverageRatio title='Debt Coverage Ratio' :value=debitCoverageRatioCalc />
          <InfoBox :class=yrsInBusiness title='Years in Business' :value=yearsInBusiness  />
          <InfoBox :class=crdScore title='Credit Score' :value=creditScore />
          <InfoBox :class=businessStrength title='Business Health' :value=businessStrengthRating />

        </div>



        <div class="text-right mt-5">
          <button class="btn btn-outline-secondary w-24 mr-1" data-dismiss="modal" aria-label="Cancel">
            Cancel
          </button>
          <button class="btn btn-primary w-24 mr-1" data-dismiss="modal" aria-label="Save" @click="">
            Save
          </button>

        </div>




      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  reactive,
  onMounted
} from 'vue';
import {
  useRouter
} from 'vue-router';
import DataTable from '@/components/data-table/Main.vue';
import getUser from '@/composables/getUser';
import getDocument from '@/composables/getDocument';
import userCRUD from '@/composables/userCRUD';
import {
  faker
} from '@/utils/faker';
import InfoBox from '@/components/info-box/Main.vue';





export default defineComponent({
  props: ['org'],
  components: {
    DataTable,
    InfoBox
  },
  setup(props) {
    const displayName = ref();
    const totalDebtService = ref();
    const totalNetIncome = ref();
    const debitCoverageRatioCalc = ref();

    const organization = ref(props.org);

    const router = useRouter();
    const {
      user
    } = getUser();

    const creditScore = ref()
    const yearsInBusiness = ref()

    const {
      error: getDocumentError,
      getDocumentByID,
      getDocumentByEmail,
      getDocumentByOrgName,
      document: profileDetails
    } = getDocument();

    const orgName = ref(null);
    const {
      getUserByID,
      user: result,
      disableUser,
      enableUser
    } = userCRUD();

    const pageTitle = props.org + " - Member Management";
    const showSearch = false;

    const addRecordButtonProps = {
      value: "Invite a Member",
      action: addRecordAction
    }




    function addRecordAction() {
      router.push({
        name: 'invite-user',
        query: {
          org: props.org
        }
      });
    }

    var columns = [{
        formatter: 'responsiveCollapse',
        width: 40,
        minWidth: 30,
        hozAlign: 'center',
        resizable: false,
        headerSort: false
      },
      {
        title: "NAME",
        field: "name",
        sorter: "string",
        width: 200,
        formatter: nameFormatter
      },
      {
        title: "COMPANY NAME",
        field: "companyName"
      },
      {
        title: "EMAIL",
        field: "email"
      },
      {
        title: "ACTIONS",
        field: "actions",
        formatter: actionsFormatter
      },
    ];

    function nameFormatter(cell) {
      return `<div>
                <div class="font-medium whitespace-nowrap ${cell.getData().disabled == true ? 'text-gray-600' : ''}">${
                  cell.getData().name
                }</div>
                <div class="text-gray-600 text-xs whitespace-nowrap ${cell.getData().admin == true ? 'text-theme-25' : ''}">${
                  cell.getData().admin == true ? 'Org. Admin' : 'Member'
                } ${cell.getData().disabled == true ? '(Disabled)' : ''}</div>
              </div>`
    }

    function actionsFormatter(cell) {

      const a = cash(`<div class="flex lg:justify-center items-center">
        ${cell.getData().disabled != true ?
        `<a class="flex items-center mr-3" data-toggle="modal"  href="javascript:;" data-target="#edit-assets-modal">
          <i data-feather="check-square" class="w-4 h-4 mr-1"></i> <u>Business</u>
        </a>` : ''}
          ${cell.getData().disabled != true ?
          `<a class="flex items-center mr-3" href="javascript:;">
            <i data-feather="check-square" class="w-4 h-4 mr-1"></i> <u>Edit</u>
          </a>` : ''}
          ${cell.getData().id != user.value.uid ?
              `<a class="flex items-center ${cell.getData().disabled == true ? "text-theme-25" : "text-theme-21"} " href="javascript:;">
            <i data-feather=${cell.getData().disabled == true ? "user-check" : "user-x"} class="w-4 h-4 mr-1"></i> <u>${cell.getData().disabled == true ? "Enable" : "Disable"}</u>
          </a>` : ''}
        </div>`);

      cash(a).on('click', async function(e) {
        // On click actions
        var memberIDString = window.btoa(cell.getData().uid);
        var memberNameString = window.btoa(cell.getData().name);

        switch (e.target.outerText) {
          case 'View':
            router.push({
              path: `/member-dashboard`,
              query: {
                org: cell.getData().orgName,
                member: memberIDString,
                name: memberNameString
              }
            });
            break;
          case 'Edit':
            router.push({
              path: `/edit-user/${cell.getData().id}`,
              query: {
                id: cell.getData().uid,
                org: cell.getData().orgName,
                email: cell.getData().email
              }
            });
            break;
          case 'Business':
            displayName.value = cell.getData().name;

            getDocumentByID("required-form", cell.getData().email).then(() => {
              yearsInBusiness.value = profileDetails.value.bizInfo.yrsInBusiness;
              creditScore.value = profileDetails.value.ownerInfo.creditScore;
              totalNetIncome.value = profileDetails.value.sourceIncome.totalNetIncome;
            }).then(() => {
              getDocumentByID("financials", cell.getData().email).then(() => {
                totalDebtService.value = 0;
                profileDetails.value.notespayable.forEach(function(item) {
                  if (!isNaN(item.paymentAmount)) {
                    totalDebtService.value = totalDebtService.value + parseInt(item.paymentAmount) * 12;
                  }
                });

                debitCoverageRatioCalc.value = (totalNetIncome.value / totalDebtService.value).toFixed(1);

                console.log("yearsInBusiness: " + yearsInBusiness.value);
                console.log("creditScore: " + creditScore.value);
                console.log("displayName: " + displayName.value);
                console.log("totalDebtService:" + totalDebtService.value);

              })
            });

            break;
          case 'Disable':
            await disableUser(cell.getData().id).then(() => {
              router.go(0);
            });
            break;
          case 'Enable':
            await enableUser(cell.getData().id).then(() => {
              router.go(0);
            });
            break;
          default:
            break;
        }
      })

      return a[0]
    }

    var tableData = [];

    const yrsInBusiness = computed(function() {
      let yib = "";
      if (yearsInBusiness.value <= 2 ) {
        yib = 'alert-danger'
      } else if (yearsInBusiness.value == 3) {
        yib = 'alert-warning'
      } else if (yearsInBusiness.value > 3) {
        yib = 'alert-success'
      }

      return yib;
    });


    const debitCoverageRatio = computed(function() {
      let dcrValue = totalNetIncome.value / totalDebtService.value;
      /*
      Red < 1.15
      ﻿Yellow 1.15 - 1.24
      ﻿Green 1.25  >
      */
      let dcr = "";
      if (dcrValue <= 1.15 ) {
        dcr = 'alert-danger'
      } else if (dcrValue >= 1.15 && dcrValue <= 1.24) {
        dcr = 'alert-warning'
      } else if (dcrValue >= 1.25) {
        dcr = 'alert-success'
      }

      return dcr
    });

    const crdScore = computed(function() {
      let cs = "";
      if (creditScore.value <= 620 ) {
        cs = 'alert-danger'
      } else if (creditScore.value >= 621 && creditScore.value <= 720) {
        cs = 'alert-warning'
      } else if (creditScore.value >= 721) {
        cs = 'alert-success'
      }

      return cs;
    });

    const businessStrengthRating = computed(() => {
         return ((totalNetIncome.value/totalDebtService.value * .6) +
        (yearsInBusiness.value * .15) +
        (creditScore.value * .25)).toFixed(1);
    });

    const businessStrength = computed(function() {
      let color = '';
      if (businessStrengthRating <= 155.999) {
        color = 'alert-secondary text-red';
      } else if (businessStrengthRating <= 181.194) {
        color = 'alert-secondary  text-yellow';
      } else  {
        color = 'alert-secondary text-green';
      }

      return color
    });

    onMounted(async () => {
      await getDocumentByOrgName('profileDetails', props.org);

     console.log(JSON.stringify("props.org:" + props.org))
     console.log(JSON.stringify("profileDetails.value:" + profileDetails.value))
     console.log(JSON.stringify("profileDetails.value.length:" + profileDetails.value.length))
    })

    return {
      pageTitle,
      addRecordButtonProps,
      showSearch,
      columns,
      displayName,
      creditScore,
      yearsInBusiness,
      tableData,
      totalDebtService,
      businessStrengthRating,
      businessStrength,
      yrsInBusiness,
      debitCoverageRatio,
      debitCoverageRatioCalc,
      organization,
      crdScore
    }
  }
})
</script>
