import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'


const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('valuation')


const state = () => {
  // TODO: must deal with date input type

  return {
    balanceSheet: {
      assets: [],
      liabilities: [],
      equities: []
    },
    incomeStatement: {
      revenue: [],
      expenses: [],
      gains: [],
      losses: []
    },
    cashFlowStatement: {
      operating: [],
      investing: [],
      financing: []
    }
  }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    const {
      user
    } = getUser()
    await addDocWithGenId(this.state.valuation, user._value.email)
  }
}


// mutations
const mutations = {
  addItemAssets(state, item) {
    this.state.valuation.balanceSheet.assets.push(item)
  },
  addItemLiabilities(state, item) {
    this.state.valuation.balanceSheet.liabilities.push(item)
  },
  addItemEquities(state, item) {
    this.state.valuation.balanceSheet.equities.push(item)
  },




  addItemFinancing(state, item) {
    this.state.valuation.cashFlowStatement.financing.push(item)
  },
  addItemInvesting(state, item) {
    this.state.valuation.cashFlowStatement.investing.push(item)
  },
  addItemOperating(state, item) {
    this.state.valuation.cashFlowStatement.operating.push(item)
  },



  addItemExpenses(state, item) {
    this.state.valuation.incomeStatement.expenses.push(item)
  },
  addItemGains(state, item) {
    this.state.valuation.incomeStatement.gains.push(item)
  },
  addItemLoses(state, item) {
    this.state.valuation.incomeStatement.losses.push(item)
  },
  addItemRevenue(state, item) {
    this.state.valuation.incomeStatement.revenue.push(item)
  },


  setBalanceSheetObj(state, value) {
    this.state.valuation = value
  }


}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
