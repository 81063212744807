<template>
  <div class="text-2xl">
    {{ $t('message.finance') }}
  </div>
<div class="flex flex-wrap">
  <div class="grid grid-cols-12 gap-6 p-10">
    <div class="col-span-6">
      <h1 class="text-lg font-medium truncate"> Report your Notes Payable </h1>
      <small class="text-theme-25 intro-y">You can report your notes payable by updating your information in appropriate columns. </small>
    </div>
  </div>
</div>
<div class="col-span-6">




</div>
<div class="flex flex-wrap">
  <div class="w-full">
    <a class="btn btn-primary mb-4  text-blue-600 hover:text-blue-800 visited:text-purple-600" href="javascript:;" data-toggle="modal" data-target="#exampleModalLabel" @click="setClearValuesFromRow()">
      <PlusIcon class="w-4 h-4 mr-1" /> New Notes Payable
    </a>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded" style="background-color: #fafafa;">
      <div class="px-12 py-12 flex-auto">
        <div class="tab-content tab-space">
          <div v-bind:class="{'hidden': countNotePayable !== 0 }">
            <div class="text-center">
              No Notes Payable Records
            </div>
          </div>
          <div v-bind:class="{'hidden': countNotePayable === 0}">
            <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
              <table class="table table-report -mt-2">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap">Category</th>
                    <th class="text-center whitespace-nowrap">Updated Date</th>
                    <th class="text-center whitespace-nowrap">Amount</th>
                    <th class="text-center whitespace-nowrap">Action</th>
                    <!--    <th class="text-center whitespace-nowrap">ACTIONS</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(values , index) in this.$store.state.financials.notespayable" class="intro-x">
                    <td class="w-40 uppercase">
                      {{ values.accountTitle }}
                    </td>
                    <td class="w-40 text-center">
                      {{values.updatedDate}}
                    </td>
                    <td class="w-40 text-center">
                      ${{values.currentBalance}}
                    </td>
                    <td class="w-40 text-center">
                      <a href="javascript:;" data-toggle="modal" data-target="#exampleModalLabel" @click="setValuesFromRow(index)">
                        <EditIcon class="w-4 h-4 mr-1" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- END: Pagination -->
<div id="exampleModalLabel" class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto" tabindex="-1" aria-hidden="true" @click.self="resetDeleteID()">
  <div class="modal-dialog modal-fullscreen relative w-auto pointer-events-none" style="width: 60%;">
    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
        <h5 class="text-xl font-medium leading-normal text-gray-800">Edit Notes Payable</h5>
        <button type="button" class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-dismiss="modal"
          aria-label="Close">X</button>
      </div>
      <div class="modal-body p-0">
        <div class="
                    px-5
                    sm:px-20
                    mt-1
                    pt-1
                    dark:border-dark-5
                  ">
          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-12 sm:col-span-6">
              <label for="input-wizard-9" class="form-label">Account Title</label>
              <input id="input-wizard-9" type="text" class="form-control" v-model="accountTitle" />
              <template v-if="errorValidation.accountTitle !== ''">
                <small class="text-theme-21 mt-2">
                  {{errorValidation.accountTitle}} <br>
                </small>
              </template>
            </div>
          </div>



          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-3 sm:col-span-3">
              <label for="input-wizard-91" class="form-label">Current Balance</label>
               <CurrencyInput id="input-wizard-91" type="text" class="form-control" v-model="currentBalance" :options="opt" />
            </div>
            <div class="intro-y col-span-3 sm:col-span-3">
              <label for="input-wizard-92" class="form-label">Original Balance</label>
             <CurrencyInput id="input-wizard-92" type="text" class="form-control" v-model="originalBalance" :options="opt" />
            </div>
            <div class="intro-y col-span-3 sm:col-span-3">
              <label for="input-wizard-93" class="form-label">Payment Amount</label>
               <CurrencyInput id="input-wizard-93" type="text" class="form-control" v-model="paymentAmount" :options="opt" />
            </div>
            <div class="intro-y col-span-3 sm:col-span-3">
              <label for="input-wizard-94" class="form-label">Interest Rate</label>
              <input id="input-wizard-94" type="text" class="form-control" v-mask="['#.##%', '##.##%']" masked="false" v-model="interestRate" />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-4 sm:col-span-3">
              <div id="input-group-datepicker">
                <label for="input-wizard-x" class="form-label">Repayment Date</label>
                <div class="relative w-50">
                  <div
                    class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
                  >
                    <CalendarIcon class="w-4 h-4" />
                  </div>
                  <Litepicker
                    id="input-wizard-x"
                    v-model="repaymentDate"
                    :options="{
                      format: 'MM-DD-YYYY',
                      autoApply: true,
                      showWeekNumbers: false,
                      dropdowns: {
                        minYear: 1930,
                        maxYear: null,
                        months: true,
                        years: true
                      }
                    }"
                    class="form-control pl-12"
                  />
                </div>
              </div>
            </div>

            <div class="intro-y col-span-8 sm:col-span-3">
              <label for="input-wizard-12" class="form-label">Select Frequency</label><select id="input-wizard-12" class="form-select" v-model="frequency">
                <option>Daily</option>
                <option>Weekly</option>
                <option>Bi-Weekly</option>
                <option>Monthly</option>
                <option>Yearly</option>
              </select>
            </div>

          </div>




          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-12">
              <label for="input-wizard-9" class="form-label">Address 1</label>
              <input id="input-wizard-9" type="text" class="form-control" v-model="address1" />
              <template v-if="errorValidation.address1 !== ''">
                <small class="text-theme-21 mt-2">
                  {{errorValidation.address1}} <br>
                </small>
              </template>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-12">
              <label for="input-wizard-9" class="form-label">Address 2</label>
              <input id="input-wizard-9" type="text" class="form-control" v-model="address2" />
            </div>
          </div>

          <div class="intro-y col-span-4">
            <label for="input-wizard-9" class="form-label">City</label>
            <input id="input-wizard-9" type="text" class="form-control" v-model="city" />
            <template v-if="errorValidation.city !== ''">
              <small class="text-theme-21 mt-2">
                {{errorValidation.city}} <br>
              </small>
            </template>
          </div>

          <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
            <div class="intro-y col-span-4">
              <label for="input-wizard-12" class="form-label">Select State</label>
              <select id="input-wizard-12" class="form-select" v-model="state">
                <option selected value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>

            <div class="intro-y col-span-4">
              <label for="input-wizard-9" class="form-label">Zip Code</label>
              <input id="input-wizard-9" type="text" class="form-control" v-mask="'#####'" v-model="zipcode" />
              <template v-if="errorValidation.zipcode !== ''">
                <small class="text-theme-21 mt-2">
                  {{errorValidation.zipcode}} <br>
                </small>
              </template>
            </div>


          </div>




          <div class="text-right mt-5">
            <button class="btn btn-outline-secondary w-24 mr-1" data-dismiss="modal" aria-label="Cancel">
              Cancel
            </button>
            <button class="btn btn-primary w-24 mr-1" data-dismiss="modal" aria-label="Save" @click="saveValues()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  defineComponent,
  computed
} from 'vue'
import {
  useStore
} from '@/store'


import {
  object,
  string,
  number,
  bool
} from "yup";
import * as yup from 'yup';
import {
  mask
} from 'vue-the-mask';

import CurrencyInput from "@/components/currency-input/Main.vue";



const dateRegExp =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/


const notesPayableSchema = object().shape({
  accountTitle: string().required('Account Title is required'),
  address1: string().required('Address name is a required field'),
  city: string().required('City is a required field'),
  state: string().required('State is a required field'),
  zipcode: string().required('Zipcode is a required field').matches(/^[0-9]+$/, "Must be only digits").min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
});

export default {
  name: "notes-payable",
    directives: { mask },
    components: {
     CurrencyInput,
   },
  data() {

    let errorValidation = {
      accountTitle: '',
      address1: '',
      city: '',
      state: '',
      zipcode: ''
    };

    const opt = {currency: 'USD',currencyDisplay: 'symbol',hideCurrencySymbolOnFocus: false,  hideGroupingSeparatorOnFocus: false, precision: 0 }

    const store = useStore();
    return {
      store,
      opt,
      editModalAmount: 0,
      currentSaveIndex: -1,
      errorValidation,
      notespayable: {
        updatedDate: '',
        accountTitle: '',
        currentBalance: 0,
        originalBalance: 0,
        paymentAmount: 0,
        interestRate: 0,
        repaymentDate: "0",
        frequency: 'Daily',
        address1: '',
        address2: '',
        city: '',
        state: 'AL',
        zipcode: 0,
      }
    }
  },



  computed: {
    countNotePayable: function() {
      return this.$store.state.financials.notespayable.length;
    },
    accountTitle: {
      get() {
        return this.notespayable.accountTitle;
      },
      set(value) {
        this.validate('accountTitle', value)
      }
    },
    currentBalance: {
      get() {
        return this.notespayable.currentBalance;
      },
      set(value) {
        this.notespayable.currentBalance = value;
      }
    },
    originalBalance: {
      get() {
        return this.notespayable.originalBalance;
      },
      set(value) {
        this.notespayable.originalBalance = value;
      }
    },
    paymentAmount: {
      get() {
        return this.notespayable.paymentAmount;
      },
      set(value) {
        this.notespayable.paymentAmount = value;
      }
    },
    interestRate: {
      get() {
        return this.notespayable.interestRate;
      },
      set(value) {
        this.notespayable.interestRate = value;
      }
    },
    repaymentDate: {
      get() {
        return this.notespayable.repaymentDate;
      },
      set(value) {
        this.notespayable.repaymentDate = value;
      }
    },
    frequency: {
      get() {
        return this.notespayable.frequency;
      },
      set(value) {
        this.notespayable.frequency = value;
      }
    },
    address1: {
      get() {
        return this.notespayable.address1;
      },
      set(value) {
        this.validate('address1', value);
      }
    },
    address2: {
      get() {
        return this.notespayable.address2;
      },
      set(value) {
        this.notespayable.address2 = value;
      }
    },
    city: {
      get() {
        return this.notespayable.city;
      },
      set(value) {
        this.validate('city', value);
      }
    },
    state: {
      get() {
        return this.notespayable.state;
      },
      set(value) {
        this.validate('state', value);
      }
    },
    zipcode: {
      get() {
        return this.notespayable.zipcode;
      },
      set(value) {
        this.validate('zipcode', value);
      }
    },
  },

  methods: {

    async validate(field, value) {
      let obj = {};
      obj[field] = value;

      await notesPayableSchema
        .validateAt(field, obj).then(() => {
          this.errorValidation[field] = '';
          this.notespayable[field] = value;
          //this.notespayable.accountTitle = value;
        }).catch(err => {
          console.log(Object.keys(err).length);
          if (Object.keys(err).length !== 0) {
            // this.notespayable[field] = '';
            this.errorValidation[err.path] = err.message;
            console.log(err.message);
          }
          this.notespayable[field] = value;
        });
    },

    setValuesFromRow: function(index) {
      this.currentSaveIndex = index;
      console.log("Setting currentSaveIndex: " + JSON.stringify(this.currentSaveIndex));
      this.notespayable.accountTitle = this.$store.state.financials.notespayable[index].accountTitle;
      this.notespayable.currentBalance = this.$store.state.financials.notespayable[index].currentBalance;
      this.notespayable.originalBalance = this.$store.state.financials.notespayable[index].originalBalance;
      this.notespayable.paymentAmount = this.$store.state.financials.notespayable[index].paymentAmount;
      this.notespayable.interestRate = this.$store.state.financials.notespayable[index].interestRate;
      this.notespayable.repaymentDate = this.$store.state.financials.notespayable[index].repaymentDate;
      this.notespayable.frequency = this.$store.state.financials.notespayable[index].frequency;
      this.notespayable.address1 = this.$store.state.financials.notespayable[index].address1;
      this.notespayable.address2 = this.$store.state.financials.notespayable[index].address2;
      this.notespayable.city = this.$store.state.financials.notespayable[index].city;
      this.notespayable.state = this.$store.state.financials.notespayable[index].state;
      this.notespayable.zipcode = this.$store.state.financials.notespayable[index].zipcode;

      // this.modalTitle = Category;
      // this.Category = Category;
      // this.editModalAmount = amount;
      // this.currentCategory = currentCategory;
    },
    setClearValuesFromRow: function() {
      console.log("setClearValueFromRow");
      this.currentSaveIndex = -1;
      this.notespayable.accountTitle = "";
      this.notespayable.currentBalance = 0;
      this.notespayable.originalBalance = 0;
      this.notespayable.paymentAmount = 0;
      this.notespayable.interestRate = 0;
      this.notespayable.repaymentDate = "";
      this.notespayable.frequency = "Daily";
      this.notespayable.address1 = "";
      this.notespayable.address2 = "";
      this.notespayable.city = "";
      this.notespayable.state = "AL";
      this.notespayable.zipcode = "";
    },
    resetDeleteID() {},
    getCurrentDateStr() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      return mm + '/' + dd + '/' + yyyy;
    },
    saveValues: function(index) {
      console.log("Setting index: " + index);
      console.log("saveEditValues.currentIndex: " + this.currentSaveIndex);
      this.store.commit('financials/setNotesPayable', {
        index: this.currentSaveIndex,
        notespayable: {
          type: this.notespayable.accountTitle,
          updatedDate: this.getCurrentDateStr(),
          accountTitle: this.notespayable.accountTitle,
          currentBalance: this.notespayable.currentBalance,
          originalBalance: this.notespayable.originalBalance,
          paymentAmount: this.notespayable.paymentAmount,
          interestRate: this.notespayable.interestRate,
          repaymentDate: this.notespayable.repaymentDate,
          frequency: this.notespayable.frequency,
          address1: this.notespayable.address1,
          address2: this.notespayable.address2,
          city: this.notespayable.city,
          state: this.notespayable.state,
          zipcode: this.notespayable.zipcode,
        }
      });

      this.store.dispatch('financials/submitFirebase');
    },
  },


}
</script>