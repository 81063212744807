<template>
  <DataTable 
    :pageTitle="helloWorld" 
    :addRecordButtonProps="addRecordButtonProps" 
    :showSearch="showSearch"
    :columns="columns"
    :tableData="tableData"
  />


</template>

<script>

import DataTable from './Main.vue';
import getUser from '@/composables/getUser';
import useCollection from '@/composables/useCollection';
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';

export default {
components: {
    DataTable,


},
    setup() {

        const router = useRouter();
        const { user } = getUser();
        const { error, addDoc, getCollection, isPending, newDoc, documents: applications } = useCollection('applications');

        const helloWorld = "This is it"
        const showSearch = true;

        var columns = [
            {title:"Type", field:"type", sorter:"string", width:200},
            {title:"Started On", field:"started"},
            {title:"Status", field:"status"},
            {title:"Actions", field:"actions", formatter: actionsFormatter},
        ]

        var tableData = [];

        const addRecordButtonProps = {
            value: "Button",
            location: "#something",
            action: addRecordAction
        }

        function addRecordAction() {
            alert('Called!');
        }

        function actionsFormatter(cell) {

            const a = cash(`<div class="flex lg:justify-center items-center text-theme-25">
            <a class="flex items-center mr-3" href="javascript:;">
                <i data-feather="eye" class="w-4 h-4 mr-1"></i> <u>View</u>
            </a>
            <a class="flex items-center text-theme-21" href="javascript:;">
                <i data-feather="trash-2" class="w-4 h-4 mr-1"></i> <u>Delete</u>
            </a>
            </div>`);

            cash(a).on('click', function(e) {
                // On click actions go here!
                console.log(e.target.outerText);
            });

            return a[0]
        }
        
        onMounted(async () => {
            await getCollection(
                'applications', 
                ['userID', '==', user.value.uid],
                ['type', 'started', 'status']);

            var tempData = Object.values(applications.value);
            tempData.forEach(entry => {
                entry.started = entry.started.toDate().toDateString();
            });
            console.log("tempData:", tempData);
            
            tableData.push(...tempData);
        });

        

        return {
            helloWorld,
            addRecordButtonProps,
            showSearch,
            columns,
            tableData
        }
    }
}
</script>

<style>

</style>