import { ref } from 'vue';
import { projectAuth, projectFirestore } from '../firebase/config';

const error = ref(null);
const isPending = ref(false);

const profileDetails = {
  address: '',
  business: {
    address: null,
    married: null,
    name: null,
    ownershipPercent: null,
    phoneNumber: null,
    type: null,
    wosb: null
  },
  citizenship: {
    alienRegistrationNo: null,
    pob_city: null,
    pob_country: null,
    pob_state: null,
    usCitizen: null
  },
  criminalInfo: {
    anyPunishment: null,
    past6Months: null,
    presentlySubject: null
  },
  dob: null,
  finances: {
    assets: {
      aNReceivable: null,
      automobiles: null,
      cash: null,
      lifeInsurance: null,
      otherAssets: null,
      otherPersonal: null,
      realEstate: null,
      retirement: null,
      savings: null,
      stocksBonds: null,
      assetTotal: null
    },
    liabilities: {
      accountsPayable: null,
      againstLifeInsurance: null,
      installmentAuto: null,
      installmentOther: null,
      mortgages: null,
      notesPayable: null,
      otherLiabilities: null,
      totalLiabilities: null,
      netWorth: null,
      liabilityTotal: null,
    },
    income: {
      netInvestment: null,
      otherIncome: null,
      realEstate: null,
      salary: null
    },
    contingentLiabilities: {
      endorser: null,
      legalClaims: null,
      otherSpecial: null,
      provision: null
    }
  },
  phoneNumber: null,
  ssn: null
}

const login = async (email, password) => {
  error.value = null;
  isPending.value = true;

  try {
    console.log(email, password);
    const res = await projectAuth.signInWithEmailAndPassword(email, password);

    console.log(res.user.uid);
    // const { error, document } = getDocument('profileDetails', res.user.uid);
    const profileDoc = await projectFirestore.collection('profileDetails').doc(res.user.uid).get();
    const profile = profileDoc.data();

    if(profile != null) {
      const profileArray = Object.entries(profile);
      const nonNullValues = profileArray.filter(([key, value]) => value != null);
      const noOfNonNulls = nonNullValues.length;

      const nullValues = profileArray.filter(([key, value]) => value == null);
      console.log(nullValues)

      if(noOfNonNulls < 1) {
        console.log('Creating Profile Details Document...');
        await projectFirestore.collection('profileDetails').doc(res.user.uid).set(profileDetails);
      } else {
        console.log('Profile Details already exists');
      }
    } else {
      console.log('Profile is NULL!!');
      console.log('Creating Profile Details Document...');
      await projectFirestore.collection('profileDetails').doc(res.user.uid).set(profileDetails);
    }

    error.value = null;
    isPending.value = false;
    return res;
  }
  catch(err) {
    console.log(err.message);
    error.value = err.message;
    isPending.value = false;
  }
};

const sendPasswordResetEmail = async (email) => {
  var actionCodeSettings = {
    url: 'http://' + window.location.host + '/login',
  };

  error.value = null;
  isPending.value = true;

  await projectAuth.sendPasswordResetEmail(email, actionCodeSettings)
    .then(() => {
      console.log('Password Reset Email Sent!');
      error.value = null;
      isPending.value = false;
    })
    .catch((err) => {
      console.log(err.message);
      error.value = err.message;
      isPending.value = false;
    })
}

const useLogin = () => {
  return { error, login, isPending, sendPasswordResetEmail }
};

export default useLogin;
