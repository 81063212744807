<template>
  <div class='text-left text-2xl mt-5 mb-3'>
    Now Account
  </div>

  <div class="bg-white shadow-md rounded-md bg-blue-500 p-4">
    <div class="intro-y grid ">

      <div class="grid-rows-9 col-span-2">
        <div class="pb-5">
          <label for="input-wizard-1"
                 class="form-label">{{$t('message.nowAccountQuestion1')}}</label>
          <input id="input-wizard-1" type="text" class="form-control"
                 placeholder=""
                 value='33333'
                 v-model='totalNumInvoicesPerMonth' />
        </div>

        <div class="pb-5">
          <label for="input-wizard-1"
                 class="form-label">{{$t('message.nowAccountQuestion2')}}</label>
          <input id="input-wizard-1" type="text" class="form-control"
                 placeholder=""
                 v-model='averageInvoiceSize' />
        </div>

        <div class="pb-5">
          <label for="input-wizard-1" class="form-label">
            {{$t('message.nowAccountQuestion3')}}
          </label>
          <input id="input-wizard-1" type="text" class="form-control"
                 placeholder=""
                 v-model='averageDaysInvoiceOutstanding' />
        </div>

        <div class="pb-5">
          <label for="input-wizard-1"
                 class="form-label">
            {{$t('message.nowAccountQuestion4')}}
          </label>
          <input id="input-wizard-1" type="text" class="form-control"
                 placeholder=""
                 v-model='totalNumberCustomers' />
        </div>

        <div class="pb-5">
          <label for="input-wizard-1"
                 class="form-label">
            {{$t('message.nowAccountQuestion5')}}
          </label>
          <input id="input-wizard-1" type="text" class="form-control"
                 placeholder=""
                 v-model='averageInvoiceTerms' />
        </div>

        <div class="pb-5">
          <label for="input-wizard-1"
                 class="form-label">
            {{$t('message.nowAccountQuestion6')}}
          </label>
          <input id="input-wizard-1" type="text" class="form-control"
                 placeholder="discount, returns and allowances"
                 v-model='annualVolDiscountReturnAllowance' />
        </div>

        <div class="mt-10">
          <div class="mt-5">
            <label>
              {{$t('message.nowAccountQuestion7')}}
            </label>
            <div class="form-check mt-3">
              <input id="radio-switch-1" class="form-check-input" type="checkbox"
                     value="true" name="subcontract-choice1"
                     v-model='specialInvoiceTypeBilling' />
              <label class="form-check-label" for="radio-switch-1">
                {{$t('message.nowAccountQuestion7Option1')}}
              </label>
            </div>

            <div class="form-check-input mt-3">
              <input id="checkbox-switch-2" class="form-check-input"
                     type="checkbox"
                     value="true" name="specialInvoiceTypeRetainageName"
                     v-model='specialInvoiceTypeRetainage' />
              <label class="form-check-label" for="radio-switch-2">
                {{$t('message.nowAccountQuestion7Option2')}}
              </label>
            </div>

            <div class="form-check mt-3">
              <input id="radio-switch-3" class="form-check-input" type="checkbox"
                     value="true" name="subcontract-choice1"
                     v-model='specialInvoiceTypeGovSales' />
              <label class="form-check-label" for="radio-switch-3">
                {{$t('message.nowAccountQuestion7Option3')}}
              </label>
            </div>

            <div class="form-check mt-3">
              <input id="radio-switch-4" class="form-check-input" type="checkbox"
                     value="true" name="subcontract-choice21"
                     v-model='specialInvoiceTypeConsignmentSale' />
              <label class="form-check-label"
                     for="radio-switch-4">
                {{$t('message.nowAccountQuestion7Option4')}}
              </label>
            </div>

            <div class="form-check mt-3">
              <input id="radio-switch-5" class="form-check-input" type="checkbox"
                     value="true" name="subcontract-choice-5"
                     v-model='specialInvoiceTypeBillingPriorComplete' />
              <label class="form-check-label"
                     for="radio-switch-5">
                {{$t('message.nowAccountQuestion7Option5')}}
              </label>
            </div>

          </div>
          <div class="mt-5">
            <label>
              {{$t('message.nowAccountQuestion8')}}
            </label>
            <div class="form-check mt-3">
              <input id="radio-switch-7" class="form-check-input" type="radio"
                     value="true" name="subcontract-choice-7"
                     v-model='useSubContractors' />
              <label class="form-check-label" for="radio-switch-7">Yes</label>
            </div>

            <div class="form-check">
              <input id="radio-switch-7" class="form-check-input" type="radio"
                     value="false" name="subcontract-choice-7"
                     v-model='useSubContractors' />
              <label class="form-check-label" for="radio-switch-7">No</label>
            </div>
          </div>
          <div class="mt-5">
            <label>
              {{$t('message.nowAccountQuestion9')}}
            </label>
            <div class="form-check mt-3">
              <input id="radio-switch-9" class="form-check-input" type="radio"
                     value="true" name="doc-choice-9"
                     v-model='documentationRequiredClientsAccompanyInvoices' />
              <label class="form-check-label" for="radio-switch-9">Yes</label>
            </div>
            <div class="form-check">
              <input id="radio-switch-9" class="form-check-input" type="radio"
                     value="false" name="doc-choice-9"
                     v-model='documentationRequiredClientsAccompanyInvoices' />
              <label class="form-check-label" for="radio-switch-9">No</label>
            </div>
          </div>

          <div class="mt-5">
            <label>
              {{$t('message.nowAccountQuestion10')}}
            </label>
            <div class="form-check mt-3">
              <input id="radio-switch-11" class="form-check-input" type="radio"
                     value="true" name="commercial-choice-11"
                     v-model="anyCommercialLoans" />
              <label class="form-check-label" for="radio-switch-11">Yes</label>
            </div>
            <div class="form-check">
              <input id="radio-switch-11" class="form-check-input" type="radio"
                     value="false" name="commercial-choice-11"
                     v-model="anyCommercialLoans" />
              <label class="form-check-label" for="radio-switch-11">No</label>
            </div>
          </div>

          <div class="mt-5">
            <label>
              {{$t('message.nowAccountQuestion11')}}
            </label>
            <div class="form-check mt-3">
              <input id="radio-switch-13" class="form-check-input" type="radio"
                     value="true" name="tax-choice-13"
                     v-model='taxesPastDue' />
              <label class="form-check-label" for="radio-switch-13">Yes</label>
            </div>
            <div class="form-check">
              <input id="radio-switch-13" class="form-check-input" type="radio"
                     value="false" name="tax-choice-13"
                     v-model='taxesPastDue' />
              <label class="form-check-label" for="radio-switch-13">No</label>
            </div>
          </div>

          <div class="mt-5">
            <label>
              {{$t('message.nowAccountQuestion12')}}
            </label>
            <div class="form-check mt-3">
              <input id="radio-switch-15" class="form-check-input" type="radio"
                     value="true" name="bankruptcy-choice-15"
                     v-model='bankruptcy' />
              <label class="form-check-label" for="radio-switch-15">Yes</label>
            </div>
            <div class="form-check">
              <input id="radio-switch-15" class="form-check-input" type="radio"
                     value="false" name="bankruptcy-choice-15"
                     v-model='bankruptcy' />
              <label class="form-check-label" for="radio-switch-15">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right mt-5">
      <button type="button" class="btn btn-outline-secondary w-24 mr-1"
              @click="cancelChanges">
        Cancel
      </button>
      <button type="button" class="btn btn-primary w-24 mr-1"
              @click="submitChanges">
        Save
      </button>
    </div>
  </div>

  <div id="success-notification-content_toast"
       class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">CONGRATULATION</div>
      <div class="text-gray-600 mt-1">
        You have successfully save your Account Now data for Archymedes.
      </div>
    </div>
  </div>
  <div id="success-notification-content_toast_cancel"
       class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Account Now</div>
      <div class="text-gray-600 mt-1">
        You have cancelled saving of the AccountNow application for Archymedes.
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  reactive
} from 'vue'
import {
  useStore
} from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import Toastify from 'toastify-js'
// const { errorMessage, firstName } = useField('firstName', yup.string().required().min(8));


const store = useStore()
const router = useRouter()


const specialInvoiceTypeConsignmentSale = computed({
  get() {
    return store.state.accountNow.specialInvoiceTypeConsignmentSale
  },
  set(value) {
    store.commit('accountNow/setSpecialInvoiceTypeConsignmentSale', value)
  }
})

const specialInvoiceTypeGovSales = computed({
  get() {
    return store.state.accountNow.specialInvoiceTypeGovSales
  },
  set(value) {
    store.commit('accountNow/setSpecialInvoiceTypeGovSales', value)
  }
})

const specialInvoiceTypeRetainage = computed({
  get() {
    return store.state.accountNow.specialInvoiceTypeRetainage
  },
  set(value) {
    store.commit('accountNow/setSpecialInvoiceTypeRetainage', value)
  }
})

const specialInvoiceTypeBilling = computed({
  get() {
    return store.state.accountNow.specialInvoiceTypeBilling
  },
  set(value) {
    store.commit('accountNow/setSpecialInvoiceTypeBilling', value)
  }
})

const specialInvoiceTypeBillingPriorComplete = computed({
  get() {
    return store.state.accountNow.specialInvoiceTypeBillingPriorComplete
  },
  set(value) {
    store.commit('accountNow/setSpecialInvoiceTypeBillingPriorComplete', value)
  }
})

const totalNumInvoicesPerMonth = computed({
  get() {
    return store.state.accountNow.totalNumInvoicesPerMonth
  },
  set(value) {
    store.commit('accountNow/setTotalNumInvoicesPerMonth', value)
  }
})

const averageInvoiceSize = computed({
  get() {
    return store.state.accountNow.averageInvoiceSize
  },
  set(value) {
    store.commit('accountNow/setAverageInvoiceSize', value)
  }
})

const averageDaysInvoiceOutstanding = computed({
  get() {
    return store.state.accountNow.averageDaysInvoiceOutstanding
  },
  set(value) {
    store.commit('accountNow/setAverageDaysInvoiceOutstanding', value)
  }
})

const totalNumberCustomers = computed({
  get() {
    return store.state.accountNow.totalNumberCustomers
  },
  set(value) {
    store.commit('accountNow/setTotalNumberCustomers', value)
  }
})

const averageInvoiceTerms = computed({
  get() {
    return store.state.accountNow.averageInvoiceTerms
  },
  set(value) {
    store.commit('accountNow/setAverageInvoiceTerms', value)
  }
})

const documentationRequiredClientsAccompanyInvoices = computed({
  get() {
    return store.state.accountNow.documentationRequiredClientsAccompanyInvoices
  },
  set(value) {
    store.commit('accountNow/setDocumentationRequiredClientsAccompanyInvoices',
      value)
  }
})

const annualVolDiscountReturnAllowance = computed({
  get() {
    return store.state.accountNow.annualVolDiscountReturnAllowance
  },
  set(value) {
    store.commit('accountNow/setAnnualVolDiscountReturnAllowance', value)
  }
})


const useSubContractors = computed({
  get() {
    return store.state.accountNow.useSubContractors
  },
  set(value) {
    store.commit('accountNow/setUseSubContractors', value)
  }
})

const anyCommercialLoans = computed({
  get() {
    return store.state.accountNow.anyCommercialLoans
  },
  set(value) {
    store.commit('accountNow/setAnyCommercialLoans', value)
  }
})

const taxesPastDue = computed({
  get() {
    return store.state.accountNow.taxesPastDue
  },
  set(value) {
    store.commit('accountNow/setTaxesPastDue', value)
  }
})

const bankruptcy = computed({
  get() {
    return store.state.accountNow.bankruptcy
  },
  set(value) {
    store.commit('accountNow/setBankruptcy', value)
  }
})


function submitChanges() {
  store.commit('accountNow/setAccountNowObj', store.state.accountNow)
  store.dispatch('accountNow/submitFirebase')

  Toastify({
    node: cash('#success-notification-content_toast')
      .clone()
      .removeClass('hidden')[0],
    duration: 8000,
    newWindow: true,
    close: true,
    gravity: 'top',
    position: 'right',
    stopOnFocus: true
  }).showToast()

  router.push('/')
}

function cancelChanges() {
  // this.$store.dispatch('loan504/submitFirebase')

  Toastify({
    node: cash('#success-notification-content_toast_cancel')
      .clone()
      .removeClass('hidden')[0],
    duration: 8000,
    newWindow: true,
    close: true,
    gravity: 'top',
    position: 'right',
    stopOnFocus: true
  }).showToast()

  router.push('/')
}
</script>

<style>
</style>