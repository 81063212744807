<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Complete Your Profile</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="flex justify-center">
        <button
          v-bind:class= "[page == 1 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          1
        </button>
        <button
          v-bind:class= "[page == 2 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          2
        </button>
        <!-- <button
          v-bind:class= "[page == 3 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          3
        </button> -->
      </div>
      <div class="px-5 mt-10">
        <div class="font-medium text-center text-lg"><strong>Individual Owner Info</strong></div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 1">
          Info about You
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 2">
          Questions
        </div>
        <!-- <div class="text-gray-600 text-center mt-2" v-if="page == 3">
          Source of Income
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 4">
          Contingent Liabilities
        </div> -->
      </div>
      <div
        class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base"><strong>Please answer the following questions honestly!</strong></div>

        <!-- BEGIN: Page 1 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 1">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Veteran Status</label>
            <select
              class="form-select mt-2 sm:mr-2"
              aria-label="Default select example"
              v-model="veteran"
            >
              <option>I am not a veteran</option>
              <option disabled>---------------------------</option>
              <option>Non-Veteran</option>
              <option>Veteran</option>
              <option>Service-Disabled Veteran</option>
              <option>Spouse of Veteran</option>
            </select>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Gender</label>
            <select
              class="form-select mt-2 sm:mr-2"
              aria-label="Default select example"
              v-model="gender"
            >
              <option>I prefer not to disclose</option>
              <option disabled>---------------------------</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Race</label>
            <select
              class="form-select mt-2 sm:mr-2"
              aria-label="Default select example"
              v-model="race"
            >
              <option>I prefer not to disclose</option>
              <option>American Indian or Alaska Native</option>
              <option>Asian</option>
              <option>Black or African American</option>
              <option>Native Hawaiian or Pacific Islander</option>
              <option>White</option>
            </select>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Ethnicity</label>
            <select
              class="form-select mt-2 sm:mr-2"
              aria-label="Default select example"
              v-model="ethnicity"
            >
              <option>I prefer not to disclose</option>
              <option>Hispanic or Latino</option>
              <option>Not Hispanic or Latino</option>
            </select>
          </div>
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-5" class="form-label">Doesn't Have</label>
            <input
              id="input-wizard-5"
              type="text"
              class="form-control"
              placeholder="Job, Work, Documentation"
            />
          </div> -->
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-6" class="form-label">Size</label>
            <select id="input-wizard-6" class="form-select">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div> -->
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            <button class="btn btn-secondary w-24" @click="handleBackToSection1">Back to Section 1</button>
            <button class="btn btn-primary w-24 ml-2" @click="handleNext">Next</button>
          </div>
        </div>
        <!-- END: Page 1 -->

        <!-- BEGIN: Page 2 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 2">
          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
                <label>Are you currently suspended, debarred or declared unable to apply for this loan by the Federal government?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="ineligible-choice"
                    v-bind:value="true"
                    v-model="ineligible"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="ineligible-choice"
                    v-bind:value="false"
                    v-model="ineligible"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false">
            <div>
                <label>If you own more than half of your business, are you more than 60 days late on any child support payments?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="lateCS-choice"
                    v-bind:value="true"
                    v-model="lateCS"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="lateCS-choice"
                    v-bind:value="false"
                    v-model="lateCS"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false && lateCS == false">
            <div>
                <label>Do you own any part of an Affiliate of your business?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="affiliate-choice"
                    v-bind:value="true"
                    v-model="affiliate"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="affiliate-choice"
                    v-bind:value="false"
                    v-model="affiliate"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false && lateCS == false">
            <div>
                <label>Have you or any business you've owned ever filed for bankruptcy protection?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="bProtection-choice"
                    v-bind:value="true"
                    v-model="bProtection"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="bProtection-choice"
                    v-bind:value="false"
                    v-model="bProtection"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false && lateCS == false">
            <div>
                <label>Are you or any business you own presently involved in any pending legal action?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="pendingLegal-choice"
                    v-bind:value="true"
                    v-model="pendingLegal"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="pendingLegal-choice"
                    v-bind:value="false"
                    v-model="pendingLegal"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false && lateCS == false">
            <div>
                <label>Have you or any business you own ever gotten, guaranteed or applied for an SBA/Federal loan?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="returnApplicant-choice"
                    v-bind:value="true"
                    v-model="returnApplicant"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="returnApplicant-choice"
                    v-bind:value="false"
                    v-model="returnApplicant"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="returnApplicant == true">
            <div>
                <label>Are any of the loan/loans presently considered delinquent?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="presentlyDelinquent-choice"
                    v-bind:value="true"
                    v-model="presentlyDelinquent"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="presentlyDelinquent-choice"
                    v-bind:value="false"
                    v-model="presentlyDelinquent"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
              </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="returnApplicant == true">
            <div>
                <label>Has you or any business you own ever defaulted on a loan/loans?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="defaulted-choice"
                    v-bind:value="true"
                    v-model="defaulted"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="defaulted-choice"
                    v-bind:value="false"
                    v-model="defaulted"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
              </div>
          </div>
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Date of Birth</label>
            <div class="grid grid-cols-12 gap-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Month"
                  aria-label="default input inline 1"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Day"
                  aria-label="default input inline 2"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Year"
                  aria-label="default input inline 3"
                />
              </div>
          </div> -->
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-5" class="form-label">Doesn't Have</label>
            <input
              id="input-wizard-5"
              type="text"
              class="form-control"
              placeholder="Job, Work, Documentation"
            />
          </div> -->
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-6" class="form-label">Size</label>
            <select id="input-wizard-6" class="form-select">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div> -->

              <div
                class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
              >
                <button class="btn btn-secondary w-24" @click="handlePrev" v-if="!isPending">Previous</button>
                <!-- <button class="btn btn-dark mr-1 mb-2" v-else>
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button> -->
                <button class="btn btn-dark w-24 ml-2" @click="handleIneligibleFinish" v-if="ineligible || lateCS">Finish</button>
                <button class="btn btn-success w-24 ml-2" @click="handleFinish" v-if="!isPending && ineligible == false && lateCS  == false">Finish Section 2</button>
                <button class="btn btn-dark mr-1 mb-2" v-else-if="isPending">
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
                <button class="btn btn-success w-24 ml-2" @click="handleFinishandQuit" v-if="!isPending && ineligible == false && lateCS  == false">Save & Quit</button>
                <button class="btn btn-dark mr-1 mb-2" v-else-if="isPending">
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
              </div>
        </div>
        <!-- END: Page 2 -->

        <!-- BEGIN: Page 3 -->
        <!-- <div id="radio" class="intro-y p-5" v-else>
            <div class="preview">
              <div>
                <label>Are you presently subject to an indictment, criminal info, arraignment or other means by which formal criminal charges are brought in any jurisdiction?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="presentlySubject-choice"
                    v-bind:value="true"
                    v-model="presentlySubject"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="presentlySubject-choice"
                    v-bind:value="false"
                    v-model="presentlySubject"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
              </div>
              <div class="intro-y mt-5">
                <label>Have you been arrested in the past 6 months?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="past6Months-choice"
                    v-bind:value="true"
                    v-model="past6Months"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="past6Months-choice"
                    v-bind:value="false"
                    v-model="past6Months"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
              </div>
              <div class="intro-y mt-5">
                <label>For any criminal offense (other than a minor vehicle violation), have you ever been convicted, pleaded guilty, pleaded nolo contendere, been placed on pretrial diversion or been placed on any form of parole or probation?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="anyPunishment-choice"
                    v-bind:value="true"
                    v-model="anyPunishment"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="anyPunishment-choice"
                    v-bind:value="false"
                    v-model="anyPunishment"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
              </div>

              <div
                class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
              >
                <button class="btn btn-secondary w-24" @click="handlePrev" v-if="!isPending">Previous</button>
                <button class="btn btn-dark mr-1 mb-2" v-else>
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
                <button class="btn btn-success w-24 ml-2" @click="handleFinish" v-if="!isPending">Continue to Business Info</button>
                <button class="btn btn-dark mr-1 mb-2" v-else>
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
              </div>

            </div>
          </div> -->
        <!-- END: Page 3 -->

        <div class="w-full border-t border-gray-200 dark:border-dark-5 mt-5"></div>

          <!-- Ineligible Answer Alert -->
          <div class="intro-y alert alert-danger-soft show flex items-center mb-2" role="alert" v-if="ineligible || lateCS">
            <XOctagonIcon class="w-6 h-6 mr-2" /> Based on this answer, we can't go any further with this application because you're unable to apply for SBA financial assistance!
          </div>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue';
import getUser from '@/composables/getUser';
import useDocument from '@/composables/useDocument';
import getDocument from '@/composables/getDocument';
import { useRouter } from 'vue-router';

export default {
  props: ['appID'],
  setup(props) {
    const router = useRouter();
    const {user} = getUser();
    const {
      error: getDocumentError,
      getDocumentByID,
      document: application
    } = getDocument('applications', props.appID)



    const page = ref(router.currentRoute.value.query.page != null ? router.currentRoute.value.query.page : 1);

    const personalInfo = reactive({
      veteran: 'I am not a veteran',
      gender: 'I prefer not to disclose',
      race: 'I prefer not to disclose',
      ethnicity: 'I prefer not to disclose',
    });

    const questions = reactive({
      ineligible: null,
      lateCS: null,
      affiliate: null,
      bProtection: null,
      pendingLegal: null,
      returnApplicant: null,
      presentlyDelinquent: null,
      defaulted: null,
    });

    const handleNext = () => {
        page.value++;
    };

    const handleBackToSection1 = () => {
      router.push({ name: 'side-menu-7a-section-1', query: {page: 3} });
    }

    const handlePrev = () => {
        page.value--;
    };

    const handleFinish = async () => {
      const application = {
        individualOwnerInfo: {
          personalInfo: {
            ...personalInfo
          },
          questions: {
            ...questions
          }
        },
        currentSection: 3
      };

      await updateDoc(application);

      router.push({ name: 'side-menu-7a-section-3' });
    }

    const handleFinishandQuit = async () => {
      // const application = {
      //   section2: {
      //     status: 'In Progress',
      //     veteran: veteran.value,
      //     gender: gender.value,
      //     race: race.value,
      //     ethnicity: ethnicity.value,

      //     questions: {
      //       ineligible: ineligible.value,
      //       lateCS: lateCS.value,
      //       affiliate: affiliate.value,
      //       bProtection: bProtection.value,
      //       pendingLegal: pendingLegal.value,
      //       returnApplicant: returnApplicant.value,
      //       presentlyDelinquent: returnApplicant.value == true ? presentlyDelinquent.value : false,
      //       defaulted: returnApplicant.value == true ? defaulted.value : false,
      //     }
      //   },
      // };

      // await updateDoc(application);

      router.push({ name: 'portal-page' });
    }

    const handleIneligibleFinish = () => {


      router.push({ name: 'portal-page' });
    }

    onMounted(async () => {
      await getDocumentByID('applications', props.appID);

      // If values exist in the DB, pre-populate them in the form
      if (application.value.individualOwnerInfo != null) {
        let appPersonalInfo = application.value.individualOwnerInfo.personalInfo;
        let appQuestions = application.value.individualOwnerInfo.questions;

        Object.keys(appPersonalInfo).forEach(function(key) {
          if(key in personalInfo) {
            personalInfo[key] = appPersonalInfo[key];
          }
        })

        Object.keys(appQuestions).forEach(function(key) {
          if(key in questions) {
            questions[key] = appQuestions[key];
          }
        })
      }
    })

    return { ...toRefs(personalInfo), ...toRefs(questions),
            page, handleNext, handlePrev, handleBackToSection1, handleFinish, handleFinishandQuit, handleIneligibleFinish, isPending };
  },
}
</script>
