import _ from 'lodash'
import dayjs from 'dayjs'

const fakers = {
  fakeUsers() {
    const users = [
      { name: 'Daniel Day-Lewis', gender: 'male' },
      { name: 'Robert De Niro', gender: 'male' },
      { name: 'Jack Nicholson', gender: 'male' },
      { name: 'Anthony Hopkins', gender: 'male' },
      { name: 'Robert Duvall', gender: 'male' },
      { name: 'Gene Hackman', gender: 'male' },
      { name: 'Edward Norton', gender: 'male' },
      { name: 'Gary Oldman', gender: 'male' },
      { name: 'Tom Hanks', gender: 'male' },
      { name: 'Morgan Freeman', gender: 'male' },
      { name: 'Sean Penn', gender: 'male' },
      { name: 'Philip Seymour Hoffman', gender: 'male' },
      { name: 'Jeff Bridges', gender: 'male' },
      { name: 'Al Pacino', gender: 'male' },
      { name: 'Paul Newman', gender: 'male' },
      { name: 'Michael Caine', gender: 'male' },
      { name: 'Clint Eastwood', gender: 'male' },
      { name: 'Leonardo DiCaprio', gender: 'male' },
      { name: 'Brad Pitt', gender: 'male' },
      { name: 'Johnny Depp', gender: 'male' },
      { name: 'Russell Crowe', gender: 'male' },
      { name: 'Denzel Washington', gender: 'male' },
      { name: 'Peter OToole', gender: 'male' },
      { name: 'John Malkovich', gender: 'male' },
      { name: 'Benicio Del Toro', gender: 'male' },
      { name: 'Kevin Spacey', gender: 'male' },
      { name: 'Christopher Walken', gender: 'male' },
      { name: 'Ben Kingsley', gender: 'male' },
      { name: 'Jeremy Irons', gender: 'male' },
      { name: 'Dustin Hoffman', gender: 'male' },
      { name: 'Christian Bale', gender: 'male' },
      { name: 'Dennis Hopper', gender: 'male' },
      { name: 'Jon Voight', gender: 'male' },
      { name: 'Ralph Fiennes', gender: 'male' },
      { name: 'James Cromwell', gender: 'male' },
      { name: 'Albert Finney', gender: 'male' },
      { name: 'Geoffrey Rush', gender: 'male' },
      { name: 'Ed Harris', gender: 'male' },
      { name: 'Joaquin Phoenix', gender: 'male' },
      { name: 'Liam Neeson', gender: 'male' },
      { name: 'Djimon Hounsou', gender: 'male' },
      { name: 'Tim Robbins', gender: 'male' },
      { name: 'Tom Wilkinson', gender: 'male' },
      { name: 'William Hurt', gender: 'male' },
      { name: 'Matt Damon', gender: 'male' },
      { name: 'Robin Williams', gender: 'male' },
      { name: 'Robert Redford', gender: 'male' },
      { name: 'Willem Dafoe', gender: 'male' },
      { name: 'Robert Downey Jr.', gender: 'male' },
      { name: 'Tommy Lee Jones', gender: 'male' },
      { name: 'Forest Whitaker', gender: 'male' },
      { name: 'Ian McShane', gender: 'male' },
      { name: 'Paul Giamatti', gender: 'male' },
      { name: 'Michael Douglas', gender: 'male' },
      { name: 'Mel Gibson', gender: 'male' },
      { name: 'Brian Dennehy', gender: 'male' },
      { name: 'Jude Law', gender: 'male' },
      { name: 'Nick Nolte', gender: 'male' },
      { name: 'Sean Connery', gender: 'male' },
      { name: 'Tom Cruise', gender: 'male' },
      { name: 'Kevin Costner', gender: 'male' },
      { name: 'George Clooney', gender: 'male' },
      { name: 'Samuel L. Jackson', gender: 'male' },
      { name: 'Gary Sinise', gender: 'male' },
      { name: 'Harrison Ford', gender: 'male' },
      { name: 'Colin Firth', gender: 'male' },
      { name: 'Viggo Mortensen', gender: 'male' },
      { name: 'Billy Bob Thornton', gender: 'male' },
      { name: 'Mickey Rourke', gender: 'male' },
      { name: 'Don Cheadle', gender: 'male' },
      { name: 'Christoph Waltz', gender: 'male' },
      { name: 'Will Smith', gender: 'male' },
      { name: 'Alec Baldwin', gender: 'male' },
      { name: 'Kevin Bacon', gender: 'male' },
      { name: 'Ian McKellen', gender: 'male' },
      { name: 'Sam Rockwell', gender: 'male' },
      { name: 'Barry Pepper', gender: 'male' },
      { name: 'Orlando Bloom', gender: 'male' },
      { name: 'Steve Buscemi', gender: 'male' },
      { name: 'John Travolta', gender: 'male' },
      { name: 'John Hawkes', gender: 'male' },
      { name: 'David Strathairn', gender: 'male' },
      { name: 'Gerard Butler', gender: 'male' },
      { name: 'Bruce Willis', gender: 'male' },
      { name: 'Bill Murray', gender: 'male' },
      { name: 'Heath Ledger', gender: 'male' },
      { name: 'Clive Owen', gender: 'male' },
      { name: 'Tom Sizemore', gender: 'male' },
      { name: 'Christopher Lee', gender: 'male' },
      { name: 'Jean Reno', gender: 'male' },
      { name: 'Adrien Brody', gender: 'male' },
      { name: 'Daniel Craig', gender: 'male' },
      { name: 'William H. Macy', gender: 'male' },
      { name: 'Alan Arkin', gender: 'male' },
      { name: 'Tim Roth', gender: 'male' },
      { name: 'Javier Bardem', gender: 'male' },
      { name: 'Tom Hardy', gender: 'male' },
      { name: 'James Caan', gender: 'male' },
      { name: 'Chris Cooper', gender: 'male' },
      { name: 'Josh Hartnett', gender: 'male' },
      { name: 'Harvey Keitel', gender: 'male' },
      { name: 'Armin Mueller-Stahl', gender: 'male' },
      { name: 'Mark Wahlberg', gender: 'male' },
      { name: 'Christopher Plummer', gender: 'male' },
      { name: 'Michael Shannon', gender: 'male' },
      { name: 'Jamie Foxx', gender: 'male' },
      { name: 'Donald Sutherland', gender: 'male' },
      { name: 'Hugh Jackman', gender: 'male' },
      { name: 'John Lithgow', gender: 'male' },
      { name: 'Ryan Gosling', gender: 'male' },
      { name: 'Richard Gere', gender: 'male' },
      { name: 'Ben Affleck', gender: 'male' },
      { name: 'Elliott Gould', gender: 'male' },
      { name: 'Warren Beatty', gender: 'male' },
      { name: 'Bradley Cooper', gender: 'male' },
      { name: 'Martin Sheen', gender: 'male' },
      { name: 'Woody Harrelson', gender: 'male' },
      { name: 'Brian Cox', gender: 'male' },
      { name: 'John Cusack', gender: 'male' },
      { name: 'Joe Pesci', gender: 'male' },
      { name: 'Dominic Chianese', gender: 'male' },
      { name: 'Oliver Platt', gender: 'male' },
      { name: 'Hal Holbrook', gender: 'male' },
      { name: 'Keith David', gender: 'male' },
      { name: 'James Gandolfini', gender: 'male' },
      { name: 'Andy Garcia', gender: 'male' },
      { name: 'Peter Dinklage', gender: 'male' },
      { name: 'Alan Rickman', gender: 'male' },
      { name: 'Stellan Skarsgård', gender: 'male' },
      { name: 'Ray Liotta', gender: 'male' },
      { name: 'Tom Berenger', gender: 'male' },
      { name: 'Jeremy Piven', gender: 'male' },
      { name: 'Ethan Hawke', gender: 'male' },
      { name: 'Laurence Fishburne', gender: 'male' },
      { name: 'Justin Theroux', gender: 'male' },
      { name: 'Ken Watanabe', gender: 'male' },
      { name: 'Colm Meaney', gender: 'male' },
      { name: 'Eli Wallach', gender: 'male' },
      { name: 'Scott Glenn', gender: 'male' },
      { name: 'James Woods', gender: 'male' },
      { name: 'Richard Jenkins', gender: 'male' },
      { name: 'Colin Farrell', gender: 'male' },
      { name: 'Chazz Palminteri', gender: 'male' },
      { name: 'John Goodman', gender: 'male' },
      { name: 'John C. Reilly', gender: 'male' },
      { name: 'Jim Carrey', gender: 'male' },
      { name: 'Wes Studi', gender: 'male' },
      { name: 'David Morse', gender: 'male' },
      { name: 'Richard Dreyfuss', gender: 'male' },
      { name: 'Kevin Kline', gender: 'male' },
      { name: 'Idris Elba', gender: 'male' },
      { name: 'Kevin Pollak', gender: 'male' },
      { name: 'Josh Lucas', gender: 'male' },
      { name: 'John Turturro', gender: 'male' },
      { name: 'Matthew McConaughey', gender: 'male' },
      { name: 'Stephen Rea', gender: 'male' },
      { name: 'Thomas Jane', gender: 'male' },
      { name: 'Stanley Tucci', gender: 'male' },
      { name: 'Val Kilmer', gender: 'male' },
      { name: 'Vincent Cassel', gender: 'male' },
      { name: 'Matt Dillon', gender: 'male' },
      { name: 'Will Patton', gender: 'male' },
      { name: 'Hector Elizondo', gender: 'male' },
      { name: 'Sidney Poitier', gender: 'male' },
      { name: 'James Earl Jones', gender: 'male' },
      { name: 'Greg Kinnear', gender: 'male' },
      { name: 'Michael Keaton', gender: 'male' },
      { name: 'Max von Sydow', gender: 'male' },
      { name: 'Edward Burns', gender: 'male' },
      { name: 'Sam Neill', gender: 'male' },
      { name: 'Michael Madsen', gender: 'male' },
      { name: 'Sylvester Stallone', gender: 'male' },
      { name: 'Michael Clarke Duncan', gender: 'male' },
      { name: 'Timothy Hutton', gender: 'male' },
      { name: 'Bob Hoskins', gender: 'male' },
      { name: 'Nicolas Cage', gender: 'male' },
      { name: 'Justin Timberlake', gender: 'male' },
      { name: 'Robert Patrick', gender: 'male' },
      { name: 'Sam Shepard', gender: 'male' },
      { name: 'Joe Pantoliano', gender: 'male' },
      { name: 'Bryan Cranston', gender: 'male' },
      { name: 'Ian Holm', gender: 'male' },
      { name: 'Mark Ruffalo', gender: 'male' },
      { name: 'Giovanni Ribisi', gender: 'male' },
      { name: 'Ben Stiller', gender: 'male' },
      { name: 'Anthony Mackie', gender: 'male' },
      { name: 'Woody Allen', gender: 'male' },
      { name: 'Eddie Murphy', gender: 'male' },
      { name: 'Jeremy Renner', gender: 'male' },
      { name: 'Joseph Gordon-Levitt', gender: 'male' },
      { name: 'James Coburn', gender: 'male' },
      { name: 'Martin Landau', gender: 'male' },
      { name: 'Guy Pearce', gender: 'male' },
      { name: 'William Fichtner', gender: 'male' },
      { name: 'Danny DeVito', gender: 'male' },
      { name: 'Dennis Farina', gender: 'male' },
      { name: 'Jeffrey Wright', gender: 'male' },
      { name: 'John Hurt', gender: 'male' },
      { name: 'Christian Slater', gender: 'male' },
      { name: 'Cuba Gooding Jr.', gender: 'male' }
    ]

    return _.sampleSize(users, 120).map(user => {
      return {
        name: user.name,
        gender: user.gender,
        email: _.toLower(_.replace(user.name, / /g, '') + '@gmail.com')
      }
    })
  },
  fakePhotos() {
    const photos = []
    for (let i = 0; i < 15; i++) {
      photos[photos.length] = 'profile-' + _.random(1, 15) + '.jpg'
    }
    return _.sampleSize(photos, 10)
  },
  fakeImages() {
    const images = []
    for (let i = 0; i < 15; i++) {
      images[images.length] = 'preview-' + _.random(1, 15) + '.jpg'
    }
    return _.sampleSize(images, 10)
  },
  fakeDates() {
    const dates = []
    for (let i = 0; i < 5; i++) {
      dates[dates.length] = dayjs
        .unix(_.random(1586584776897, 1672333200000) / 1000)
        .format('DD MMMM YYYY')
    }
    return _.sampleSize(dates, 3)
  },
  fakeTimes() {
    const times = [
      '01:10 PM',
      '05:09 AM',
      '06:05 AM',
      '03:20 PM',
      '04:50 AM',
      '07:00 PM'
    ]
    return _.sampleSize(times, 3)
  },
  fakeFormattedTimes() {
    const times = [
      _.random(10, 60) + ' seconds ago',
      _.random(10, 60) + ' minutes ago',
      _.random(10, 24) + ' hours ago',
      _.random(10, 20) + ' days ago',
      _.random(10, 12) + ' months ago'
    ]
    return _.sampleSize(times, 3)
  },
  fakeTotals() {
    return _.shuffle([_.random(20, 220), _.random(20, 120), _.random(20, 50)])
  },
  fakeTrueFalse() {
    return _.sampleSize([0, 1, 1], 1)
  },
  fakeStocks() {
    return _.shuffle([_.random(50, 220), _.random(50, 120), _.random(50, 50)])
  },
  fakeProducts() {
    const products = [
      { name: 'Dell XPS 13', category: 'PC & Laptop' },
      { name: 'Apple MacBook Pro 13', category: 'PC & Laptop' },
      { name: 'Oppo Find X2 Pro', category: 'Smartphone & Tablet' },
      { name: 'Samsung Galaxy S20 Ultra', category: 'Smartphone & Tablet' },
      { name: 'Sony Master Series A9G', category: 'Electronic' },
      { name: 'Samsung Q90 QLED TV', category: 'Electronic' },
      { name: 'Nike Air Max 270', category: 'Sport & Outdoor' },
      { name: 'Nike Tanjun', category: 'Sport & Outdoor' },
      { name: 'Nikon Z6', category: 'Photography' },
      { name: 'Sony A7 III', category: 'Photography' }
    ]
    return _.shuffle(products)
  },
  fakeNews() {
    const news = [
      {
        title: 'Desktop publishing software like Aldus PageMaker',
        superShortContent: _.truncate(
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          {
            length: 30,
            omission: ''
          }
        ),
        shortContent: _.truncate(
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          {
            length: 150,
            omission: ''
          }
        ),
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
      },
      {
        title: 'Dummy text of the printing and typesetting industry',
        superShortContent: _.truncate(
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
          {
            length: 30,
            omission: ''
          }
        ),
        shortContent: _.truncate(
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
          {
            length: 150,
            omission: ''
          }
        ),
        content:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
      },
      {
        title: 'Popularised in the 1960s with the release of Letraset',
        superShortContent: _.truncate(
          'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
          {
            length: 30,
            omission: ''
          }
        ),
        shortContent: _.truncate(
          'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
          {
            length: 150,
            omission: ''
          }
        ),
        content:
          'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.'
      },
      {
        title: '200 Latin words, combined with a handful of model sentences',
        superShortContent: _.truncate(
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
          {
            length: 50,
            omission: ''
          }
        ),
        shortContent: _.truncate(
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
          {
            length: 150,
            omission: ''
          }
        ),
        content:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
      }
    ]
    return _.shuffle(news)
  },
  fakeFiles() {
    const files = [
      { fileName: 'Celine Dion - Ashes.mp4', type: 'MP4', size: '20 MB' },
      { fileName: 'Laravel 7', type: 'Empty Folder', size: '120 MB' },
      { fileName: fakers.fakeImages()[0], type: 'Image', size: '1.2 MB' },
      { fileName: 'Repository', type: 'Folder', size: '20 KB' },
      { fileName: 'Resources.txt', type: 'TXT', size: '2.2 MB' },
      { fileName: 'Routes.php', type: 'PHP', size: '1 KB' },
      { fileName: 'Dota 2', type: 'Folder', size: '112 GB' },
      { fileName: 'Documentation', type: 'Empty Folder', size: '4 MB' },
      { fileName: fakers.fakeImages()[0], type: 'Image', size: '1.4 MB' },
      { fileName: fakers.fakeImages()[0], type: 'Image', size: '1 MB' }
    ]
    return _.shuffle(files)
  },
  fakeJobs() {
    const jobs = [
      'Frontend Engineer',
      'Software Engineer',
      'Backend Engineer',
      'DevOps Engineer'
    ]
    return _.shuffle(jobs)
  },
  fakeNotificationCount() {
    return _.random(1, 7)
  },
  fakeFoods() {
    const foods = [
      { name: 'Vanilla Latte', image: 'food-beverage-1.jpg' },
      { name: 'Milkshake', image: 'food-beverage-2.jpg' },
      { name: 'Soft Drink', image: 'food-beverage-3.jpg' },
      { name: 'Root Beer', image: 'food-beverage-4.jpg' },
      { name: 'Pocari', image: 'food-beverage-5.jpg' },
      { name: 'Ultimate Burger', image: 'food-beverage-6.jpg' },
      { name: 'Hotdog', image: 'food-beverage-7.jpg' },
      { name: 'Avocado Burger', image: 'food-beverage-8.jpg' },
      {
        name: 'Spaghetti Fettucine Aglio with Beef Bacon',
        image: 'food-beverage-9.jpg'
      },
      {
        name: 'Spaghetti Fettucine Aglio with Smoked Salmon',
        image: 'food-beverage-10.jpg'
      },
      { name: 'Curry Penne and Cheese', image: 'food-beverage-11.jpg' },
      { name: 'French Fries', image: 'food-beverage-12.jpg' },
      { name: 'Virginia Cheese Fries', image: 'food-beverage-13.jpg' },
      { name: 'Virginia Cheese Wedges', image: 'food-beverage-14.jpg' },
      { name: 'Fried/Grilled Banana', image: 'food-beverage-15.jpg' },
      { name: 'Crispy Mushroom', image: 'food-beverage-16.jpg' },
      { name: 'Fried Calamari', image: 'food-beverage-17.jpg' },
      { name: 'Chicken Wings', image: 'food-beverage-18.jpg' },
      { name: 'Snack Platter', image: 'food-beverage-19.jpg' }
    ]
    return _.shuffle(foods)
  }
}


const fakerData = []
for (let i = 0; i < fakers.fakeUsers().length; i++) {
  fakerData[fakerData.length] = {
    users: fakers.fakeUsers(),
    photos: fakers.fakePhotos(),
    images: fakers.fakeImages(),
    dates: fakers.fakeDates(),
    times: fakers.fakeTimes(),
    formattedTimes: fakers.fakeFormattedTimes(),
    totals: fakers.fakeTotals(),
    trueFalse: fakers.fakeTrueFalse(),
    stocks: fakers.fakeStocks(),
    products: fakers.fakeProducts(),
    news: fakers.fakeNews(),
    files: fakers.fakeFiles(),
    jobs: fakers.fakeJobs(),
    notificationCount: fakers.fakeNotificationCount(),
    foods: fakers.fakeFoods()
  }
}

const install = app => {
  app.config.globalProperties.$f = () => {
    return fakerData
  }
}

export { install as default, fakerData as faker }
