import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'



const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('equity-valuation');


const state = () => {
  // TODO: must deal with date input type

  return {
    equities: []
  }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    const {
      user
    } = getUser();
    const application = await addDocWithGenId(this.state.equityValuation, user._value.email);
  }
}



// mutations
const mutations = {
  setEquity(state, value) {
    console.log("setLiability: [" + state + "][ " + value + "]");
    if (value.index === -1) {
      this.state.equityValuation.equities.push(value);
    } else {
      this.state.equityValuation.equities[value.index] = value;
    }

    console.log("this.state.equityValuation.equities:" + JSON.stringify(this.state.equityValuation.equities));
  },


  setEquityValuationObj(state, value) {
    this.state.equityValuation = value;
  }



}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
