<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Upload Document</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Multiple File Upload -->
        <div class="intro-y box mt-5">
          <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base mr-auto">Only upload one file.</h2>
          </div>
          <div id="multiple-file-upload" class="p-5">
            <div class="preview">
              <Dropzone
                ref-key="dropzoneMultipleRef"
                :options="{
                  url: 'https://httpbin.org/post',
                  thumbnailWidth: 150,
                  thumbnailHeight: 150,
                  maxFilesize: 10000000,
                  maxFiles: 1,
                  headers: { 'My-Awesome-Header': 'header value' },
                  addRemoveLinks: false,
                  acceptedFiles: '.jpg, .jpeg, .png, .pdf',
                  dictDefaultMessage: `<p class='text-default'>
                        <i class='fa fa-cloud-upload mr-2'></i> Drag Images or Click Here</p>
                        <p class='form-text'>Allowed Files: .png</p>`
                }"
                class="dropzone">
                <div class="text-lg font-medium">
                  Drop files here or click to upload.
                </div>
                <div class="text-gray-600">
                  This is just a demo dropzone. Selected files are
                  <span class="font-medium">not</span> actually uploaded.
                </div>
              </Dropzone>
            </div>
          </div>
          <div class="grid" style="display: flex;">
            <button class="btn text-center btn-primary shadow-md" @click="handleFileManagerClick" style="margin: auto; margin-bottom: 1em;">
              < Back
            </button>
          </div>
        </div>
        <!-- END: Multiple File Upload -->
      </div>
    </div>
  </div>

  <div
    id="successfully-uploaded-file"
    class="toastify-content hidden flex"
  >
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Your file has Successfully uploaded.</div>
<!--      <div class="text-gray-600 mt-1">-->
<!--        Please upload a NEW file }}.-->
<!--      </div>-->
    </div>
  </div>

  <div
    id="uploaded-processing-file"
    class="toastify-content hidden flex"
  >
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Your file is being uploaded.</div>
      <!--      <div class="text-gray-600 mt-1">-->
      <!--        Please upload a NEW file }}.-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, provide, onMounted} from 'vue'
import {useRoute, useRouter} from "vue-router";

import firebase from "firebase";
import {projectStorage} from "../../firebase/config";
import getUser from '@/composables/getUser';
import getCurrentProfileDetails from '@/composables/getCurrentUserProfileDetails';
import Modal from '@/components/modal/Main.vue';
import Toastify from "toastify-js";
import cash from 'cash-dom/dist/cash'

let uuid = require("uuid");

export default defineComponent({
  components: {
    Modal
  },
  setup() {
    const router = useRouter();
    const dropzoneSingleRef = ref();
    const dropzoneMultipleRef = ref();
    const dropzoneValidationRef = ref();
    const {collect, getCurrentProfileDetailsData} = getCurrentProfileDetails();
    const route = useRoute();

    const images = ref([]);
    const documentType = ref();


    const handleFileManagerClick = () => {
      router.back();
    }

    const {user} = getUser();

    provide('bind[dropzoneMultipleRef]', el => {
      dropzoneMultipleRef.value = el
    })


    onMounted(async () => {

      console.log(route.query);
      let filename = route.query;
      const elDropzoneMultipleRef = dropzoneMultipleRef.value
      elDropzoneMultipleRef.dropzone.on('sending', async (file, xhr, formData) => {
        Toastify({
          node: cash('#uploaded-processing-file')
            .clone()
            .removeClass('hidden')[0],
          duration: 4000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();
      });

      elDropzoneMultipleRef.dropzone.on('success', async (upload) => {

        let rmSpecialCharacters = user.value.email.replace(/[^A-Z0-9]/ig, "_");

        await getCurrentProfileDetailsData(user.value.email);

        console.log("OnMount: elDropzoneMultipleRef event " + collect.value[0].orgName);
        console.log("rmSpecialCharacters: " + rmSpecialCharacters);
        console.log("filename: " + JSON.stringify(filename.documentType));

        console.log("Saving: " + `arch-documents/${collect.value[0].orgName}/${rmSpecialCharacters}/${filename.documentType}.pdf`);


        let file = upload;
        let metadata = {contentType: upload.type};
        let rm = upload.type;
        let removeExtension = rm.split('/');

        let storageRef = projectStorage.ref();

        let imageRef = storageRef.child(`arch-documents/${collect.value[0].orgName}/${rmSpecialCharacters}/${filename.documentType}.pdf`);
        await imageRef.put(file, metadata);
        let downloadURL = await imageRef.getDownloadURL();

        //images.value.push({src: downloadURL});

        //await router.push({path: '/file-manager'});
        Toastify({
          node: cash('#successfully-uploaded-file')
            .clone()
            .removeClass('hidden')[0],
          duration: 4000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();

        router.back();
      })
      elDropzoneMultipleRef.dropzone.on('error', () => {
        alert('Only one file can be loaded at one time.')
      })


    })

    return {documentType, handleFileManagerClick, images};
  }
})
</script>