<template>
  <Suspense>
    <template #default>
      <EditUser :id="id" />
    </template>

    <template #fallback>
      <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
        <LoadingIcon icon="grid" class="w-8 h-8" />
      </div>
    </template>
  </Suspense>
</template>

<script>
import { ref } from 'vue';
import EditUser from './EditUser.vue';

export default {
  props: ['id'],
  components: { EditUser },
  setup(props) {
    console.log(props.id);
    return { props: props.id };
  }
}
</script>
