import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'


const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('now-account')


const state = () => {
  // TODO: must deal with date input type

  return {
    totalNumInvoicesPerMonth: 0,
    averageInvoiceSize: 0,
    averageDaysInvoiceOutstanding: 0,
    totalNumberCustomers: 0,
    averageInvoiceTerms: 0,
    documentationRequiredClientsAccompanyInvoices: false,
    annualVolDiscountReturnAllowance: 0,
    useSubContractors: false,
    anyCommercialLoans: false,
    taxesPastDue: false,
    bankruptcy: false,
    specialInvoiceTypeBilling: false,
    specialInvoiceTypeRetainage: false,
    specialInvoiceTypeGovSales: false,
    specialInvoiceTypeConsignmentSale: false,
    specialInvoiceTypeBillingPriorComplete: false
  }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    console.log(JSON.stringify(this.state.accountNow))
    const {
      user
    } = getUser()
    await addDocWithGenId(this.state.accountNow, user._value.email)
  }
}


// mutations
const mutations = {
  setAccountNowObj(state, value) {
    this.state.accountNow = value
  },
  setTotalNumInvoicesPerMonth(state, value) {
    this.state.accountNow.totalNumInvoicesPerMonth = value
  },
  setAverageInvoiceSize(state, value) {
    this.state.accountNow.averageInvoiceSize = value
  },
  setAverageDaysInvoiceOutstanding(state, value) {
    this.state.accountNow.averageDaysInvoiceOutstanding = value
  },
  setTotalNumberCustomers(state, value) {
    this.state.accountNow.totalNumberCustomers = value
  },
  setAverageInvoiceTerms(state, value) {
    this.state.accountNow.averageInvoiceTerms = value
  },
  setDocumentationRequiredClientsAccompanyInvoices(state, value) {
    this.state.accountNow.documentationRequiredClientsAccompanyInvoices = value
  },
  setAnnualVolDiscountReturnAllowance(state, value) {
    this.state.accountNow.annualVolDiscountReturnAllowance = value
  },
  setUseSubContractors(state, value) {
    this.state.accountNow.useSubContractors = value
  },
  setAnyCommercialLoans(state, value) {
    this.state.accountNow.anyCommercialLoans = value
  },
  setTaxesPastDue(state, value) {
    this.state.accountNow.taxesPastDue = value
  },
  setBankruptcy(state, value) {
    this.state.accountNow.bankruptcy = value
  },
  setSpecialInvoiceTypeBillingPriorComplete(state, value) {
    this.state.accountNow.specialInvoiceTypeBillingPriorComplete = value
  },
  setSpecialInvoiceTypeConsignmentSale(state, value) {
    this.state.accountNow.specialInvoiceTypeConsignmentSale = value
  },
  setSpecialInvoiceTypeGovSales(state, value) {
    this.state.accountNow.specialInvoiceTypeGovSales = value
  },
 setSpecialInvoiceTypeRetainage(state, value) {
    this.state.accountNow.specialInvoiceTypeRetainage = value
  },
 setSpecialInvoiceTypeBilling(state, value) {
    this.state.accountNow.specialInvoiceTypeBilling = value
  }







}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}