<template>
  <div class="flex flex-wrap">
    <div class="grid grid-cols-12 gap-6 pt-5">
      <div style="font-weight: bold;font-size: x-large;" class="col-span-12">
        Income Statement
      </div>
    </div>
  </div>


  <div class="sm:px-20 mt-10 pt-10 py-10 border-t border-gray-200 box dark:border-dark-5 ">

    <p style="font-weight: bold;font-size: large;" class="pb-5">
      Revenue
    </p>


    <div class="grid grid-cols-2 gap-4">
      <label for="input-wizard-1" class="pl-5 form-label">Merchandise Sale</label>
      <input id="input-wizard-1" type="number" class="form-control" v-model="merchandiseSale"/>

      <label for="input-wizard-2" class="pl-5 orm-label">Revenue from training</label>
      <input id="input-wizard-2" type="number" class="form-control "  v-model="revenueFromTraining"/>

      <label for="input-wizard-3" class="pl-5 form-label">Total Revenue</label>
      <input id="input-wizard-3" type="number" class="form-control" v-model="totalRevenue" readonly/>
    </div>



    <p style="font-weight: bold;font-size: large;" class="pb-5 pt-5">Expenses</p>



    <div class="grid grid-cols-2 gap-4">
      <label for="input-wizard-4" class="pl-5 form-label">Procurement Costs</label>
      <input id="input-wizard-4" type="number" class="form-control" v-model="procurementCost"/>

      <label for="input-wizard-5" class="pl-5 form-label">Wages</label>
      <input id="input-wizard-5" type="number" class="form-control" v-model="wages"/>

      <label for="input-wizard-6" class="pl-5 form-label">Rent</label>
      <input id="input-wizard-6" type="number" class="form-control" v-model="rent"/>

      <label for="input-wizard-7" class="pl-5 form-label">Interest Paid</label>
      <input id="input-wizard-7" type="number" class="form-control" v-model="interestPaid"/>

      <label for="input-wizard-8" class="pl-5 form-label">Transportation</label>
      <input id="input-wizard-8" type="number" class="form-control" v-model="transportation"/>

      <label for="input-wizard-9" class="pl-5 form-label">Utilities</label>
      <input id="input-wizard-9" type="number" class="form-control" v-model="utilities"/>

      <label for="input-wizard-10" class="pl-5 form-label">Total Expenses</label>
      <input id="input-wizard-10" type="number" class="form-control" v-model="totalExpenses" readonly/>
    </div>


    <p style="font-weight: bold;font-size: large;" class="pb-5 pt-5">
      Gains
    </p>




    <div class="grid grid-cols-2 gap-4">
      <label for="input-wizard-3" class="pl-5 form-label">Income from sale of van</label>
      <input id="input-wizard-3" type="number" class="form-control" v-model="incomeFromSaleOfValue"/>
    </div>



    <p style="font-weight: bold;font-size: large;" class="pb-5 pt-5">
      Losses
    </p>




    <div class="grid grid-cols-2 gap-4">
      <label for="input-wizard-3" class="pl-5 form-label">(Revenue + Gains) - (Expenses + Losses)</label>
      <input id="input-wizard-3" type="number" class="form-control" v-model="revenueExpenses"/>
    </div>


    <div class="grid grid-cols-2 gap-4">
      <div class="intro-y col-span-12 flex sm:justify-end mt-10"><!---->
        <button class="btn btn-primary w-24 ml-2"  @click="saveEditValues">Save</button>
        <button class="btn btn-primary w-24 ml-2"  @click="cancelButton">Cancel</button>
      </div>
    </div>
  </div>


  <div
    id="success-notification-content_toast"
    class="toastify-content hidden flex"
  >
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">CONGRATULATION</div>
      <div class="text-gray-600 mt-1">
        You have successfully save your income statement data for Archymedes.
      </div>
    </div>
  </div>

</template>

<script>
// https://www.investopedia.com/terms/i/incomestatement.asp
import Toastify from 'toastify-js';
import {  defineComponent, computed, refs, ref } from 'vue'
import { useStore } from '@/store'
import CurrencyInput from "@/components/currency-input/Main.vue";


export default {
  name: "income-statement",
  components: {
    CurrencyInput,
  },
  data() {
    const store = useStore();

    console.log(document.getElementById('app').__vue_app__.config.globalProperties.$store.state);
    console.log(this.$store.state.incomeStatement);


    return {
      store,
    }
  },

  // TODO computed is not needed
  computed: {
    merchandiseSale: {
      get() {
        return this.$store.state.incomeStatement.merchandiseSale;
      },
      set(value) {
        this.$store.commit('incomeStatement/setMerchandiseSale', value);
      }
    },

    totalRevenue() {
      this.$store.state.incomeStatement.totalRevenue =  this.$store.state.incomeStatement.revenueFromTraining + this.$store.state.incomeStatement.merchandiseSale;
      this.$store.commit('incomeStatement/setTotalRevenue', this.$store.state.incomeStatement.totalRevenue);
      return this.$store.state.incomeStatement.revenueFromTraining + this.$store.state.incomeStatement.merchandiseSale;
    },

    revenueFromTraining: {
      get() {
        return this.$store.state.incomeStatement.revenueFromTraining;
      },
      set(value) {
        console.log("setting the revenueFromTraing:" + value)
        this.$store.commit('incomeStatement/setRevenueFromTraining', value);
      }
    },

    procurementCost: {
      get() {
        return this.$store.state.incomeStatement.procurementCost;
      },
      set(value) {
        this.$store.commit('incomeStatement/setProcurementCost', value);
      }
    },


    wages: {
      get() {
        return this.$store.state.incomeStatement.wages;
      },
      set(value) {
        this.$store.commit('incomeStatement/setWages', value);
      }
    },


    rent: {
      get() {
        return this.$store.state.incomeStatement.rent;
      },
      set(value) {
        this.$store.commit('incomeStatement/setRent', value);
      }
    },


    interestPaid: {
      get() {
        return this.$store.state.incomeStatement.interestPaid;
      },
      set(value) {
        this.$store.commit('incomeStatement/setInterestPaid', value);
      }
    },


    transportation: {
      get() {
        return this.$store.state.incomeStatement.transportation;
      },
      set(value) {
        this.$store.commit('incomeStatement/setTransportation', value);
      }
    },


    utilities: {
      get() {
        return this.$store.state.incomeStatement.utilities;
      },
      set(value) {
        this.$store.commit('incomeStatement/setUtilities', value);
      }
    },

    totalExpenses() {
      console.log(this.$store.state.incomeStatement.utilities);
      console.log(this.$store.state.incomeStatement.transportation);
      console.log(this.$store.state.incomeStatement.interestPaid);
      console.log(this.$store.state.incomeStatement.rent);
      console.log(this.$store.state.incomeStatement.wages);
      console.log(this.$store.state.incomeStatement.procurementCost);

      this.$store.state.incomeStatement.totalExpenses = this.$store.state.incomeStatement.utilities
         + this.$store.state.incomeStatement.transportation
         + this.$store.state.incomeStatement.interestPaid
         + this.$store.state.incomeStatement.rent
         + this.$store.state.incomeStatement.wages
         + this.$store.state.incomeStatement.procurementCost;
      this.$store.commit('incomeStatement/setTotalRevenue', this.$store.state.incomeStatement.totalExpenses);
      return this.$store.state.incomeStatement.totalExpenses;
    },

    incomeFromSaleOfValue: {
      get() {
        return this.$store.state.incomeStatement.incomeFromSaleOfValue;
      },
      set(value) {
        this.$store.commit('incomeStatement/setIncomeFromSaleOfValue', value);
      }
    },

    revenueExpenses() {
      this.$store.commit('incomeStatement/setRevenueExpenses', (this.$store.state.incomeStatement.totalRevenue + this.$store.state.incomeStatement.incomeFromSaleOfValue) -
        (this.$store.state.incomeStatement.totalExpenses ));
        return (this.$store.state.incomeStatement.totalRevenue + this.$store.state.incomeStatement.incomeFromSaleOfValue) -
          (this.$store.state.incomeStatement.totalExpenses )
    },
  },

  methods: {
    cancelButton: function () {
      this.$router.push('/performance');
    },
    saveEditValues: function () {
      this.store.dispatch('incomeStatement/submitFirebase');
      Toastify({
        node: cash('#success-notification-content_toast')
          .clone()
          .removeClass('hidden')[0],
        duration: 6000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast();

      this.$router.push('/performance');
    }

  }
}
</script>
