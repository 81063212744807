<template>
<div class="intro-y box p-10 mt-10">
  <div class="center-content">
    <div ref="exampletable" id="exampletable"></div>
     <input type="hidden" id="1" name="cust1" :value="questionUpdate">
  </div>
</div>
</template>

<script>
import { defineComponent,  ref,reactive,  onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import getUser from '@/composables/getUser';
import getDocument from '@/composables/getDocument';
import userCRUD from '@/composables/userCRUD';
import {  faker } from '@/utils/faker';
import { useStore } from '@/store'
import feather from 'feather-icons'
import getCurrentUserProfileDetails from '@/composables/getCurrentUserProfileDetails';
import { projectStorage } from "../../../firebase/config";
import Tabulator from 'tabulator-tables';
import { pdfWebServices } from '@/composables/pdfWebServices';

export default ({
  setup() {
    console.log("HW")
    const table = ref(null);
    const store = useStore();
    const router = useRouter();
    // const { user } = getUser();

    const { error: getDocumentError, getDocumentByID, getDocumentByEmail, getDocumentByOrgName, document: profileDetails } = getDocument();

    const orgName = ref(null);
    const {  getUserByID, user: result, disableUser, enableUser } = userCRUD();

    const pageTitle = " - Member Management";
    const showSearch = false;

    const { user, collect, getCurrentProfileDetailsData} = getCurrentUserProfileDetails();

    const rmSpecialCharactersEmail = ref();

    const affiliatesFileUpload = ref(store.state.loan504.section2.affiliates.fileUpload);
    const affiliatesResult = ref(store.state.loan504.section2.affiliates.result);

    const tabledata = ref([]);
    const exampletable = ref(null);



    const questionUpdate = computed(function(){
      console.log("console.log(store.state.loan504.section2.affiliates.fileUpload):", store.state.loan504.section2.affiliates.fileUpload);
      console.log("store.state.loan504.section2.affiliates.result:", store.state.loan504.section2.affiliates.result);
      let results = false;

      tabledata.value = tabledata.value.filter( el => el.filename !== "504loanform.pdf" );
      tabledata.value.push({
        id: 1,
        filename: '504loanform.pdf',
        section: 'section0'
      });


      tabledata.value = tabledata.value.filter( el => el.filename !== "question1Affiliates.pdf" );
      if (store.state.loan504.section2.affiliates.fileUpload && store.state.loan504.section2.affiliates.result) {
        let results = true;
        tabledata.value.push({
          id: 2,
          filename: 'question1Affiliates.pdf',
          section: 'section2'
        });
      }

      tabledata.value = tabledata.value.filter( el => el.filename !== "question2DirectGuaranteedLoan.pdf" );
      if (store.state.loan504.section2.directGuaranteedLoan.fileUpload && store.state.loan504.section2.directGuaranteedLoan.result) {
          let results = true;
          tabledata.value.push({
            id: 3,
            filename: 'question2DirectGuaranteedLoan.pdf',
            section: 'section2'
          });
      }

      tabledata.value = tabledata.value.filter( el => el.filename !== "declaredBankruptcy.pdf" );
      if (store.state.loan504.section2.declaredBankruptcy.fileUpload && store.state.loan504.section2.declaredBankruptcy.result) {
         let results = true;
         tabledata.value.push({
            id: 3,
              filename: 'declaredBankruptcy.pdf',
              section: 'section2'
            });
      }

      tabledata.value = tabledata.value.filter( el => el.filename !== "arrested6Months.pdf" );
      if (store.state.loan504.section3.arrested6Months.fileUpload && store.state.loan504.section3.arrested6Months.result) {
         let results = true;
         tabledata.value.push({
            id: 3,
              filename: 'arrested6Months.pdf',
              section: 'section3'
            });
      }


      tabledata.value = tabledata.value.filter( el => el.filename !== "previousGovernmentFinancing.pdf" );
      if (store.state.loan504.section3.previousGovernmentFinancing.fileUpload && store.state.loan504.section3.previousGovernmentFinancing.result) {
         let results = true;
         tabledata.value.push({
            id: 3,
              filename: 'previousGovernmentFinancing.pdf',
              section: 'section3'
            });
      }


      if (table.value != null) {
         table.value.setData(tabledata.value);
    }
      return results;
    })

  



    async function retrieve504LoanForm () {
      const response = await pdfWebServices();
      const content = await response.blob();

        console.log("content size: " + content.size);
        let x = URL.createObjectURL(content);
        console.log(x);
        let html = '<!doctype html><html><head><title>BOL PDF</title></head>' +
          '<body>' +
          '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
          x +
          '"></iframe>' +
          '</style></body></html>';

        let popup = window.open("", "_blank");

        popup.document.write(html);
    }






    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }



    async function handleViewDocument(rowInfo) {

      if (rowInfo.filename != '504loanform.pdf') {
      rmSpecialCharactersEmail.value = user.value.email.replace(/[^A-Z0-9]/ig, "_");
      let resumePath = `arch-documents/${collect.value[0].orgName}/${rmSpecialCharactersEmail.value}/504loan/${rowInfo.section}/${rowInfo.filename}`;
      let storageRef = projectStorage.ref();
      let desertRef = storageRef.child(resumePath);

      console.log(JSON.stringify(desertRef));
      desertRef.getDownloadURL()
        .then((url) => {
          let xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          xhr.send();

          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            let blobFile = window.URL.createObjectURL(xhr.response);
            // document.querySelector("iframe").src = blobFile;

            let html = '<!doctype html><html><head><title>BOL PDF</title></head>' +
              '<body>' +
              '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
              blobFile +
              '"></iframe>' +
              '</style></body></html>';

            let popup = window.open("", "_blank");
            popup.document.write(html);
            popup.document.close();
          } });
        } else {
            retrieve504LoanForm();
        }






    }



    onMounted(async () => {
      await getCurrentProfileDetailsData(user.value.email);

      var printIcon = function(cell, formatterParams) { //plain text value
        const a = cash(`<a class="flex items-center mr-3" href="javascript:;">
              <i data-feather="file-text" class="w-4 h-4 mr-1"></i> <u>View</u>
           </a>`);
        return a[0];
      };

      //initialize table
       table.value = new Tabulator("#exampletable", {
        data: tabledata.value, //assign data to table
        // autoColumns:true, //create columns from data field names
        layout: 'fitColumns',
        columns: [
          {
            title: "Name",
            field: "filename"
          },
          {
            title: "Section",
            field: "section"
          },
          {
            title: "Action",
            formatter: printIcon,
            width: 123,
            cellClick: function(e, cell) {
              handleViewDocument(cell.getRow().getData());
            }
          }
        ],
        ajaxURL: "http://help", //ajax URL
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      });
    })

    return {
      questionUpdate,
      tabledata
    }
  }
})
</script>
