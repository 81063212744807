<template>
  <div class="max-w-md mx-auto bg-white shadow-md p-6 rounded-md">
    <h2 class="text-lg font-semibold mb-4">Cash Flow Statement</h2>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Operating Activities</h3>
      <div v-for="(item, index) in this.$store.state.valuation.cashFlowStatement.operating" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2">
        <div>Total Operating Activities</div>
        <div class="text-right">{{ totalOperatingActivities }}</div>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Investing Activities</h3>
      <div v-for="(item, index) in  this.$store.state.valuation.cashFlowStatement.investing" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2">
        <div>Total Investing Activities</div>
        <div class="text-right">{{ totalInvestingActivities }}</div>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Financing Activities</h3>
      <div v-for="(item, index) in  this.$store.state.valuation.cashFlowStatement.financing" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2">
        <div>Total Financing Activities</div>
        <div class="text-right">{{ totalFinancingActivities }}</div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2 border-t border-gray-300 mt-4 pt-4">
      <div class="font-semibold">Net Cash Flow</div>
      <div class="text-right font-semibold">{{ netCashFlow }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CashFlowStatement',
  data() {
    return {

    };
  },
  methods: {
    homeAction() {
      this.$router.push('/eight-a-evaluation2');
    },
  },
  computed: {
    totalOperatingActivities() {
      return  this.$store.state.valuation.cashFlowStatement.operating.reduce((total, item) => total + item.value, 0);
    },
    totalInvestingActivities() {
      return  this.$store.state.valuation.cashFlowStatement.investing.reduce((total, item) => total + item.value, 0);
    },
    totalFinancingActivities() {
      return  this.$store.state.valuation.cashFlowStatement.financing.reduce((total, item) => total + item.value, 0);
    },
    netCashFlow() {
      return this.totalOperatingActivities + this.totalInvestingActivities + this.totalFinancingActivities;
    },
  },
};
</script>

<style>
</style>
