import { ref } from 'vue';

export function useGlobalStaticData() {
  // const globalStaticData = ref({ /* your static data*/ });
  const applicationPdfMapping = ref({
    // SECTION 1
    OCApplicantLegalName: ["store.state.requiredForm.bizInfo.companyName"],
    OCBusinessAddress: ["store.state.requiredForm.bizInfo.address1", "store.state.requiredForm.bizInfo.address2", "store.state.requiredForm.bizInfo.city", "store.state.requiredForm.bizInfo.state", "store.state.requiredForm.bizInfo.zipcode"],
    OCLegalStructure: ["store.state.requiredForm.bizInfo.entityType"],
    OCTaxID: ["store.state.requiredForm.bizInfo.taxId"],
    OCDUNSNumber: ["store.state.requiredForm.bizInfo.duns"],
    OCContactName: ["store.state.requiredForm.ownerInfo.firstName", "store.state.requiredForm.ownerInfo.middleName", "store.state.requiredForm.ownerInfo.lastName"],
    OCEmailAddress: ["store.state.requiredForm.ownerInfo.ownerInfoEmail"],
    OCPhoneNumber: ["store.state.requiredForm.ownerInfo.phone"],
    OCBusinessWebAddress: ["store.state.requiredForm.bizInfo.website"],

    ApplicantHaveAffiliatesQuesYes: ["store.state.loan504.section2.affiliates.result"],
    ApplicantHaveAffiliatesQuesNo: ["store.state.loan504.section2.affiliates.result"],
    ApplicantAffiliatesObtainedLoanFromSBAQuesYes: ["store.state.loan504.section2.directGuaranteedLoan.result"],
    ApplicantAffiliatesObtainedLoanFromSBAQuesNo: ["store.state.loan504.section2.directGuaranteedLoan.result"],
    ApplicantionLoanSubmittedPreviouslyQuesYes: ["store.state.loan504.section2.previouslySubmitted.result"],
    ApplicantionLoanSubmittedPreviouslyQuesNo: ["store.state.loan504.section2.previouslySubmitted.result"],
    ApplicantFiledBankruptcyQuesYes: ["store.state.loan504.section2.declaredBankruptcy.result"],
    ApplicantFiledBankruptcyQuesNo: ["store.state.loan504.section2.declaredBankruptcy.result"],
    ApplicantPresentlyInLegalActionQuesYes: ["store.state.loan504.section2.pendingLawsuits.result"],
    ApplicantPresentlyInLegalActionQuesNo: ["store.state.loan504.section2.pendingLawsuits.result"],
    X401kCheckbox: ["store.state.loan504.section2.businessOwnedBy.type"],
    ESOPCheckbox: ["store.state.loan504.section2.businessOwnedBy.type"],
    TrustCheckbox: ["store.state.loan504.section2.businessOwnedBy.type"],
    CooperativeCheckbox: ["store.state.loan504.section2.businessOwnedBy.type"],
    OCOwnerLegalName1: ["store.state.requiredForm.ownerInfo.firstName", "store.state.requiredForm.ownerInfo.middleName", "store.state.requiredForm.ownerInfo.lastName"],
    // OCTitleOrganizationType1: ["OWNER"],
    OCSSNTIN1: ["store.state.requiredForm.ownerInfo.ssn"],
    // OCOwnershipPercentage1: ["100%"],




    // SECTION 2
    DateOfBirth: ["store.state.requiredForm.ownerInfo.dob"],
    HomeAddress: ["store.state.requiredForm.ownerInfo.ownerInfoAddress1", "store.state.requiredForm.ownerInfo.ownerInfoAddress2", "store.state.requiredForm.ownerInfo.ownerInfoCity", "store.state.requiredForm.ownerInfo.ownerInfoState", "store.state.requiredForm.ownerInfo.ownerInfoZipcode"],
    OwnersFullName: ["store.state.requiredForm.ownerInfo.firstName", "store.state.requiredForm.ownerInfo.middleName", "store.state.requiredForm.ownerInfo.lastName"],
    SSNIRSTIN: ["store.state.requiredForm.ownerInfo.ssn"],
    HomePhone:["store.state.requiredForm.ownerInfo.phone"],
    USCitizenQuesYes: ["store.state.requiredForm.ownerInfo.citizen"],
    USCitizenQuesNo: ["store.state.requiredForm.ownerInfo.citizen"],

    SubjectToIndictmentQuesYes: ["store.state.requiredForm.ownerInfo.indictment"],
    SubjectToIndictmentQuesNo: ["store.state.requiredForm.ownerInfo.indictment"],

    ArrestedInLast6MonthsQuesYes: ["store.state.requiredForm.ownerInfo.arrested"],
    ArrestedInLast6MonthsQuesNo: ["store.state.requiredForm.ownerInfo.arrested"],


    ForAnyCriminalOffenseQuesYes: ["store.state.requiredForm.ownerInfo.convicted"],
    ForAnyCriminalOffenseQuesNo: ["store.state.requiredForm.ownerInfo.convicted"],


    PresentlySuspendedDebarredQuesYes: ["store.state.requiredForm.ownerInfo.suspendFederalAgency"],
    PresentlySuspendedDebarredQuesNo: ["store.state.requiredForm.ownerInfo.suspendFederalAgency"],


    X50PctOrMoreOwnerQuesYes:["store.state.requiredForm.ownerInfo.childSupport"],
    X50PctOrMoreOwnerQuesNo:["store.state.requiredForm.ownerInfo.childSupport"],



    DeclaredBankruptcyQuesYes:["store.state.loan504.section2.declaredBankruptcy.result"],
    DeclaredBankruptcyQuesNo:["store.state.loan504.section2.declaredBankruptcy.result"],



    SubjectOfPendingLawsuitQuesYes:["store.state.loan504.section2.pendingLawsuits.result"],
    SubjectOfPendingLawsuitQuesNo:["store.state.loan504.section2.pendingLawsuits.result"],



    ApplicantReceivedPreviousGovernmentFundingQuesYes:["store.state.loan504.section3.previousGovernmentFinancing.result"],
    ApplicantReceivedPreviousGovernmentFundingQuesNo:["store.state.loan504.section3.previousGovernmentFinancing.result"],


    Veteran: ["store.state.requiredForm.ownerInfo.veteran"],
    Gender: ["store.state.requiredForm.ownerInfo.gender"],
    Race: ["store.state.requiredForm.ownerInfo.race"],
    Ethnicity: ["store.state.requiredForm.ownerInfo.ethnicity"],

    // SECTION 3
    LegalNameApplicantBusiness: ["store.state.requiredForm.bizInfo.companyName"],
    //EPCOC1: "OC",
    PrintNameAuthorizedRep1: ["store.state.requiredForm.ownerInfo.firstName", "store.state.requiredForm.ownerInfo.middleName", "store.state.requiredForm.ownerInfo.lastName"]
    // Title1: ["store.state.requiredForm.ownerInfo.firstName", "store.state.requiredForm.ownerInfo.middleName", "store.state.requiredForm.ownerInfo.lastName"],
  });


  const sbaFormPFSMapping = ref({
    businessPhone: ["store.state.requiredForm.bizInfo.businessContactNumber"],
    name: ["store.state.requiredForm.ownerInfo.firstName", "store.state.requiredForm.ownerInfo.middleName", "store.state.requiredForm.ownerInfo.lastName"],
    homeAddress: ["store.state.requiredForm.ownerInfo.ownerInfoAddress1", "store.state.requiredForm.ownerInfo.ownerInfoAddress1"],
    homePhone: ["store.state.requiredForm.ownerInfo.phone"],
    cityStateZipCode: ["store.state.requiredForm.ownerInfo.ownerInfoCity","store.state.requiredForm.ownerInfo.ownerInfoState","store.state.requiredForm.ownerInfo.ownerInfoZipcode"],
    businessNameApplicantBorrower: ["store.state.requiredForm.bizInfo.companyName"],
    businessAddress: ["store.state.requiredForm.bizInfo.address1", "store.state.requiredForm.bizInfo.a"],
    cooporationCheckBox: ["store.state.requiredForm.bizInfo.entityType"],
    sCorpCheckBox: ["store.state.requiredForm.bizInfo.entityType"],
    llcCheckBox: ["store.state.requiredForm.bizInfo.entityType"],
    partnershipCheckBox: ["store.state.requiredForm.bizInfo.entityType"],
    soleProprietorCheckbox: ["store.state.requiredForm.bizInfo.entityType"],
    // monthDatyYear: [""store.state.requiredForm.bizInfo.companyName""],
    cashonHandBankAmt: ["store.state.financials.owner.assets.cash.amount"],
    savingsAccountAmt: ["store.state.financials.owner.assets.savingAccount.amount"],
    iraRetirementAmt: ["store.state.financials.owner.assets.retirementAccount.amount"],
    accountsAndNotesRecAmt: ["store.state.financials.owner.assets.accountReceivable.amount"],
    lifeInsuranceAmt: ["store.state.financials.owner.assets.lifeInsurance.amount"],

    stocksAndBondAmt: ["store.state.financials.owner.assets.stocksAndBond.amount"],
    realEstateAmt: ["store.state.financials.owner.assets.realEstate.amount"],
    automobilesAmt: ["store.state.financials.owner.assets.automobile.amount"],
    otherPersonalAmt: ["store.state.financials.owner.assets.personalProperties.amount"],


    // otherAssetsAmt: ["store.state.financials.owner.assign.otherAssetsAmt"],
    // totalAmt1: ["store.state.financials.owner.assign.totalAmt1"],
    // accountPayableAmt: ["store.state.financials.owner.assign.liabilities.accountsPayable"],
    // notesPayableBanksAmt: ["store.state.financials.owner.assign.liabilities.notesPayableBanksAmt"],
    // installmentAccountAutoAmt: ["store.state.financials.owner.assign.liabilities.installmentAuto"],
    // monthlyPaymentsAmt1: ["store.state.financials.owner.assign.liabilities.monthlyPaymentsAmt1"],
    // installmentAccountOtherAmt: ["store.state.financials.owner.assign.liabilities.installmentAccountOtherAmt"],
    // monthlyPaymentsAmt2: ["store.state.financials.owner.assign.liabilities.monthlyPaymentsAmt2"],
    // loansAgainstLifeInsAmt: ["store.state.financials.owner.assign.liabilities.loansAgainstLifeInsAmt"],
    // morgagesOnRealEstateAmt: ["store.state.financials.owner.assign.liabilities.morgagesOnRealEstateAmt"],
    // unpaidTaxesAmt: ["store.state.financials.owner.assign.liabilities.unpaidTaxesAmt"],
    // otherLiabilitiesAmt: ["store.state.financials.owner.assign.liabilities.otherLiabilitiesAmt"],
    // netWorthAmt: ["store.state.financials.owner.assign.liabilities.netWorth"],
    // totalLiabilitiesAmt: ["store.state.financials.owner.assign.liabilities.totalLiabilitiesAmt"],
    // totalAmt2: ["store.state.financials.owner.assign.liabilities.totalAmt2"],
    salaryAmt: ["store.state.requiredForm.sourceIncome.salary"],
    netInvestmentIncomeAmt: ["store.state.requiredForm.sourceIncome.netInvestIncome"],
    realEstateIncomeAmt: ["store.state.requiredForm.sourceIncome.realEstateIncome"],
    otherIncomeAmt: ["store.state.requiredForm.sourceIncome.otherIncome"],
    endorserComakerAmt: ["store.state.requiredForm.contingentLiabilities.otherIncome"],
    legalClaimJudgementAmt: ["store.state.requiredForm.contingentLiabilities.legalClaimsFederal"],
    provisionFederalIncomeTaxAmt: ["store.state.requiredForm.contingentLiabilities.incomeTax"],
    otherSpecialDebtAmt: ["store.state.requiredForm.contingentLiabilities.otherSpecialDebt"],
    otherIncome: ["store.state.requiredForm.contingentLiabilities.otherIncome"],
  //   nameAddressNoteHolder1: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameAddressNoteHolder2: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameAddressNoteHolder3: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameAddressNoteHolder4: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameAddressNoteHolder5: ["store.state.requiredForm.bizInfo.companyName"],
  //   originalBalanceAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   originalBalanceAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   originalBalanceAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   originalBalanceAmt4: ["store.state.requiredForm.bizInfo.companyName"],
  //   originalBalanceAmt5: ["store.state.requiredForm.bizInfo.companyName"],
  //   currentBalanceAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   currentBalanceAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   currentBalanceAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   currentBalanceAmt4: ["store.state.requiredForm.bizInfo.companyName"],
  //   currentBalanceAmt5: ["store.state.requiredForm.bizInfo.companyName"],
  //   paymentAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   paymentAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   paymentAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   paymentAmt4: ["store.state.requiredForm.bizInfo.companyName"],
  //   paymentAmt5: ["store.state.requiredForm.bizInfo.companyName"],
  //   frequencyMonthlyAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   frequencyMonthlyAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   frequencyMonthlyAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   frequencyMonthlyAmt4: ["store.state.requiredForm.bizInfo.companyName"],
  //   frequencyMonthlyAmt5: ["store.state.requiredForm.bizInfo.companyName"],
  //   typeCollateral1: ["store.state.requiredForm.bizInfo.companyName"],
  //   typeCollateral2: ["store.state.requiredForm.bizInfo.companyName"],
  //   typeCollateral3: ["store.state.requiredForm.bizInfo.companyName"],
  //   typeCollateral4: ["store.state.requiredForm.bizInfo.companyName"],
  //   typeCollateral5: ["store.state.requiredForm.bizInfo.companyName"],
  //   numberSharesAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   numberSharesAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   numberSharesAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   numberSharesAmt4: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameSecurities1: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameSecurities2: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameSecurities3: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameSecurities4: ["store.state.requiredForm.bizInfo.companyName"],
  //   costAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   costAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   costAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   costAmt4: ["store.state.requiredForm.bizInfo.companyName"],
  //   marketValueQuotationExchangeAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   marketValueQuotationExchangeAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   marketValueQuotationExchangeAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   marketValueQuotationExchangeAmt4: ["store.state.requiredForm.bizInfo.companyName"],
  //   dateQuotationExchange1: ["store.state.requiredForm.bizInfo.companyName"],
  //   dateQuotationExchange2: ["store.state.requiredForm.bizInfo.companyName"],
  //   dateQuotationExchange3: ["store.state.requiredForm.bizInfo.companyName"],
  //   dateQuotationExchange4: ["store.state.requiredForm.bizInfo.companyName"],
  //   totalValueAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   totalValueAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   totalValueAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   totalValueAmt4: ["store.state.requiredForm.bizInfo.companyName"],
  //   typeRealEstate1: ["store.state.requiredForm.bizInfo.companyName"],
  //   typeRealEstate2: ["store.state.requiredForm.bizInfo.companyName"],
  //   typeRealEstate3: ["store.state.requiredForm.bizInfo.companyName"],
  //   propertyAddress1: ["store.state.requiredForm.bizInfo.companyName"],
  //   propertyAddress2: ["store.state.requiredForm.bizInfo.companyName"],
  //   propertyAddress3: ["store.state.requiredForm.bizInfo.companyName"],
  //   datePurchased1: ["store.state.requiredForm.bizInfo.companyName"],
  //   datePurchased2: ["store.state.requiredForm.bizInfo.companyName"],
  //   datePurchased3: ["store.state.requiredForm.bizInfo.companyName"],
  //   originalCostAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   originalCostAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   originalCostAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   presentMarketValue1: ["store.state.requiredForm.bizInfo.companyName"],
  //   presentMarketValue2: ["store.state.requiredForm.bizInfo.companyName"],
  //   presentMarketValue3: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameAddressMorgageholder1: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameAddressMorgageholder2: ["store.state.requiredForm.bizInfo.companyName"],
  //   nameAddressMorgageholder3: ["store.state.requiredForm.bizInfo.companyName"],
  //   mortgageAccountNumber1: ["store.state.requiredForm.bizInfo.companyName"],
  //   mortgageAccountNumber2: ["store.state.requiredForm.bizInfo.companyName"],
  //   mortgageAccountNumber3: ["store.state.requiredForm.bizInfo.companyName"],
  //   mortgageBalanceAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   mortgageBalanceAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   mortgageBalanceAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   paymentMonthYearAmt1: ["store.state.requiredForm.bizInfo.companyName"],
  //   paymentMonthYearAmt2: ["store.state.requiredForm.bizInfo.companyName"],
  //   paymentMonthYearAmt3: ["store.state.requiredForm.bizInfo.companyName"],
  //   statusMortgage1: ["store.state.requiredForm.bizInfo.companyName"],
  //   statusMortgage2: ["store.state.requiredForm.bizInfo.companyName"],
  //   statusMortgage3: ["store.state.requiredForm.bizInfo.companyName"],
  //   otherPersonalPropertyOtherAssets: ["store.state.requiredForm.bizInfo.companyName"],
  //   unpaidTaxes: ["store.state.requiredForm.bizInfo.companyName"],
  //   otherLiabilities: ["store.state.requiredForm.bizInfo.companyName"],
  //   lifeInsuranceHeld: ["store.state.requiredForm.bizInfo.companyName"],
  //   signature1: ["store.state.requiredForm.bizInfo.companyName"],
  //   printName1: ["store.state.requiredForm.bizInfo.companyName"],
  //   signature2: ["store.state.requiredForm.bizInfo.companyName"],
  //   printName2: ["store.state.requiredForm.bizInfo.companyName"],
  //   dateSignature1: ["store.state.requiredForm.bizInfo.companyName"],
  //   socialSecurity1: ["store.state.requiredForm.bizInfo.companyName"],
  //   dateSignature2: ["store.state.requiredForm.bizInfo.companyName"],
  //   socialSecurity2: ["store.state.requiredForm.bizInfo.companyName"],
   });

  const sbaForm1919PdfMapping = ref({
    // SECTION 1
    applicantBusinessLegalName: ["store.state.requiredForm.bizInfo.companyName"],
    PrimaryIndustry: ["store.state.requiredForm.bizInfo.industryType"],
    DBA: ["store.state.requiredForm.bizInfo.dbaName"],
    entityType: [ "store.state.requiredForm.bizInfo.entityType"],
    industryType:[ "store.state.requiredForm.bizInfo.industryType"],

    BusinessAddress:["store.state.requiredForm.bizInfo.address1","store.state.requiredForm.bizInfo.address2","store.state.requiredForm.bizInfo.city","store.state.requiredForm.bizInfo.state","store.state.requiredForm.bizInfo.zipcode"],
    PrimaryContactName: ["store.state.requiredForm.ownerInfo.firstName", "store.state.requiredForm.ownerInfo.middleName", "store.state.requiredForm.ownerInfo.lastName"],

    Veteran: ["store.state.requiredForm.ownerInfo.veteran"],
    Gender: ["store.state.requiredForm.ownerInfo.gender"],
    Race: ["store.state.requiredForm.ownerInfo.race"],
    Ethnicity: ["store.state.requiredForm.ownerInfo.ethnicity"],


    ques1Yes: ["store.state.loan7a.sevenAQuestion1"],
    ques1No: ["store.state.loan7a.sevenAQuestion1"],

    ques2Yes: ["store.state.loan7a.sevenAQuestion2"],
    ques2No: ["store.state.loan7a.sevenAQuestion2"],

    ques3Yes: ["store.state.loan7a.sevenAQuestion3"],
    ques3No: ["store.state.loan7a.sevenAQuestion3"],

    ques4Yes: ["store.state.loan7a.sevenAQuestion4"],
    ques4No: ["store.state.loan7a.sevenAQuestion4"],

    ques5Yes: ["store.state.loan7a.sevenAQuestion5"],
    ques5No: ["store.state.loan7a.sevenAQuestion5"],
    export1: ["store.state.loan7a.sevenAQuestion5b1"],
    export2: ["store.state.loan7a.sevenAQuestion5b2"],
    export3: ["store.state.loan7a.sevenAQuestion5b3"],

    ques6Yes: ["store.state.loan7a.sevenAQuestion6"],
    ques6No: ["store.state.loan7a.sevenAQuestion6"],

    ques7Yes: ["store.state.loan7a.sevenAQuestion7"],
    ques7No: ["store.state.loan7a.sevenAQuestion7"],

    ques8Yes: ["store.state.loan7a.sevenAQuestion8"],
    ques8No: ["store.state.loan7a.sevenAQuestion8"],

    ques9Yes: ["store.state.loan7a.sevenAQuestion9"],
    ques9No: ["store.state.loan7a.sevenAQuestion9"],

    ques10Yes: ["store.state.loan7a.sevenAQuestion10"],
    ques10No: ["store.state.loan7a.sevenAQuestion10"],

    ques11Yes: ["store.state.loan7a.sevenAQuestion11"],
    ques11No: ["store.state.loan7a.sevenAQuestion11"],

    ques12Yes: ["store.state.loan7a.sevenAQuestion12"],
    ques12No: ["store.state.loan7a.sevenAQuestion12"],

    ques13Yes: ["store.state.loan7a.sevenAQuestion13"],
    ques13No: ["store.state.loan7a.sevenAQuestion13"],

    printName: ["store.state.requiredForm.ownerInfo.firstName", "store.state.requiredForm.ownerInfo.middleName", "store.state.requiredForm.ownerInfo.lastName"]

  });



  return {
    applicationPdfMapping,
    sbaForm1919PdfMapping,
    sbaFormPFSMapping
  }
}