<template>
<div class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    ">

    <a href="javascript:;" ref="showAlertDialog" data-toggle="modal" @click="" data-target="#edit-assets-modal"/>

        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5 pb-10">
          <div class="intro-y col-span-12 text-center">
      <h1 class="font-bold" >Section 1 - Answer the following questions before moving on to next sessions.</h1>
</div>
  </div>


    <div class="intro-y col-span-12 sm:col-span-6 pb-10">
      <label class="font-bold" >1. Are you presently subject to an idictment, criminal information, arraignment, or other means by which formal criminal charges are brought in any
jurisdiction?*</label>
      <div class="form-check mt-2">
        <input id="radio-switch-1" class="form-check-input" type="radio" value="true" name="indictment-choice" v-model='indictment' />
        <label class="form-check-label" for="radio-switch-1">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-2" class="form-check-input" type="radio" value="false" name="indictment-choice" v-model='indictment' />
        <label class="form-check-label" for="radio-switch-2">No</label>
      </div>
    </div>

    <div class="intro-y col-span-12 sm:col-span-6 pb-10">
      <label class="font-bold" >2. Are you previously suspended, debarrared, proposed for debarment, declared ineligible, or voluntarily excluded from participation in this
transaction by any Federal Department or agency?*</label>
      <div class="form-check mt-2">
        <input id="radio-switch-3" class="form-check-input" type="radio" value="true" name="suspendFederalAgency-choice" v-model='suspendFederalAgency' />
        <label class="form-check-label" for="radio-switch-3">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-4" class="form-check-input" type="radio" value="false" name="suspendFederalAgency-choice" v-model='suspendFederalAgency' />
        <label class="form-check-label" for="radio-switch-4">No</label>
      </div>
    </div>

    <div class="intro-y col-span-12 sm:col-span-6 pb-10">
      <label class="font-bold" >3. If you are a 50% or more owner of the Applicant, are you more than 60 days delinquent on any obligation to pay child support arising under an
administrative order, court order, repayment agreement between the holder and a custodial parent, or repayment agreement between the holder
and a state agency providing child support enforcement service?*</label>
      <div class="form-check mt-2">
        <input id="radio-switch-5" class="form-check-input" type="radio" value="true" name="chilSupport-choice" v-model='chilSupport' />
        <label class="form-check-label" for="radio-switch-5">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-6" class="form-check-input" type="radio" value="false" name="chilSupport" v-model='chilSupport' />
        <label class="form-check-label" for="radio-switch-6">No</label>
      </div>
    </div>




        <div class="intro-y col-span-12 pb-10">
          <label class="font-bold">Disclaimer: Answering ‘yes’ to any of the questions above will automatically eliminate you from continuing your application.</label>

    </div>

    <div id="edit-assets-modal" class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto" tabindex="-1" aria-hidden="true" @click.self="">
      <div class="modal-dialog modal-fullscreen relative w-auto pointer-events-none" style="width: 70%; height: 50%">
        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">


          </div>
          <div class="modal-body p-10">
  <div class="flex flex-row items-center justify-center">

          <AlertCircleIcon class="size-48" />
        </div>
        <div class="text-lg text-center text-red-600">
        Sorry! You’re not eligible for for SBA assistance since you answered ‘yes’ for Question:
              <div class="text-lg text-center text-[#50d71e]">
      Are you presently subject to an indictment, criminal information, arraignment or other means by which formal criminal charges are brought in any jurisdiction?
</div>
          </div>
          </div>
          <div class="text-center mt-5 mb-10">
            <button class="btn btn-outline-secondary w-24 mr-1" data-dismiss="modal" aria-label="Cancel">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

</div>

</template>
<style>
.size-48{
width: 100px;
height: 100px;
padding:10px;
stroke: red;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
</style>

<script>
import {
  defineComponent,
  computed,
  methods,
  ref
} from 'vue'
import {
  useStore
} from '@/store'

import {
  object,
  string,
  number,
  bool
} from "yup";
import * as yup from 'yup';
import {
  mask
} from 'vue-the-mask';

const store = useStore();


let errorValidation = {
  companyName: '',
  dbaName: '',
  address1: '',
  city: '',
  state: '',
  zipcode: '',
  taxId: '',
  duns: '',
  businessContactNumber: '',
  email: '',
};


const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
const dateRegExp =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/

const businessSchema = object().shape({
  companyName: string().required('Company name is a required field'),
  dbaName: string().required('Dba name is a required field'),
  address1: string().required('Address name is a required field'),
  city: string().required('City is a required field'),
  state: string().required('State is a required field'),
  zipcode: string().required('Zipcode is a required field').matches(/^[0-9]+$/, "Must be only digits").min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
  taxId: string().required('Tax ID is a required field'),
  duns: string().required('DUNS is a required field'),
  businessContactNumber: string().required('Phone number is a required field').matches(phoneRegExp, 'Phone number is not valid'),
  email: string().required('Email is a required field').email('Email is not in an email format'),
});

export default {
      directives: {mask},
  data() {
    return {
      store,
      errorValidation,
      businessSchema,
    }

  },
  methods: {
    convertFirstLetterUpper(str) {
      return str.charAt(0).toUpperCase() + str.substring(1, str.length)
    },


    async validate(field, value) {
      let obj = {};
      obj[field] = value;

      await businessSchema
        .validateAt(field, obj).then(() => {
          eval("errorValidation." + field + " = ''");
          store.commit("requiredForm/set" + this.convertFirstLetterUpper(field), value);
        }).catch(err => {
          eval("errorValidation." + field + " = '" + err.message + "'");
          store.commit("requiredForm/set" + this.convertFirstLetterUpper(field), value);
        });
    },

    async commitStore() {
      console.log("Section1 commitStore")
      // await loan504.validate(store.state.loan504, {
      //   abortEarly: false
      // });
    },

    getCurrentDateStr() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();


      return mm + '/' + dd + '/' + yyyy;
    },

    proceed() {
      let result = false;
      if (!this.indictment && !this.suspendFederalAgency &&  !this.chilSupport) {
         console.log("can proceed");
         result = true;
       } else {

                  this.$refs.showAlertDialog.click();

       }

      return result
      // await loan504.validate(store.state.loan504, {
      //   abortEarly: false
      // });
    },

    setErrors(e) {
      for (var i in e.inner) {
        eval("this.errorValidation." + e.inner[i].path + " = '" + e.inner[i].errors + "'");
      }
    },

  },


  computed: {
    indictment: {
      get() {
        return this.$store.state.loan504.section1.indictment.result;
      },
      set(value) {
        this.$store.commit('loan504/setIndictment', (value == "true"));
        this.$store.commit('loan504/setIndictmentUpdatedDate', this.getCurrentDateStr());
      }
    },
    suspendFederalAgency: {
      get() {

        return this.$store.state.loan504.section1.suspendFederalAgency.result;
      },
      set(value) {
        this.$store.commit('loan504/setSuspendFederalAgency', (value == "true"))
        this.$store.commit('loan504/setSuspendFederalAgencyUpdatedDate', this.getCurrentDateStr());
      }
    },
    chilSupport: {
      get() {
        return this.$store.state.loan504.section1.chilSupport.result;
      },
      set(value) {
        this.$store.commit('loan504/setChilSupport', (value == "true"))
        this.$store.commit('loan504/setChilSupportUpdatedDate', this.getCurrentDateStr());

      }
    },



  }







}
</script>

<style>
</style>