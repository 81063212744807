<template>
<div class="flex flex-wrap">
  <div class="grid grid-cols-12 gap-6 p-10">
    <div class="col-span-8">
      <h1 class="text-lg font-medium truncate">Valuation Assets for Balance Sheet</h1>
      <small class="text-theme-25 intro-y">You can report your assets by updating your information in appropriate columns. </small>
    </div>
  </div>
</div>
<div>
  <ItemForm :store-value='section' :store-object='this.$store.state.valuation.balanceSheet.assets' :prev-button='prevbutton' :next-button='nextbutton' :save-button='savebutton' ></ItemForm>
</div>

</template>

<script>
import {
  defineComponent,
  computed, onMounted, ref
} from 'vue'
import {
  useStore
} from '@/store'
import { mapState } from 'vuex';

import {
  object,
  string,
  number,
  bool
} from "yup";
import * as yup from 'yup';
import {
  mask
} from 'vue-the-mask';


import ItemForm from '../../../components/item-store/Main.vue';
export default {
  name: "assets",
    directives: { mask },
    components: {
    // CurrencyInput,
      ItemForm,
   },
  setup: function() {
    const store = useStore()

    const delayedAction = () => {
      setTimeout(() => {
        // Code to run after delay
        // console.log('This message will be logged after 3 seconds');
        // console.log("setup: " + store.state)
        // console.log("setup XXXXX " + JSON.stringify(store.state.valuation))
        console.log('setupXXXXX Component is mounted.' + JSON.stringify(store.state.valuation.balanceSheet.assets))

      }, 8000);
    }



    onMounted(
      function async ()  {
        delayedAction();

      }
    )
    return {
      store,
      section: 'Assets',
      prevbutton: undefined,
      nextbutton: '/financial-valuation/equities',
      savebutton: true
    }


  }

}
</script>
