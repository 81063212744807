import { ref } from 'vue';
import firebase from 'firebase';
import { useRouter } from 'vue-router';
import getUser from '@/composables/getUser';

const userCRUD = () => {
    const functions = firebase.functions();
    // firebase.functions().useEmulator("localhost", 5001);

    const router = useRouter();
    const {user: currentUser} = getUser();

    const allUsers = ref(null);
    const user = ref(null);
    const userList = ref(null);
    const deleteID = ref(null);

    const addAdminRole = functions.httpsCallable('addAdminRole');
    const removeAdminRole = functions.httpsCallable('removeAdminRole');

    const promoteToAdmin = async (email) => {
        console.log('Promoting email to Admin:', email);
         await addAdminRole({ email: email}).then(result => {
             console.log(result);
             if(router.currentRoute.value.name == 'side-menu-crud-data-list') {
                 router.go(0);
             }
         });
     }

    const demoteAdmin = async (email) => {
        await removeAdminRole({ email: email}).then(result => {
            console.log(result);

            if(currentUser.value.email != email) {
                router.go(0);
            } else {
                router.go('/');
            }
        });
    }

    // Get All Users from Firebase Authentication
    const getAllUsers = async () => {
        const response = await fetch("https://us-central1-udemy-vue-firebase-site-f35dc.cloudfunctions.net/getAllUsers");
        const data = await response.json();
        console.log(data);

        allUsers.value = data;
        return { allUsers };
    }

    // Get a single User from Firebase Authentication
    const getUserByID = async (id) => {
        console.log(id);
        const response = await fetch("https://us-central1-udemy-vue-firebase-site-f35dc.cloudfunctions.net/getUserByID?id=" + id);
        const data = await response.json();
        console.log(data);

        user.value = data;
        return { user };
    }

    // Get a list of Users from Firebase Authentication
    const getUsersByID = async (usersQueryString) => {
        console.log(usersQueryString);
        const response = await fetch("https://us-central1-udemy-vue-firebase-site-f35dc.cloudfunctions.net/getUsersByID" + usersQueryString);
        const data = await response.json();
        console.log(data);

        userList.value = data;
        return { userList };
    }

    // Update a single User from Firebase Authentication
    const updateUser = async (id, params) => {
        console.log(id);
        console.log(params);

        let paramsQueryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        console.log(paramsQueryString);

        const response = await fetch("https://us-central1-udemy-vue-firebase-site-f35dc.cloudfunctions.net/updateUser?id=" + id + "&" + paramsQueryString);
        const data = await response.json();
        console.log(data);

        user.value = data;
        return { user };
    }

    // Disable a single user
    const disableUser = async (id) => {
        console.log(id);
        const response = await fetch("https://us-central1-udemy-vue-firebase-site-f35dc.cloudfunctions.net/disableUser?id=" + id);
        const data = await response.json();
        console.log(data);

        user.value = data;

        return { user };
    }

    // Enable a single user
    const enableUser = async (id) => {
        console.log(id);
        const response = await fetch("https://us-central1-udemy-vue-firebase-site-f35dc.cloudfunctions.net/enableUser?id=" + id);
        const data = await response.json();
        console.log(data);

        user.value = data;

        return { user };
    }

    // Delete a single user
    const deleteUser = async (id) => {
        console.log(id);
        const response = await fetch("https://us-central1-udemy-vue-firebase-site-f35dc.cloudfunctions.net/deleteUser?id=" + id);
        const data = await response.json();
        console.log(data);

        deleteID.value = data;
        return { deleteID };
    }

    const deleteAllUsers = async () => {
        await getAllUsers();
        let listUsers =  allUsers.value;
        for (const account of listUsers) {
            await deleteUser(account.uid);
        }
    }

    return { allUsers, user, userList,
             getAllUsers, getUserByID, getUsersByID, deleteUser, deleteID,
             updateUser, disableUser, enableUser,
             promoteToAdmin, demoteAdmin, deleteAllUsers };

}

export default userCRUD;
