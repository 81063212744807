<template>
  <div class="flex flex-wrap">
    <div class="grid grid-cols-12 gap-6 p-10">
      <div class="col-span-6">
        <h1 class="text-lg font-medium truncate">Valuation Liabilities for Balance Sheet</h1>
        <small class="text-theme-25 intro-y">You can report your assets by updating your information in appropriate columns. </small>
      </div>
    </div>
  </div>
  <div class="col-span-6">
    <ItemForm :store-value='section' :store-object='this.$store.state.valuation.balanceSheet.liabilities' :prev-button='prevbutton' :next-button='nextbutton' :save-button='savebutton'></ItemForm>
  </div>

</template>

<script>
import {
  defineComponent,
  computed
} from 'vue'
import {
  useStore
} from '@/store'
import { mapState } from 'vuex';

import {
  object,
  string,
  number,
  bool
} from "yup";
import * as yup from 'yup';
import {
  mask
} from 'vue-the-mask';


import ItemForm from '../../../components/item-store/Main.vue';

export default {
  name: "liabilities",
  directives: { mask },
  components: {
    // CurrencyInput,
    ItemForm,
  },
  data() {

    const store = useStore();
    return {
      store,
      section: 'Liabilities',
      prevbutton: '/financial-valuation/equities',
      nextbutton: undefined,
      savebutton: false
    }
  },



  computed: {
    localAssetObject: function() {
      return mapState({localAssetObject: state => state.valuation.balanceSheet });
    },

    localCount: function() {
      return this.$store.state.valuation.balanceSheet.liabilities.length;
    },
  },


  methods: {
    resetDeleteID() {},

    getCurrentDateStr() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      return mm + '/' + dd + '/' + yyyy;
    },

  },


}
</script>
