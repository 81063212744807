<template>
  <!-- START: More Info Slide Overs -->
        <div id="ocEPC-slide-over" class="p-5">
          <div class="preview">
            <!-- BEGIN: Slide Over Content -->
            <div
              id="form-slider"
              class="modal modal-slide-over"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header p-5">
                    <h2 class="font-medium text-base mr-auto">
                      {{sliderTitle}}
                    </h2>
                  </div>
                  <div class="modal-body">
                    {{sliderBody}}
                  </div>
                </div>
              </div>
            </div>
            <!-- End Slide over Content -->
          </div>
        </div>
        <!-- END: More Info Slide Overs -->
</template>

<script>
export default {
    props: {
        sliderTitle: {
            type: String,
            required: true
        },
        sliderBody: {
            type: String,
            required: true
        }
    }

}
</script>

