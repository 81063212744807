<template>
  <d-stepper :steps="steps" @confirm="submitChanges" @nextStepChange="nextStepChangeUpdate" >
    <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
  </d-stepper>
  <div
    id="success-notification-content_toast"
    class="toastify-content hidden flex"
  >
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">CONGRATULATION - Required Forms Completed</div>
      <div class="text-gray-600 mt-1">
        You have successfully completed the required form for Archymedes.
      </div>
    </div>
    </div>
</template>


<script>
import { shallowRef, ref, computed, reactive } from 'vue'
import DStepper from "../../components/stepper/Step"

import Section1 from "./Steps/Section1"
import Section2 from "./Steps/Section2"
import Section3 from "./Steps/Section3"
import Section4 from "./Steps/Section4"


import { useStore } from '@/store'
import { useRouter } from 'vue-router';
import Toastify from 'toastify-js';



export default {
  name: "FormStepper",
  components: {
    DStepper
  },
  methods: {
    nextStepChangeUpdate(){
      console.log(JSON.stringify(this.$store.state.loan504))
      this.$store.dispatch('loan504/submitFirebase');
    },
     submitChanges () {
        this.$store.dispatch('loan504/submitFirebase');

        Toastify({
          node: cash('#success-notification-content_toast')
            .clone()
            .removeClass('hidden')[0],
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();

        this.$router.push('portal');
       //console.log('dispatching to vuex to save in firebse:' + JSON.stringify(this.$store));
     }
   },
  data() {
    const store = useStore();
    const router = useRouter();
    const stepEn = reactive([
      {
        name: "Section 1",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(Section2),
      },
      {
        name: "Section 2",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(Section3),
      },
      {
        name: "Section 3",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(Section4),
        confirm:"Submit"
      }
      ]);

    const stepEs = reactive([
      {
        name: "Sección 1",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(Section2),
      },
      {
        name: "Sección 2",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(Section3),
      },
      {
        name: "Sección 3",
        desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
        icon: "fas fa-car",
        disabled: false,
        active: true,
        component: shallowRef(Section4),
        confirm:"Submit"
      }
    ]);

    const steps = this.$i18n.locale === 'en' ? stepEn : stepEs;

    return {
      steps,
      store,

    }
  }
}
</script>

<style>
</style>