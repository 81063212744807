<template>
  <div class="flex flex-wrap">
    <div class="grid grid-cols-12 gap-6 pt-5">
      <div style="font-weight: bold;font-size: x-large;" class="col-span-12">
        Cash Flow Statement
      </div>
    </div>
  </div>

  <div class="sm:px-20 mt-10 pt-10 py-10 border-t border-gray-200 box dark:border-dark-5 ">


    <div style="font-weight: bold; font-size: medium" class="pb-5">
      Cash Flow From Operations
    </div>

    <div>

      <div class="grid grid-cols-2 gap-2">
        <label for="input-wizard-0" class="form-label">Net Earnings</label>
        <input id="input-wizard-0"  class="form-control" type="number" v-model="netEarnings"/>
      </div>

      <p style="font-style: italic;" class="pb-5">
        Additions to Cash
      </p>

      <div class="grid grid-cols-2 gap-2">
        <label for="input-wizard-1" class="pl-5 form-label">Depreciations</label>
        <input id="input-wizard-1" type="number" class="form-control"  v-model="depreciations"/>

        <label for="input-wizard-2" class="pl-5 form-label">Decrease in Accounts Receivable</label>
        <input id="input-wizard-2" type="number" class="form-control" v-model="decreaseInAccountsReceivable"/>

        <label for="input-wizard-3" class="pl-5 form-label">Increase in Accounts Payable</label>
        <input id="input-wizard-3" type="number" class="form-control" v-model="increaseInAccountsPayable"/>

        <label for="input-wizard-4" class="pl-5 form-label">Increase in Taxes Payable</label>
        <input id="input-wizard-4" type="number" class="form-control" v-model="increaseInTaxesPayable"/>
      </div>

      <p style="font-style: italic;" class="pb-5">
        Subtractions From Cash
      </p>

      <div class="grid grid-cols-2 gap-2">
        <label for="input-wizard-5" class="pl-5 form-label">Increase in Inventory</label>
        <input id="input-wizard-5" type="number" class="form-control" v-model="increaseInInventory"/>

        <label for="input-wizard-6" style="font-style: normal;" class="form-label">
          <u>Net Cash From Operations</u>
        </label>
        <input id="input-wizard-6" type="number" class="form-control" v-model="netCashFromOperations"/>

      </div>

      <p style="font-weight: bold; font-size: medium" class="pb-5">
        Cash Flow From Investing
      </p>

      <div class="grid grid-cols-2 gap-2">
        <label for="input-wizard-7" class="pl-5 form-label">Equipment</label>
        <input id="input-wizard-7" type="number" class="form-control" v-model="equipment"/>
      </div>


      <p style="font-weight: bold; font-size: medium" class="pb-5">
        Cash Flow From Financing
      </p>

      <div class="grid grid-cols-2 gap-2">
        <label for="input-wizard-8" class="pl-5 form-label">Notes Payable</label>
        <input id="input-wizard-8" type="number" class="form-control" v-model="notesPayable"/>

        <label for="input-wizard-9" style="font-weight: bold; font-size: medium" class="form-label">Cash Flow for
          period</label>
        <input id="input-wizard-9" type="number" class="form-control" v-model="cashFlowForPeriod" readonly/>

      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="intro-y col-span-12 flex sm:justify-end mt-10"><!---->
        <button class="btn btn-primary w-24 ml-2"  @click="saveEditValues">Save</button>
        <button class="btn btn-primary w-24 ml-2"  @click="cancelButton">Cancel</button>
      </div>
    </div>
  </div>


  <div id="success-notification-content_toast" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">CONGRATULATION</div>
      <div class="text-gray-600 mt-1">
        You have successfully save your cash flow data for Archymedes.
      </div>
    </div>
  </div>

</template>

<script>
// https://www.investopedia.com/investing/what-is-a-cash-flow-statement/
import Toastify from 'toastify-js';
import {defineComponent, computed, refs, ref} from 'vue'
import {useStore} from '@/store'
import CurrencyInput from "@/components/currency-input/Main.vue";


export default {
  name: "assets",
  data() {
    const store = useStore();
    return {
      store
    }
  },


  computed: {
    netEarnings: {
      get() {
        return this.$store.state.cashFlow.netEarnings;
      },
      set(value) {
        this.$store.commit('cashFlow/setNetEarnings', value);
      }
    },

    depreciations: {
      get() {
        return this.$store.state.cashFlow.depreciations;
      },
      set(value) {
        this.$store.commit('cashFlow/setDepreciations', value);
      }
    },

    decreaseInAccountsReceivable: {
      get() {
        return this.$store.state.cashFlow.decreaseInAccountsReceivable;
      },
      set(value) {
        this.$store.commit('cashFlow/setDecreaseInAccountsReceivable', value);
      }
    },

    increaseInAccountsPayable: {
      get() {
        return this.$store.state.cashFlow.increaseInAccountsPayable;
      },
      set(value) {
        this.$store.commit('cashFlow/setIncreaseInAccountsPayable', value);
      }
    },

    increaseInTaxesPayable: {
      get() {
        return this.$store.state.cashFlow.increaseInTaxesPayable;
      },
      set(value) {
        this.$store.commit('cashFlow/setIncreaseInTaxesPayable', value);
      }
    },

    increaseInInventory: {
      get() {
        return this.$store.state.cashFlow.increaseInInventory;
      },
      set(value) {
        this.$store.commit('cashFlow/setIncreaseInInventory', value);
      }
    },

    netCashFromOperations: {
      get() {
        return this.$store.state.cashFlow.netCashFromOperations;
      },
      set(value) {
        this.$store.commit('cashFlow/setNetCashFromOperations', value);
      }
    },

    equipment: {
      get() {
        return this.$store.state.cashFlow.equipment;
      },
      set(value) {
        this.$store.commit('cashFlow/setEquipment', value);
      }
    },

    notesPayable: {
      get() {
        return this.$store.state.cashFlow.notesPayable;
      },
      set(value) {
        this.$store.commit('cashFlow/setNotesPayable', value);
      }
    },

    cashFlowForPeriod() {
      this.$store.state.cashFlow.cashFlowForPeriod = Number(this.$store.state.cashFlow.netEarnings)
      + Number(this.$store.state.cashFlow.depreciations)
      + Number(this.$store.state.cashFlow.decreaseInAccountsReceivable)
      + Number(this.$store.state.cashFlow.increaseInAccountsPayable)
      + Number(this.$store.state.cashFlow.increaseInTaxesPayable)
      + Number(this.$store.state.cashFlow.increaseInInventory)
      + Number(this.$store.state.cashFlow.netCashFromOperations)
      + Number(this.$store.state.cashFlow.equipment)
      + Number(this.$store.state.cashFlow.notesPayable)


      this.$store.commit('cashFlow/setCashFlowForPeriod', this.$store.state.cashFlow.cashFlowForPeriod);
      return this.$store.state.cashFlow.cashFlowForPeriod;
    }



    // cashFlowForPeriod: {
    //   get() {
    //     return this.$store.state.cashFlow.cashFlowForPeriod;
    //   },
    //   set(value) {
    //     this.$store.commit('cashFlow/setCashFlowForPeriod', value);
    //   }
    // },
  },

  methods: {
    cancelButton: function () {
      this.$router.push('/performance');
    },
    saveEditValues: function () {
      this.store.dispatch('cashFlow/submitFirebase');
      Toastify({
        node: cash('#success-notification-content_toast')
          .clone()
          .removeClass('hidden')[0],
        duration: 6000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast();

      this.$router.push('/performance');
    }
  }




}
</script>
