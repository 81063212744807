<template>
  <div class="text-2xl mb-10">
    {{ $t('message.dashboard') }}
  </div>
  <div v-bind:data-id="firebaseConfigData.projectId"></div>
  <!-- Firebase Project ID is stored in the 'data-id' attribute -->
  <div class="mb-10">
    <div class='intro-y box'>
      <div class="grid grid-cols-4 gap-4 p-4">
        <InfoBox @buttonClicked="showData(1)" :class=debitCoverageRatio :title="$t('message.debtCoverageRatio')"
                 :value=(store.state.requiredForm.sourceIncome.totalNetIncome/totalDebtService()).toFixed(1) />
        <InfoBox @buttonClicked="showData(2)" :class=yearsInBusiness :title="$t('message.yearsInBusiness')"
                 :value=store.state.requiredForm.bizInfo.yrsInBusiness />
        <InfoBox @buttonClicked="showData(3)" :class=creditScore :title="$t('message.creditScore')"
                 :value=store.state.requiredForm.ownerInfo.creditScore />
        <InfoBox @buttonClicked="showData(4)" :class=businessStrength :title="$t('message.businessStrength')"
                 :extraText="businessStrengthName" />

          <div class="col-span-4" id="dataDisplay"></div>

      </div>
    </div>
  </div>
  <div class="mb-10">
    <div class='intro-y box'>
      <div class="grid grid-cols-6 gap-4 p-4">
        <div class="grid grid-rows-5 col-span-1  grid-flow-col gap-4 whitespace-nowrap">
          <div v-if="true">
            <CheckSquareIcon class="w-4 h-4 text-green mr-2" />
            Welcome
          </div>
          <div v-else>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welcome
          </div>

          <div v-if="requireFormComplete" >
            <CheckSquareIcon class="w-4 h-4 text-green mr-2" />
            Required Forms
          </div>
          <div v-else><router-link to="/required-forms">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Required Forms</router-link></div>

          <div v-if="financeComplete && requireFormComplete">
            <CheckSquareIcon class="w-4 h-4 text-green mr-2" />
            Financial
          </div>
          <div v-else><router-link to="/financial-statement/assets">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Financial</router-link></div>

          <div v-if="isFoundDocuments && financeComplete && requireFormComplete">
            <CheckSquareIcon class="w-4 h-4 text-green mr-2" />
            File Manager
          </div>
          <div v-else><router-link to="/file-manager">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;File Manager</router-link></div>

<!--          <div v-if="isFoundDocuments && financeComplete && requireFormComplete">-->
<!--            <CheckSquareIcon class="w-4 h-4 text-green mr-2" />-->
<!--            Application-->
<!--          </div>-->
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Application</div>

        </div>
        <div class="col-span-5" id="dataDisplayComplete">
        </div>
      </div>
      </div>
    </div>
</template>

<script setup>

import { defineComponent, computed, ref, reactive, provide, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import { projectStorage, firebaseConfig } from '@/firebase/config'
import getCurrentUserProfileDetails from '@/composables/getCurrentUserProfileDetails'

const store = useStore()
const prediction = ref('')
const isFoundDocuments = ref(false)
const { user, collect, getCurrentProfileDetailsData } = getCurrentUserProfileDetails()
const resumePath = ref('')
const firebaseConfigData = ref(firebaseConfig);

onMounted(async () => {
  showData(1);
  setTimeout(() => {
    console.log('This will Log after 2 seconds');
  }, 2000);


  let dataHtml = 'The debt-service coverage ratio (DSCR) measures a firm\'s available cash flow to pay current debt obligations. The DSCR shows investors and lenders whether a company has enough income to pay its debts. The ratio is calculated by dividing net operating income by debt service, including principal and interest';

  const dataDisplay = document.getElementById('dataDisplay')
  dataDisplay.innerHTML = dataHtml


  await getCurrentProfileDetailsData(user.value.email).then((data) => {
    let rmSpecialCharactersEmail = user.value.email.replace(/[^A-Z0-9]/ig, '_')
    resumePath.value = `arch-documents/${collect.value[0].orgName}/${rmSpecialCharactersEmail}/`
//    console.log("resumePath.value:" + resumePath.value);
    fileExistDocument().then(() => {
      showProgress()
    })

  })


})

function showData(data) {
  let dataHtml = ''
  if (data === 1) {
    dataHtml = 'The debt-service coverage ratio (DSCR) measures a firm\'s available cash flow to pay current debt obligations. The DSCR shows investors and lenders whether a company has enough income to pay its debts. The ratio is calculated by dividing net operating income by debt service, including principal and interest'
  } else if (data === 2) {
    dataHtml = 'Years in Business is counting the number of years the business has been registered and operating regardless of profit or loss of the business.'
  } else if (data === 3) {
    dataHtml = 'A credit score is a three-digit number that reflects your creditworthiness. It typically ranges from 300 to 850. Lenders use this score to assess the likelihood that you’ll repay loans promptly. Factors like repayment history, types of loans, and credit utilization contribute to your credit score. A higher score increases your chances of loan approval and better interest rates'
  } else if (data === 4) {
    dataHtml = 'Archymedes Business Health calculates a health number for the business showing how well the business stands in an overall evaluation. Based on debt coverage ratio (Revenue, income statement, balance sheet, Notes payable).'
}

  const dataDisplay = document.getElementById('dataDisplay')
  dataDisplay.innerHTML = dataHtml
}


function showProgress() {
  let dataHtml = "PLEASE REVIEW THE WELCOME TO ARCYMEDES INFORMATIONAL ABOVE"
                    + "<br/><br/>CONGRATULATIONS & WELCOME TO ARCHYMEDES! YOU ARE TAKING THE RIGHT STEPS TO ACQUIRING A BUSINESS LOAN! NEXT, YOU WILL NEED TO COMPLETE THE REQUIRED FORMS. CHECK IT OUT <a href='/required-forms' class='font-bold'>HERE!</a>"

  if (requireFormComplete.value) {
    dataHtml = "CONGRATULATIONS! YOU COMPLETED THE REQUIRED FORMS! YOU ARE TAKING THE RIGHT STEPS TO ACQUIRING A BUSINESS LOAN! NEXT, YOU WILL NEED TO ADD YOUR PERSONAL AND BUSINESS FINANCES. CHECK IT OUT <a href='/financial-statement/assets' class='font-bold'> HERE!</a>"
  }
  if (financeComplete.value) {
    dataHtml = "CONGRATULATIONS! YOU’VE COMPLETED YOUR FINANCIAL INFORMATION! YOU ARE TAKING THE RIGHT STEPS TO ACQUIRING A BUSINESS LOAN! NEXT YOU WILL NEED TO UPLOAD YOUR PERSONAL AND BUSINESS DOCUMENTS. CHECK OUT OUR <a href='/file-manager' class='font-bold'>FILE MANAGER</a>"
  }
  if (isFoundDocuments.value) {
    dataHtml = "CONGRATULATIONS! YOU UPLOADED ALL YOUR INFORMATION INTO THE FILE MANAGER! YOU ARE TAKING THE RIGHT STEPS TO ACQUIRING A BUSINESS LOAN! NEXT, YOU WILL NEED TO ANSWER A FEW QUESTIONS TO BUILD YOUR LOAN APPLICATION PACKET! CHECK OUT OUR <a href='/7a-loan' class='font-bold'>APPLICATIONS!</a>"
  }

  const dataDisplay = document.getElementById('dataDisplayComplete')
  dataDisplay.innerHTML = dataHtml
}


const creditScore = computed(function() {
  let cs = ''
  if (store.state.requiredForm.ownerInfo.creditScore <= 620) {
    cs = 'alert-danger'
  } else if (store.state.requiredForm.ownerInfo.creditScore >= 621 && store.state.requiredForm.ownerInfo.creditScore <= 720) {
    cs = 'alert-warning'
  } else if (store.state.requiredForm.ownerInfo.creditScore >= 721) {
    cs = 'alert-success'
  }

  return cs
})

const requireFormComplete = computed(function() {
  let done = false

  if (store.state.requiredForm.lastUpdated !== null) {
    done = true
  }
  return done
})

const financeComplete = computed(function() {
  let done = false


  // alert(JSON.stringify(store.state.financials.owner.assets));
  // console.log("store.state.financials.owner.assets");
  for (let key in store.state.financials.owner.assets) {
    if (store.state.financials.owner.assets[key].amount > 0) {
      done = true
    }
    // console.log(`Key: ${key}, Value:`, store.state.financials.owner.assets[key].amount);
  }

  // console.log("store.state.financials.owner.liabilities");
  for (let key in store.state.financials.owner.liabilities) {
    if (store.state.financials.owner.liabilities[key].amount > 0) {
      done = true
    }
    // console.log(`Key: ${key}, Value:`, store.state.financials.owner.liabilities[key].amount);
  }

  return done
})
const handleButtonClicked = (data) => {
  console.log(data); // 'Button is clicked'
}

async function fileExistDocument() {
  console.log('fileExistChangeColors')
  let storageRef = projectStorage.ref()

  console.log('fileExistChangeColors: resumePath.value: ' + resumePath.value)

  await storageRef.child(resumePath.value).listAll().then((listAllFiles) => {
    isFoundDocuments.value = false
    console.log('fileExistChangeColors: ' + JSON.stringify(listAllFiles.items.length))
    for (let i = 0; i < listAllFiles.items.length; i++) {
      console.log('fileExistChangeColors: listAllFiles.items: ' + listAllFiles.items[i].name)

      let filename = listAllFiles.items[i].name

      switch (filename) {
        case 'RESUME.pdf':
          isFoundDocuments.value = true
          console.log('isFoundResume.value = true;')
          break
        case 'PROFITANDLOSS.pdf':
          isFoundDocuments.value = true
          console.log('isFoundProfitAndLoss.value = true;')
          break
        case 'FINANCIALSTATEMENT.pdf':
          isFoundDocuments.value = true
          console.log('isFoundFinancialStatement.value = true;')
          break
        case 'BUSINESSLICENSE.pdf':
          isFoundDocuments.value = true
          console.log('isFoundBusinessLicense.value = true;')
          break
        case 'INCOMETAXI.pdf':
          isFoundDocuments.value = true
          console.log('isFoundIncomeTaxI.value = true;')
          break
        case 'INCOMETAXII.pdf':
          isFoundDocuments.value = true
          console.log('isFoundIncomeTaxII.value = true;')
          break
        case 'INCOMETAXIII.pdf':
          isFoundDocuments.value = true
          console.log('isFoundIncomeTaxIII.value = true;')
          break
      }
    }
  })


}

const yearsInBusiness = computed(function() {
  console.log('store.state.requiredForm.ownerInfo.yrsInBusiness:' + store.state.requiredForm.bizInfo.yrsInBusiness)
  let yib = ''
  if (store.state.requiredForm.bizInfo.yrsInBusiness <= 2) {
    yib = 'alert-danger'
  } else if (store.state.requiredForm.bizInfo.yrsInBusiness === 3) {
    yib = 'alert-warning'
  } else if (store.state.requiredForm.bizInfo.yrsInBusiness > 3) {
    yib = 'alert-success'
  }

  return yib
})

const totalDebtService = function() {
  let totalDebtService = 0
  let sumDebtService = 0

  store.state.financials.notespayable.forEach(function(item) {
    totalDebtService = 0
    if (!isNaN(item.paymentAmount)) {
      totalDebtService = totalDebtService + parseInt(item.paymentAmount) * 12
    }
    sumDebtService = sumDebtService + totalDebtService
  })

  return sumDebtService
}


const businessStrengthRating = computed(function() {
  let businessStrengthValue = 0
  businessStrengthValue =
    ((store.state.requiredForm.sourceIncome.totalNetIncome / totalDebtService()) * .8)
    + (store.state.requiredForm.bizInfo.yrsInBusiness * .10)
    + (store.state.requiredForm.ownerInfo.creditScore * .10)

  return businessStrengthValue.toFixed(1)
})


const debitCoverageRatio = computed(function() {
  let dcrValue = store.state.requiredForm.sourceIncome.totalNetIncome / totalDebtService()
  /*
      Red < 1.15
      ﻿Yellow 1.15 - 1.24
      ﻿Green 1.25  >
      */
  let dcr = ''
  if (dcrValue <= 1.15) {
    dcr = 'alert-danger'
  } else if (dcrValue >= 1.15 && dcrValue <= 1.24) {
    dcr = 'alert-warning'
  } else if (dcrValue >= 1.25) {
    dcr = 'alert-success'
  }

  return dcr
})

const businessStrength = computed(function() {
  let color = ''
  if (businessStrengthRating <= 63.12) {
    color = 'alert-secondary text-red'
  } else if (businessStrengthRating <= 73.2) {
    color = 'alert-secondary  text-yellow'
  } else {
    color = 'alert-secondary text-green'
  }


  if (prediction.value === 'Denied') {
    color = 'alert-secondary text-red'
  }
  if (prediction.value === 'Maybe') {
    color = 'alert-secondary  text-yellow'
  }
  if (prediction.value === 'Approved') {
    color = 'alert-secondary text-green'
  }
  return color
})

const businessStrengthName = computed(function() {
  return store.state.requiredForm.watsonBusinessStrength;
})

</script>

<script>
import InfoBox from '@/components/info-box/Main.vue'

export default {
  components: {
    InfoBox // Register the component
  }
}
</script>