import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'



const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('financials');


const state = () => {
  // TODO: must deal with date input type

  return {
    notespayable: [],
    realestate: [],
    stocksbonds: [],
    owner: {
      assets: {
        cash: {
          type: 'Cash',
          amount: 0,
          updatedDate: '',
          sortOrder: 0
        },
        savingAccount: {
          type: 'Saving Account',
          amount: 0,
          updatedDate: '',
          sortOrder: 1
        },
        retirementAccount: {
          type: 'Retirement Account',
          amount: 0,
          updatedDate: '',
          sortOrder: 2
        },
        accountReceivable: {
          type: 'Account Receivable',
          amount: 0,
          updatedDate: '',
          sortOrder: 3
        },
        lifeInsurance: {
          type: 'Life Insurance',
          amount: 0,
          updatedDate: '',
          sortOrder: 3
        },
        stocksAndBond: {
          type: 'Stocks and Bonds',
          amount: 0,
          updatedDate: '',
          sortOrder: 3
        },
        realEstate: {
          type: 'Real Estate',
          amount: 0,
          updatedDate: '',
          sortOrder: 3
        },
        automobile: {
          type: 'Automobile',
          amount: 0,
          updatedDate: '',
          sortOrder: 4
        },
        personalProperties: {
          type: 'Personal Properties',
          amount: 0,
          updatedDate: '',
          sortOrder: 5
        },
        otherAssets: {
          type: 'Other Assets',
          amount: 0,
          updatedDate: '',
          sortOrder: 6
        }
      },
      liabilities: {
        accountPayable: {
          type: 'Account Payable',
          amount: 0,
          updatedDate: '',
          sortOrder: 1
        },
        installmentAccount: {
          type: 'installment Account',
          amount: 0,
          updatedDate: '',
          sortOrder: 2
        },
        unpaidTaxes: {
          type: 'Unpaid Taxes',
          amount: 0,
          updatedDate: '',
          sortOrder: 3
        },
        otherLiabilities: {
          type: 'Other Liabilites',
          amount: 0,
          updatedDate: '',
          sortOrder: 4
        }
      },
      },
    business: {
      assets: {
        cash: {
          type: 'Cash',
          amount: 0,
          updatedDate: '',
        },
        savingAccount: {
          type: 'Saving Account',
          amount: 0,
          updatedDate: '',
        },
        retirementAccount: {
          type: 'Retirement Account',
          amount: 0,
          updatedDate: '',
        },
        accountReceivable: {
          type: 'Account Receivable',
          amount: 0,
          updatedDate: '',
        },
        automobile: {
          type: 'Automobile',
          amount: 0,
          updatedDate: '',
        },
        personalProperties: {
          type: 'Personal Properties',
          amount: 0,
          updatedDate: '',
        }
      },
      liabilities: {
        accountPayable: {
          type: 'Account Payable',
          amount: 0,
          dateCreated: '12/12/2022',
        },
        installmentAccount: {
          type: 'Installment Account',
          amount: 0,
          dateCreated: '12/12/2022',
        },
        unpaidTaxes: {
          type: 'Unpaid Taxes',
          amount: 0,
          dateCreated: '12/12/2022',
        },
        otherLiabilities: {
          type: 'Other Liabilites',
          amount: 0,
          dateCreated: '12/12/2022',
        }
      },
    }
  }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    const {
      user
    } = getUser();
    const application = await addDocWithGenId(this.state.financials, user._value.email);
  }
}



// mutations
const mutations = {
  setOwnerAssetsCashAmount(state, value) {
    this.state.financials.owner.assets.cash.amount = value;
  },
  setOwnerAssetsCashUpdatedDate(state, value) {
    this.state.financials.owner.assets.cash.updatedDate = value;
  },

  setOwnerAssetsSavingAccountAmount(state, value) {
    this.state.financials.owner.assets.savingAccount.amount = value;
  },
  setOwnerAssetsSavingAccountUpdatedDate(state, value) {
    this.state.financials.owner.assets.savingAccount.updatedDate = value;
  },


  setOwnerAssetsRetirementAccountAmount(state, value) {
    this.state.financials.owner.assets.retirementAccount.amount = value;
  },
  setOwnerAssetsRetirementAccountUpdatedDate(state, value) {
    this.state.financials.owner.assets.retirementAccount.updatedDate = value;
  },


  setOwnerAssetsAccountReceivableAmount(state, value) {
    this.state.financials.owner.assets.accountReceivable.amount = value;
  },
  setOwnerAssetsAccountReceivableUpdatedDate(state, value) {
    this.state.financials.owner.assets.accountReceivable.updatedDate = value;
  },


  setOwnerAssetsAutomobileAmount(state, value) {
    this.state.financials.owner.assets.automobile.amount = value;
  },
  setOwnerAssetsAutomobileUpdatedDate(state, value) {
    this.state.financials.owner.assets.automobile.updatedDate = value;
  },


  setOwnerAssetsPersonalPropertiesAmount(state, value) {
    this.state.financials.owner.assets.personalProperties.amount = value;
  },

  setOwnerAssetsPersonalPropertiesUpdatedDate(state, value) {
    this.state.financials.owner.assets.personalProperties.updatedDate = value;
  },

  setOwnerAssetsOtherAssetsAmount(state, value) {
    this.state.financials.owner.assets.otherAssets.amount = value;
  },

  setOwnerAssetsOtherAssetsUpdatedDate(state, value) {
    this.state.financials.owner.assets.otherAssets.updatedDate = value;
  },




  setOwnerLiabilitiesInstallmentAccountAmount(state, value) {
    this.state.financials.owner.liabilities.installmentAccount.amount = value;
  },

  setOwnerLiabilitiesInstallmentAccountUpdatedDate(state, value) {
    this.state.financials.owner.liabilities.installmentAccount.updatedDate = value;
  },


  setOwnerLiabilitiesAccountPayableAmount(state, value) {
    this.state.financials.owner.liabilities.accountPayable.amount = value;
  },

  setOwnerLiabilitiesAccountPayableUpdatedDate(state, value) {
    this.state.financials.owner.liabilities.accountPayable.updatedDate = value;
  },


  setOwnerLiabilitiesUnpaidTaxesAmount(state, value) {
    this.state.financials.owner.liabilities.unpaidTaxes.amount = value;
  },

  setOwnerLiabilitiesUnpaidTaxesUpdatedDate(state, value) {
    this.state.financials.owner.liabilities.unpaidTaxes.updatedDate = value;
  },


  setOwnerLiabilitiesOtherLiabilitiesAmount(state, value) {
    this.state.financials.owner.liabilities.otherLiabilities.amount = value;
  },

  setOwnerLiabilitiesOtherLiabilitiesUpdatedDate(state, value) {
    this.state.financials.owner.liabilities.otherLiabilities.updatedDate = value;
  },









  setBusinessAssetsCashAmount(state, value) {
    this.state.financials.business.assets.cash.amount = value;
  },
  setBusinessAssetsCashUpdatedDate(state, value) {
    this.state.financials.business.assets.cash.updatedDate = value;
  },


  setBusinessAssetsSavingAccountAmount(state, value) {
    this.state.financials.business.assets.savingAccount.amount = value;
  },
  setBusinessAssetsSavingAmountUpdatedDate(state, value) {
    this.state.financials.business.assets.aavingAmount.updatedDate = value;
  },


  setBusinessAssetsRetirementAccountAmount(state, value) {
    this.state.financials.business.assets.retirementAccount.amount = value;
  },
  setBusinessAssetsRetirementAccountUpdatedDate(state, value) {
    this.state.financials.business.assets.retirementAccount.updatedDate = value;
  },


  setBusinessAssetsAccountReceivableAmount(state, value) {
    this.state.financials.business.assets.accountReceivable.amount = value;
  },
  setBusinessAssetsAccountReceivableUpdatedDate(state, value) {
    this.state.financials.business.assets.accountReceivable.updatedDate = value;
  },


  setBusinessAssetsAutomobileAmount(state, value) {
    this.state.financials.business.assets.automobile.amount = value;
  },
  setBusinessAssetsAutomobileUpdatedDate(state, value) {
    this.state.financials.business.assets.automobile.updatedDate = value;
  },


  setBusinessAssetsPersonalPropertiesAmount(state, value) {
    this.state.financials.business.assets.personalProperties.amount = value;
  },
  setBusinessAssetsPersonalPropertiesUpdatedDate(state, value) {
    this.state.financials.business.assets.personalProperties.updatedDate = value;
  },




  setBusinessLiabilitiesInstallmentAccountAmount(state, value) {
    this.state.financials.business.liabilities.installmentAccount.amount = value;
  },

  setBusinessLiabilitiesInstallmentAccountUpdatedDate(state, value) {
    this.state.financials.business.liabilities.installmentAccount.updatedDate = value;
  },


  setBusinessLiabilitiesAccountPayableAmount(state, value) {
    this.state.financials.business.liabilities.accountPayable.amount = value;
  },

  setBusinessLiabilitiesAccountPayableUpdatedDate(state, value) {
    this.state.financials.business.liabilities.accountPayable.updatedDate = value;
  },


  setBusinessLiabilitiesUnpaidTaxesAmount(state, value) {
    this.state.financials.business.liabilities.unpaidTaxes.amount = value;
  },

  setBusinessLiabilitiesUnpaidTaxesUpdatedDate(state, value) {
    this.state.financials.business.liabilities.unpaidTaxes.updatedDate = value;
  },


  setBusinessLiabilitiesOtherLiabilitiesAmount(state, value) {
    this.state.financials.business.liabilities.otherLiabilities.amount = value;
  },

  setBusinessLiabilitiesOtherLiabilitiesUpdatedDate(state, value) {
    this.state.financials.business.liabilities.otherLiabilities.updatedDate = value;
  },

  setNotesPayable(state, value) {

    if (value.index == -1) {
      this.state.financials.notespayable.push(value.notespayable);
      // console.log(JSON.stringify(value.notespayable));
    } else {
      this.state.financials.notespayable[value.index] = value.notespayable;
      // console.log(JSON.stringify(this.state.financials.notespayable[value.index]));
    }
  },


  setRealEstate(state, value) {
    if (value.index == -1) {
      this.state.financials.realestate.push(value.realestate);
      // console.log(JSON.stringify(value.notespayable));
    } else {
      this.state.financials.realestate[value.index] = value.realestate;
      // console.log(JSON.stringify(this.state.financials.notespayable[value.index]));
    }
  },



  setStocksBonds(state, value) {
// **********************************************
// Value should be -1 because an array starts with zero
// index can be zero


// **********************************************

    if (value.index == -1) {
      this.state.financials.stocksbonds.push(value.stocksbonds);
      // console.log(JSON.stringify(value.notespayable));
    } else {
      this.state.financials.stocksbonds[value.index] = value.stocksbonds;
      // console.log(JSON.stringify(this.state.financials.notespayable[value.index]));
    }
  },


  setFinancialsStateObj(state, value) {
    this.state.financials = value;
  }



}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}