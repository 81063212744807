<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Complete Your Profile</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="flex justify-center">
        <button 
          v-bind:class= "[page == 1 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          1
        </button>
        <button
          v-bind:class= "[page == 2 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          2
        </button>
        <button
          v-bind:class= "[page == 3 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          3
        </button>
        <button
          v-bind:class= "[page == 4 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          4
        </button>
      </div>
      <div class="px-5 mt-10">
        <div class="font-medium text-center text-lg"><strong>Financial Info</strong></div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 1">
          <a 
            href="javascript:;"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#modal-box"
          >
            <PlayIcon class="w-4 h-4 mr-2" /> Assets
          </a>
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 2">
          Liabilities
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 3">
          Source of Income
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 4">
          Contingent Liabilities
        </div>
      </div>
      <div
        class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base"><strong>Please enter the following information honestly!</strong></div>
        <div class="text-gray-600">Whole Dollar Amounts Only</div>
        <div class="text-xs">
          <span class="text-theme-21">*</span> ALL fields are required
        </div>

        <!-- BEGIN: Page 1 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 1">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Cash on Hand & in banks</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.cash.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.cash.$model"
              />
            </div>
            <template v-if="assetsValidate.cash.$error">
              <small
                v-for="(error, index) in assetsValidate.cash.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Savings Accounts</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.savings.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.savings.$model"
              />
            </div>
            <template v-if="assetsValidate.savings.$error">
              <small
                v-for="(error, index) in assetsValidate.savings.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">IRA / Other Retirement Account</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-3"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.retirement.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.retirement.$model"
              />
            </div>
            <template v-if="assetsValidate.retirement.$error">
              <small
                v-for="(error, index) in assetsValidate.retirement.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Accounts & Notes Receivable</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.aNReceivable.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.aNReceivable.$model"
              />
            </div>
            <template v-if="assetsValidate.aNReceivable.$error">
              <small
                v-for="(error, index) in assetsValidate.aNReceivable.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-5" class="form-label">Life Insurance (Cash Surrender Value Only)</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-5"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.lifeInsurance.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.lifeInsurance.$model"
              />
            </div>
            <template v-if="assetsValidate.lifeInsurance.$error">
              <small
                v-for="(error, index) in assetsValidate.lifeInsurance.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-6" class="form-label">Stocks & Bonds</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-6"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.stocksBonds.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.stocksBonds.$model"
              />
            </div>
            <template v-if="assetsValidate.stocksBonds.$error">
              <small
                v-for="(error, index) in assetsValidate.stocksBonds.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-7" class="form-label">Real Estate</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-7"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.realEstate.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.realEstate.$model"
              />
            </div>
            <template v-if="assetsValidate.realEstate.$error">
              <small
                v-for="(error, index) in assetsValidate.realEstate.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-8" class="form-label">Automobiles</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-8"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.automobiles.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.automobiles.$model"
              />
            </div>
            <template v-if="assetsValidate.automobiles.$error">
              <small
                v-for="(error, index) in assetsValidate.automobiles.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-9" class="form-label">Other Personal Property</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-9"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.otherPersonal.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.otherPersonal.$model"
              />
            </div>
            <template v-if="assetsValidate.otherPersonal.$error">
              <small
                v-for="(error, index) in assetsValidate.otherPersonal.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-10" class="form-label">Other Assets</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-10"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': assetsValidate.otherAssets.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="assetsValidate.otherAssets.$model"
              />
            </div>
            <template v-if="assetsValidate.otherAssets.$error">
              <small
                v-for="(error, index) in assetsValidate.otherAssets.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>

          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            <button class="btn btn-primary w-24 ml-2" @click="handleNext" v-if="!isPending">Next</button>
            <button class="btn btn-dark mr-1 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>

            <button class="btn btn-linkedin ml-2" @click="handleSaveandQuit" v-if="!isPending">Save & Exit</button>
            <button class="btn btn-dark mr-1 ml-2 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
        <!-- END: Page 1 -->
        
        <!-- BEGIN: Page 2 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 2">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Accounts Payable</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': liabilitiesValidate.accountsPayable.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="liabilitiesValidate.accountsPayable.$model"
              />
            </div>
            <template v-if="liabilitiesValidate.accountsPayable.$error">
              <small
                v-for="(error, index) in liabilitiesValidate.accountsPayable.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Notes Payable to Banks and Others</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': liabilitiesValidate.notesPayable.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="liabilitiesValidate.notesPayable.$model"
              />
            </div>
            <template v-if="liabilitiesValidate.notesPayable.$error">
              <small
                v-for="(error, index) in liabilitiesValidate.notesPayable.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">Installment Account - Auto (Montly Payment Amount)</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-3"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': liabilitiesValidate.installmentAuto.$error }"
                placeholder="Montly Payment Amount ($)"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="liabilitiesValidate.installmentAuto.$model"
              />
            </div>
            <template v-if="liabilitiesValidate.installmentAuto.$error">
              <small
                v-for="(error, index) in liabilitiesValidate.installmentAuto.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Installment Account - Other (Montly Payment Amount)</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': liabilitiesValidate.installmentOther.$error }"
                placeholder="Montly Payment Amount ($)"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="liabilitiesValidate.installmentOther.$model"
              />
            </div>
            <template v-if="liabilitiesValidate.installmentOther.$error">
              <small
                v-for="(error, index) in liabilitiesValidate.installmentOther.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-5" class="form-label">Loan(s) AGAINST Life Insurance</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-5"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': liabilitiesValidate.againstLifeInsurance.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="liabilitiesValidate.againstLifeInsurance.$model"
              />
            </div>
            <template v-if="liabilitiesValidate.againstLifeInsurance.$error">
              <small
                v-for="(error, index) in liabilitiesValidate.againstLifeInsurance.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-6" class="form-label">Mortgage(s) on Real Estate</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-6"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': liabilitiesValidate.mortgages.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="liabilitiesValidate.mortgages.$model"
              />
            </div>
            <template v-if="liabilitiesValidate.mortgages.$error">
              <small
                v-for="(error, index) in liabilitiesValidate.mortgages.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-7" class="form-label">Unpaid Taxes</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-7"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': liabilitiesValidate.unpaidTaxes.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="liabilitiesValidate.unpaidTaxes.$model"
              />
            </div>
            <template v-if="liabilitiesValidate.unpaidTaxes.$error">
              <small
                v-for="(error, index) in liabilitiesValidate.unpaidTaxes.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-8" class="form-label">Other Liabilities</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-8"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': liabilitiesValidate.otherLiabilities.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="liabilitiesValidate.otherLiabilities.$model"
              />
            </div>
            <template v-if="liabilitiesValidate.otherLiabilities.$error">
              <small
                v-for="(error, index) in liabilitiesValidate.otherLiabilities.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            <button class="btn btn-secondary w-24" @click="handlePrev" v-if="!isPending">Previous</button>
              <button class="btn btn-dark mr-1 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button class="btn btn-primary w-24 ml-2" @click="handleNext" v-if="!isPending">Next</button>
              <button class="btn btn-dark mr-1 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button class="btn btn-linkedin ml-2" @click="handleSaveandQuit" v-if="!isPending">Save & Exit</button>
              <button class="btn btn-dark mr-1 ml-2 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
          </div>
        </div>
        <!-- END: Page 2 -->
        
        <!-- BEGIN: Page 3 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 3">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Salary</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': incomeValidate.salary.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="incomeValidate.salary.$model"
              />
            </div>
            <template v-if="incomeValidate.salary.$error">
              <small
                v-for="(error, index) in incomeValidate.salary.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Net Investment Income</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': incomeValidate.netInvestment.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="incomeValidate.netInvestment.$model"
              />
            </div>
            <template v-if="incomeValidate.netInvestment.$error">
              <small
                v-for="(error, index) in incomeValidate.netInvestment.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">
              Net Operating Income
              <small>
                <a
                  href="javascript:;" class="text-theme-25 font-normal"
                  data-toggle="modal" data-target="#form-slider"
                  @click="moreInfoForNOIncome()"
                >
                  More Info 
                </a>
              </small>
            </label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': incomeValidate.netOperating.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="incomeValidate.netOperating.$model"
              />
            </div>
            <template v-if="incomeValidate.netOperating.$error">
              <small
                v-for="(error, index) in incomeValidate.netOperating.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">Real Estate Income</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-3"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': incomeValidate.incomeRealEstate.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="incomeValidate.incomeRealEstate.$model"
              />
            </div>
            <template v-if="incomeValidate.incomeRealEstate.$error">
              <small
                v-for="(error, index) in incomeValidate.incomeRealEstate.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Other Income</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': incomeValidate.otherIncome.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="incomeValidate.otherIncome.$model"
              />
            </div>
            <template v-if="incomeValidate.otherIncome.$error">
              <small
                v-for="(error, index) in incomeValidate.otherIncome.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            <button class="btn btn-secondary w-24" @click="handlePrev" v-if="!isPending">Previous</button>
              <button class="btn btn-dark mr-1 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button class="btn btn-primary w-24 ml-2" @click="handleNext" v-if="!isPending">Next</button>
              <button class="btn btn-dark mr-1 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button class="btn btn-linkedin ml-2" @click="handleSaveandQuit" v-if="!isPending">Save & Exit</button>
              <button class="btn btn-dark mr-1 ml-2 mb-2" v-else>
                Saving
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
          </div>
        </div>
        <!-- END: Page 3 -->
        
        <!-- BEGIN: Page 4 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 4">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">As Endorser or Co-Maker</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': CLValidate.endorser.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="CLValidate.endorser.$model"
              />
            </div>
            <template v-if="CLValidate.endorser.$error">
              <small
                v-for="(error, index) in CLValidate.endorser.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Legal Claims & Judgments</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': CLValidate.legalClaims.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="CLValidate.legalClaims.$model"
              />
            </div>
            <template v-if="CLValidate.legalClaims.$error">
              <small
                v-for="(error, index) in CLValidate.legalClaims.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">Provision for Federal Income Tax</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-3"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': CLValidate.provision.$error }"
                placeholder="Montly Payment Amount ($)"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="CLValidate.provision.$model"
              />
            </div>
            <template v-if="CLValidate.provision.$error">
              <small
                v-for="(error, index) in CLValidate.provision.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Other Special Debt</label>
            <div class="input-group">
              <div id="input-group-price" class="input-group-text">$</div>
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': CLValidate.otherSpecial.$error }"
                placeholder="Montly Payment Amount ($)"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="CLValidate.otherSpecial.$model"
              />
            </div>
            <template v-if="CLValidate.otherSpecial.$error">
              <small
                v-for="(error, index) in CLValidate.otherSpecial.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            <button class="btn btn-secondary w-24" @click="handlePrev" v-if="!isPending">Previous</button>
            <button class="btn btn-dark mr-1 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
            
            <button class="btn btn-success w-24 ml-2" @click="handleFinish" v-if="!isPending">Finish Profile Setup</button>
            <button class="btn btn-dark mr-1 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>

            <button class="btn btn-linkedin ml-2" @click="handleSaveandQuit" v-if="!isPending">Save & Exit</button>
            <button class="btn btn-dark mr-1 ml-2 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
        <!-- END: Page 4 -->

        <div class="w-full border-t border-gray-200 dark:border-dark-5 mt-5"></div>
      </div>
    </div>
    
    <!-- END: Wizard Layout -->
  </div>

  <Modal>
    <video :src="`${publicPath}Archymedes_Preview.mp4`" width="1000" controls></video>
  </Modal>

  <FormSlider 
        :sliderTitle="sliderTitle"
        :sliderBody="sliderBody" />
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue';
import Modal from '@/components/modal/Main.vue';
import FormSlider from '@/components/form-slider/Main.vue';
import getUser from '@/composables/getUser';
import useDocument from '@/composables/useDocument';
import getDocument from '@/composables/getDocument';
import { useRouter } from 'vue-router';
import {
  required,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    Modal,
    FormSlider,
  },
  setup() {
    const router = useRouter();
    const {user} = getUser();
    const { error,
            getDocumentByID, document: profileDetails } = getDocument('profileDetails', user.value.uid);

    const { error: useDocumentError, isPending, deleteDoc, updateDoc } = useDocument('profileDetails', user.value.uid);
    const publicPath = process.env.BASE_URL;

    const page = ref(1);

    const sliderTitle = ref('');
    const sliderBody = ref('');

    // Asset Refs & Validation
    const assets = reactive({
      cash: 0,
      savings: 0,
      retirement: 0,
      aNReceivable: 0,
      lifeInsurance: 0,
      stocksBonds: 0,
      realEstate: 0,
      automobiles: 0,
      otherPersonal: 0,
      otherAssets: 0,
    });

    const assetRules = {
      cash: { required },
      savings: { required },
      retirement: { required },
      aNReceivable: { required },
      lifeInsurance: { required },
      stocksBonds: { required },
      realEstate: { required },
      automobiles: { required },
      otherPersonal: { required },
      otherAssets: { required },
    }
    const assetsValidate = useVuelidate(assetRules, toRefs(assets));

    // Liability Refs & Validation
    const liabilities = reactive({
      accountsPayable: 0,
      notesPayable: 0,
      installmentAuto: 0,
      installmentOther: 0,
      againstLifeInsurance: 0,
      mortgages: 0,
      unpaidTaxes: 0,
      otherLiabilities: 0,
    });

    const liabilityRules = {
      accountsPayable: { required },
      notesPayable: { required },
      installmentAuto: { required },
      installmentOther: { required },
      againstLifeInsurance: { required },
      mortgages: { required },
      unpaidTaxes: { required },
      otherLiabilities: { required },
    }
    const liabilitiesValidate = useVuelidate(liabilityRules, toRefs(liabilities));

    // Income Refs & Validation
    const income = reactive({
      salary: 0,
      netInvestment: 0,
      netOperating: 0,
      incomeRealEstate: 0,
      otherIncome: 0,
    });

    const incomeRules = {
      salary: { required },
      netInvestment: { required },
      netOperating: { required },
      incomeRealEstate: { required },
      otherIncome: { required },
    }
    const incomeValidate = useVuelidate(incomeRules, toRefs(income));

    // Contingent Liability Refs & Validation
    const contingentLiabilities = reactive({
      endorser: 0,
      legalClaims: 0,
      provision: 0,
      otherSpecial: 0,
    });

    const CLRules = {
      endorser: { required },
      legalClaims: { required },
      provision: { required },
      otherSpecial: { required },
    }
    const CLValidate = useVuelidate(CLRules, toRefs(contingentLiabilities));

    // Total Refs
    const totals = reactive({
      assetTotal: null,
      totalLiabilities: null,
      netWorth: null,
      liabilityTotal: null,
    })

    function findTotal(arrayToAdd) {
      let total = 0;

      console.log(arrayToAdd);
      for(let i = 0; i < arrayToAdd.length; i++) {
        total += arrayToAdd[i];
      }

      console.log('Array Total: ', total);
      return total;
    }

    const handleNext = () => {
      switch(page.value) {
        case 1: {
          assetsValidate.value.$touch();
          if(!assetsValidate.value.$invalid) {
            page.value++;
          }
          break;
        }
        case 2: {
          liabilitiesValidate.value.$touch();
          if(!liabilitiesValidate.value.$invalid) {
            page.value++;
          }
          break;
        }
        case 3: {
          incomeValidate.value.$touch();
          if(!incomeValidate.value.$invalid) {
            page.value++;
          }
          break;
        }
        default:
          break;
      }
    };
    
    const handlePrev = () => {
        page.value--;
    };

    const handleSaveandQuit = async () => {
      switch(page.value) {
        case 1: {
          const assetsArray = [
            assets.cash == '' ? 0 : assets.cash,
            assets.savings == '' ? 0 : assets.savings,
            assets.retirement == '' ? 0 : assets.retirement,
            assets.aNReceivable == '' ? 0 : assets.aNReceivable,
            assets.lifeInsurance == '' ? 0 : assets.lifeInsurance,
            assets.stocksBonds == '' ? 0 : assets.stocksBonds,
            assets.realEstate == '' ? 0 : assets.realEstate,
            assets.automobiles == '' ? 0 : assets.automobiles,
            assets.otherPersonal == '' ? 0 : assets.otherPersonal,
            assets.otherAssets == '' ? 0 : assets.otherAssets,
          ];
          totals.assetTotal = findTotal(assetsArray);

          const updates = {
            "finances.assets": {
              cash: assets.cash == '' ? 0 : assets.cash,
              savings: assets.savings == '' ? 0 : assets.savings,
              retirement: assets.retirement == '' ? 0 : assets.retirement,
              aNReceivable: assets.aNReceivable == '' ? 0 : assets.aNReceivable,
              lifeInsurance: assets.lifeInsurance == '' ? 0 : assets.lifeInsurance,
              stocksBonds: assets.stocksBonds == '' ? 0 : assets.stocksBonds,
              realEstate: assets.realEstate == '' ? 0 : assets.realEstate,
              automobiles: assets.automobiles == '' ? 0 : assets.automobiles,
              otherPersonal: assets.otherPersonal == '' ? 0 : assets.otherPersonal,
              otherAssets: assets.otherAssets == '' ? 0 : assets.otherAssets,
              assetTotal: totals.assetTotal,
            },
            "finances.page": 1
          };
    
          await updateDoc(updates);
    
          router.push({ name: 'portal-page' });
          break;
        }
        case 2: {
          const assetsArray = [
            assets.cash == '' ? 0 : assets.cash,
            assets.savings == '' ? 0 : assets.savings,
            assets.retirement == '' ? 0 : assets.retirement,
            assets.aNReceivable == '' ? 0 : assets.aNReceivable,
            assets.lifeInsurance == '' ? 0 : assets.lifeInsurance,
            assets.stocksBonds == '' ? 0 : assets.stocksBonds,
            assets.realEstate == '' ? 0 : assets.realEstate,
            assets.automobiles == '' ? 0 : assets.automobiles,
            assets.otherPersonal == '' ? 0 : assets.otherPersonal,
            assets.otherAssets == '' ? 0 : assets.otherAssets,
          ];
          
          const liabilitiesArray = [
            liabilities.accountsPayable == '' ? 0 : liabilities.accountsPayable,
            liabilities.notesPayable == '' ? 0 : liabilities.notesPayable,
            liabilities.installmentAuto == '' ? 0 : liabilities.installmentAuto,
            liabilities.installmentOther == '' ? 0 : liabilities.installmentOther,
            liabilities.againstLifeInsurance == '' ? 0 : liabilities.againstLifeInsurance,
            liabilities.mortgages == '' ? 0 : liabilities.mortgages,
            liabilities.unpaidTaxes == '' ? 0 : liabilities.unpaidTaxes,
            liabilities.otherLiabilities == '' ? 0 : liabilities.otherLiabilities,
          ];
          
          totals.assetTotal = findTotal(assetsArray);
          totals.totalLiabilities = findTotal(liabilitiesArray);
          totals.netWorth = totals.assetTotal - totals.totalLiabilities;
          totals.liabilityTotal = totals.netWorth + totals.totalLiabilities;

          const updates = {
            "finances.assets": {
              cash: assets.cash == '' ? 0 : assets.cash,
              savings: assets.savings == '' ? 0 : assets.savings,
              retirement: assets.retirement == '' ? 0 : assets.retirement,
              aNReceivable: assets.aNReceivable == '' ? 0 : assets.aNReceivable,
              lifeInsurance: assets.lifeInsurance == '' ? 0 : assets.lifeInsurance,
              stocksBonds: assets.stocksBonds == '' ? 0 : assets.stocksBonds,
              realEstate: assets.realEstate == '' ? 0 : assets.realEstate,
              automobiles: assets.automobiles == '' ? 0 : assets.automobiles,
              otherPersonal: assets.otherPersonal == '' ? 0 : assets.otherPersonal,
              otherAssets: assets.otherAssets == '' ? 0 : assets.otherAssets,
              assetTotal: totals.assetTotal,
            },
            "finances.liabilities": {
              accountsPayable: liabilities.accountsPayable == '' ? 0 : liabilities.accountsPayable,
              notesPayable: liabilities.notesPayable == '' ? 0 : liabilities.notesPayable,
              installmentAuto: liabilities.installmentAuto == '' ? 0 : liabilities.installmentAuto,
              installmentOther: liabilities.installmentOther == '' ? 0 : liabilities.installmentOther,
              againstLifeInsurance: liabilities.againstLifeInsurance == '' ? 0 : liabilities.againstLifeInsurance,
              mortgages: liabilities.mortgages == '' ? 0 : liabilities.mortgages,
              unpaidTaxes: liabilities.unpaidTaxes == '' ? 0 : liabilities.unpaidTaxes,
              otherLiabilities: liabilities.otherLiabilities == '' ? 0 : liabilities.otherLiabilities,
              totalLiabilities: totals.totalLiabilities,
              netWorth: totals.netWorth,
              liabilityTotal: totals.liabilityTotal,
            },
            "finances.page": 2
          };
    
          await updateDoc(updates);
    
          router.push({ name: 'portal-page' });
          break;
        }
        case 3: {
          const assetsArray = [
            assets.cash == '' ? 0 : assets.cash,
            assets.savings == '' ? 0 : assets.savings,
            assets.retirement == '' ? 0 : assets.retirement,
            assets.aNReceivable == '' ? 0 : assets.aNReceivable,
            assets.lifeInsurance == '' ? 0 : assets.lifeInsurance,
            assets.stocksBonds == '' ? 0 : assets.stocksBonds,
            assets.realEstate == '' ? 0 : assets.realEstate,
            assets.automobiles == '' ? 0 : assets.automobiles,
            assets.otherPersonal == '' ? 0 : assets.otherPersonal,
            assets.otherAssets == '' ? 0 : assets.otherAssets,
          ];
          
          const liabilitiesArray = [
            liabilities.accountsPayable == '' ? 0 : liabilities.accountsPayable,
            liabilities.notesPayable == '' ? 0 : liabilities.notesPayable,
            liabilities.installmentAuto == '' ? 0 : liabilities.installmentAuto,
            liabilities.installmentOther == '' ? 0 : liabilities.installmentOther,
            liabilities.againstLifeInsurance == '' ? 0 : liabilities.againstLifeInsurance,
            liabilities.mortgages == '' ? 0 : liabilities.mortgages,
            liabilities.unpaidTaxes == '' ? 0 : liabilities.unpaidTaxes,
            liabilities.otherLiabilities == '' ? 0 : liabilities.otherLiabilities,
          ];
          
          totals.assetTotal = findTotal(assetsArray);
          totals.totalLiabilities = findTotal(liabilitiesArray);
          totals.netWorth = totals.assetTotal - totals.totalLiabilities;
          totals.liabilityTotal = totals.netWorth + totals.totalLiabilities;

          const updates = {
            "finances.assets": {
              cash: assets.cash == '' ? 0 : assets.cash,
              savings: assets.savings == '' ? 0 : assets.savings,
              retirement: assets.retirement == '' ? 0 : assets.retirement,
              aNReceivable: assets.aNReceivable == '' ? 0 : assets.aNReceivable,
              lifeInsurance: assets.lifeInsurance == '' ? 0 : assets.lifeInsurance,
              stocksBonds: assets.stocksBonds == '' ? 0 : assets.stocksBonds,
              realEstate: assets.realEstate == '' ? 0 : assets.realEstate,
              automobiles: assets.automobiles == '' ? 0 : assets.automobiles,
              otherPersonal: assets.otherPersonal == '' ? 0 : assets.otherPersonal,
              otherAssets: assets.otherAssets == '' ? 0 : assets.otherAssets,
              assetTotal: totals.assetTotal,
            },
            "finances.liabilities": {
              accountsPayable: liabilities.accountsPayable == '' ? 0 : liabilities.accountsPayable,
              notesPayable: liabilities.notesPayable == '' ? 0 : liabilities.notesPayable,
              installmentAuto: liabilities.installmentAuto == '' ? 0 : liabilities.installmentAuto,
              installmentOther: liabilities.installmentOther == '' ? 0 : liabilities.installmentOther,
              againstLifeInsurance: liabilities.againstLifeInsurance == '' ? 0 : liabilities.againstLifeInsurance,
              mortgages: liabilities.mortgages == '' ? 0 : liabilities.mortgages,
              unpaidTaxes: liabilities.unpaidTaxes == '' ? 0 : liabilities.unpaidTaxes,
              otherLiabilities: liabilities.otherLiabilities == '' ? 0 : liabilities.otherLiabilities,
              totalLiabilities: totals.totalLiabilities,
              netWorth: totals.netWorth,
              liabilityTotal: totals.liabilityTotal,
            },
            "finances.income": {
              salary: income.salary == '' ? 0 : income.salary,
              netInvestment: income.netInvestment == '' ? 0 : income.netInvestment,
              netOperating: income.netOperating == '' ? 0 : income.netOperating,
              incomeRealEstate: income.incomeRealEstate == '' ? 0 : income.incomeRealEstate,
              otherIncome: income.otherIncome == '' ? 0 : income.otherIncome,
            },
            "finances.page": 3
          };
    
          await updateDoc(updates);
    
          router.push({ name: 'portal-page' });
          break;
        }
        case 4: {
          const assetsArray = [
            assets.cash == '' ? 0 : assets.cash,
            assets.savings == '' ? 0 : assets.savings,
            assets.retirement == '' ? 0 : assets.retirement,
            assets.aNReceivable == '' ? 0 : assets.aNReceivable,
            assets.lifeInsurance == '' ? 0 : assets.lifeInsurance,
            assets.stocksBonds == '' ? 0 : assets.stocksBonds,
            assets.realEstate == '' ? 0 : assets.realEstate,
            assets.automobiles == '' ? 0 : assets.automobiles,
            assets.otherPersonal == '' ? 0 : assets.otherPersonal,
            assets.otherAssets == '' ? 0 : assets.otherAssets,
          ];
          
          const liabilitiesArray = [
            liabilities.accountsPayable == '' ? 0 : liabilities.accountsPayable,
            liabilities.notesPayable == '' ? 0 : liabilities.notesPayable,
            liabilities.installmentAuto == '' ? 0 : liabilities.installmentAuto,
            liabilities.installmentOther == '' ? 0 : liabilities.installmentOther,
            liabilities.againstLifeInsurance == '' ? 0 : liabilities.againstLifeInsurance,
            liabilities.mortgages == '' ? 0 : liabilities.mortgages,
            liabilities.unpaidTaxes == '' ? 0 : liabilities.unpaidTaxes,
            liabilities.otherLiabilities == '' ? 0 : liabilities.otherLiabilities,
          ];
          
          totals.assetTotal = findTotal(assetsArray);
          totals.totalLiabilities = findTotal(liabilitiesArray);
          totals.netWorth = totals.assetTotal - totals.totalLiabilities;
          totals.liabilityTotal = totals.netWorth + totals.totalLiabilities;
          
          const updates = {
            "finances.assets": {
              cash: assets.cash == '' ? 0 : assets.cash,
              savings: assets.savings == '' ? 0 : assets.savings,
              retirement: assets.retirement == '' ? 0 : assets.retirement,
              aNReceivable: assets.aNReceivable == '' ? 0 : assets.aNReceivable,
              lifeInsurance: assets.lifeInsurance == '' ? 0 : assets.lifeInsurance,
              stocksBonds: assets.stocksBonds == '' ? 0 : assets.stocksBonds,
              realEstate: assets.realEstate == '' ? 0 : assets.realEstate,
              automobiles: assets.automobiles == '' ? 0 : assets.automobiles,
              otherPersonal: assets.otherPersonal == '' ? 0 : assets.otherPersonal,
              otherAssets: assets.otherAssets == '' ? 0 : assets.otherAssets,
              assetTotal: totals.assetTotal,
            },
            "finances.liabilities": {
              accountsPayable: liabilities.accountsPayable == '' ? 0 : liabilities.accountsPayable,
              notesPayable: liabilities.notesPayable == '' ? 0 : liabilities.notesPayable,
              installmentAuto: liabilities.installmentAuto == '' ? 0 : liabilities.installmentAuto,
              installmentOther: liabilities.installmentOther == '' ? 0 : liabilities.installmentOther,
              againstLifeInsurance: liabilities.againstLifeInsurance == '' ? 0 : liabilities.againstLifeInsurance,
              mortgages: liabilities.mortgages == '' ? 0 : liabilities.mortgages,
              unpaidTaxes: liabilities.unpaidTaxes == '' ? 0 : liabilities.unpaidTaxes,
              otherLiabilities: liabilities.otherLiabilities == '' ? 0 : liabilities.otherLiabilities,
              totalLiabilities: totals.totalLiabilities,
              netWorth: totals.netWorth,
              liabilityTotal: totals.liabilityTotal,
            },
            "finances.income": {
              salary: income.salary == '' ? 0 : income.salary,
              netInvestment: income.netInvestment == '' ? 0 : income.netInvestment,
              netOperating: income.netOperating == '' ? 0 : income.netOperating,
              incomeRealEstate: income.incomeRealEstate == '' ? 0 : income.incomeRealEstate,
              otherIncome: income.otherIncome == '' ? 0 : income.otherIncome,
            },
            "finances.contingentLiabilities": {
              endorser: contingentLiabilities.endorser == '' ? 0 : contingentLiabilities.endorser,
              legalClaims: contingentLiabilities.legalClaims == '' ? 0 : contingentLiabilities.legalClaims,
              provision: contingentLiabilities.provision == '' ? 0 : contingentLiabilities.provision,
              otherSpecial: contingentLiabilities.otherSpecial == '' ? 0 : contingentLiabilities.otherSpecial,
            },
            "finances.page": 4
          };
    
          await updateDoc(updates);
    
          router.push({ name: 'portal-page' });
          break;
        }
        default:
          break;
      }

    };

    const handleFinish = async () => {
      const assetsArray = Object.values(assets);
      
      const liabilitiesArray = Object.values(liabilities);
      
      totals.assetTotal = findTotal(assetsArray);
      totals.totalLiabilities = findTotal(liabilitiesArray);
      totals.netWorth = totals.assetTotal - totals.totalLiabilities;
      totals.liabilityTotal = totals.netWorth + totals.totalLiabilities;

      CLValidate.value.$touch();
      if(!CLValidate.value.$invalid) {
        const updates = {
          finances: {
            assets: {
              ...assets,
              assetTotal: totals.assetTotal,
            },
            liabilities: {
              ...liabilities,
              totalLiabilities: totals.totalLiabilities,
              netWorth: totals.netWorth,
              liabilityTotal: totals.liabilityTotal,
            },
            income: {
              ...income,
            },
            contingentLiabilities: {
              ...contingentLiabilities,
            }
          }
        };
  
        await updateDoc(updates);
  
        router.push({ name: 'portal-page' }); 
      }
    };

    function moreInfoForNOIncome() {
      let title = "Net Operating Income";
      let body = "You'll need your income statement to fill in/calculate this value.";

      fillFormSlider(title, body);
    }

    function fillFormSlider(title, body) {
        sliderTitle.value = title
        sliderBody.value = body
    }

    onMounted(async () => {
      await getDocumentByID('profileDetails', user.value.uid);
      
      // If values exist in the DB, populate them in the form
      if (profileDetails.value.finances != null) {
        let profileAssets = profileDetails.value.finances.assets;
        let profileLiabilities = profileDetails.value.finances.liabilities;
        let profileIncome = profileDetails.value.finances.income;
        let profileCLiabilities = profileDetails.value.finances.contingentLiabilities;

        Object.keys(profileAssets).forEach(function(key) {
          if(key in assets) {
            assets[key] = profileAssets[key];
          } else if(key in totals) {
            totals[key] = profileAssets[key];
          }
        })

        Object.keys(profileLiabilities).forEach(function(key) {
          if(key in liabilities) {
            liabilities[key] = profileLiabilities[key];
          } else if(key in totals) {
            totals[key] = profileLiabilities[key];
          }
        })

        Object.keys(profileIncome).forEach(function(key) {
          if(key in income) {
            income[key] = profileIncome[key];
          }
        })

        Object.keys(profileCLiabilities).forEach(function(key) {
          if(key in contingentLiabilities) {
            contingentLiabilities[key] = profileCLiabilities[key];
          }
        })
      }

      if(profileDetails.value.finances.page != null) {
        page.value = profileDetails.value.finances.page
      }
    })

    return { ...toRefs(assets), ...toRefs(liabilities), 
             ...toRefs(income), ...toRefs(contingentLiabilities), ...toRefs(totals),
             assetsValidate, liabilitiesValidate, incomeValidate, CLValidate,
             page, handleNext, handlePrev, handleFinish, isPending, publicPath, handleSaveandQuit,
             sliderTitle, sliderBody, moreInfoForNOIncome, };
  },
}
</script>
