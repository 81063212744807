<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Profile Layout</h2>
    </div>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 items-center justify-center lg:justify-start"
        >
          <div
            class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
          >
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="rounded-full"
              :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
            />
            <div
              class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-25 rounded-full p-2"
            >
              <CameraIcon class="w-4 h-4 text-white" />
            </div>
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
              {{user.displayName}}
            </div>
            <div class="text-gray-600">{{user.email}}</div>
            <!--
            <button class="text-theme-25" @click="handleEditClick"><u>Edit Account</u></button>
            -->
          </div>
        </div>
        <div
          class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <div class="font-medium text-center lg:text-left lg:mt-3">
            Contact Details
          </div>
          <div
            class="flex flex-col justify-center items-center lg:items-start mt-4"
          >
            <div class="truncate sm:whitespace-normal flex items-center">
              <MailIcon class="w-4 h-4 mr-2" />
              {{ user.email }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center mt-3">
              <BriefcaseIcon class="w-4 h-4 mr-2" />
              {{ profile.business.name }}
            </div>
            <div class="truncate sm:whitespace-normal flex items-center mt-3">
              <PhoneIcon class="w-4 h-4 mr-2" />
              {{ profile.phoneNumber }}
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>



</template>

<script>
import getUser from '@/composables/getUser';
import getDocument from '@/composables/getDocument';
import { useRouter } from 'vue-router';

export default ({
  async setup() {
        const router = useRouter();
        const {user} = getUser();
        const {
          error: getDocumentError,
          getDocumentByID,
          document: profile
        } = getDocument('profileDetails', user.value.email);
        await getDocumentByID('profileDetails', user.value.uid);


        return { user, profile };
  }
})
</script>
