<template>
  <div class="text-2xl">
    {{ $t('message.fileManager') }}
  </div>
  To upload a file, simply click on the <MoreVerticalIcon class="w-5 h-5 text-gray-600"/> icon in each square.
  <div class="grid grid-cols-1 gap-6 mt-8">

      <div class="intro-y grid grid-cols-6 gap-6 sm:gap-6 mt-5">
        <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
          <FileIcon v-if="isFoundResume" fill="#4CB24C" width="80%" height="80%" class="mx-auto"></FileIcon>
          <FileIcon v-if="!isFoundResume" width="80%" height="80%" class="mx-auto"></FileIcon>
          <div class="block font-medium text-center truncate">
            Resume
          </div>

          <div ref="parentDropdownResume" class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
            <a
              class="dropdown-toggle w-5 h-5 block"
              href="#"
              aria-expanded="false"
            >
              <MoreVerticalIcon class="w-5 h-5 text-gray-600"/>
            </a>
            <div  class="dropdown-menu w-40 fffff">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">

                <a
                  @click="handleUploadFileClick('RESUME')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  Upload
                </a>

                <a
                  @click="handleViewDocument('RESUME')"
                  href="#"
                  v-if="isFoundResume"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  View
                </a>
                <a
                  v-if="isFoundResume"
                  href="#"
                  @click="handleDeleteDocument('RESUME')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <TrashIcon class="w-4 h-4 mr-2"/>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
          <FileIcon v-if="isFoundProfitAndLoss" fill="#4CB24C" width="80%" height="80%" class="mx-auto"></FileIcon>
          <FileIcon v-if="!isFoundProfitAndLoss" width="80%" height="80%" class="mx-auto"></FileIcon>
          <div class="block font-medium text-center truncate">
            Profit & Loss
          </div>

          <div ref="parentDropdownProfitAndLoss" class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
            <a
              class="dropdown-toggle w-5 h-5 block"
              href="#"
              aria-expanded="false"
            >
              <MoreVerticalIcon class="w-5 h-5 text-gray-600"/>
            </a>
            <div  ref="menuAction" class="dropdown-menu w-40 ">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">

                <a
                  @click="handleUploadFileClick('PROFITANDLOSS')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  Upload
                </a>

                <a
                  @click="handleViewDocument('PROFITANDLOSS')"
                  href="#"
                  v-if="isFoundProfitAndLoss"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  View
                </a>
                <a
                  v-if="isFoundProfitAndLoss"
                  href="#"
                  @click="handleDeleteDocument('PROFITANDLOSS')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <TrashIcon class="w-4 h-4 mr-2"/>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
          <FileIcon v-if="isFoundFinancialStatement" fill="#4CB24C" width="80%" height="80%"  class="mx-auto"></FileIcon>
          <FileIcon v-if="!isFoundFinancialStatement" width="80%" height="80%" class="mx-auto"></FileIcon>
          <div class="block font-medium text-center truncate">
            Financial Statement
          </div>

          <div ref="parentDropdownFinancialStatement" class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
            <a
              class="dropdown-toggle w-5 h-5 block"
              href="#"
              aria-expanded="false"
            >
              <MoreVerticalIcon class="w-5 h-5 text-gray-600"/>
            </a>
            <div class="dropdown-menu w-40 ">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">

                <a
                  @click="handleUploadFileClick('FINANCIALSTATEMENT')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  Upload
                </a>

                <a
                  @click="handleViewDocument('FINANCIALSTATEMENT')"
                  href="#"
                  v-if="isFoundFinancialStatement"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  View
                </a>
                <a
                  v-if="isFoundFinancialStatement"
                  @click="handleDeleteDocument('FINANCIALSTATEMENT')"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <TrashIcon class="w-4 h-4 mr-2"/>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
          <FileIcon v-if="isFoundBusinessLicense" fill="#4CB24C" width="80%" height="80%"  class="mx-auto"></FileIcon>
          <FileIcon v-if="!isFoundBusinessLicense" width="80%" height="80%" class="mx-auto"></FileIcon>
          <div class="block font-medium text-center truncate">
            Business License
          </div>

          <div ref="parentDropdownBusinessLicense" class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
            <a
              class="dropdown-toggle w-5 h-5 block"
              href="#"
              aria-expanded="false"
            >
              <MoreVerticalIcon class="w-5 h-5 text-gray-600"/>
            </a>
            <div class="dropdown-menu w-40 ">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">

                <a
                  @click="handleUploadFileClick('BUSINESSLICENSE')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  Upload
                </a>

                <a
                  @click="handleViewDocument('BUSINESSLICENSE')"
                  href="#"
                  v-if="isFoundBusinessLicense"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  View
                </a>
                <a
                  v-if="isFoundBusinessLicense"
                  href="#"
                  @click="handleDeleteDocument('BUSINESSLICENSE')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <TrashIcon class="w-4 h-4 mr-2"/>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
          <FileIcon v-if="isFoundIncomeTaxI" fill="#4CB24C" width="80%" height="80%"  class="mx-auto"></FileIcon>
          <FileIcon v-if="!isFoundIncomeTaxI" width="80%" height="80%" class="mx-auto"></FileIcon>
          <div class="block font-medium text-center truncate">
            Income Tax I
          </div>

          <div ref="parentDropdownIncomeTaxI" class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
            <a
              class="dropdown-toggle w-5 h-5 block"
              href="#"
              aria-expanded="false"
            >
              <MoreVerticalIcon class="w-5 h-5 text-gray-600"/>
            </a>
            <div class="dropdown-menu w-40 ">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">

                <a
                  @click="handleUploadFileClick('INCOMETAXI')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  Upload
                </a>

                <a
                  @click="handleViewDocument('INCOMETAXI')"
                  href="#"
                  v-if="isFoundIncomeTaxI"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  View
                </a>
                <a
                  v-if="isFoundIncomeTaxI"
                  href="#"
                  @click="handleDeleteDocument('INCOMETAXI')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <TrashIcon class="w-4 h-4 mr-2"/>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
          <FileIcon v-if="isFoundIncomeTaxII" fill="#4CB24C" width="80%" height="80%"  class="mx-auto"></FileIcon>
          <FileIcon v-if="!isFoundIncomeTaxII" width="80%" height="80%" class="mx-auto"></FileIcon>
          <div class="block font-medium text-center truncate">
            Income Tax II
          </div>

          <div ref="parentDropdownIncomeTaxII" class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
            <a
              class="dropdown-toggle w-5 h-5 block"
              href="#"
              aria-expanded="false"
            >
              <MoreVerticalIcon class="w-5 h-5 text-gray-600"/>
            </a>
            <div class="dropdown-menu w-40 ">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">

                <a
                  @click="handleUploadFileClick('INCOMETAXII')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  Upload
                </a>

                <a
                  @click="handleViewDocument('INCOMETAXII')"
                  href="#"
                  v-if="isFoundIncomeTaxII"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <UsersIcon class="w-4 h-4 mr-2"/>
                  View
                </a>
                <a
                  v-if="isFoundIncomeTaxII"
                  href="#"
                  @click="handleDeleteDocument('INCOMETAXII')"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                >
                  <TrashIcon class="w-4 h-4 mr-2"/>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
            <FileIcon v-if="isFoundIncomeTaxIII" fill="#4CB24C" width="80%" height="80%" class="mx-auto"></FileIcon>
            <FileIcon v-if="!isFoundIncomeTaxIII" width="80%" height="80%" class="mx-auto"></FileIcon>
            <div class="block font-medium text-center truncate">
              Income Tax III
            </div>
            <div ref="parentDropdownIncomeTaxIII" class="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
              <a
                class="dropdown-toggle w-5 h-5 block"
                href="#"
                aria-expanded="false"
              >
                <MoreVerticalIcon class="w-5 h-5 text-gray-600"/>
              </a>
              <div class="dropdown-menu w-40 ">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">

                  <a
                    @click="handleUploadFileClick('INCOMETAXIII')"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                  >
                    <UsersIcon class="w-4 h-4 mr-2"/>
                    Upload
                  </a>

                  <a
                    @click="handleViewDocument('INCOMETAXIII')"
                    href="#"
                    v-if="isFoundIncomeTaxIII"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                  >
                    <UsersIcon class="w-4 h-4 mr-2"/>
                    View
                  </a>
                  <a
                    v-if="isFoundIncomeTaxIII"
                    href="#"
                    @click="handleDeleteDocument('INCOMETAXIII')"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2  rounded-md"
                  >
                    <TrashIcon class="w-4 h-4 mr-2"/>
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>


  <div
    id="successfully-delete-resume-notification"
    class="toastify-content hidden flex"
  >
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Your {{ documentTypeProcessing }} has been PERMANENTLY deleted.</div>
      <div class="text-gray-600 mt-1">
        Please upload a NEW {{ documentTypeProcessing }}.
      </div>
    </div>
  </div>

  <div
    id="failure-delete-resume-notification"
    class="toastify-content hidden flex"
  >
    <XCircleIcon  class="text-theme-21" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Something went wrong and we could not DELETE your {{ documentTypeProcessing }}.</div>
      <div class="text-gray-600 mt-1">
        Please try again later.
      </div>
    </div>
  </div>



  <!--   <button class="btn btn-outline-secondary w-30 mr-1" id="show-modal" @click="showModal = true">Show Modal</button> -->
    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <modal @created="deleteDocument" :documentType="documentTypeProcessing" :show="showModal" @close="showModal = false">

        <template #body>
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-21 mx-auto mt-3"/>
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete this {{ documentTypeProcessing }}? <br/>This process
                cannot be undone.
              </div>
            </div>
          </template>
      </modal>
    </Teleport>
</template>

<script>

import {useRouter} from 'vue-router';
import {ref, onMounted} from "vue";
import getCurrentUserProfileDetails from '@/composables/getCurrentUserProfileDetails';
import { projectStorage } from "../../firebase/config";
import Toastify from "toastify-js";
import cash from "cash-dom/dist/cash";
import Modal from '@/components/modal/Main.vue'


export default {
  components: {
    Modal
  },
  setup() {
    const showModal = ref(null);

    const isFoundResume = ref(false);
    const isFoundProfitAndLoss = ref(false);
    const isFoundBusinessLicense = ref(false);
    const isFoundFinancialStatement = ref(false);
    const isFoundIncomeTaxI = ref(false);
    const isFoundIncomeTaxII = ref( false );
    const isFoundIncomeTaxIII = ref( false );



    const parentDropdownResume = ref( true );
    const parentDropdownProfitAndLoss = ref( true );
    const parentDropdownFinancialStatement = ref( true );
    const parentDropdownBusinessLicense = ref( true );
    const parentDropdownIncomeTaxI = ref( true );
    const parentDropdownIncomeTaxII = ref( true );
    const parentDropdownIncomeTaxIII = ref( true );

    const showMenu = ref(false);
    const resumePath = ref('');
    const rmSpecialCharactersEmail = ref('');
    const parentDropdown = ref(null);

    const router = useRouter();
    const {user, collect, getCurrentProfileDetailsData} = getCurrentUserProfileDetails();
    const documentTypeProcessing = ref();


     fileExistChangeColors();




    onMounted(async () => {
      await getCurrentProfileDetailsData(user.value.email);
      rmSpecialCharactersEmail.value = user.value.email.replace(/[^A-Z0-9]/ig, "_");
      resumePath.value = `arch-documents/${collect.value[0].orgName}/${rmSpecialCharactersEmail.value}/`

      await fileExistChangeColors();
    });



    async function fileExistChangeColors() {

      let storageRef = projectStorage.ref();
      let listAllFiles = await storageRef.child(resumePath.value).listAll()
      console.log("resumePath.value: " + resumePath.value);

      isFoundResume.value = false;
      isFoundProfitAndLoss.value = false;
      isFoundBusinessLicense.value = false;
      isFoundFinancialStatement.value = false;
      isFoundIncomeTaxI.value = false;
      isFoundIncomeTaxII.value = false;
      isFoundIncomeTaxIII.value = false;


      for (let i =0; i < listAllFiles.items.length;i++){
        console.log("listAllFiles.items: " + listAllFiles.items[i].name);

        let filename = listAllFiles.items[i].name;

        switch (filename) {
          case "RESUME.pdf":
            isFoundResume.value = true;
            break;
          case "PROFITANDLOSS.pdf":
            isFoundProfitAndLoss.value = true;
            break;
          case "FINANCIALSTATEMENT.pdf":
            isFoundFinancialStatement.value = true;
            break;
          case "BUSINESSLICENSE.pdf":
            isFoundBusinessLicense.value = true;
            break;
          case "INCOMETAXI.pdf":
            isFoundIncomeTaxI.value = true;
            break;
          case "INCOMETAXII.pdf":
            isFoundIncomeTaxII.value = true;
            break;
          case "INCOMETAXIII.pdf":
            isFoundIncomeTaxIII.value = true;
            break;
        }
      }
    }




    function closeMenu(documentType) {
      let replacer = null;

      switch(documentType) {
        case "RESUME":
          replacer = parentDropdownResume.value.childNodes[1].getAttribute('data-dropdown-replacer');
          break;
        case "PROFITANDLOSS":
          replacer = parentDropdownProfitAndLoss.value.childNodes[1].getAttribute('data-dropdown-replacer');
          break;
        case "FINANCIALSTATEMENT":
          replacer = parentDropdownFinancialStatement.value.childNodes[1].getAttribute('data-dropdown-replacer');
          break;
        case "BUSINESSLICENSE":
          replacer = parentDropdownBusinessLicense.value.childNodes[1].getAttribute('data-dropdown-replacer');
          break;
        case "INCOMETAXI":
          replacer = parentDropdownIncomeTaxI.value.childNodes[1].getAttribute('data-dropdown-replacer');
          break;
        case "INCOMETAXII":
          replacer = parentDropdownIncomeTaxII.value.childNodes[1].getAttribute('data-dropdown-replacer');
          break;
        case "INCOMETAXIII":
          replacer = parentDropdownIncomeTaxIII.value.childNodes[1].getAttribute('data-dropdown-replacer');
          break;
      }

      if (replacer != null || replacer != undefined ) {
        document.getElementById(replacer).classList.remove('show');
      }
    }




    function handleUploadFileClick(documentType) {
      closeMenu(documentType);
      router.push({path: '/file-upload', query: { documentType: documentType}});
    }




    function deleteDocument() {
      /* *******************************************************************
      Function used to actually delete physical document from the datastore.
      Function is passed using props to Modal Component so the button of the
      confirm dialog in pressed.
      **********************************************************************/

      let storageRef = projectStorage.ref();
      resumePath.value = `arch-documents/${collect.value[0].orgName}/${rmSpecialCharactersEmail.value}/${documentTypeProcessing.value}.pdf`;
      let desertRef = storageRef.child(resumePath.value);

      desertRef.delete().then(() => {

      switch (`${documentTypeProcessing.value}.pdf`) {
          case "RESUME.pdf":
            isFoundResume.value = false;
            break;
          case "PROFITANDLOSS.pdf":
            isFoundProfitAndLoss.value = false;
            break;
          case "FINANCIALSTATEMENT.pdf":
            isFoundFinancialStatement.value = false;
            break;
          case "BUSINESSLICENSE.pdf":
            isFoundBusinessLicense.value = false;
            break;
          case "INCOMETAXI.pdf":
            isFoundIncomeTaxI.value = false;
            break;
          case "INCOMETAXII.pdf":
            isFoundIncomeTaxII.value = false;
            break;
          case "INCOMETAXIII.pdf":
            isFoundIncomeTaxIII.value = false;
            break;
      }

        Toastify({
          node: cash('#successfully-delete-resume-notification')
            .clone()
            .removeClass('hidden')[0],
          duration: 4000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();
      }).catch((error) => {
        Toastify({
          node: cash('#failure-delete-resume-notification')
            .clone()
            .removeClass('hidden')[0],
          duration: 4000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();
      });
    }







    async function handleViewDocument(documentType) {
      documentTypeProcessing.value = documentType;

      resumePath.value = `arch-documents/${collect.value[0].orgName}/${rmSpecialCharactersEmail.value}/${documentType}.pdf`;
      let storageRef = projectStorage.ref();
      let desertRef = storageRef.child(resumePath.value);
      desertRef.getDownloadURL()
        .then((url) => {
          let xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          xhr.send();

          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            let blobFile = window.URL.createObjectURL(xhr.response);
            // document.querySelector("iframe").src = blobFile;

            let html = '<!doctype html><html><head><title>Display Archymedes File</title></head>' +
              '<body>' +
              '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
              blobFile +
              '"></iframe>' +
              '</style></body></html>';

            let popup = window.open("", "_blank");
            popup.document.write(html);
            popup.document.close();
          } });
    }



    function handleDeleteDocument(documentType) {
      showModal.value = true;
      documentTypeProcessing.value = documentType;
      closeMenu(documentType);
    }

    return { documentTypeProcessing, showModal,
      isFoundProfitAndLoss, isFoundResume, isFoundIncomeTaxIII, isFoundIncomeTaxII, isFoundIncomeTaxI, isFoundBusinessLicense, isFoundFinancialStatement,
      parentDropdownProfitAndLoss, parentDropdownResume, parentDropdownFinancialStatement, parentDropdownBusinessLicense, parentDropdownIncomeTaxI, parentDropdownIncomeTaxII, parentDropdownIncomeTaxIII,
      parentDropdown, showMenu, resumePath, rmSpecialCharactersEmail,
      deleteDocument, handleViewDocument, handleUploadFileClick, handleDeleteDocument, closeMenu};
  }
}
</script>