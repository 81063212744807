<template>
  <DataTable ref="childComponentRef" :pageTitle="pageTitle" :showSearch="showSearch" :addRecordButtonProps="addRecordButtonProps"  :organization="organization"/>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  reactive,
  onMounted
} from 'vue';
import {
  useRouter
} from 'vue-router';
import DataTable from '@/components/data-table/ajax-data-table.vue';
import getUser from '@/composables/getUser';
import getDocument from '@/composables/getDocument';
import userCRUD from '@/composables/userCRUD';
import {
  faker
} from '@/utils/faker';
import InfoBox from '@/components/info-box/Main.vue';




export default defineComponent({
  props: ['org'],
  components: {
    DataTable,
    InfoBox
  },
   setup(props) {
     const childComponentRef = ref( );

    const displayName = ref();
    const totalDebtService = ref();
    const totalNetIncome = ref();
    const debitCoverageRatioCalc = ref();

    const organization = ref(props.org);
    const router = useRouter();
    const {
      user
    } = getUser();

    const creditScore = ref()
    const yearsInBusiness = ref()

    const {
      error: getDocumentError,
      getDocumentByID,
      getDocumentByEmail,
      getDocumentByOrgName,
      document: profileDetails
    } = getDocument();

    const orgName = ref(null);
    const {
      getUserByID,
      user: result,
      disableUser,
      enableUser
    } = userCRUD();

    const pageTitle = "8(a) Evaluation";
    const showSearch = true;

    const addRecordButtonProps = {
      value: "Invite a Member",
      action: addRecordAction
    }




    function addRecordAction() {
      router.push({
        name: 'invite-user',
        query: {
          org: props.org
        }
      });
    }




    function nameFormatter(cell) {
      return `<div>
                <div class="font-medium whitespace-nowrap ${cell.getData().disabled == true ? 'text-gray-600' : ''}">${
        cell.getData().name
      }</div>
                <div class="text-gray-600 text-xs whitespace-nowrap ${cell.getData().admin == true ? 'text-theme-25' : ''}">${
        cell.getData().admin == true ? 'Org. Admin' : 'Member'
      } ${cell.getData().disabled == true ? '(Disabled)' : ''}</div>
              </div>`
    }

    // function actionsFormatter(cell) {
    //
    //   let a = cash(`<div class="flex lg:justify-center items-center">
    //                         <a href="javascript:;" data-toggle="modal" data-target="#superlarge-modal-size-preview" class=" financialInfo btn btn-primary mr-1 mb-2">Financial</a>
    //                         <a href="javascript:;" data-toggle="modal" data-target="#superlarge-modal-size-preview" class=" contractInfo btn btn-success mr-1 mb-2">Contracts</a>
    //                     </div>`);
    //   cash(a)
    //     .find(".financialInfo")
    //     .on("click", function () {
    //       //alert("EDIT");
    //     });
    //
    //   cash(a)
    //     .find(".contractInfo")
    //     .on("click", function () {
    //       //alert("EDIT");
    //     });
    //
    //   return a[0];
    //
    // }



    onMounted( () => {



    })

    return {
      pageTitle,
      addRecordButtonProps,
      showSearch,
      displayName,
      creditScore,
      yearsInBusiness,
      totalDebtService,
      debitCoverageRatioCalc,
      organization,
      childComponentRef,
    }
  }
})
</script>
