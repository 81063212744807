<template>
<!-- BEGIN: Info Box -->
<div class="info-box" @click="$emit('buttonClicked','Button is clicked')" >
    <div class="zoom-in" v-if="value != null">

            <div
            class="alert show mb-2"
            v-bind:class="[{
                'alert-success': rating == 'Excellent',
                'alert-warning': rating == 'Okay',
                'alert-danger': rating == 'Low',
            }]"
            role="alert"
            >
                <div class="flex items-center">
                    <div class="font-medium text-lg ">{{ title }}</div>
                    <!-- <div class="text-xs bg-white px-1 rounded-md text-gray-800 ml-auto">New</div> -->
                </div>
                <div class="xl:text-3xl md:text-lg sm:text-sm mt-3">{{ value }} {{ extraText }}</div>
            </div>

    </div>

    <div v-else>
        <div class="alert show mb-2" role="alert" >
            <div class="flex items-center">
                <div class="font-medium text-lg">{{ title }}</div>
                <!-- <div class="text-xs bg-white px-1 rounded-md text-gray-800 ml-auto">New</div> -->
            </div>
            <div class="xl:text-3xl md:text-lg sm:text-sm mt-3">{{ extraText }}</div>

        </div>
    </div>
</div>
<!-- END: Info Box -->
</template>

<script>
export default {
  name: "InfoBox",
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number],
        },
        rating: {
            type: String,
        },
        extraText: {
            type: String
        }
    }
}
</script>