<template>
<div class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    ">

  <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5 pb-3">

    <div class="intro-y col-span-12 text-center">
      <h1 class="font-bold">{{$t('message.application504Sec1Heading')}}</h1>
    </div>


    <div class="intro-y col-span-12 pb-3">
      <label class="font-bold">{{$t('message.application504Sec1LoanQuestion1')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-1" class="form-check-input" type="radio" value="true" name="affiliates-choice" :checked="showAffiliates" @click="showAffiliatesData(true)" />
        <label class="form-check-label" for="radio-switch-1">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-2" class="form-check-input" type="radio" value="false" name="affiliates-choice" :checked="!showAffiliates" @click="showAffiliatesData(false)" />
        <label class="form-check-label" for="radio-switch-2">No</label>
      </div>

      <div v-if="showAffiliates">
        <UploadFile :hasUploaded='store.state.loan504.section2.affiliates.fileUpload' :question="'question1'" :questionType="'affiliates'" :section="'section2'" />
      </div>

    </div>


    <div class="intro-y col-span-12 pb-3">
      <label class="font-bold">{{$t('message.application504Sec1LoanQuestion2')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-3" class="form-check-input" type="radio" value="true" name="directGuaranteedLoan-choice" :checked="showDirectGuaranteedLoan" @click="showDirectGuaranteedLoanData(true)" />
        <label class="form-check-label" for="radio-switch-3">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-4" class="form-check-input" type="radio" value="false" name="directGuaranteedLoan-choice" :checked="!showDirectGuaranteedLoan" @click="showDirectGuaranteedLoanData(false)" />
        <label class="form-check-label" for="radio-switch-4">No</label>
      </div>


      <div v-if="showDirectGuaranteedLoan">
        <UploadFile :hasUploaded='store.state.loan504.section2.directGuaranteedLoan.fileUpload' :question="'question2'" :questionType="'directGuaranteedLoan'"  :section="'section2'" />
      </div>

    </div>




    <div class="intro-y col-span-12 pb-3">
      <label class="font-bold">{{$t('message.application504Sec1LoanQuestion3')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-5" class="form-check-input" type="radio" value="true" name="previouslySubmitted-choice" :checked="showPreviouslySubmitted" @click="showPreviouslySubmittedData(true)" />
        <label class="form-check-label" for="radio-switch-5">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-6" class="form-check-input" type="radio" value="false" name="previouslySubmitted-choice" :checked="!showPreviouslySubmitted" @click="showPreviouslySubmittedData(false)" />
        <label class="form-check-label" for="radio-switch-6">No</label>
      </div>
      <div v-show="showPreviouslySubmitted">

        <div class="grid grid-cols-12 gap-4 gap-y-5">
          <div class="intro-y col-span-12 mt-10 font-bold">
            Provide CDC/Lender Name and Loan Program:
          </div>
          <div class="intro-y col-span-6">
            <input id="input-wizard-3" type="text" class="form-control" name="datafileInput11" v-model="lenderNameLoanProgram" />
          </div>
        </div>

      </div>
    </div>


    <div class="intro-y col-span-12 pb-3">
      <label class="font-bold">{{$t('message.application504Sec1LoanQuestion4')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-7" class="form-check-input" type="radio" value="true" name="declaredBankruptcy-choice" :checked="showDeclaredBankruptcy" @click="showDeclaredBankruptcyData(true)" />
        <label class="form-check-label" for="radio-switch-7">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-8" class="form-check-input" type="radio" value="false" name="declaredBankruptcy-choice" :checked="!showDeclaredBankruptcy" @click="showDeclaredBankruptcyData(false)" />
        <label class="form-check-label" for="radio-switch-8">No</label>
      </div>

      <div v-if="showDeclaredBankruptcy">
        <UploadFile :hasUploaded='store.state.loan504.section2.declaredBankruptcy.fileUpload' :question="'question2'" :questionType="'declaredBankruptcy'"  :section="'section2'" />
      </div>
    </div>


    <div class="intro-y col-span-12 pb-3">
      <label class="font-bold">{{$t('message.application504Sec1LoanQuestion5')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-9" class="form-check-input" type="radio" value="true" name="pendingLawsuits-choice" v-model='pendingLawsuits' />
        <label class="form-check-label" for="radio-switch-9">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-10" class="form-check-input" type="radio" value="false" name="pendingLawsuits-choice" v-model='pendingLawsuits' />
        <label class="form-check-label" for="radio-switch-10">No</label>
      </div>
    </div>


    <div class="intro-y col-span-12 pb-3">
      <label class="font-bold">{{$t('message.application504Sec1LoanQuestion6')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-11" class="form-check-input" type="radio" value="true" name="businessOwnedBy-choice" :checked="showBusinessOwnedBy" @click="showBusinessOwnedByData(true)" />
        <label class="form-check-label" for="radio-switch-11">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-12" class="form-check-input" type="radio" value="false" name="businessOwnedBy-choice" :checked="!showBusinessOwnedBy" @click="showBusinessOwnedByData(false)" />
        <label class="form-check-label" for="radio-switch-12">No</label>
      </div>
    </div>
    <div v-show="showBusinessOwnedBy">
      <div class="grid grid-cols-12 gap-4 ">
        <div class="intro-y col-span-12 font-bold">
          <label for="input-wizard-12" class="form-label">Select Type</label>
        </div>
        <div class="intro-y col-span-8">
          <select id="input-wizard-12" class="form-select" v-model="businessOwnedByType">
            <option>401(k)</option>
            <option>ESOP</option>
            <option>Trust</option>
            <option>Cooperative</option>
          </select>
        </div>
      </div>
    </div>



    <div class="intro-y col-span-12 pb-3">
      <label class="font-bold">
        {{$t('message.application504Sec1LoanStatement')}}
      </label>
    </div>

  </div>
</div>
</template>

<script>
import {
  computed,
  ref
} from 'vue'
import {
  useStore
} from '@/store'
import CurrencyInput from "@/components/currency-input/Main.vue";
import useStorage from '@/composables/useStorage';
import {
  helper as $h
} from '@/utils/helper'
import UploadFile from "@/components/upload-file/Main"
export default {
  components: {
    CurrencyInput,
    UploadFile,
  },
  setup() {
    const store = useStore()
    const {
      url,
      filePath,
      error,
      uploadImage,
      uploadData
    } = useStorage();
    const showDirectGuaranteedLoan = ref()
    const showAffiliates = ref()
    const showPreviouslySubmitted = ref()
    const showDeclaredBankruptcy = ref()
    const showBusinessOwnedBy = ref()



    // set initial values from database/firebase
    showAffiliates.value = store.state.loan504.section2.affiliates.result;
    showDirectGuaranteedLoan.value = store.state.loan504.section2.directGuaranteedLoan.result;
    showPreviouslySubmitted.value = store.state.loan504.section2.previouslySubmitted.result;
    showDeclaredBankruptcy.value = store.state.loan504.section2.declaredBankruptcy.result;
    showBusinessOwnedBy.value = store.state.loan504.section2.businessOwnedBy.result;



    const opt = {
      currency: 'USD',
      currencyDisplay: 'symbol',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      precision: 0
    }

    const commitStore = async function() {
      await ownerSchema.validate(store.state.requiredForm.ownerInfo, {
        abortEarly: false
      });
    }

    const getCurrentDateStr = function() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();


      return mm + '/' + dd + '/' + yyyy;
    }




    function showAffiliatesData(value) {
      showAffiliates.value = value;
      store.commit('loan504/setAffiliates', value)
      store.commit('loan504/setAffiliatesUpdatedDate', getCurrentDateStr())
    }

    function showDirectGuaranteedLoanData(value) {
      showDirectGuaranteedLoan.value = value;
      store.commit('loan504/setDirectGuaranteedLoan', value)
      store.commit('loan504/setDirectGuaranteedLoanUpdatedDate', getCurrentDateStr())
    }

    function showPreviouslySubmittedData(value) {
      showPreviouslySubmitted.value = value;
      store.commit('loan504/setPreviouslySubmitted', value)
      store.commit('loan504/setPreviouslySubmittedUpdatedDate', getCurrentDateStr())
    }



    function showDeclaredBankruptcyData(value) {
      showDeclaredBankruptcy.value = value;
      store.commit('loan504/setDeclaredBankruptcy', value)
      store.commit('loan504/setDeclaredBankruptcyUpdatedDate', getCurrentDateStr())
    }


    function showBusinessOwnedByData(value) {
      showBusinessOwnedBy.value = value;
      store.commit('loan504/setBusinessOwnedBy', value)
      store.commit('loan504/setBusinessOwnedByUpdatedDate', getCurrentDateStr())
    }


    const pendingLawsuits = computed({
      get() {
        return store.state.loan504.section2.pendingLawsuits.result;
      },
      set(value) {
        store.commit('loan504/setPendingLawsuits', (value == "true"))
        store.commit('loan504/setPendingLawsuitsUpdatedDate', getCurrentDateStr())
      }
    })

    const lenderNameLoanProgram = computed({
      get() {
        return store.state.loan504.section2.previouslySubmitted.lenderNameLoanProgram;
      },
      set(value) {
        store.commit('loan504/setPreviouslySubmittedLenderNameLoanProgram', value)
        store.commit('loan504/setPreviouslySubmittedUpdatedDate', getCurrentDateStr())
      }
    })

    const businessOwnedByType = computed({
      get() {
        return store.state.loan504.section2.businessOwnedBy.type;
      },
      set(value) {
        store.commit('loan504/setBusinessOwnedByType', value);
        store.commit('loan504/setBusinessOwnedByUpdatedDate', getCurrentDateStr())
      }
    })


    return {
      store,
      opt,
      pendingLawsuits,
      businessOwnedByType,
      lenderNameLoanProgram,
      showDirectGuaranteedLoanData,
      showDirectGuaranteedLoan,
      showAffiliatesData,
      showAffiliates,
      showPreviouslySubmittedData,
      showPreviouslySubmitted,
      showDeclaredBankruptcy,
      showDeclaredBankruptcyData,
      showBusinessOwnedBy,
      showBusinessOwnedByData,
    }
  }
}
</script>

<style>
</style>