<template>
<div class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    ">

  <div class="intro-y col-span-12 sm:col-span-3">
    <label for="input-wizard-12" class="form-label">Select {{$t('message.businessInformationEntityType')}} Type</label><select id="input-wizard-12" class="form-select" v-model="entityType">
      <option selected>Sole proprietor</option>
      <option>Partnership</option>
      <option>C-Corp</option>
      <option>S-Corp</option>
      <option>LLC</option>
      <option>Other</option>
    </select>
  </div>

  <div class="intro-y col-span-12a sm:col-span-3">
    <label for="input-wizard-12a" class="form-label">Select {{$t('message.businessInformationIndustryType')}}</label><select id="input-wizard-12a" class="form-select" v-model="industryType">
      <option selected>Automobiles and Components</option>
      <option>Banks</option>
      <option>Capital Goods</option>
      <option>Commercial and Professional Services</option>
      <option>Consumer Durables and Apparel</option>
      <option>Consumer Services</option>
      <option>Diversified Financials</option>
      <option>Energy</option>
      <option>Food, Beverage, and Tobacco</option>
      <option>Food and Staples Retailing</option>
      <option>Health Care Equipment and Services</option>
      <option>Household and Personal Products</option>
      <option>Insurance</option>
      <option>Materials</option>
      <option>Media and Entertainment</option>
      <option>Pharmaceuticals, Biotechnology, and Life Sciences</option>
      <option>Real Estate</option>
      <option>Retailing</option>
      <option>Semiconductors and Semiconductor Equipment</option>
      <option>Software and Services</option>
      <option>Technology Hardware and Equipment</option>
      <option>Telecommunication Services</option>
      <option>Transportation</option>
      <option>Utilities</option>
    </select>
  </div>


  <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
    <div class="intro-y col-span-12 sm:col-span-6">
      <label for="input-wizard-1" class="form-label">{{$t('message.businessInformationCompanyName')}}*</label>
      <input id="input-wizard-1" type="text" class="form-control" :placeholder="$t('message.businessInformationCompanyName')" v-model="companyName" />
      <template v-if="errorValidation.companyName !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.companyName}} <br>
        </small>
      </template>
    </div>

    <div class="intro-y col-span-12 sm:col-span-6">
      <label for="input-wizard-3" class="form-label">{{$t('message.businessInformationDBA')}}</label>
      <input id="input-wizard-3" type="text" class="form-control" :placeholder="$t('message.businessInformationDBA')" v-model="dbaName" />
      <template v-if="errorValidation.dbaName !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.dbaName}} <br>
        </small>
      </template>
    </div>


    <div class="intro-y col-span-12 sm:col-span-12">
      <label for="input-wizard-5" class="form-label">{{$t('message.businessInformationAddress1')}}*</label>
      <input id="input-wizard-5" type="text" class="form-control" placeholder="1 Main St" v-model="address1" />
      <template v-if="errorValidation.address1 !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.address1}} <br>
        </small>
      </template>
    </div>


    <div class="intro-y col-span-12 sm:col-span-12">
      <label for="input-wizard-6" class="form-label">{{$t('message.businessInformationAddress2')}}</label>
      <input id="input-wizard-6" type="text" class="form-control" placeholder="Suite 100 Bld 200" v-model="address2" />
    </div>
    <div class="intro-y col-span-12 sm:col-span-7">
      <label for="input-wizard-7" class="form-label">{{$t('message.businessInformationCity')}}*</label>
      <input id="input-wizard-7" type="text" class="form-control" :placeholder="$t('message.businessInformationCity')" v-model="city" />
      <template v-if="errorValidation.city !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.city}} <br>
        </small>
      </template>
    </div>
    <div class="intro-y col-span-12 sm:col-span-3">
      <label for="input-wizard-12" class="form-label">Select {{$t('message.businessInformationState')}}*</label>
      <select id="input-wizard-12" class="form-select" v-model="state">
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District Of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </select>
    </div>
    <div class="intro-y col-span-12 sm:col-span-2">
      <label for="input-wizard-9" class="form-label">{{$t('message.businessInformationZipCode')}}*</label>
      <input id="input-wizard-9" type="text" class="form-control" v-mask="'#####'" placeholder="#####" v-model="zipcode" />
      <template v-if="errorValidation.zipcode !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.zipcode}} <br>
        </small>
      </template>
    </div>

    <div class="
      intro-y col-span-12
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    "></div>


    <div class="intro-y col-span-12 sm:col-span-4">
      <label for="input-wizard-10" class="form-label">{{$t('message.businessInformationBusinessContactNumber')}}*</label>
      <input id="input-wizard-10" type="tel" class="form-control" placeholder="(###) ###-####" v-mask="'(###) ###-####'" v-model="businessContactNumber" />
      <template v-if="errorValidation.businessContactNumber !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.businessContactNumber}} <br>
        </small>
      </template>
    </div>
    <div class="intro-y col-span-12 sm:col-span-4">
      <label for="input-wizard-10" class="form-label">{{$t('message.businessInformationEmail')}}*</label>
      <input id="input-wizard-10" type="text" class="form-control" placeholder="somebody@email.com" v-model="email" />
      <template v-if="errorValidation.email !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.email}} <br>
        </small>
      </template>
    </div>
    <div class="intro-y col-span-12 sm:col-span-4">
      <label for="input-wizard-10" class="form-label">{{$t('message.businessInformationWebsite')}}</label>
      <input id="input-wizard-10" type="text" class="form-control" placeholder="www.acme.com" v-model="website" />
      <template v-if="errorValidation.website !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.website}} <br>
        </small>
      </template>
    </div>
    <div class="intro-y col-span-12 sm:col-span-12">
      <label for="input-wizard-50" class="form-label">{{$t('message.businessInformationYearsInBusiness')}}*</label>
      <input id="input-wizard-50" type="text" class="form-control"  v-model="yrsInBusiness" />
      <template v-if="errorValidation.yrsInBusiness !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.yrsInBusiness}} <br>
        </small>
      </template>
    </div>
    <div class="intro-y col-span-12 sm:col-span-12">
      <label for="input-wizard-10" class="form-label">{{$t('message.businessInformationTaxId')}}*</label>
      <input id="input-wizard-10" type="text" class="form-control"
             v-mask="'##-#######'" placeholder="##-#######" v-model="taxId" />
      <template v-if="errorValidation.taxId !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.taxId}} <br>
        </small>
      </template>
    </div>
    <div class="intro-y col-span-12 sm:col-span-12">
      <label for="input-wizard-11" class="form-label">{{$t('message.businessInformationDUNS')}}</label>
      <input id="input-wizard-11" type="text" class="form-control"
             v-mask="'##-###-####'" placeholder="##-###-####" v-model="duns" />
      <template v-if="errorValidation.duns !== ''">
        <small class="text-theme-21 mt-2">
          {{errorValidation.duns}} <br>
        </small>
      </template>
    </div>


  </div>
</div>
</template>

<script>
import { defineComponent, computed, methods } from 'vue';
import { useStore } from '@/store';
import {object, string, number, bool} from "yup";
import * as yup from 'yup';
import {mask} from 'vue-the-mask';



const store = useStore();


let errorValidation = {
  companyName: '',
  dbaName: '',
  address1: '',
  city: '',
  state: '',
  zipcode: '',
  taxId: '',
  duns: '',
  businessContactNumber: '',
  email: '',
  yrsInBusiness:'',
};


const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
const dateRegExp =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/

const businessSchema = object().shape({
  companyName: string().required('Company name is a required field'),
  // dbaName: string().required('Dba name is a required field'),
  address1: string().required('Address name is a required field'),
  city: string().required('City is a required field'),
  state: string().required('State is a required field'),
  zipcode: string().required('Zipcode is a required field').matches(/^[0-9]+$/, "Must be only digits").min(5, 'Must be exactly 5 digits').max(5, 'Must be exactly 5 digits'),
  taxId: string().required('Tax ID is a required field'),
  // duns: string().required('DUNS is a required field'),
  businessContactNumber: string().required('Phone number is a required field').matches(phoneRegExp, 'Phone number is not valid'),
  email: string().required('Email is a required field').email('Email is not in an email format'),
  yrsInBusiness: number().required('Years in Business is a required field'),
});

export default {
      directives: {mask},
  data() {
    return {
      store,
      errorValidation,
      businessSchema,
    }

  },
  methods: {
    convertFirstLetterUpper(str) {
      return str.charAt(0).toUpperCase() + str.substring(1, str.length)
    },


    async validate(field, value) {
      let obj = {};
      obj[field] = value;

      await businessSchema
        .validateAt(field, obj).then(() => {
          eval("errorValidation." + field + " = ''");
          store.commit("requiredForm/set" + this.convertFirstLetterUpper(field), value);
        }).catch(err => {
          eval("errorValidation." + field + " = '" + err.message + "'");
          store.commit("requiredForm/set" + this.convertFirstLetterUpper(field), value);
        });
    },

    async commitStore() {
      await businessSchema.validate(store.state.requiredForm.bizInfo, {
        abortEarly: false
      });
    },


    setErrors(e) {
      for (var i in e.inner) {
        eval("this.errorValidation." + e.inner[i].path + " = '" + e.inner[i].errors + "'");
      }
    },

  },
  computed: {
    companyName: {
      get() {
        return this.$store.state.requiredForm.bizInfo.companyName;
      },
      set(value) {
        this.validate('companyName', value);
      }
    },
    dbaName: {
      get() {
        return this.$store.state.requiredForm.bizInfo.dbaName;
      },
      set(value) {
        this.validate('dbaName', value);
      }
    },
    address1: {
      get() {
        return this.$store.state.requiredForm.bizInfo.address1;
      },
      set(value) {
        this.validate('address1', value);
      }
    },
    address2: {
      get() {
        return this.$store.state.requiredForm.bizInfo.address2;
      },
      set(value) {
        this.$store.commit('requiredForm/setAddress2', value)
      }
    },
    city: {
      get() {
        return this.$store.state.requiredForm.bizInfo.city;
      },
      set(value) {
        this.validate('city', value);
      }
    },
    state: {
      get() {
        return this.$store.state.requiredForm.bizInfo.state;
      },
      set(value) {
        this.validate('state', value);
      }
    },
    zipcode: {
      get() {
        return this.$store.state.requiredForm.bizInfo.zipcode;
      },
      set(value) {
        this.validate('zipcode', value);
      }
    },
    yrsInBusiness: {
      get() {
        return this.$store.state.requiredForm.bizInfo.yrsInBusiness;
      },
      set(value) {
        this.validate('yrsInBusiness', value);
          //this.$store.commit('requiredForm/setYrsInBusiness', value)
      }
    },
    taxId: {
      get() {
        return this.$store.state.requiredForm.bizInfo.taxId;
      },
      set(value) {
        this.validate('taxId', value);
      }
    },
    duns: {
      get() {
        return this.$store.state.requiredForm.bizInfo.duns;
      },
      set(value) {
        this.$store.commit('requiredForm/setDuns', value)
        // this.validate('duns', value);
      }
    },
    businessContactNumber: {
      get() {
        return this.$store.state.requiredForm.bizInfo.businessContactNumber;
      },
      set(value) {
        this.validate('businessContactNumber', value);
      }
    },
    email: {
      get() {
        return this.$store.state.requiredForm.bizInfo.email;
      },
      set(value) {
        this.validate('email', value);
      }
    },
    website: {
      get() {
        return this.$store.state.requiredForm.bizInfo.website;
      },
      set(value) {
        this.$store.commit('requiredForm/setWebsite', value)
      }
    },
    entityType: {
      get() {
        return this.$store.state.requiredForm.bizInfo.entityType;
      },
      set(value) {
        this.$store.commit('requiredForm/setEntityType', value)
      }
    },
    industryType: {
      get() {
        return this.$store.state.requiredForm.bizInfo.industryType;
      },
      set(value) {
        this.$store.commit('requiredForm/setIndustryType', value)
      }
    }
  }
}
</script>

<style>
</style>