
export const i18nMixin = {
  beforeCreate() {
    let locale = this.$cookies.get('language');

    if (locale) {
      this.$i18n.locale = locale;
    } else {
      this.$i18n.locale = 'en'; // replace 'default-locale' with your default locale
    }
  },
};