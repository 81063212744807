<template>
  <div class='text-left text-2xl mt-5 mb-3'>
    {{ $t('message.balanceSheet') }}
  </div>

  <div class="grid  grid-col-1 grid-rows-1">
    <div class='bg-white shadow-md rounded-md bg-blue-500 w-80 p-4 '>
      <!-- BEGIN: General Report -->
      <div class="intro-y block sm:flex col-span-6">
        <h2 class="truncate mr-5">
          {{ $t('message.completeFormFitness') }}
        </h2>
      </div>
      <p class="pt-10">
        <a href="/financial-valuation/assets">
          <button class="btn btn-primary p-5 w-auto mb-10 text-left ">
            <ColumnsIcon class="w-4 h-4 mr-2"></ColumnsIcon>
            {{ $t('message.balanceSheet') }}
            <ChevronRightIcon class="w-4 h-4 ml-8"></ChevronRightIcon>
          </button>
        </a>
      </p>
      <p>
        <a href="/financial-valuation/cash-flow/financing">
          <button class="btn btn-primary p-5 w-auto mb-10 ">
            <DollarSignIcon class="w-4 h-4 mr-2"></DollarSignIcon>
            {{ $t('message.cashFlow') }}
            <ChevronRightIcon class="w-4 h-4 ml-8"></ChevronRightIcon>
          </button>
        </a>
      </p>
      <p>
        <a href="/financial-valuation/income-statement/revenue">
          <button class="btn btn-primary p-5 w-auto mb-10 ">
            <PercentIcon class="w-4 h-4 mr-2"></PercentIcon>
            {{ $t('message.incomeStatement') }}
            <ChevronRightIcon class="w-4 h-4 ml-8"></ChevronRightIcon>
          </button>
        </a>
      </p>
    </div>

    <div class="bg-white shadow-md rounded-md w-56 bg-blue-500 p-4">
      <div class="intro-y mt-12 sm:mt-5">
        <div class="px-8 py-12 flex flex-col justify-center flex-1 ">
          <div class="leading-7 text-gray-600">{{ $t('message.leverage')
            }}</div>
          <div class="mt-1.5 flex items-center">
            <div class="order-first text-3xl font-semibold tracking-tight text-gray-900 ">{{ currentRatio }}</div>
          </div>
          <div class="leading-7 text-gray-600">{{ $t('message.liquidity')
            }}</div>
          <div class="mt-1.5 flex items-center">
            <div class="order-first text-3xl font-semibold tracking-tight text-gray-900"> {{ currentRatio }}</div>
          </div>
          <div class="leading-7 text-gray-600">{{ $t('message.profitable')
            }}</div>
          <div class="mt-1.5 flex items-center">
            <div class="order-first text-3xl font-semibold tracking-tight text-gray-900">{{ debtToEquityRatio }}
            </div>
          </div>
          <div class="leading-7 text-gray-600">{{ $t('message.valuation')
            }}</div>
          <div class="mt-1.5 flex items-center">
            <div class="order-first text-3xl font-semibold tracking-tight text-gray-900 ">{{ bookValue }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Liabilities from '@/views/financials/tabs/liabilities.vue'
import feather from 'feather-icons'

export default {
  components: { Liabilities },

  setup() {

  },

  methods: {
    formatCurrency(value) {
      return `$${value.toLocaleString()}`
    },
    calculateTotal(items) {
      return items.reduce((total, item) => total + parseInt(item.value), 0)
    }

  },
  computed: {
    totalAssets() {
      return this.calculateTotal(this.$store.state.valuation.balanceSheet.assets)
    },
    totalLiabilities() {
      return this.calculateTotal(this.$store.state.valuation.balanceSheet.liabilities)
    },
    totalEquity() {
      return this.calculateTotal(this.$store.state.valuation.balanceSheet.equities)

    },
    debtToEquityRatio() {
      // console.log('totalAssets: ' + this.totalAssets)
      // console.log('totalLiabilities: ' + this.totalLiabilities)
      // console.log('totalEquity: ' + this.totalEquity)
      let asset = this.totalAssets
      let liabilities = this.totalLiabilities
      let equity = this.totalEquity

      if (typeof asset === 'undefined') {
        asset = 0
      }
      if (typeof liabilities === 'undefined') {
        liabilities = 0
      }
      if (typeof equity === 'undefined') {
        equity = 0
      }
      // console.log('asset: ' + asset)
      // console.log('liabilities: ' + liabilities)
      // console.log('equity: ' + equity)

      let debtToEquityRatioVar = 0
      if (equity !== 0) {
        debtToEquityRatioVar = liabilities / equity
      }

      return debtToEquityRatioVar.toFixed(2)
    },

    currentRatio() {
      // console.log('totalAssets: ' + this.totalAssets)
      // console.log('totalLiabilities: ' + this.totalLiabilities)
      // console.log('totalEquity: ' + this.totalEquity)
      let asset = this.totalAssets
      let liabilities = this.totalLiabilities
      let equity = this.totalEquity

      if (typeof asset === 'undefined') {
        asset = 0
      }
      if (typeof liabilities === 'undefined') {
        liabilities = 0
      }
      if (typeof equity === 'undefined') {
        equity = 0
      }
      // console.log('asset: ' + asset)
      // console.log('liabilities: ' + liabilities)
      // console.log('equity: ' + equity)

      return (asset / liabilities).toFixed(2)
    },

    bookValue() {
      // console.log('totalAssets: ' + this.totalAssets)
      // console.log('totalLiabilities: ' + this.totalLiabilities)
      // console.log('totalEquity: ' + this.totalEquity)
      let asset = this.totalAssets
      let liabilities = this.totalLiabilities
      let equity = this.totalEquity

      if (typeof asset === 'undefined') {
        asset = 0
      }
      if (typeof liabilities === 'undefined') {
        liabilities = 0
      }
      if (typeof equity === 'undefined') {
        equity = 0
      }
      // console.log('asset: ' + asset)
      // console.log('liabilities: ' + liabilities)
      // console.log('equity: ' + equity)

      return ((asset - liabilities) + equity).toFixed(2)
    }
  }

}

</script>
<style>
/* Style the button */
.button {
  border-radius: 50%;
  background-color: #f1f1f1;
  border: none;
  color: black;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

/* Style the feather icon */
.feather-icon {
  font-family: 'Feather';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-feature-settings: 'liga';
  -webkit-text-stroke-width: 0px;
}

/* Style the text below the button */
.text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

/* Style the grid */
.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  margin-top: 20px;
}
</style>
