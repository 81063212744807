<template>
  <div class="flex flex-wrap">
    <div class="grid grid-cols-12 gap-6 pt-5">
      <div style="font-weight: bold;font-size: x-large;" class="col-span-12">
        Balance Sheet
      </div>
    </div>
  </div>


  <div class="sm:px-20 mt-10 pt-10 py-10 border-t border-gray-200 box dark:border-dark-5 ">

    <p style="font-weight: bold;font-size: large;" class="pb-5">
      Sales
    </p>



    <div class="grid grid-cols-2 gap-2">
      <label for="input-wizard-0" class="pl-5 form-label">Opening inventory</label>
      <input id="input-wizard-0" type="number" class="form-control" v-model="openInventory"/>

      <label for="input-wizard-1" class="pl-5 form-label ">Purchases (direct materials)</label>
      <input id="input-wizard-1" type="number" class="form-control" v-model="purchase"/>

      <label for="input-wizard-3" class="pl-5 form-label">Factory wages</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="factoryWages"/>

      <label for="input-wizard-3" class="pl-5 form-label">Total</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="total" readonly/>

      <label for="input-wizard-3" class="pl-5 form-label">Less closing inventory</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="lessClosingInventory"/>

      <label for="input-wizard-3" class="pl-5 form-label">Equals cost of goods sold</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="equalsCostOfGoodsSold"/>

      <label for="input-wizard-3" class="pl-5 form-label">Gross profit (or income)</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="grossProfit"/>
    </div>

    <p style="font-weight: bold;font-size: large;" class="pt-5 pb-5">
      Expenses
    </p>

    <div class="grid grid-cols-2 gap-2">
      <label for="input-wizard-3" class="pl-5 form-label">Salary (owner)</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="salary"/>

      <label for="input-wizard-3" class="pl-5 form-label">Wage</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="wage"/>

      <label for="input-wizard-3" class="pl-5 form-label">Rent or mortgage interest</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="rentOrMortgageInterest"/>

      <label for="input-wizard-3" class="pl-5 form-label">Utilities (electricity, heat, etc.)</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="utilities"/>

      <label for="input-wizard-3" class="pl-5 form-label">Insurance</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="insurance"/>

      <label for="input-wizard-3" class="pl-5 form-label">Advertising and promotion</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="advertisingAndPromotion"/>

      <label for="input-wizard-3" class="pl-5 form-label">Automobile</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="automobile"/>

      <label for="input-wizard-3" class="pl-5 form-label">Other travel</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="otherTravel"/>

      <label for="input-wizard-3" class="pl-5 form-label">Office expenses</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="officeExpenses"/>

      <label for="input-wizard-3" class="pl-5 form-label">Storage</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="storage"/>

      <label for="input-wizard-3" class="pl-5 form-label">Maintenance</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="maintenance"/>

      <label for="input-wizard-3" class="pl-5 form-label">Depreciation</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="depreciation"/>

      <label for="input-wizard-3" class="pl-5 form-label">Bad debts</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="badDebts"/>

      <label for="input-wizard-3" class="pl-5 form-label">Interest</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="interest"/>

      <label for="input-wizard-3" class="pl-5 form-label">Other expenses</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="otherExpenses"/>

      <label for="input-wizard-3" style="font-weight: bold;font-size: medium;" class="form-label">Total expenses</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="totalExpenses" readonly/>

      <label for="input-wizard-3" style="font-weight: bold;font-size: medium;" class="form-label">Earning (or loss)
        Before Taxes</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="earning"/>

      <label for="input-wizard-3" class="pl-5 form-label">Income Taxes</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="incomeTax"/>

      <label for="input-wizard-3" style="font-weight: bold;font-size: medium;" class="form-label">Net Earning (or
        lose)</label>
      <input id="input-wizard-3" type="number" class="form-control"  v-model="netEarning" readonly/>

    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="intro-y col-span-12 flex sm:justify-end mt-10"><!---->
        <button class="btn btn-primary w-24 ml-2"  @click="saveEditValues">Save</button>

        <button class="btn btn-primary w-24 ml-2"  @click="cancelButton">Cancel</button>
      </div>
    </div>
  </div>


  <div id="success-notification-content_toast" class="toastify-content hidden flex">
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">CONGRATULATION</div>
      <div class="text-gray-600 mt-1">
        You have successfully save your balance sheet data for Archymedes.
      </div>
    </div>
  </div>
</template>

<script>
// https://wds-band.monday.com/my_inbox
import Toastify from 'toastify-js';
import {defineComponent, computed, refs, ref} from 'vue'
import {useStore} from '@/store'
import CurrencyInput from "@/components/currency-input/Main.vue";



export default {
  name: "assets",
  data() {
    const store = useStore();
    return {
      store
    }
  },

  computed: {
    openInventory: {
      get() {
        return this.$store.state.balanceSheet.openInventory;
      },
      set(value) {
        this.$store.commit('balanceSheet/setOpenInventory', value);
      }
    },

    purchase: {
      get() {
        return this.$store.state.balanceSheet.purchase;
      },
      set(value) {
        this.$store.commit('balanceSheet/setPurchase', value);
      }
    },

    factoryWages: {
      get() {
        return this.$store.state.balanceSheet.factoryWages;
      },
      set(value) {
        this.$store.commit('balanceSheet/setFactoryWages', value);
      }
    },


    total() {
      this.$store.state.balanceSheet.total = this.$store.state.balanceSheet.openInventory
         + this.$store.state.balanceSheet.purchase
         + this.$store.state.balanceSheet.factoryWages;

      this.$store.commit('balanceSheet/setTotal', this.$store.state.balanceSheet.total);

      return this.$store.state.balanceSheet.total;
    },


    // total: {
    //   get() {
    //     return this.$store.state.balanceSheet.total;
    //   },
    //   set(value) {
    //     this.$store.commit('balanceSheet/setTotal', value);
    //   }
    // },

    lessClosingInventory: {
      get() {
        return this.$store.state.balanceSheet.lessClosingInventory;
      },
      set(value) {
        this.$store.commit('balanceSheet/setLessClosingInventory', value);
      }
    },

    equalsCostOfGoodsSold: {
      get() {
        return this.$store.state.balanceSheet.equalsCostOfGoodsSold;
      },
      set(value) {
        this.$store.commit('balanceSheet/setEqualsCostOfGoodsSold', value);
      }
    },

    grossProfit: {
      get() {
        return this.$store.state.balanceSheet.grossProfit;
      },
      set(value) {
        this.$store.commit('balanceSheet/setGrossProfit', value);
      }
    },

    salary: {
      get() {
        return this.$store.state.balanceSheet.salary;
      },
      set(value) {
        this.$store.commit('balanceSheet/setBSSalary', value);
      }
    },

    wage: {
      get() {
        return this.$store.state.balanceSheet.wage;
      },
      set(value) {
        this.$store.commit('balanceSheet/setBSWage', value);
      }
    },

    rentOrMortgageInterest: {
      get() {
        return this.$store.state.balanceSheet.rentOrMortgageInterest;
      },
      set(value) {
        this.$store.commit('balanceSheet/setRentOrMortgageInterest', value);
      }
    },

    utilities: {
      get() {
        return this.$store.state.balanceSheet.utilities;
      },
      set(value) {
        this.$store.commit('balanceSheet/setBSUtilities', value);
      }
    },

    insurance: {
      get() {
        return this.$store.state.balanceSheet.insurance;
      },
      set(value) {
        this.$store.commit('balanceSheet/setInsurance', value);
      }
    },

    advertisingAndPromotion: {
      get() {
        return this.$store.state.balanceSheet.advertisingAndPromotion;
      },
      set(value) {
        this.$store.commit('balanceSheet/setAdvertisingAndPromotion', value);
      }
    },


    automobile: {
      get() {
        return this.$store.state.balanceSheet.automobile;
      },
      set(value) {
        this.$store.commit('balanceSheet/setAutomobile', value);
      }
    },

    otherTravel: {
      get() {
        return this.$store.state.balanceSheet.otherTravel;
      },
      set(value) {
        this.$store.commit('balanceSheet/setOtherTravel', value);
      }
    },

    officeExpenses: {
      get() {
        return this.$store.state.balanceSheet.officeExpenses;
      },
      set(value) {
        this.$store.commit('balanceSheet/setOfficeExpenses', value);
      }
    },

    storage: {
      get() {
        return this.$store.state.balanceSheet.storage;
      },
      set(value) {
        this.$store.commit('balanceSheet/setStorage', value);
      }
    },

    maintenance: {
      get() {
        return this.$store.state.balanceSheet.maintenance;
      },
      set(value) {
        this.$store.commit('balanceSheet/setMaintenance', value);
      }
    },

    depreciation: {
      get() {
        return this.$store.state.balanceSheet.depreciation;
      },
      set(value) {
        this.$store.commit('balanceSheet/setDepreciation', value);
      }
    },

    badDebts: {
      get() {
        return this.$store.state.balanceSheet.badDebts;
      },
      set(value) {
        this.$store.commit('balanceSheet/setBadDebts', value);
      }
    },

    interest: {
      get() {
        return this.$store.state.balanceSheet.interest;
      },
      set(value) {
        this.$store.commit('balanceSheet/setInterest', value);
      }
    },

    otherExpenses: {
      get() {
        return this.$store.state.balanceSheet.otherExpenses;
      },
      set(value) {
        this.$store.commit('balanceSheet/setOtherExpenses', value);
      }
    },

    totalExpenses() {
          let salary = this.$store.state.balanceSheet.salary;
          let wage = this.$store.state.balanceSheet.wage;
          let rentOrMortgageInterest = this.$store.state.balanceSheet.rentOrMortgageInterest;
          let utilities = this.$store.state.balanceSheet.utilities;
          let insurance = this.$store.state.balanceSheet.insurance;
          let advertisingAndPromotion  = this.$store.state.balanceSheet.advertisingAndPromotion;
          let automobile = this.$store.state.balanceSheet.automobile;
          let otherTravel = this.$store.state.balanceSheet.otherTravel;
          let officeExpenses = this.$store.state.balanceSheet.officeExpenses;
          let storage = this.$store.state.balanceSheet.storage;
          let maintenance = this.$store.state.balanceSheet.maintenance;
          let depreciation = this.$store.state.balanceSheet.depreciation;
          let badDebts = this.$store.state.balanceSheet.badDebts;
          let interest = this.$store.state.balanceSheet.interest;
          let otherExpenses = this.$store.state.balanceSheet.otherExpenses;


          this.$store.state.balanceSheet.totalExpenses = Number(salary)
            + Number(wage)
            + Number(rentOrMortgageInterest)
            + Number(utilities)
            + Number(insurance)
            + Number(advertisingAndPromotion)
            + Number(automobile)
            + Number(otherTravel)
            + Number(officeExpenses)
            + Number(storage)
            + Number(maintenance)
            + Number(depreciation)
            + Number(badDebts)
            + Number(interest)
            + Number(otherExpenses);

           this.$store.commit('balanceSheet/setBSTotalExpenses', this.$store.state.balanceSheet.totalExpenses);
           return this.$store.state.balanceSheet.totalExpenses;
    },

    earning() {
      this.$store.state.balanceSheet.earning = this.$store.state.balanceSheet.total
        + this.$store.state.balanceSheet.lessClosingInventory
        + this.$store.state.balanceSheet.equalsCostOfGoodsSold
        + this.$store.state.balanceSheet.grossProfit
      this.$store.commit('balanceSheet/setEarning', this.$store.state.balanceSheet.earning);
      return this.$store.state.balanceSheet.earning;
    },


    incomeTax: {
      get() {
        return this.$store.state.balanceSheet.incomeTax;
      },
      set(value) {
        this.$store.commit('balanceSheet/setBSIncomeTax', value);
      }
    },

    netEarning: {
      get() {
        return this.$store.state.balanceSheet.netEarning = (this.$store.state.balanceSheet.earning
          + this.$store.state.balanceSheet.incomeTax)
          - this.$store.state.balanceSheet.totalExpenses;
      },
      set(value) {
        this.$store.commit('balanceSheet/setNetEarning', value);
      }
    },




  },
  methods: {
    cancelButton: function () {
      this.$router.push('/performance');
    },

    saveEditValues: function () {
      this.store.dispatch('balanceSheet/submitFirebase');
      Toastify({
        node: cash('#success-notification-content_toast')
          .clone()
          .removeClass('hidden')[0],
        duration: 6000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast();

      this.$router.push('/performance');
    },
  }
}
</script>
