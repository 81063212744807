<template>
      <div class="grid grid-cols-1 mt-8 gap-4 gap-y-5">
  <label class="file-select">
    <span class="select-button" >
    <span class="select-color-success" v-if="hasFileLoaded">Document uploaded successfully</span>
     <span class="select-color-select" v-else><UploadIcon class="mr-1"/>  Click to upload file </span>
   </span>
  <input type="file" @change="showName($event,question,questionType,section)"/>
</label>
</div>
</template>
<style scoped>

.file-select > .select-button > .select-color-success {
  padding: 1rem;
  padding-right: 3rem;
  padding-left: 3rem;

  margin-top: 100px;

  color: white;


  border-radius: .3rem;

  text-align: center;
  font-weight: bold;
    background-color: #b6d7a8;
}
.file-select > .select-button > .select-color-select {
  padding: 1rem;
  padding-right: 5rem;
  padding-left: 5rem;

  margin-top: 100px;

  color: white;


  border-radius: .3rem;

  text-align: center;
  font-weight: bold;
  background-color: #3d85c6;
}


.file-select > input[type="file"] {
  display: none;
}
</style>

<script setup>
import {  defineProps, defineEmits, ref, computed } from 'vue'
import useStorage from '@/composables/useStorage';
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'


const props = defineProps(['hasUploaded', 'question', 'questionType','section']);
const emit = defineEmits(['enlarge-text'])


const hasFileLoaded = ref(props.hasUploaded);

const store = useStore()
const { url, filePath, error, uploadImage, uploadData } = useStorage();


function showName(event, questionNumber, questionType, section) {
  console.log(event);
  console.log("questionNumber:"+questionNumber);
  console.log("questionType:"+questionType);


  const files = event.target.files;
  hasFileLoaded.value = true;
  uploadData(files[0], { filename:`${questionNumber}${$h.capitalizeFirstLetter(questionType)}.pdf`, dir1:'504loan', dir2:section})
  console.log(`${questionNumber}${$h.capitalizeFirstLetter(questionType)}.pdf`);
  console.log(`loan504/set${$h.capitalizeFirstLetter(questionType)}FileUpload`)
  store.commit(`loan504/set${$h.capitalizeFirstLetter(questionType)}FileUpload`, true)
 };


</script>
