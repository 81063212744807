<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Apply for a Loan: SBA 7(a)</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="flex justify-center">
        <button 
          v-bind:class= "[page == 1 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          1
        </button>
        <!-- <button
          v-bind:class= "[page == 2 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          2
        </button> -->
        <!-- <button
          v-bind:class= "[page == 3 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          3
        </button>
        <button
          v-bind:class= "[page == 4 ? 'intro-y w-10 h-10 rounded-full btn btn-primary mx-2' : 'intro-y w-10 h-10 rounded-full btn bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2']"
        >
          4
        </button> -->
      </div>
      <div class="px-5 mt-10">
        <div class="font-medium text-center text-lg"><strong>Entity Owner Info</strong></div>
        <!-- <div class="text-gray-600 text-center mt-2" v-if="page == 1">
          Questions
        </div> -->
        <!-- <div class="text-gray-600 text-center mt-2" v-if="page == 2">
          Questions 
        </div> -->
        <!-- <div class="text-gray-600 text-center mt-2" v-if="page == 3">
          Source of Income
        </div>
        <div class="text-gray-600 text-center mt-2" v-if="page == 4">
          Contingent Liabilities
        </div> -->
      </div>
      <div
        class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base"><strong>Please answer the following questions honestly!</strong></div>
        
        <!-- BEGIN: Page 1 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
                <label>Are any of the owners of this entity unable to apply for this loan?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="ineligible-choice"
                    v-bind:value="true"
                    v-model="ineligible"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="ineligible-choice"
                    v-bind:value="false"
                    v-model="ineligible"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false">
            <div>
                <label>Does the entity have any affiliates?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="affiliate-choice"
                    v-bind:value="true"
                    v-model="affiliate"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="affiliate-choice"
                    v-bind:value="false"
                    v-model="affiliate"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false">
            <div>
                <label>Has the entity and/or its affiliates ever filed for bankruptcy protection?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="bProtection-choice"
                    v-bind:value="true"
                    v-model="bProtection"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="bProtection-choice"
                    v-bind:value="false"
                    v-model="bProtection"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false">
            <div>
                <label>Is the entity and/or its affiliates presently involved in any pending legal action?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="pendingLegal-choice"
                    v-bind:value="true"
                    v-model="pendingLegal"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="pendingLegal-choice"
                    v-bind:value="false"
                    v-model="pendingLegal"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="ineligible == false">
            <div>
                <label>Has the Entity ever gotten, guaranteed or applied for an SBA/Federal loan?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="returnApplicant-choice"
                    v-bind:value="true"
                    v-model="returnApplicant"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="returnApplicant-choice"
                    v-bind:value="false"
                    v-model="returnApplicant"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="returnApplicant == true">
            <div>
                <label>Are any of the loan/loans presently considered delinquent?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="presentlyDelinquent-choice"
                    v-bind:value="true"
                    v-model="presentlyDelinquent"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="presentlyDelinquent-choice"
                    v-bind:value="false"
                    v-model="presentlyDelinquent"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
              </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="returnApplicant == true">
            <div>
                <label>Has the entity ever defaulted on the loan/loans?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="defaulted-choice"
                    v-bind:value="true"
                    v-model="defaulted"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="defaulted-choice"
                    v-bind:value="false"
                    v-model="defaulted"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
              </div>
          </div>
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Date of Birth</label>
            <div class="grid grid-cols-12 gap-2">
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Month"
                  aria-label="default input inline 1"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Day"
                  aria-label="default input inline 2"
                />
                <input
                  type="number"
                  class="form-control col-span-4"
                  placeholder="Year"
                  aria-label="default input inline 3"
                />
              </div>
          </div> -->
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-5" class="form-label">Doesn't Have</label>
            <input
              id="input-wizard-5"
              type="text"
              class="form-control"
              placeholder="Job, Work, Documentation"
            />
          </div> -->
          <!-- <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-6" class="form-label">Size</label>
            <select id="input-wizard-6" class="form-select">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div> -->

              <div
                class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
                v-if="ineligible != null"
              >
                <button class="btn btn-secondary w-24" @click="handleBackToSection2" v-if="!isPending">Back to Section 2</button>
                <button class="btn btn-dark mr-1 mb-2" v-else>
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
                <button class="btn btn-dark w-24 ml-2" @click="handleIneligibleFinish" v-if="ineligible">Finish</button>
                <button class="btn btn-success w-24 ml-2" @click="handleFinish" v-if="!isPending && ineligible == false">Finish Application - Green</button>
                <button class="btn btn-warning w-24 ml-2" @click="handleYellowFinish" v-if="!isPending && ineligible == false">Finish Application - Yellow</button>
                <button class="btn btn-danger w-24 ml-2" @click="handleRedFinish" v-if="!isPending && ineligible == false">Finish Application - Red</button>
                <button class="btn btn-dark mr-1 mb-2" v-else-if="isPending">
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
              </div>
        </div>
        <!-- END: Page 1 -->
        
        <!-- BEGIN: Page 2 -->
        <!-- <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 2">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Accounts Payable</label>
            <div class="input-group">
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="accountsPayable"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Notes Payable to Banks and Others</label>
            <div class="input-group">
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="notesPayable"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">Installment Account - Auto (Montly Payment Amount)</label>
            <div class="input-group">
              <input
                id="input-wizard-3"
                type="number"
                class="form-control"
                placeholder="Montly Payment Amount ($)"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="installmentAuto"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Installment Account - Other (Montly Payment Amount)</label>
            <div class="input-group">
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                placeholder="Montly Payment Amount ($)"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="installmentOther"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-5" class="form-label">Loan(s) AGAINST Life Insurance</label>
            <div class="input-group">
              <input
                id="input-wizard-5"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="againstLifeInsurance"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-6" class="form-label">Mortgage(s) on Real Estate</label>
            <div class="input-group">
              <input
                id="input-wizard-6"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="mortgages"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-7" class="form-label">Unpaid Taxes</label>
            <div class="input-group">
              <input
                id="input-wizard-7"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="unpaidTaxes"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-8" class="form-label">Other Liabilities</label>
            <div class="input-group">
              <input
                id="input-wizard-8"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="otherLiabilities"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            <button class="btn btn-secondary w-24" @click="handlePrev">Previous</button>
            <button class="btn btn-primary w-24 ml-2" @click="handleNext">Next</button>
          </div>
        </div> -->
        <!-- END: Page 2 -->
        
        <!-- BEGIN: Page 3 -->
        <!-- <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 3">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Salary</label>
            <div class="input-group">
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="salary"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Net Investment Income</label>
            <div class="input-group">
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="netInvestment"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">Real Estate Income</label>
            <div class="input-group">
              <input
                id="input-wizard-3"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="incomeRealEstate"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Other Income</label>
            <div class="input-group">
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="otherIncome"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            <button class="btn btn-secondary w-24" @click="handlePrev">Previous</button>
            <button class="btn btn-primary w-24 ml-2" @click="handleNext">Next</button>
          </div>
        </div> -->
        <!-- END: Page 3 -->
        
        <!-- BEGIN: Page 4 -->
        <!-- <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5" v-if="page == 4">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">As Endorser or Co-Maker</label>
            <div class="input-group">
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="endorser"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Legal Claims & Judgments</label>
            <div class="input-group">
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="legalClaims"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-3" class="form-label">Provision for Federal Income Tax</label>
            <div class="input-group">
              <input
                id="input-wizard-3"
                type="number"
                class="form-control"
                placeholder="Montly Payment Amount ($)"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="provision"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">Other Special Debt</label>
            <div class="input-group">
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                placeholder="Montly Payment Amount ($)"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="otherSpecial"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
              <div
                class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
              >
                <button class="btn btn-secondary w-24" @click="handlePrev" v-if="!isPending">Previous</button>
                <button class="btn btn-dark mr-1 mb-2" v-else>
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
                <button class="btn btn-success w-24 ml-2" @click="handleFinish" v-if="!isPending">Finish Profile Setup</button>
                <button class="btn btn-dark mr-1 mb-2" v-else>
                  Saving
                  <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
                </button>
              </div>
        </div> -->
        <!-- END: Page 4 -->

        <div class="w-full border-t border-gray-200 dark:border-dark-5 mt-5"></div>
          
          <!-- Ineligible Answer Alert -->
          <div class="intro-y alert alert-danger-soft show flex items-center mb-2" role="alert" v-if="ineligible">
            <XOctagonIcon class="w-6 h-6 mr-2" /> Based on this answer, we can't go any further with this application because you're unable to apply for SBA financial assistance!
          </div>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue';
import getUser from '@/composables/getUser';
import useDocument from '@/composables/useDocument';
import getDocument from '@/composables/getDocument';
import { useRouter } from 'vue-router';

export default {
  props: ['appID'],
  setup(props) {
    const router = useRouter();
    const {user} = getUser();
    const {
      error: getDocumentError,
      getDocumentByID,
      document: application
    } = getDocument('applications', props.appID)

    const {
      error: useDocumentError,
      updateDoc,
      isPending
    } = useDocument('applications', props.appID)

    const page = ref(1);

    const questions = reactive({
      ineligible: null,
      affiliate: null,
      bProtection: null,
      pendingLegal: null,
      returnApplicant: null,
      presentlyDelinquent: null,
      defaulted: null,
    })

    const handleNext = () => {
        page.value++;
    };
    
    const handlePrev = () => {
        page.value--;
    };

    const handleBackToSection2 = () => {
      router.push({ name: 'side-menu-7a-section-2', query: {page: 2} });
    }

    const handleFinish = async () => {
      if(questions.returnApplicant == false) {
        questions.presentlyDelinquent = false;
        questions.defaulted = false;
      }

      const applicationData = {
        entityOwnerInfo: {
          questions: {
            ...questions
          }
        },
        status: 'Ready for Review',
        assessment: 'Green'
      };

      await updateDoc(applicationData);

      router.push({ name: 'application-assessment-success' });
    };

    const handleYellowFinish = async () => {
      if(questions.returnApplicant == false) {
        questions.presentlyDelinquent = false;
        questions.defaulted = false;
      }

      const applicationData = {
        entityOwnerInfo: {
          questions: {
            ...questions
          }
        },
        status: 'Ready for Review',
        assessment: 'Yellow'
      };

      await updateDoc(applicationData);

      router.push({ name: 'side-menu-assessment-failure-yellow' });
    }

    const handleRedFinish = async () => {
      if(questions.returnApplicant == false) {
        questions.presentlyDelinquent = false;
        questions.defaulted = false;
      }

      const applicationData = {
        entityOwnerInfo: {
          questions: {
            ...questions
          }
        },
        status: 'Ready for Review',
        assessment: 'Red'
      };

      await updateDoc(applicationData);

      router.push({ name: 'side-menu-assessment-failure-red' });
    }

    const handleIneligibleFinish = () => {
      

      router.push({ name: 'portal-page' });
    }

    onMounted(async () => {
      await getDocumentByID('applications', props.appID);
      
      // If values exist in the DB, pre-populate them in the form
      if (application.value.entityOwnerInfo != null) {
        let appQuestions = application.value.entityOwnerInfo.questions;

        Object.keys(appQuestions).forEach(function(key) {
          if(key in questions) {
            questions[key] = appQuestions[key];
          }
        })
      }
    })

    return {...toRefs(questions),
            page, handleNext, handlePrev, handleBackToSection2, handleFinish, handleYellowFinish, handleRedFinish, handleIneligibleFinish, isPending };
  },
}
</script>
