import { ref, watchEffect } from 'vue';
import { projectFirestore } from '../firebase/config';

const getDocument = () => {

  const document = ref(null)
  const error = ref(null)

  const getDocumentByID = async (collection, id) => {

    // register the firestore collection reference
    let documentRef = await projectFirestore.collection(collection).doc(id);

    const doc = await documentRef.get();

    if(doc.data()) {
      document.value = {...doc.data(), id: doc.id};
      error.value = null;
    } else {
      error.value = 'That document does not exist!';
    }
  }

  const getDocumentByOrgName = async (collection, orgName) => {

    //console.log("getDocumentByOrgName:" + orgName);
      let collect = [];
    // register the firestore collection reference
    await projectFirestore.collection(collection).where("orgName", "==", orgName)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          collect.push({...doc.data(), id: doc.id});
          error.value = null;
        });

        document.value = _.cloneDeep(collect);
        //console.log("done org:" + document.value);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });


  }

  const getDocumentByEmail = async (collection, email) => {

   // console.log("getDocumentByEmail:" + email);
    let collect = [];
    // register the firestore collection reference
    await projectFirestore.collection(collection).where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
         // console.log(doc.data().email, " => ", doc.data());
          collect.push({...doc.data(), id: doc.id});
          error.value = null;
        });

        document.value = _.cloneDeep(collect);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }

  const deleteAllDocuments = async ( collection, id ) => {
    await projectFirestore.collection(collection).doc(id).delete().then(() => {
      console.log("All Documents successfully deleted!");
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });

  }

  return { error, getDocumentByID, getDocumentByOrgName, getDocumentByEmail, deleteAllDocuments, document }
};

export default getDocument
