<script setup>
import { ref, computed, defineProps } from 'vue'
import {
  accountNowPdfPackage
  ,pdf1919SbaForm
  ,pdfPFSSbaForm,
  pdfPackage,
  pdfWebServices
} from '@/composables/pdfWebServices'
import Toastify from 'toastify-js'
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'
import getDocument from '@/composables/getDocument'
const selected = ref()


const {
  user
} = getUser();


const {
  error: getDocumentError,
  getDocumentByEmail,
  document,
} = getDocument();


getDocumentByEmail('profileDetails', user.value.email).then(() => {
  // alert("1:" + JSON.stringify(document.value[0].orgName))
})

function handleSaveEdit() {
 // alert('Hello World')
}

async function handlePdf1919SbaFormRetrieval() {
  try {
    Toastify({
      node: cash('#success-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()

    //  alert(JSON.stringify("2:" + document.value[0].orgName))
    // const response = await pdf1919SbaForm()
    const response = await pdf1919SbaForm(user.value.email, document.value[0].orgName)
    const content = await response.blob()

      let x = URL.createObjectURL(content)
    console.log(x)
    let html = '<!doctype html><html><head><title>Packaged PDF</title></head>' +
      '<body>' +
      '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
      x +
      '"></iframe>' +
      '</style></body></html>'

    let popup = window.open('', '_blank')

    popup.document.write(html)


  } catch (error) {
    console.log(error.message)
    Toastify({
      node: cash('#failed-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }
}
async function handlePdfWebServicesRetrieval() {
  try {
    Toastify({
      node: cash('#success-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()

    //  alert(JSON.stringify("2:" + document.value[0].orgName))
    const response = await pdfWebServices(user.value.email, document.value[0].orgName)
    const content = await response.blob()


    console.log('content size: ' + content.size)
    let x = URL.createObjectURL(content)
    console.log(x)
    let html = '<!doctype html><html><head><title>Packaged PDF</title></head>' +
      '<body>' +
      '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
      x +
      '"></iframe>' +
      '</style></body></html>'

    let popup = window.open('', '_blank')

    popup.document.write(html)


  } catch (error) {
    console.log(error.message)
    Toastify({
      node: cash('#failed-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }
}
async function handleStandardPackageRetrieval() {
  try {
    Toastify({
      node: cash('#success-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()

  //  alert(JSON.stringify("2:" + document.value[0].orgName))
    const response = await pdfPackage(user.value.email, document.value[0].orgName)
    const content = await response.blob()


    console.log('content size: ' + content.size)
    let x = URL.createObjectURL(content)
    console.log(x)
    let html = '<!doctype html><html><head><title>Packaged PDF</title></head>' +
      '<body>' +
      '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
      x +
      '"></iframe>' +
      '</style></body></html>'

    let popup = window.open('', '_blank')

    popup.document.write(html)


  } catch (error) {
    console.log(error.message)
    Toastify({
      node: cash('#failed-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }
}
async function handleNowAccountPackageRetrieval() {
  try {
    Toastify({
      node: cash('#success-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()



    //  alert(JSON.stringify("2:" + document.value[0].orgName))
    const response = await accountNowPdfPackage(user.value.email, document.value[0].orgName)
    const content = await response.blob()


    console.log('content size: ' + content.size)
    let x = URL.createObjectURL(content)
    console.log(x)
    let html = '<!doctype html><html><head><title>Packaged PDF</title></head>' +
      '<body>' +
      '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
      x +
      '"></iframe>' +
      '</style></body></html>'

    let popup = window.open('', '_blank')

    popup.document.write(html)


  } catch (error) {
    console.log(error.message)
    Toastify({
      node: cash('#failed-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }
}
async function handlePFSFormRetrieval() {
  try {
    Toastify({
      node: cash('#success-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()

   // alert(JSON.stringify("2:" + document.value[0].orgName))
    const response = await pdfPFSSbaForm(user.value.email, document.value[0].orgName)
    const content = await response.blob()


    console.log('content size: ' + content.size)
    let x = URL.createObjectURL(content)
    console.log(x)
    let html = '<!doctype html><html><head><title>Packaged PDF</title></head>' +
      '<body>' +
      '<iframe id="iframe_print" style="width:100%;height:1000px;" src="' +
      x +
      '"></iframe>' +
      '</style></body></html>'

    let popup = window.open('', '_blank')

    popup.document.write(html)


  } catch (error) {
    console.log(error.message)
    Toastify({
      node: cash('#failed-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }
}
</script>

<template>
  <div class='flex flex-wrap'>
    <div class='grid grid-cols-12 gap-6 p-10'>
      <div class='col-span-12'>
        <h1 class='text-lg font-medium truncate'> Package Creation </h1>
        <small class='text-theme-25 intro-y'>You select an appropriate loan to package.</small>
      </div>
    </div>
  </div>
  <div class='flex flex-wrap'>
    <div class='w-full'>
      <div class='relative flex flex-col min-w-0 break-words mb-6 shadow-lg rounded' style='background-color: #fafafa;'>
        <div class='col-span-1'>
          <div class='intro-y p-20'>
<!--            <label class='font-bold'>Select A Loan Packages</label>-->
<!--            <div class='mt-2 mr-96'>-->
<!--              <select v-model='selected' class='form-select'>-->
<!--                <option disabled value=''>Please select one</option>-->
<!--                <option>504 Loan</option>-->
<!--                <option>Personal Loan</option>-->
<!--                <option>8(a) Loan</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <div class='mt-10'>-->
<!--              <button type='button' class='btn btn-primary mr-1'-->
<!--                      @click='handlePFSFormRetrieval'>-->
<!--                PFS Loan Package-->
<!--              </button>-->
<!--            </div>-->
            <div class='mt-10'>
              <button type='button' class='btn btn-primary mr-1'
                      @click='handlePdf1919SbaFormRetrieval'>
                7(a) Package
              </button>
            </div>
            <div class='mt-10'>
              <button type='button' class='btn btn-primary mr-1'
                      @click='handlePdfWebServicesRetrieval'>
                504 Package
              </button>
            </div>
<!--            <div class='mt-10'>-->
<!--              <button type='button' class='btn btn-primary mr-1'-->
<!--                      @click='handleStandardPackageRetrieval'>-->
<!--                Standard Package-->
<!--              </button>-->
<!--            </div>-->
            <div class='mt-10'>
              <button type='button' class='btn btn-primary mr-1'
                      @click='handleNowAccountPackageRetrieval'>
                Now Account Package
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id='success-notification-content'
    class='toastify-content hidden flex'
  >
    <CheckCircleIcon class='text-theme-20' />
    <div class='ml-4 mr-4'>
      <div class='font-medium'>Loan package will download shortly</div>
      <div class='text-gray-600 mt-1'>
        Please allow popup so the downloaded pdf will be displayed.
      </div>
    </div>
  </div>
  <div
    id="failed-notification-content"
    class="toastify-content hidden flex"
  >
    <XCircleIcon class="text-theme-21" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Something went wrong...</div>
    </div>
  </div>
</template>


<style scoped>

</style>