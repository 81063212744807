<template>
  <div>
    <div id='fred' class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Tinker Tailwind HTML Admin Template"
              :src="require(`@/assets/images/archymedes-owl-logo-WEB-01.png`)"
              style="width: 40%"
            />
          </a>

        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Sign In
            </h2>
            <p>Selected fruit: </p>
            <div>
              <LanguageSelector :selected-option="selectedOption" :options="options" @language-selected="updateLanguage" />
                       </div>

            <div class="intro-x mt-8">
              <input
                type="email"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                :class="{ 'border-theme-21': validate.email.$error }"
                :placeholder="$t('message.email')"

                v-model="validate.email.$model"
              />
              <template v-if="validate.email.$error">
                <small
                  v-for="(error, index) in validate.email.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>

              <input
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                :class="{ 'border-theme-21': validate.password.$error }"
                :placeholder="$t('message.password')"
                v-model="validate.password.$model"
              />
              <template v-if="validate.password.$error">
                <small
                  v-for="(error, index) in validate.password.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>

            </div>
            <div class="text-theme-21" v-if="error">{{error}}</div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                @click="handleSubmit"
              >
                {{ $t('message.login') }}
              </button>
              <button
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
                @click="handleSignupClick"
              >
                {{ $t('message.signup') }}
              </button>
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <a href="" class="text-theme-25 block font-normal"
                 @click.prevent="handleForgotClick"><u>{{
                  $t('message.forgotPassword')}} ?</u></a>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, nextTick, watch } from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue';
import useLogin from '@/composables/useLogin';
import { useRouter } from 'vue-router';
import {
  required,
  minLength,
  email,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import getCurrentUserProfileDetails from '@/composables/getCurrentUserProfileDetails';
import getUser from '@/composables/getUser';
import LanguageSelector from '@/components/languageSelector/Main.vue';
// import { i18nMixin } from '@/composables/i18nMixin'
import Cookies from 'vue-cookies';



export default {
  name: 'Login',
  // mixins: [i18nMixin],
  components: {
    DarkModeSwitcher,
    LanguageSelector
  },
  setup(props, context) {

    const selectedOption = ref(Cookies.get('language') || 'en');
    const options = ref(['en', 'es']); // Add more languages as

    const updateLanguage = (language) => {
      selectedOption.value = language;
      Cookies.set('language', language);
    };


    const { user:newUser, collect, getCurrentProfileDetailsData} = getCurrentUserProfileDetails();


    onMounted(() => {
        if (!Cookies.get('language')) {
          Cookies.set('language', 'en');
        }

      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login');

    });

    const loginForm = reactive({
      email: null,
      password: null,
    });

    const rules = {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
    const validate = useVuelidate(rules, toRefs(loginForm));
    const router = useRouter();

    const enterPortal = () => {
      router.push({ name: 'portal-page' });
    }


    const enterAdminPortal = (orgName) => {
      router.push({ name: 'member-list', params: {org: orgName} });
    }


    const {error, login, isPending } = useLogin();

    const handleSubmit = async () => {
      validate.value.$touch();
      if(!validate.value.$invalid) {
        const res = await login(loginForm.email, loginForm.password);
        if(!error.value) {
          await getCurrentProfileDetailsData(loginForm.email);


          context.emit('login');
          // console.log("Logged in!" + JSON.stringify(collect.value[0].role ));
          // console.log("Logged in!" + JSON.stringify(collect.value[0].orgName ));

          if (collect.value[0].role == "ROLE_ADMIN") {
            enterAdminPortal(collect.value[0].orgName);
          } else {
            enterPortal();
          }

        }
      }
    }

    const handleSignupClick = () => {
      router.push({ name: 'register' });
    }

    const handleForgotClick = () => {
      router.push({ name: 'forgot-password' });
    }



    return {
      selectedOption,
      options,
      updateLanguage,
      ...toRefs(loginForm), validate,
      handleSubmit,
      error,
      enterPortal,
      handleSignupClick,
      handleForgotClick
    }
  }
};
</script>