<template>
  <div class="max-w-md mx-auto bg-white shadow-md p-6 rounded-md">
    <h2 class="text-lg font-semibold mb-4">Balance Sheet</h2>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Assets</h3>
      <div v-for="(item, index) in this.$store.state.valuation.balanceSheet.assets" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2 font-semibold">
        <div>Total Assets</div>
        <div class="text-right">{{ totalOperatingActivities }}</div>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Liabilities</h3>
      <div v-for="(item, index) in this.$store.state.valuation.balanceSheet.liabilities" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2">
        <div>Total Total Liabilities</div>
        <div class="text-right">{{ totalInvestingActivities }}</div>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Equities</h3>
      <div v-for="(item, index) in this.$store.state.valuation.balanceSheet.liabilities" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2 font-semibold">
        <div>Total Equities</div>
        <div class="text-right">{{ totalFinancingActivities }}</div>
      </div>
    </div>
    <div class="balance">
      <span class="description">Balance:</span>
      <span class="value" :class="isBalanced(yearData) ? 'text-green-600' : 'text-red-600'">
              {{ isBalanced() ? 'Balanced' : 'Not Balanced' }}
            </span>
    </div>
  </div>


  <div class='text-center mt-5'>
    <a class='bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded' href='javascript:'
       @click='homeAction'>
      Dashboard
    </a>
  </div>



</template>

<script>

import {
  useStore
} from '@/store'
import Assets from '@/views/financials/tabs/assets.vue'
import { useRouter } from 'vue-router'


export default {
  components: { Assets },
  data() {
    const store = useStore();


    return {
      store,
      // yearsData: [
      //   {
      //     year: 2021,
      //     assets: [
      //       { id: 1, name: 'Cash', value: 5000 },
      //       { id: 2, name: 'Accounts Receivable', value: 3000 },
      //       { id: 3, name: 'Inventory', value: 7000 },
      //     ],
      //     liabilities: [
      //       { id: 1, name: 'Accounts Payable', value: 2000 },
      //       { id: 2, name: 'Loan', value: 5000 },
      //     ],
      //     equity: [
      //       { id: 1, name: 'Retained Earnings', value: 6000 },
      //       { id: 2, name: 'Share Capital', value: 8000 },
      //     ],
      //   },
      //   // Add data for other years as needed
      // ],
    };
  },
  methods: {
    homeAction() {
      this.$router.push('/eight-a-evaluation2');
    },
    formatCurrency(value) {
      return `$${value.toLocaleString()}`;
    },
    calculateTotal(items) {
      return items.reduce((total, item) => total + parseInt(item.value), 0);
    },
    isBalanced() {
      const totalAssets = this.calculateTotal(this.$store.state.valuation.balanceSheet.assets);
      const totalLiabilities = this.calculateTotal(this.$store.state.valuation.balanceSheet.liabilities);
      const totalEquity = this.calculateTotal(this.$store.state.valuation.balanceSheet.equities);
      return totalAssets === totalLiabilities + totalEquity;
    },
  },
};
</script>

<style>
/* Add your CSS styles here */

.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.description {
  flex: 1;
}

.value {
  text-align: right;
}

.text-green-600 {
  color: #34d399;
}

.text-red-600 {
  color: #ef4444;
}
</style>
