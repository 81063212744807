<template>
  <DataTable
    :pageTitle="pageTitle"
    :showSearch="showSearch"
    :addRecordButtonProps="addRecordButtonProps"
    :columns="columns"
    :tableData="tableData"
  />

</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import DataTable from '@/components/data-table/Main.vue';
import getUser from '@/composables/getUser';
import useCollection from '@/composables/useCollection';
import {timestamp} from '@/firebase/config'

export default defineComponent({
  components: {
    DataTable
  },
  setup() {
    const router = useRouter();
    const {user} = getUser();

    console.log(user.value.uid);
    const { error, addDoc, getCollection, isPending, newDoc, documents: applications } = useCollection('applications');

    const pageTitle = "Your Loans";
    const showSearch = false;

    const addRecordButtonProps = {
        value: "Start a 7(a) Application",
        action: addRecordAction
    }

    async function addRecordAction() {
      console.log(timestamp());


      const applicationData = {
        type: '7(a)',
        started: timestamp(),
        status: 'In Progress',
        userID: user.value.uid
      }

      const application = await addDoc(applicationData);

      console.log('Finished!', newDoc.value);
      router.push({ name: 'side-menu-7a-section-1', params: {appID: newDoc.value}});
    }

    var columns = [
      {formatter: 'responsiveCollapse', width: 40, minWidth: 30, hozAlign: 'center', resizable: false, headerSort: false},
      {title:"APPLICATION TYPE", field:"type", sorter:"string", width:200},
      {title:"STARTED ON", field:"started"},
      {title:"STATUS", field:"status", formatter: statusFormatter},
      {title:"ACTIONS", field:"actions", formatter: actionsFormatter},
    ];

    function statusFormatter(cell) {

      var textTheme = '';
      var statusIcon = '';
      var section = cell.getData().currentSection != null ? ': Section ' + cell.getData().currentSection : ': Section 1';

      if(cell.getData().status == 'Completed') {
        textTheme = 'text-theme-20';
        statusIcon = 'check-square';
      } else if(cell.getData().status == 'Ready for Review') {
        textTheme = 'text-theme-25';
        statusIcon = 'clipboard';
      } else {
        textTheme = '';
        statusIcon = 'more-horizontal';
      }

      return `<div class="flex items-center lg:justify-center ${textTheme}">
        <i data-feather=${statusIcon} class="w-4 h-4 mr-2"></i> ${cell.getData().status}${cell.getData().status == 'In Progress' ? section : ''}
      </div>`
    }

    function actionsFormatter(cell) {

      var actionText = '';
      var actionIcon = '';

      if(cell.getData().status == 'Completed') {
        actionText = 'View';
        actionIcon = 'eye';
      } else if(cell.getData().status == 'Ready for Review') {
        actionText = 'Review';
        actionIcon = 'clipboard';
      } else {
        actionText = 'Continue';
        actionIcon = 'arrow-right';
      }


      const a = cash(`<div class="flex lg:justify-center items-center">
        <a class="flex items-center mr-3" href="javascript:;">
          <i data-feather=${actionIcon} class="w-4 h-4 mr-1"></i> <u>${actionText}</u>
        </a>
        <a class="flex items-center text-theme-21" href="javascript:;">
          <i data-feather="trash-2" class="w-4 h-4 mr-1"></i> <u>Delete</u>
        </a>
      </div>`)

      cash(a).on('click', function(e) {
        // On click actions
        console.log(e.target.outerText);
        var section = 1;

        if(cell.getData().currentSection != null) {
          section = cell.getData().currentSection;
        }

        if(e.target.innerText == 'Continue') {
          router.push({ name: 'side-menu-7a-section-' + section, params: {appID: cell.getData().id}});
        } else if(e.target.innerText == 'Review') {
          console.log(cell.getData().assessment)
          if(cell.getData().assessment == 'Green') {
            router.push({ name: 'application-assessment-success', params: {appID: cell.getData().id} });
          } else if(cell.getData().assessment == 'Yellow') {
            router.push({ name: 'side-menu-assessment-failure-yellow', params: {appID: cell.getData().id} });
          } else {
            router.push({ name: 'side-menu-assessment-failure-red', params: {appID: cell.getData().id} });
          }
        } else if(e.target.innerText == 'View') {
          router.push({ name: 'view-loan-application', params: {appID: cell.getData().id} });
        }

      })

      return a[0]
    }

    var tableData = [];

    onMounted(async () => {
      await getCollection(
                'applications',
                ['userID', '==', user.value.uid],
                ['type', 'started', 'status', 'assessment']);

      var tempData = Object.values(applications.value);
      tempData.forEach(entry => {
          entry.started = entry.started.toDate().toDateString();
      });
      console.log("tempData:", tempData);

      tableData.push(...tempData);
    })

    return {
      pageTitle,
      addRecordButtonProps,
      showSearch,
      columns,
      tableData
    }
  }
})
</script>
