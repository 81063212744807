import { ref } from 'vue';
import firebase from 'firebase';

const functions = firebase.functions();
// firebase.functions().useEmulator("localhost", 5001);
const sendMail = functions.httpsCallable('sendMail');

const emailWebServices = () => {

  const sendEmailNow = async (dest) => {
  await sendMail({dest: 'email'}).then(
    result => {
      console.log(result);
    });
}



  const sendEmailWDS = async (to, from, subject, text) => {
      let mailObj = {};
      mailObj["from"] = from;
      mailObj["to"] = to;
      mailObj["subject"] = subject;
      mailObj["text"] = text;

      let jsonEmailData =  JSON.stringify(mailObj);
      const requestOptions = {
        method: "POST",
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=utf-8'
        },
        body: jsonEmailData
      };


  await fetch(`${process.env.VUE_APP_API_URL}/sendmail`, requestOptions).then((response) => {
      const data = response;
     console.log(data);
  });

}


return { sendEmailWDS, sendEmailNow };
}
export default emailWebServices
