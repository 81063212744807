<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Edit User: {{displayName}}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <label for="crud-form-1" class="form-label">Name</label>
          <div class="input-group">
            <input
              id="crud-form-1"
              type="text"
              class="form-control w-full"
              placeholder="New Display Name"
              v-model="displayName"
            />
          </div>
          <br>
          <div>
            <label for="crud-form-2" class="form-label">Password</label>
            <div class="input-group">
            <input
              id="crud-form-2"
              type="password"
              class="form-control w-full"
              placeholder=""
              v-model="password"
            />
          </div>
          </div>
          <div class="text-right mt-5">
            <button type="button" class="btn btn-outline-secondary w-24 mr-1" @click="handleCancel">
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-24 mr-1" @click="handleSaveEdit">
              Save
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import userCRUD from '@/composables/userCRUD';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import getDocument from '@/composables/getDocument';
import useDocument from '@/composables/useDocument';
import getUser from '@/composables/getUser';



export default {
  props: ['id'],
  async setup(props) {
    const {
      error: getDocumentError,
      getDocumentByID,
      getDocumentByEmail,
      document: profileInfo
    } = getDocument();



    const displayName = ref('');
    const password = ref('');
    const router = useRouter();

    const { allUsers, user, userList,
      getAllUsers, getUserByID, getUsersByID, deleteUser, deleteID,
      updateUser, disableUser, enableUser,
      promoteToAdmin, demoteAdmin, deleteAllUsers } = userCRUD();


    console.log("EditUser User: " + router.currentRoute.value.query.id);
    // console.log("EditUser User.uid: " + JSON.stringify(x.value.uid));

    await getUserByID(router.currentRoute.value.query.id).then(() => {
      console.log("UserList: " + JSON.stringify(user.value.uid));
      });


    await getDocumentByEmail('profileDetails', router.currentRoute.value.query.email).then(() => {
      displayName.value = profileInfo.value[0].displayName;
    });

    const {
      error: useDocumentError,
      updateDoc,
      isPending
    } = useDocument('profileDetails', profileInfo.value[0].id);

    const handleSaveEdit = async () => {
      await updateDoc({displayName:displayName.value});
      if (password.value.trim().length > 0) {
        await updateUser(user.value.uid, {password: password.value});
      }
      console.log('handleSaveEdit');
      router.push({ name: 'member-list', params: { org: router.currentRoute.value.query.org } });
    }

    const handleCancel = () => {
      if(router.currentRoute.value.query.org != null) {
        let org = router.currentRoute.value.query.org;
        router.push({ name: 'member-list', params: { org: org } });
      } else if(router.currentRoute.value.query.profile != null){
        router.push({ name: 'user-profile' });
      } else {
        router.push({ name: 'side-menu-crud-data-list' });
      }
    }

    const handleUpdate = async () => {

      //let params = {};

      // if (profileDetails.value[0].displayName != '') {
      //   params.displayName = profileDetails.value[0].displayName;
      // }

      //console.log("After displayName check:", params);

      // if (password.value != '') {
      //   params.password = password.value;
      // }
      //
      // console.log("After password check:", params);
      //
      // await updateUser(props.id, params);
      // console.log(user.value);
      //
      // await updateDoc({displayName:displayName.value, password: password.value});

      // displayName.value = '';
      // password.value = '';
      // router.push({ name: 'member-list', params: { org:  router.currentRoute.value.query.org } });

      // if(router.currentRoute.value.query.org != null) {
      //   let org = router.currentRoute.value.query.org;
      //   router.push({ name: 'member-list', params: { org: org } });
      // } else if(router.currentRoute.value.query.profile != null){
      //   router.push({ name: 'user-profile' });
      // } else {
      //   router.push({ name: 'side-menu-crud-data-list' });
      // }
    }

    return {
      displayName,
      password,
      // user,
      updateDoc,
      handleCancel,
      handleUpdate,
      handleSaveEdit
    }
  }
}
</script>
