<template>
  <div class="max-w-md mx-auto bg-white shadow-md p-6 rounded-md">
    <h2 class="text-lg font-semibold mb-4">Income Statement</h2>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Revenue</h3>
      <div v-for="(item, index) in this.$store.state.valuation.incomeStatement.revenue" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2 font-semibold">
        <div>Total Revenue</div>
        <div class="text-right">{{ totalRevenue }}</div>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Gains</h3>
      <div v-for="(item, index) in this.$store.state.valuation.incomeStatement.gains" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2 font-semibold">
        <div>Total Gains</div>
        <div class="text-right">{{ totalGains }}</div>
      </div>
    </div>
    <div class="mt-4">
      <h3 class="text-lg font-semibold mb-2">Expenses</h3>
      <div v-for="(item, index) in this.$store.state.valuation.incomeStatement.expenses" :key="index" class="grid grid-cols-2 gap-2 mb-2">
        <div>{{ item.description }}</div>
        <div class="text-right">{{ item.value }}</div>
      </div>
      <div class="grid grid-cols-2 gap-2 mb-2 font-semibold">
        <div>Total Total Expenses</div>
        <div class="text-right">{{ totalExpenses }}</div>
      </div>
    </div>
    <div class="mt-4">
      <div class="grid grid-cols-2 gap-2 mb-2 font-semibold">
        <div>Net Income</div>
        <div class="text-right">{{ netIncome }}</div>
      </div>
    </div>
  </div>

  <div class='text-center mt-5'>
    <a class='bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded' href='javascript:'
       @click='homeAction'>
      Dashboard
    </a>
  </div>

</template>

<script>
export default {
  name: 'IncomeStatement',
  data() {
    return {

    };
  },
  methods: {
    homeAction() {
      this.$router.push('/eight-a-evaluation2');
    }
  },
  computed: {
    totalRevenue() {
      return this.$store.state.valuation.incomeStatement.revenue.reduce((total, item) => total + item.value, 0);
    },
    totalGains() {
      return this.$store.state.valuation.incomeStatement.gains.reduce((total, item) => total + item.value, 0);
    },
    totalLosses() {
      return this.$store.state.valuation.incomeStatement.losses.reduce((total, item) => total + item.value, 0);
    },
    totalExpenses() {
      return this.$store.state.valuation.incomeStatement.expenses.reduce((total, item) => total + item.value, 0);
    },
    netIncome() {
      return (this.totalRevenue + this.totalGains) - (this.totalExpenses + this.totalLosses);
    },
  },
};
</script>

<style scoped>
.income-statement {
  width: 300px;
  border: 1px solid #ccc;
  padding: 10px;
}

</style>
