import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'



const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('income-statement');


const state = () => {
  // TODO: must deal with date input type

  return {
      merchandiseSale: 0,
      revenueFromTraining: 0,
      totalRevenue: 0,
      procurementCost: 0,
      wages: 0,
      rent: 0,
      interestPaid: 0,
      transportation: 0,
      utilities: 0,
      totalExpenses: 0,
      incomeFromSaleOfValue: 0,
      revenueExpenses: 0,
    }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    const {
      user
    } = getUser();
    const application = await addDocWithGenId(this.state.incomeStatement, user._value.email);
  }
}



// mutations
const mutations = {
  setMerchandiseSale(state, value) {
    this.state.incomeStatement.merchandiseSale = value;
  },
  setRevenueFromTraining(state, value) {
    this.state.incomeStatement.revenueFromTraining = value;
  },
  setTotalRevenue(state, value) {
    this.state.incomeStatement.totalRevenue = value;
  },


  setProcurementCost(state, value) {
    this.state.incomeStatement.procurementCost = value;
  },
  setWages(state, value) {
    this.state.incomeStatement.wages = value;
  },
  setRent(state, value) {
    this.state.incomeStatement.rent = value;
  },


  setInterestPaid(state, value) {
    this.state.incomeStatement.interestPaid = value;
  },
  setTransportation(state, value) {
    this.state.incomeStatement.transportation = value;
  },
  setUtilities(state, value) {
    this.state.incomeStatement.utilities = value;
  },

  setTotalExpenses(state, value) {
    this.state.incomeStatement.totalExpenses = value;
  },
  setIncomeFromSaleOfValue(state, value) {
    this.state.incomeStatement.incomeFromSaleOfValue = value;
  },
  setRevenueExpenses(state, value) {
    this.state.incomeStatement.revenueExpenses = value;
  },


  setIncomeStatementObj(state, value) {
    this.state.incomeStatement = value;
  }



}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
