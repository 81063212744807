<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Register Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Tinker Tailwind HTML Admin Template"
              :src="require(`@/assets/images/archymedes-owl-logo-WEB-01.png`)"
              style="width: 40%"
            />
          </a>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              {{orgName}} Member Sign Up
            </h2>
            <div class="text-xs">
              <span class="text-theme-21">*</span> ALL fields are required
            </div>

            <div class="intro-x mt-8">
              <input
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                :class="{ 'border-theme-21': validate.firstName.$error }"
                placeholder="First Name"
                v-model="validate.firstName.$model"
              />
              <template v-if="validate.firstName.$error">
                <small
                  v-for="(error, index) in validate.firstName.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>

              <input
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                :class="{ 'border-theme-21': validate.lastName.$error }"
                placeholder="Last Name"
                v-model="validate.lastName.$model"
              />
              <template v-if="validate.lastName.$error">
                <small
                  v-for="(error, index) in validate.lastName.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>

              <input
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                :class="{ 'border-theme-21': validate.password.$error }"
                placeholder="Password"
                v-model="validate.password.$model"
              />
              <template v-if="validate.password.$error">
                <small
                  v-for="(error, index) in validate.password.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>

            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                @click="handleSubmit"
              >
                Register
              </button>
              <button
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
                @click="handleLoginClick"
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue';
import useSignup from  '@/composables/useSignup';
import useCollection from '@/composables/useCollection';
import getDocument from '@/composables/getDocument';
import useDocument from '@/composables/useDocument'
import userCRUD from '@/composables/userCRUD';
import { useRouter } from 'vue-router';
import {
  required,
  minLength,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup(props, context) {
    const router = useRouter();
    onMounted(async () => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
      // await getDocumentByID('organizations', router.currentRoute.value.query.org);
      // console.log(router.currentRoute.value.query.org);
      // console.log(organization.value.members);
    })

    const {error, newID, signup} = useSignup();
    // const { error: useCollectionError, addDocwithID, getCollection, newDoc, documents: organizations } = useCollection('organizations');
    //
    // const {
    //   error: useDocumentError,
    //   updateDoc,
    //   isPending
    // } = useDocument('organizations', router.currentRoute.value.query.org);
    //
    // const {
    //       error: getDocumentError,
    //       getDocumentByID,
    //       document: organization
    //     } = getDocument('organizations', router.currentRoute.value.query.org);

   // const { promoteToAdmin } = userCRUD();

    const registerForm = reactive({
      firstName: null,
      lastName: null,
      displayName: null,
      password: null,
    })


    const email = ref(router.currentRoute.value.query.email);
    const orgName = ref(router.currentRoute.value.query.org);

    const rules = {
      firstName: {
        required,
        minLength: minLength(2)
      },
      lastName: {
        required,
        minLength: minLength(2)
      },
      password: {
        required,
        minLength: minLength(6)
      },
    }
    const validate = useVuelidate(rules, registerForm);

    const handleSubmit = async () => {
      validate.value.$touch();
      if(!validate.value.$invalid) {
        // registerForm.displayName = registerForm.firstName + " " + registerForm.lastName;
        // console.log(registerForm.displayName);
        // console.log(email);
        await signup(email.value, registerForm.password, registerForm.firstName + " " + registerForm.lastName, orgName.value, "ROLE_MEMBER");
        if(!error.value) {
          context.emit('signup');

          enterPortal();
        } else {
          console.log("ERROR:" + error.value);
        }
      }
    }

    const enterPortal =  () => {
      router.push({ name: 'portal-page' });
    }

    const handleLoginClick = () => {
      router.push({ name: 'login' });
    }

    return {
      ...toRefs(registerForm), email, orgName, validate,
      handleSubmit, error, enterPortal, handleLoginClick
    }
  }
})
</script>
