<template>
  <Chart
    type="bar"
    :width="width"
    :height="height"
    :dataInfo="dataInfo"
    :data="data"
    :options="options"
  />
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    dataInfo: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const data = computed(() => {
      console.log(props.dataInfo.map(row => row.action_date_fiscal_year));
      console.log(props.dataInfo.map(row => row.sum));
      
      return {
        labels: props.dataInfo.map(row => row.action_date_fiscal_year),
        datasets: [
          {
            label: 'Contract Value by Year (  )',
            barPercentage: 0.5,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            data: props.dataInfo.map(row => row.sum),
            backgroundColor: '#2f5ad8'
          }
        ]
      }
    })
    const options = computed(() => {
      return {
        scales: {
          xAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777'
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontSize: '12',
                fontColor: '#777777',
                callback: function(value) {
                  return '$' + value
                }
              },
              gridLines: {
                color: '#D8D8D8',
                zeroLineColor: '#D8D8D8',
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false
              }
            }
          ]
        }
      }
    })

    return {
      data,
      options
    }
  }
})
</script>
