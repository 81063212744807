import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'
import store from '../store'

const useCollection = (collection) => {

  const error = ref(null)
  const isPending = ref(false)
  const newDoc = ref(null)
  const documents = ref(null)
  const document = ref(null)


  const getCollection = async (collection, query, fields) => {
    console.log('Params:', [collection, query, fields])

    // register the firestore collection reference
    let collectionRef = projectFirestore.collection(collection)
    var snapshot

    if (query) {
      snapshot = await collectionRef.where(...query).get()
    } else {
      snapshot = await collectionRef.get()
    }

    let results = []

    snapshot.forEach(doc => {
      results.push({ ...doc.data(), id: doc.id })
    })

    if (fields) {
      results = filterResultsByFields(results, fields)
    }

    documents.value = results
  }


  const getCollectionByDocId = async (collection, docId) => {
  }


  const getCollectionByDocumentId = async (collection, docId) => {
    ///console.log("Params:", [collection, docId]);
    // register the firestore collection reference
    if (collection === 'valuation') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          console.log('Document data valuation:', JSON.stringify(doc.data()))
          store.commit('valuation/setBalanceSheetObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }

      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }
    if (collection === 'now-account') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          store.commit('accountNow/setAccountNowObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }
    if (collection === 'equity-valuation') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          console.log('Document data equity-valuation:', JSON.stringify(doc.data()))
          store.commit('equityValuation/setEquityValuationObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }
    if (collection === 'liability-valuation') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          console.log('Document data liability-valuation:', JSON.stringify(doc.data()))
          store.commit('liabilityValuation/setLiabilityValuationObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }
    if (collection === 'asset-valuation') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          console.log('Document data asset-valuation:', JSON.stringify(doc.data()))
          store.commit('assetValuation/setAssetValuationObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }
    if (collection === 'cash-flow') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          //  console.log("Document data:", JSON.stringify(doc.data()));
          store.commit('cashFlow/setCashFlowObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }
    if (collection === 'balance-sheet') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          //  console.log("Document data:", JSON.stringify(doc.data()));
          store.commit('balanceSheet/setBalanceSheetObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }
    if (collection === 'income-statement') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          //  console.log("Document data:", JSON.stringify(doc.data()));
          store.commit('incomeStatement/setIncomeStatementObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    } else if (collection === 'required-form') {
      // let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
      //   if (doc.exists) {
      //     //  console.log("Document data:", JSON.stringify(doc.data()));
      //     alert("ADSF");
          await store.dispatch('requiredForm/fetchItems')
      //
      //   } else {
      //     // doc.data() will be undefined in this case
      //     //  console.log("No such document!");
      //   }
      // }).catch((error) => {
      //   console.log('Error getting document:', error)
      // })
    } else if (collection === 'financials') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          // console.log("Document data:", JSON.stringify(doc.data()));
          store.commit('financials/setFinancialsStateObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!')
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })

    } else if (collection === 'loan7a') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          // console.log("Document data:", JSON.stringify(doc.data()));
          store.commit('loan7a/setLoan7aStateObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!')
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })

    } else if (collection === 'loan7a') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          //  console.log("Document data:", JSON.stringify(doc.data()));
          store.commit('loan504/setLoan504StateObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!')
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })


    } else if (collection === 'loan504') {
      let collectionRef = projectFirestore.collection(collection).doc(docId).get().then((doc) => {
        if (doc.exists) {
          //  console.log("Document data:", JSON.stringify(doc.data()));
          store.commit('loan504/setLoan504StateObj', doc.data())
        } else {
          // doc.data() will be undefined in this case
          //  console.log("No such document!");
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
    }

    //console.log("done:");
  }


  function filterResultsByFields(results, fields) {
    var newResults = []

    results.forEach(result => {
      let filteredResult = Object.entries(result).filter(function([key, value]) {
        return fields.includes(key) || key === 'id' || key === 'userID'
      })

      newResults.push(Object.fromEntries(filteredResult))
    })

    return newResults
  }


  // add a new document
  const addDocWithGenId = (doc, userKey) => {
    error.value = null
    console.log('addDocWithGenId')
    console.log('doc: ', doc)
    console.log('userKey: ', userKey)
    console.log('collection: ', collection)

    projectFirestore.collection(collection).doc(userKey).update(doc).then(function() {
      console.log('Document successfully updated!')
    })
      .catch(function(error) {
        // console.error("Error updating document: ", error);

        projectFirestore.collection(collection).doc(userKey).set(doc).then(function() {
          console.log('Document successfully added!' + ' ' + collection)
        })
          .catch(function(errorx) {
            //console.error("Error adding document: ", errorx);
          })
      })
  }

  const updateDocWithGenId = (userKey, valueName, value) => {
    error.value = null

    console.log('userKey: ', userKey)
    console.log('valueName: ', valueName)
    console.log('value: ', value)

    projectFirestore.collection(collection).doc(userKey).update({ [valueName]: value }).then(function() {
      console.log('Document successfully updated!')
    })
  }


  // add a new document
  const addDoc = async (doc) => {
    error.value = null
    isPending.value = true

    try {
      const res = await projectFirestore.collection(collection).add(doc)
      newDoc.value = res.id
      console.log('New Doc ID:', newDoc.value)
      isPending.value = false
    } catch (err) {
      console.log(err.message)
      error.value = 'could not send the message'
      isPending.value = true
    }
  }

  // add a new document with a custom ID
  const addDocwithID = async (doc, id) => {
    error.value = null
    isPending.value = true

    try {
      const res = await projectFirestore.collection(collection).doc(id).set(doc)
      isPending.value = false
    } catch (err) {
      console.log(err.message)
      error.value = 'could not send the message'
      isPending.value = true
    }
  }

  return {
    error,
    addDoc,
    getCollectionByDocumentId,
    getCollectionByDocId,
    addDocWithGenId,
    updateDocWithGenId,
    addDocwithID,
    getCollection,
    isPending,
    newDoc,
    documents
  }

}

export default useCollection