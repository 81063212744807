import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import sideEsMenu from './side-es-menu'
import simpleMenu from './simple-menu'
import topMenu from './top-menu'
import requiredForm from './required-form'
import financials from './financials'
import loan504 from './loan504'
import loan7a from './loan7a'
import incomeStatement from './income-statement'
import balanceSheet from './balance-sheet'
import cashFlow from './cash-flow'
import assetValuation from './asset-valuation'
import liabilityValuation from './liability-valuation'
import equityValuation from './equity-valuation'
import valuation from './valuation'
import accountNow from './now-account'

const store = createStore({
  modules: {
    main,
    sideMenu,
    sideEsMenu,
    simpleMenu,
    topMenu,
    requiredForm,
    financials,
    loan504,
    loan7a,
    incomeStatement,
    balanceSheet,
    assetValuation,
    liabilityValuation,
    equityValuation,
    valuation,
    cashFlow,
    accountNow,
  }
})

export function useStore() {
  return store
}

export default store