<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Complete Your Profile</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="flex justify-center">
        <button 
          class= "intro-y w-10 h-10 rounded-full btn btn-primary mx-2"
        >
          1
        </button>
      </div>
      <div class="px-5 mt-10">
        <div class="font-medium text-center text-lg"><strong>Business Info</strong></div>
      </div>
      <div
        class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <!-- <div class="font-medium text-base">[Insert Title Here]</div> -->
        <div class="text-xs">
          <span class="text-theme-21">*</span> ALL fields are required
        </div>
        
        <!-- BEGIN: Page 1 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Business Name</label>
            <input
              id="input-wizard-1"
              type="text"
              class="form-control"
              :class="{ 'border-theme-21': validate.name.$error }"
              placeholder="Placeholder Inc."
              v-model.trim="validate.name.$model"
            />
            <template v-if="validate.name.$error">
              <small
                v-for="(error, index) in validate.name.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-2" class="form-label">Current Business Address</label>
            <input
              id="input-wizard-2"
              type="text"
              class="form-control"
              :class="{ 'border-theme-21': validate.name.$error }"
              placeholder="1111 Placeholder Circle, Testland, Testing 00000"
              v-model="validate.address.$model"
            />
            <template v-if="validate.address.$error">
              <small
                v-for="(error, index) in validate.address.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-1" class="form-label">Business Type</label>
            <select
              class="form-select sm:mr-2"
              :class="{ 'border-theme-21': validate.name.$error }"
              aria-label="Default select example"
              v-model="validate.type.$model"
            >
              <option disabled value=''>Select a business type</option>
              <option>Sole Proprietorship</option>
              <option>Partnership</option>
              <option>Limited Partnership</option>
              <option>Corporation</option>
              <option>LLC</option>
              <option>Non-Profit</option>
              <option>Co-op</option>
            </select>
            <template v-if="validate.type.$error">
              <small
                v-for="(error, index) in validate.type.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">How much of your business do you own?</label>
            <div class="input-group">
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': validate.percentage.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="1"
                max="100"
                v-model="validate.percentage.$model"
              />
              <div id="input-group-price" class="input-group-text">%</div>
            </div>
            <template v-if="validate.percentage.$error">
              <small
                v-for="(error, index) in validate.percentage.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <label for="input-wizard-4" class="form-label">How many years have you been in business?</label>
            <div class="input-group">
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                :class="{ 'border-theme-21': validate.yearsInBusiness.$error }"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="validate.yearsInBusiness.$model"
              />
            </div>
            <template v-if="validate.yearsInBusiness.$error">
              <small
                v-for="(error, index) in validate.yearsInBusiness.$errors"
                :key="index"
                class="text-theme-21 mt-2"
              >
                {{ error.$message }} <br>
              </small>
            </template>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6">
            <div>
              <label>Are you applying as a Women Owned Small Business (WOSB)?</label>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-1"
                  class="form-check-input"
                  type="radio"
                  name="wosb-choice"
                  v-bind:value="true"
                  v-model="wosb"
                  :style="validate.wosb.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-1">Yes</label>
              </div>
              <div class="form-check mt-2">
                <input
                  id="radio-switch-2"
                  class="form-check-input"
                  type="radio"
                  name="wosb-choice"
                  v-bind:value="false"
                  v-model="wosb"
                  :style="validate.wosb.$error ? 'border: 1px solid red' : ''"
                />
                <label class="form-check-label" for="radio-switch-2">No</label>
              </div>
              <template v-if="validate.wosb.$error">
                <small
                  v-for="(error, index) in validate.wosb.$errors"
                  :key="index"
                  class="text-theme-21 mt-2"
                >
                  {{ error.$message }} <br>
                </small>
              </template>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6" v-if="wosb == true">
            <div>
                <label>Are you married?</label>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-1"
                    class="form-check-input"
                    type="radio"
                    name="usCitizen-choice"
                    v-bind:value="true"
                    v-model="married"
                    :style="validate.married.$error ? 'border: 1px solid red' : ''"
                  />
                  <label class="form-check-label" for="radio-switch-1">Yes</label>
                </div>
                <div class="form-check mt-2">
                  <input
                    id="radio-switch-2"
                    class="form-check-input"
                    type="radio"
                    name="usCitizen-choice"
                    v-bind:value="false"
                    v-model="married"
                    :style="validate.married.$error ? 'border: 1px solid red' : ''"
                  />
                  <label class="form-check-label" for="radio-switch-2">No</label>
                </div>
                <template v-if="validate.married.$error">
                  <small
                    v-for="(error, index) in validate.married.$errors"
                    :key="index"
                    class="text-theme-21 mt-2"
                  >
                    {{ error.$message }} <br>
                  </small>
                </template>
              </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            
            <button class="btn btn-success w-24 ml-2" @click="handleFinish" v-if="!isPending">Continue to Financial Info</button>
            <button class="btn btn-dark mr-1 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>

            <button class="btn btn-linkedin ml-2" @click="handleSaveandQuit" v-if="!isPending">Save & Exit</button>
            <button class="btn btn-dark mr-1 mb-2" v-else>
              Saving
              <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
        <!-- END: Page 1 -->

        <div class="w-full border-t border-gray-200 dark:border-dark-5 mt-5"></div>
      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue';
import getUser from '@/composables/getUser';
import useDocument from '@/composables/useDocument';
import getDocument from '@/composables/getDocument';
import { useRouter } from 'vue-router';
import {
  required,
  minLength,
  requiredIf,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  setup() {
    const router = useRouter();
    const {user} = getUser();
    const { error,
            getDocumentByID, document: profileDetails } = getDocument('profileDetails', user.value.uid);
    const { error: useDocumentError, isPending, deleteDoc, updateDoc } = useDocument('profileDetails', user.value.uid);

    const businessInfo = reactive({
      name: null,
      address: null,
      type: '',
      percentage: null,
      wosb: null,
      married: null,
      yearsInBusiness: null,
    });

    const rules = {
      name: {
        required,
        minLength: minLength(2)
      },
      address: {
        required
      },
      type: {
        required
      },
      percentage: {
        required
      },
      wosb: {
        required
      },
      married: {
        requiredIfWOSBApplicant: requiredIf(() => businessInfo.wosb == true)
      },
      yearsInBusiness: {
        required
      },
    }
    const validate = useVuelidate(rules, toRefs(businessInfo));

    const handleSaveandQuit = async () => {
      const updates = {
        business: {
          ...businessInfo,
          name: businessInfo.name == '' ? null : businessInfo.name,
          address: businessInfo.address == '' ? null : businessInfo.address,
          percentage: businessInfo.percentage == '' ? null : businessInfo.percentage,
          yearsInBusiness: businessInfo.yearsInBusiness == '' ? null : businessInfo.yearsInBusiness,
          married: businessInfo.wosb == true ? businessInfo.married : false
        }
      };

      await updateDoc(updates);

      router.push({ name: 'portal-page' });
    };

    const handleFinish = async () => {
      validate.value.$touch();
      if(!validate.value.$invalid) {
        const updates = {
          business: {
            ...businessInfo,
          }
        };
  
        await updateDoc(updates);
  
        router.push({ name: 'financial-info' });
      }
    };

    onMounted(async () => {
      await getDocumentByID('profileDetails', user.value.uid);
      
      // If values exist in the DB, populate them in the form
      if (profileDetails.value.business != null) {
        let profileBusinessInfo = profileDetails.value.business;

        Object.keys(profileBusinessInfo).forEach(function(key) {
          if(key in businessInfo) {
            businessInfo[key] = profileBusinessInfo[key];
          }
        });
      }
    })

    return { ...toRefs(businessInfo), validate,
             handleFinish, isPending, handleSaveandQuit };
  },
}
</script>
