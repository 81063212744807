<template>
  <div
    class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    "
  >
    <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-1" class="form-label">{{$t('message.continuousLiabilitiesOtherIncomeAsEndorserOrCoMaker')}}*</label>
          <CurrencyInput id="input-wizard-1" class="form-control"  v-model="otherIncome" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-2" class="form-label">{{$t('message.continuousLiabilitiesLegalClaimsAndJudgmentsProvisionForFederal')}}*</label>
          <CurrencyInput id="input-wizard-2" class="form-control"  v-model="legalClaimsFederal" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-3" class="form-label">{{$t('message.continuousLiabilitiesIncomeTax')}}*</label>
          <CurrencyInput id="input-wizard-3" class="form-control"  v-model="incomeTax" :options="opt" />
      </div>
      <div class="intro-y col-span-3 sm:col-span-3">
        <label for="input-wizard-4" class="form-label">{{$t('message.continuousLiabilitiesOtherSpecialDebt')}}*</label>
          <CurrencyInput id="input-wizard-4" class="form-control"  v-model="otherSpecialDebt" :options="opt" />
      </div>

  </div>
</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import CurrencyInput from "@/components/currency-input/Main.vue";


export default {
  components: {
   CurrencyInput,
 },
  setup () {
    const store = useStore()

    const opt = {currency: 'USD',currencyDisplay: 'symbol',hideCurrencySymbolOnFocus: false,  hideGroupingSeparatorOnFocus: false, precision: 0 }
    const otherIncome = computed({
        get() { return store.state.requiredForm.contingentLiabilities.otherIncome; },
        set(value) { store.commit("requiredForm/setOtherIncome", value); }
      });

    const legalClaimsFederal = computed({
        get() { return store.state.requiredForm.contingentLiabilities.legalClaimsFederal; },
        set(value) { store.commit("requiredForm/setLegalClaimsFederal", value); }
      });

      const incomeTax = computed({
          get() { return store.state.requiredForm.contingentLiabilities.incomeTax; },
          set(value) { store.commit("requiredForm/setIncomeTax", value); }
        });

      const otherSpecialDebt = computed({
          get() { return store.state.requiredForm.contingentLiabilities.otherSpecialDebt; },
          set(value) { store.commit("requiredForm/setOtherSpecialDebt", value); }
        });


    return {
      // access a state in computed function
      otherIncome,
      legalClaimsFederal,
      incomeTax,
      otherSpecialDebt,
      store,
      opt
    }
  }
}

</script>

<style>
</style>