<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-2">
      <h2 class="text-lg font-medium mr-auto">View Your Application</h2>
      <!-- <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2">Print</button>
        <div class="dropdown ml-auto sm:ml-0">
          <button
            class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"
            aria-expanded="false"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Export Word
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Export PDF
              </a>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- BEGIN: Invoice -->
    <div class="intro-y box overflow-hidden mt-3">
      <!-- <iframe :src="`${publicPath}Form_1919_Sample.pdf`" width="100%" height="100%"></iframe> -->
      <!-- <div
        class="flex flex-col lg:flex-row pt-10 px-5 sm:px-20 sm:pt-20 lg:pb-20 text-center sm:text-left"
      >
        <div class="font-semibold text-theme-25 dark:text-theme-22 text-3xl">
          INVOICE
        </div>
        <div class="mt-20 lg:mt-0 lg:ml-auto lg:text-right">
          <div class="text-xl text-theme-25 dark:text-theme-22 font-medium">
            Left4code
          </div>
          <div class="mt-1">left4code@gmail.com</div>
          <div class="mt-1">8023 Amerige Street Harriman, NY 10926.</div>
        </div>
      </div> -->
      <!-- <div
        class="flex flex-col lg:flex-row border-b px-5 sm:px-20 pt-10 pb-10 sm:pb-20 text-center sm:text-left"
      >
        <div>
          <div class="text-base text-gray-600">Client Details</div>
          <div
            class="text-lg font-medium text-theme-25 dark:text-theme-22 mt-2"
          >
            Arnold Schwarzenegger
          </div>
          <div class="mt-1">arnodlschwarzenegger@gmail.com</div>
          <div class="mt-1">260 W. Storm Street New York, NY 10025.</div>
        </div>
        <div class="mt-10 lg:mt-0 lg:ml-auto lg:text-right">
          <div class="text-base text-gray-600">Receipt</div>
          <div
            class="text-lg text-theme-25 dark:text-theme-22 font-medium mt-2"
          >
            #1923195
          </div>
          <div class="mt-1">Jan 02, 2021</div>
        </div>
      </div> -->
      <div class="px-5 sm:px-16 py-5 sm:py-10">
        <iframe :src="`${publicPath}Form_1919_Sample.pdf`" width="100%" height="750"></iframe>
        <!-- <video :src="`${publicPath}Archymedes_Preview.mp4`" width="500" controls></video> -->
        <div class="overflow-x-auto">
        </div>
      </div>
      <div
        class="px-5 sm:px-20 pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row"
      >
        <div
            class="flex items-center text-gray-700 dark:text-gray-600 sm:ml-auto mt-5 sm:mt-0"
            style="position: absolute; left: 0; bottom: 5%; margin-left: 10px;"
          >
            <Tippy
              tag="a"
              class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
              content="Back to Assessment Results"
              @click="handleBack"
            >
              <ArrowLeftIcon class="w-4 h-4 fill-current" />
            </Tippy>
            &nbsp;Back to Assessment Results
          </div>
      </div>
    </div>
    <!-- END: Invoice -->
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  setup() {
    const publicPath = process.env.BASE_URL;
    const router = useRouter();

    const handleBack = () => {
      router.push({ name: 'application-assessment-success' });
    }

    return { publicPath, handleBack };
  },
}
</script>
