<template>
<div class="flex flex-wrap">
  <div class="grid grid-cols-12 gap-6 p-10">
    <div class="col-span-6">
      <h1 class="text-lg font-medium truncate">Valuation Operating for Cash Flow Statement</h1>
      <small class="text-theme-25 intro-y">You can report your assets by updating your information in appropriate columns. </small>
    </div>
  </div>
</div>
<div class="col-span-6">
  <ItemForm :store-value='section' :store-object='this.$store.state.valuation.cashFlowStatement.operating' :prev-button='prevbutton' :next-button='nextbutton' :save-button='savebutton'></ItemForm>
</div>

</template>

<script>
import {
  defineComponent,
  computed
} from 'vue'
import {
  useStore
} from '@/store'
import { mapState } from 'vuex';

import {
  object,
  string,
  number,
  bool
} from "yup";
import * as yup from 'yup';
import {
  mask
} from 'vue-the-mask';


import ItemForm from '../../../components/item-store/Main.vue';

export default {
  name: "operating",
    directives: { mask },
    components: {
    // CurrencyInput,
      ItemForm,
   },
  data() {
    const store = useStore();
    return {
      store,
      section: 'Operating',
      prevbutton: '/financial-valuation/cash-flow/investing',
      nextbutton: undefined,
      savebutton: false
    }
  },

}
</script>
