<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <div
            class="flex items-center text-gray-700 dark:text-gray-600 sm:ml-auto mt-5 sm:mt-0"
            style="position: absolute; left: 0; margin-left: 10px; margin-top: 6px;"
          >
            <Tippy
              tag="a"
              class="w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center border dark:border-dark-5 ml-2 text-gray-500 zoom-in"
              content="Back to Member Management"
              @click="handleBackMemberCRUD"
            >
              <ArrowLeftIcon class="w-4 h-4 fill-current" />
            </Tippy>
            &nbsp;Back to Your Members <strong><i>&nbsp;({{org}})</i></strong> 
          </div>
        <!-- BEGIN: Your Business at a Glance -->
        <div class="col-span-12 mt-12">
          <div class="intro-y flex items-center">
            <h2 class="text-lg font-medium truncate text-theme-21 mr-5">(Viewing {{memberName}}'s Dashboard as <strong><i>{{user.displayName}}</i></strong>)</h2>
            <!-- <a
              href=""
              class="ml-auto flex items-center text-theme-30 dark:text-theme-25"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Reload Data
            </a> -->
          </div>
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Your Business Evaluation</h2>
            <!-- <a
              href=""
              class="ml-auto flex items-center text-theme-30 dark:text-theme-25"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3" /> Reload Data
            </a> -->
          </div>
          <small 
            class="text-theme-25 intro-y"
            v-if="overall.rating == null"
          >
            Please complete your financial profile to see these values!
          </small>
          <div class="grid grid-cols-12 gap-6 mt-5">
            
            <!-- Debt Coverage Ratio -->
            <InfoBox
              :title = debtCoverageRatio.title
              :value = debtCoverageRatio.value
              :rating = debtCoverageRatio.rating
              @click="handleModal(debtCoverageRatio.modalTarget)"
            /> 

            <!-- Years In Business -->
            <InfoBox
              :title = yearsInBusiness.title
              :value = yearsInBusiness.value
              :rating = yearsInBusiness.rating
              :extraText = "yearsInBusiness.value == 1 ? 'Year' : 'Years'"
              @click="handleModal(yearsInBusiness.modalTarget)"
            />
            
            <!-- Credit Score -->
            <InfoBox
              :title = creditScore.title
              :value = creditScore.value
              :rating = creditScore.rating
              @click="handleModal(creditScore.modalTarget)"
            />
            
            <!-- Overall Rating -->
            <InfoBox
              :title = overall.title
              :value = overall.rating
              :rating = overall.rating
              @click="handleModal(overall.modalTarget)"
            />
          </div>
        </div>
        <!-- END: Your Business at a Glance -->

        <!-- BEGIN: Financial Profile -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-4 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              Financial Profile
            </h2>
          </div>
          <div class="mt-5">
            <div
              v-for="(faker, fakerKey) in $_.take($f(), tasks.length)"
              :key="fakerKey"
              class="intro-y"
            >
              <div class="box px-4 py-4 mb-3 flex items-center zoom-in" @click.prevent="handleProfileRedirect(tasks[fakerKey].redirect)">
                <div class="ml-4 mr-auto">
                  <div class="font-medium" v-if="!tasks[fakerKey].complete">{{ tasks[fakerKey].name }}</div>
                  <div class="font-medium text-theme-20" v-else>{{ tasks[fakerKey].name }}</div>
                </div>
                <div
                  class="py-1 px-2 rounded-full text-xs bg-gray-700 text-white cursor-pointer font-medium"
                  v-if="!tasks[fakerKey].complete"
                >
                  <ChevronRightIcon class="w-4 h-4" />
                </div>
                <div
                  class="py-1 px-2 rounded-full text-xs bg-theme-20 text-white cursor-pointer font-medium"
                  v-else
                >
                  <CheckIcon class="w-4 h-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Financial Profile -->

        <!-- BEGIN: Blogs & Videos -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-4 mt-8">
          <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-auto">
                Blogs & Videos
              </h2>
              <button
                data-carousel="important-notes"
                data-target="prev"
                class="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2"
                @click="prevImportantNotes"
              >
                <ChevronLeftIcon class="w-4 h-4" />
              </button>
              <button
                data-carousel="important-notes"
                data-target="next"
                class="tiny-slider-navigator btn px-2 border-gray-400 text-gray-700 dark:text-gray-300 mr-2"
                @click="nextImportantNotes"
              >
                <ChevronRightIcon class="w-4 h-4" />
              </button>
            </div>
            <div class="mt-5 intro-x">
              <div class="box zoom-in">
                <TinySlider ref-key="importantNotesRef">
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      Types of Loans
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Business loan types include term loans, SBA loans and business lines of credit. 
                      Compare your options. Here are the 10 most-popular types of business loans.
                    </div>
                    <div class="font-medium flex mt-5">
                      <button type="button" class="btn btn-secondary py-1 px-2 ml-auto ml-auto">
                        View
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      5 Ways to Improve Your Odds of Getting a Loan
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Applying for a small business loan can be a daunting and discouraging process. 
                      In this article, we walk you through what you might need to button up before you begin the small business loan application process.
                    </div>
                    <div class="font-medium flex mt-5">
                      <!-- <button
                        type="button"
                        class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"
                      >
                        Dismiss
                      </button> -->
                      <button type="button" class="btn btn-secondary py-1 px-2 ml-auto ml-auto">
                        View
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="text-base font-medium truncate">
                      Grants for Starting a Business in Georgia
                    </div>
                    <div class="text-gray-500 mt-1">20 Hours ago</div>
                    <div class="text-gray-600 text-justify mt-1">
                      Grants for starting a business in Georgia may be available for certain types of enterprises. 
                      These opportunities are available through the state and federal government to support business development.
                    </div>
                    <div class="font-medium flex mt-5">
                      <!-- <button
                        type="button"
                        class="btn btn-outline-secondary py-1 px-2 ml-auto ml-auto"
                      >
                        Dismiss
                      </button> -->
                      <button type="button" class="btn btn-secondary py-1 px-2 ml-auto ml-auto">
                        View
                      </button>
                    </div>
                  </div>
                </TinySlider>
              </div>
            </div>
        </div>
        <!-- END: Blogs & Videos -->
        
        <!-- BEGIN: Schedules -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-4 mt-8">
          <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-5">Schedules</h2>
              <a
                href=""
                class="ml-auto text-theme-30 dark:text-theme-25 truncate flex items-center"
              >
                <PlusIcon class="w-4 h-4 mr-1" /> Add New Schedules
              </a>
          </div>
          <div class="mt-5">
              <div class="intro-x box">
                <div class="p-5">
                  <div class="flex">
                    <ChevronLeftIcon class="w-5 h-5 text-gray-600" />
                    <div class="font-medium text-base mx-auto">December</div>
                    <ChevronRightIcon class="w-5 h-5 text-gray-600" />
                  </div>
                  <div class="grid grid-cols-7 gap-4 mt-5 text-center">
                    <div class="font-medium">Su</div>
                    <div class="font-medium">Mo</div>
                    <div class="font-medium">Tu</div>
                    <div class="font-medium">We</div>
                    <div class="font-medium">Th</div>
                    <div class="font-medium">Fr</div>
                    <div class="font-medium">Sa</div>
                    <div class="py-0.5 rounded relative text-gray-600">28</div>
                    <div class="py-0.5 rounded relative text-gray-600">29</div>
                    <div class="py-0.5 rounded relative text-gray-600">30</div>
                    <div class="py-0.5 rounded relative">1</div>
                    <div class="py-0.5 rounded relative">2</div>
                    <div class="py-0.5 rounded relative">3</div>
                    <div class="py-0.5 rounded relative">4</div>
                    <div class="py-0.5 rounded relative">5</div>
                    <div class="py-0.5 rounded relative">6</div>
                    <div class="py-0.5 rounded relative">7</div>
                    <div
                      class="py-0.5 text-theme-25 dark:bg-theme-25 text-white rounded relative"
                    >
                      8
                    </div>
                    <div class="py-0.5 rounded relative">9</div>
                    <div class="py-0.5 rounded relative">10</div>
                    <div class="py-0.5 rounded relative">11</div>
                    <div class="py-0.5 rounded relative">12</div>
                    <div class="py-0.5 rounded relative">13</div>
                    <div 
                      class="py-0.5 bg-theme-11 dark:bg-theme-29 text-white rounded relative"
                    >
                      14
                    </div>
                    <div class="py-0.5 rounded relative">15</div>
                    <div class="py-0.5 rounded relative">16</div>
                    <div class="py-0.5 rounded relative">17</div>
                    <div class="py-0.5 rounded relative">18</div>
                    <div class="py-0.5 rounded relative">19</div>
                    <div class="py-0.5 rounded relative">20</div>
                    <div class="py-0.5 rounded relative">21</div>
                    <div class="py-0.5 rounded relative">22</div>
                    <div class="py-0.5 rounded relative">23</div>
                    <div class="py-0.5 rounded relative">24</div>
                    <div class="py-0.5 rounded relative">25</div>
                    <div class="py-0.5 rounded relative">26</div>
                    <div class="py-0.5 rounded relative">27</div>
                    <div class="py-0.5 rounded relative">28</div>
                    <div class="py-0.5 rounded relative">29</div>
                    <div class="py-0.5 rounded relative">30</div>
                    <div class="py-0.5 rounded relative">31</div>
                    <div class="py-0.5 rounded relative text-gray-600">1</div>
                    <div class="py-0.5 rounded relative text-gray-600">2</div>
                    <div class="py-0.5 rounded relative text-gray-600">3</div>
                    <div class="py-0.5 rounded relative text-gray-600">4</div>
                    <div class="py-0.5 rounded relative text-gray-600">5</div>
                    <div class="py-0.5 rounded relative text-gray-600">6</div>
                    <div class="py-0.5 rounded relative text-gray-600">7</div>
                    <div class="py-0.5 rounded relative text-gray-600">8</div>
                  </div>
                </div>
                <div class="border-t border-gray-200 p-5">
                  <div class="flex items-center">
                    <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
                    <span class="truncate">TOP Summit Live Demo</span>
                    <div
                      class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
                    ></div>
                    <span class="font-medium xl:ml-auto">14th</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- END: Schedules -->
      </div>
    </div>

  </div>

  <!-- BEGIN: Modal Box Component -->
  <Modal>
    
    <CheckCircleIcon class="w-16 h-16 text-theme-20 mx-auto mt-3" v-if="rating == 'Excellent'"/>
    <AlertCircleIcon class="w-16 h-16 text-theme-15 mx-auto mt-3" v-else-if="rating == 'Okay'"/>
    <XOctagonIcon class="w-16 h-16 text-theme-21 mx-auto mt-3" v-else/>

    <div class="text-3xl mt-5" v-if="modal == 'debt'">
      Your Debt Coverage Ratio is 
        <span class="text-theme-20" v-if="debtCoverageRatio.rating == 'Excellent'">Excellent!</span>
        <span class="text-theme-15" v-else-if="debtCoverageRatio.rating == 'Okay'">Okay.</span>
        <span class="text-theme-21" v-else>Low...</span>
    </div>
    <div class="text-3xl mt-5" v-else-if="modal == 'years'">
      Your number of years in business is 
        <span class="text-theme-20" v-if="yearsInBusiness.rating == 'Excellent'">Excellent!</span>
        <span class="text-theme-15" v-else-if="yearsInBusiness.rating == 'Okay'">Okay.</span>
        <span class="text-theme-21" v-else>Low...</span>
    </div>
    <div class="text-3xl mt-5" v-else-if="modal == 'credit'">
      Your personal credit score is 
        <span class="text-theme-20" v-if="creditScore.rating == 'Excellent'">Excellent!</span>
        <span class="text-theme-15" v-else-if="creditScore.rating == 'Okay'">Okay.</span>
        <span class="text-theme-21" v-else>Low...</span>
    </div>
    <div class="text-3xl mt-5" v-else>
      You're in 
        <span class="text-theme-20" v-if="overall.rating == 'Excellent'">good shape</span>
        <span class="text-theme-15" v-else-if="overall.rating == 'Okay'">decent shape</span>
        <span class="text-theme-21" v-else>bad shape</span> overall
    </div>
    <div class="text-gray-600 mt-2">
      <span v-if="modal == 'debt'" v-html="debtCoverageRatio.modalText">
      </span>
      <span v-else-if="modal == 'years'" v-html="yearsInBusiness.modalText">
      </span>
      <span v-else-if="modal == 'credit'" v-html="creditScore.modalText">
      </span>
      <span v-else>
        Quick overview based on the business information inserted into the system by the small business owner to assess general standing before additional steps.
      </span>
    </div>
  </Modal>
  <!-- END: Modal Box Component -->
</template>

<script>
import { defineComponent, ref, reactive, provide, onMounted, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import Modal from '@/components/modal/Main.vue';
import InfoBox from '@/components/info-box/Main.vue';
import ReportLineChart from '@/components/report-line-chart/Main.vue';
import ReportDonutChart from '@/components/report-donut-chart/Main.vue';
import ReportPieChart from '@/components/report-pie-chart/Main.vue';
import ReportMap from '@/components/report-map/Main.vue';
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue';
import SimpleLineChart1 from '@/components/simple-line-chart-1/Main.vue';
import getUser from '@/composables/getUser'
import getDocument from '@/composables/getDocument';

export default defineComponent({
  components: {
    Modal,
    InfoBox,
    ReportLineChart,
    ReportDonutChart,
    ReportPieChart,
    ReportMap,
    ReportDonutChart1,
    SimpleLineChart1
  },
  setup() {
    const salesReportFilter = ref()
    const importantNotesRef = ref()
    const router = useRouter();
    const {user} = getUser();
    const modal = ref('bRating');
    const rating = ref(null);

    const org = router.currentRoute.value.query.org;
    const memberID = window.atob(router.currentRoute.value.query.member);
    const memberName = window.atob(router.currentRoute.value.query.name);

    const {
      error: getDocumentError,
      getDocumentByID,
      document: profile
    } = getDocument('profileDetails', memberID);

    const businessOverview = reactive({
      debtCoverageRatio: {
        title: 'Debt Coverage Ratio',
        value: null,
        rating: null,
        modalTarget: 'debt',
        modalText: `<p class="mt-3">Your <strong>Debt Coverage Ratio</strong> shows how much of your income is available to repay current debts.</p>
                            <br>
                            <p>While the exact debt coverage ratio requirement will vary by lender, SBA loans require a Debt Coverage Ratio of <strong><i>1.15 or greater</i></strong>.</p>`
      },
      yearsInBusiness: {
        title: 'Years In Business',
        value: null,
        rating: null,
        modalTarget: 'years',
        modalText: `<p class="mt-3">When you're applying for a loan, many lenders will require a minimum time in business of <strong>two years</strong>.</p>
                            <br>
                            <p>There might be extenuating circumstances—especially if you <i>have great credit and finances or are applying for an SBA microloan</i> — but in most cases, two years is the minimum.</p>`
      },
      creditScore: {
        title: 'Credit Score',
        value: null,
        rating: null,
        modalTarget: 'credit',
        modalText: `<p class="mt-3">Remember, loans will come through a lender, and they are willing to lend <strong><i>if you have a high enough credit score</i></strong>.</p>
                            <br>
                            <p>For the SBA 7(a), this means a minimum score of <strong><i>approximately 640</i></strong>.</p>
                            <br>
                            <p>But you'll increase your chances to be approved for an SBA loan with a <strong><i>minimum credit score of 680 or higher</i></strong>.</p>`
      },
      overall: {
        title: 'Business Rating',
        rating: null,
        modalTarget: 'bRating'
      }
    });

    provide('bind[importantNotesRef]', el => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    const tasks = [
      {
        name: 'Personal Information',
        complete: true,
        redirect: 'about-you'
      },
      {
        name: 'Business Information',
        complete: true,
        redirect: 'business-info'
      },
      {
        name: 'Financial Information',
        complete: true,
        redirect: 'financial-info'
      },
      // {
      //   name: 'Assets (Novice)',
      //   complete: true,
      //   redirect: 'side-menu-novice-financial-info'
      // },
    ];

    const handleProfileRedirect = (section) => {
      router.push({ name: section });
    };

    const handleBackMemberCRUD = () => {
      router.push({ name: 'member-list', params: {org: org} });
    };

    const handleModal = (value) => {
      modal.value = value;
      console.log('New Modal Value', modal.value);

      switch(value) {
        case 'debt':
          rating.value = businessOverview.debtCoverageRatio.rating;
          break;
        case 'years':
          rating.value = businessOverview.yearsInBusiness.rating;
          break;
        case 'credit':
          rating.value = businessOverview.creditScore.rating;
          break;
        default:
          rating.value = businessOverview.overall.rating;
          break;
      }
    };

    onMounted(async () => {
      console.log(modal.value);
      await getDocumentByID('profileDetails', memberID);

      var assetTotal = profile.value.finances.assets.assetTotal;
      var liabilityTotal = profile.value.finances.liabilities.liabilityTotal;
      var debtCoverageRatio = assetTotal/liabilityTotal
      var yearsInBusiness = profile.value.business.yearsInBusiness;
      var creditScore = profile.value.creditScore;

      if(!isNaN(debtCoverageRatio)) {
        businessOverview.debtCoverageRatio.value = debtCoverageRatio.toFixed(2);
      }
      businessOverview.yearsInBusiness.value = yearsInBusiness;
      businessOverview.creditScore.value = creditScore;

      Object.keys(businessOverview).forEach((key) => {
        if(key == 'overall') {
          console.log('Key & Value BEFORE the rating update', [key, businessOverview[key].rating]);
          businessOverview[key].rating = calculateOverallBusinessRating();
          console.log('Key & Value AFTER the rating update', [key, businessOverview[key].rating]);
        } else {
          console.log('Key & Value BEFORE the rating update', [key, businessOverview[key].rating]);
          businessOverview[key].rating = updateRating(key, businessOverview[key].value);
          console.log('Key & Value AFTER the rating update', [key, businessOverview[key].rating]);
        }
      });

      tasks.forEach((task) => {
        console.log('Current Task', task);
        if(task.name == 'Assets (Novice)') {
          return;
        } else {
          task.complete = checkTaskCompletion(task.name);
        }
      })
    });

    function checkTaskCompletion(task) {
      console.log('Profile:', profile.value);
      switch(task) {
        case 'Personal Information': {
          var personalInfo = [];
          personalInfo.push([
            profile.value.address,
            profile.value.creditScore,
            profile.value.dob,
            profile.value.phoneNumber,
            profile.value.ssn,
            Object.values(profile.value.citizenship),
            Object.values(profile.value.criminalInfo)
          ]);
          console.log('Personal Info checks these values: ', personalInfo)
          
          var numOfNulls = countOccurences(personalInfo, null);
          console.log('Personal Info Nulls', numOfNulls);

          if(numOfNulls == 0) {
            return true;
          } else {
            break;
          }
        }  
        case 'Business Information': {
          var businessInfo = [];
          businessInfo.push([
            Object.values(profile.value.business),
          ]);
          console.log('Business Info checks these values: ', businessInfo)
          
          var numOfNulls = countOccurences(businessInfo, null);
          console.log('Business Info Nulls', numOfNulls);

          if(numOfNulls == 0) {
            return true;
          } else {
            break;
          }
        }
        case 'Financial Information': {
          var financialInfo = [];
          financialInfo.push([
            Object.values(profile.value.finances.assets),
            Object.values(profile.value.finances.liabilities),
            Object.values(profile.value.finances.income),
            Object.values(profile.value.finances.contingentLiabilities),
          ]);
          console.log('Financial Info checks these values: ', financialInfo)
          
          var numOfNulls = countOccurences(financialInfo, null);
          console.log('Financial Info Nulls', numOfNulls);

          if(numOfNulls == 0) {
            return true;
          } else {
            break;
          }
        }
        default:
          break;
      }
      return false;
    }

    function updateRating(sectionName, value) {
      console.log('Current Section:', [sectionName, value])
      switch (sectionName) {
        case 'debtCoverageRatio':
          if(value >= 2) {
            return 'Excellent';
          } else if(value >= 1.15 && value < 2) {
            return 'Okay';
          } else if(value < 1.15 && value != null ) {
            return 'Low';
          } else {
            return null;
          }
        case 'yearsInBusiness':
          if(value >= 4) {
            return 'Excellent';
          } else if(value >= 2 && value < 4) {
            return 'Okay';
          } else if(value < 2 && value != null) {
            return 'Low';
          } else {
            return null;
          }
        case 'creditScore':
          if(value >= 750) {
            return 'Excellent';
          } else if(value >= 640 && value < 750) {
            return 'Okay';
          } else if(value < 640 && value != null) {
            return 'Low';
          } else {
            return null;
          }
        default:
          break;
      }
    }

    function calculateOverallBusinessRating() {
      var ratingValue = 0;

      var ratings = [];
      var numOfCompleteSections = 3;

      Object.keys(businessOverview).forEach((key) => {
        if(key == 'overall') {
          return;
        } else {
          ratings.push(businessOverview[key].rating);
          if(businessOverview[key].rating == null) {
            numOfCompleteSections--;
          }
        }
      });

      Object.keys(businessOverview).forEach((key) => {
        if(key == 'overall') {
          return;
        } else {
          switch(businessOverview[key].rating) {
            case 'Excellent':
              ratingValue += 3;
              break;
            case 'Okay':
              ratingValue += 2;
              break;
            case 'Low':
              ratingValue += 1;
              break;
            default:
              ratingValue += 0;
              break;
          }
        }

      })

      switch(ratingValue) {
        case 9:
        case 8:
          return 'Excellent'
        case 7:
          return 'Okay'
        case 6:
          if(numOfCompleteSections != 3) {
            return null;
          } else {
            return 'Okay'
          }
        case 5:
          if(numOfCompleteSections != 3) {
            return null;
          } else {
            var numOfLows = countOccurences(ratings, 'Low');
            console.log(numOfLows);
            
            if(numOfLows < 1) {
              return 'Okay';
            } else {
              return 'Low';
            }
          }
        case 4:
        case 3:
          if(numOfCompleteSections != 3) {
            return null;
          } else {
            return 'Low';
          }
        default:
          return null;
      }
    }

    function countOccurences(array, value) {
      console.log(array);
      var num = array.flat(Infinity).filter(item => item === value).length;
      return num;
    }

    return {
      salesReportFilter, prevImportantNotes, nextImportantNotes,
      tasks, user, org, memberName,
      handleProfileRedirect, handleBackMemberCRUD,
      modal, handleModal, rating,
      ...toRefs(businessOverview),
    }
  }
})
</script>
