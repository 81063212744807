<template>
  <p>Click <a href="javascript:;" data-toggle="modal" data-target="#form-slider" 
                class="text-theme-25 font-normal">More Info</a > to open up the slider box</p>

  <p>Click <a href="javascript:;" data-toggle="modal" data-target="#form-slider" 
                class="text-theme-25 font-normal" @click="moreInfoForAssets()">Here</a > for more information</p>

    <FormSlider 
        :sliderTitle="sliderTitle"
        :sliderBody="sliderBody" />

   
</template>

<script>
import { ref } from 'vue-demi';
import FormSlider from './Main.vue'

export default {
    components: {
        FormSlider
    },
    setup()
    {
        var sliderTitle = ref('');
        var sliderBody = ref(''); 

        function moreInfoForAssets()
        {
            var title = 'Here is the title';
            var body = 'here is the body of the page';
            fillFormSlider(title, body);
        }

        function fillFormSlider(title, body) {
            sliderTitle.value = title
            sliderBody.value = body
        }

        return {
            sliderTitle,
            sliderBody,
            fillFormSlider,
            moreInfoForAssets
        }


    }
    
}
</script>

<style>

</style>