<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto">Assets</h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div class="flex justify-center">
        <video :src="`${publicPath}Archymedes_Preview.mp4`" width="500" controls></video>
      </div>
      <div class="px-5 mt-10">
        <div class="font-medium text-center text-lg"><strong>Assets</strong></div>
      </div>
      <div
        class="px-5 sm:px-20 mt-10 pt-10 border-t border-gray-200 dark:border-dark-5"
      >
        <div class="font-medium text-base"><strong>{{assets[page - 1].name}}</strong></div>
        <div class="text-gray-600">{{assets[page - 1].text != '' ? assets[page - 1].text : 'Lorem ipsum dolor sit amet.'}}</div>
        
        <!-- BEGIN: Page 1 -->
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-4">
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 1">
            <label for="input-wizard-1" class="form-label">Cash on Hand & in banks</label>
            <div class="input-group">
              <input
                id="input-wizard-1"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="cash"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 2">
            <label for="input-wizard-2" class="form-label">Savings Accounts</label>
            <div class="input-group">
              <input
                id="input-wizard-2"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="savings"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 3">
            <label for="input-wizard-3" class="form-label">IRA / Other Retirement Account</label>
            <div class="input-group">
              <input
                id="input-wizard-3"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="retirement"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 4">
            <label for="input-wizard-4" class="form-label">Accounts & Notes Receivable</label>
            <div class="input-group">
              <input
                id="input-wizard-4"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="aNReceivable"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 5">
            <label for="input-wizard-5" class="form-label">Life Insurance (Cash Surrender Value Only)</label>
            <div class="input-group">
              <input
                id="input-wizard-5"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="lifeInsurance"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 6">
            <label for="input-wizard-6" class="form-label">Stocks & Bonds</label>
            <div class="input-group">
              <input
                id="input-wizard-6"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="stocksBonds"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 7">
            <label for="input-wizard-7" class="form-label">Real Estate</label>
            <div class="input-group">
              <input
                id="input-wizard-7"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="realEstate"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 8">
            <label for="input-wizard-8" class="form-label">Automobiles</label>
            <div class="input-group">
              <input
                id="input-wizard-8"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="automobiles"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 9">
            <label for="input-wizard-9" class="form-label">Other Personal Property</label>
            <div class="input-group">
              <input
                id="input-wizard-9"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="otherPersonal"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div class="intro-y col-span-12 sm:col-span-6" v-if="page == 10">
            <label for="input-wizard-10" class="form-label">Other Assets</label>
            <div class="input-group">
              <input
                id="input-wizard-10"
                type="number"
                class="form-control"
                placeholder="51"
                aria-label="Percentage"
                aria-describedby="input-group-price"
                min="0"
                v-model="otherAssets"
              />
              <div id="input-group-price" class="input-group-text">$</div>
            </div>
          </div>
          <div
            class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
          >
            <button class="btn btn-secondary w-24" @click="handlePrev" v-if="page > 1">Previous</button>
            <button class="btn btn-success w-24 ml-2" @click="handleFinish" v-if="page == 10">Finish</button>
            <button class="btn btn-primary w-24 ml-2" @click="handleNext" v-else>Next</button>
          </div>
        </div>
        <!-- END: Page 1 -->


        <div class="w-full border-t border-gray-200 dark:border-dark-5 mt-5"></div>

      </div>
    </div>
    <!-- END: Wizard Layout -->
  </div>

  <!-- START: Modal Layout -->
  <div class="intro-y box mt-5">
    <div id="header-footer-modal" class="p-5">
      <div class="preview">
        <!-- BEGIN: Modal Content -->
        <div
          id="header-footer-modal-preview"
          class="modal"
          data-backdrop="static"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <!-- BEGIN: Modal Header -->
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  Here's a short video to help you out!
                </h2>
              </div>
              <!-- END: Modal Header -->
              <!-- BEGIN: Modal Body -->
              <div class="modal-body">
                <video :src="`${publicPath}Archymedes_Preview.mp4`" width="1000" controls></video>
              </div>
              <!-- END: Modal Body -->
              <!-- BEGIN: Modal Footer -->
              <div class="modal-footer text-right">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-primary w-20 mr-1"
                >
                  Close
                </button>
              </div>
              <!-- END: Modal Footer -->
            </div>
          </div>
        </div>
        <!-- END: Modal Content -->
      </div>
    </div>
  </div>
  <!-- END: Wizard Layout -->
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import getUser from '@/composables/getUser';
import useDocument from '@/composables/useDocument';
import getDocument from '@/composables/getDocument';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const {user} = getUser();
    const { error, document: profileDetails } = getDocument('profileDetails', user.value.uid);
    const { error: useDocumentError, isPending, deleteDoc, updateDoc } = useDocument('profileDetails', user.value.uid);
    const publicPath = process.env.BASE_URL;

    const correct = ref(null);
    const answer = ref(null);
    const page = ref(1);

    // Asset Refs
    const assetRefs = reactive({
      cash: 0,
      savings: 0,
      retirement: 0,
      aNReceivable: 0,
      lifeInsurance: 0,
      stocksBonds: 0,
      realEstate: 0,
      automobiles: 0,
      otherPersonal: 0,
      otherAssets: 0,
      assetTotal: 0,
    });

    const assets = [
      {
        name: 'Cash on Hand & in Banks',
        text: 'Asset accounts represent the different types of economic resources owned or controlled by an entity. Common examples of asset accounts include cash in hand, cash in bank, receivables, inventory, prepaid expenses, land, structures, equipment, patents, copyrights, licenses, etc',
      },
      {
        name: 'Savings Accounts',
        text: '',
      },
      {
        name: 'IRA / Other Retirement Accounts',
        text: '',
      },
      {
        name: 'Accounts & Notes Receivable',
        text: 'Accounts receivable is an informal, short-term payment and usually no interest, whereas notes receivable is a legal contract, long-term payment, and usually has interest',
      },
      {
        name: 'Life Insurance',
        text: '',
      },
      {
        name: 'Stocks & Bonds',
        text: 'Stocks and bonds are certificates that are sold to raise money for starting a new company or for expanding an existing company. Stocks and bonds are also called securities, and people who buy them are called investors.',
      },
      {
        name: 'Real Estate',
        text: '',
      },
      {
        name: 'Automobiles',
        text: 'A road vehicle, typically with four wheels, powered by an internal combustion engine or electric motor and able to carry a small number of people.',
      },
      {
        name: 'Other Personal Property',
        text: '',
      },
      {
        name: 'Other Assets',
        text: '',
      },
    ]

    const assetNames = [
      'Cash on Hand & in Banks',
      'Savings Accounts',
      'IRA / Other Retirement Accounts',
      'Accounts & Notes Receivable',
      'Life Insurance',
      'Stocks & Bonds',
      'Real Estate',
      'Automobiles',
      'Other Personal Property',
      'Other Assets'
    ];

    function findTotal(arrayToAdd) {
      let total = 0;

      console.log(arrayToAdd);
      for(let i = 0; i < arrayToAdd.length; i++) {
        total += arrayToAdd[i];
      }

      console.log('Array Total: ', total);
      return total;
    }

    const handleNext = () => {
        page.value++;
    };
    
    const handlePrev = () => {
        page.value--;
    };

    const handleFinish = async () => {
      const assets = [
        cash.value,
        savings.value,
        retirement.value,
        aNReceivable.value,
        lifeInsurance.value,
        stocksBonds.value,
        realEstate.value,
        automobiles.value,
        otherPersonal.value,
        otherAssets.value,
      ];
      
      assetTotal.value = findTotal(assets);

      const updates = {
        "finances.assets": {
          assets: {
            cash: cash.value,
            savings: savings.value,
            retirement: retirement.value,
            aNReceivable: aNReceivable.value,
            lifeInsurance: lifeInsurance.value,
            stocksBonds: stocksBonds.value,
            realEstate: realEstate.value,
            automobiles: automobiles.value,
            otherPersonal: otherPersonal.value,
            otherAssets: otherAssets.value,
            assetTotal: assetTotal.value
          },
        }
      };

      await updateDoc(updates);

      router.push({ name: 'user-profile', params: { id: user.value.uid } });
    };

    return { assets, ...toRefs(assetRefs),
            page, handleNext, handlePrev, handleFinish, isPending, publicPath };
  },
}
</script>
