import { createRouter, createWebHistory } from 'vue-router';
import MainTemplate from '../layouts/main-template/Main.vue'
import PackagePage from '../views/packages/Main.vue'
import PortalPage from '../views/portal-page/Main.vue'
import ViewMemberDashboard from '../views/portal-page/ViewMemberDashboard.vue'
import FileManager from '../views/file-manager/Main.vue'
import Calendar from '../views/calendar/Main.vue'
import CrudDataList from '../views/crud-data-list/Main.vue'
import CrudForm from '../views/crud-form/Main.vue'
import InviteUser from '../views/crud-form/InviteUser.vue'
import UserProfile from '../views/user-profile/Main.vue'
import AboutYou from '../views/wizard-layout-1/profile_completion/AboutYou.vue'
import BusinessInfo from '../views/wizard-layout-1/profile_completion/BusinessInfo.vue'
import FinancialInfo from '../views/wizard-layout-1/profile_completion/FinancialInfo.vue'
import NoviceFinancialInfo from '../views/wizard-layout-1/profile_completion/NoviceFinancialInfo.vue'
import LoanBusinessInfo from '../views/wizard-layout-1/7a_loan/Section1/BusinessInfo.vue'
import IndividualOwner from '../views/wizard-layout-1/7a_loan/Section2/IndividualOwner.vue'
import EntityOwner from '../views/wizard-layout-1/7a_loan/Section3/EntityOwner.vue'
import ApplicationProcess from '../views/wizard-layout-3/ApplicationProcess.vue'
import ApplicationProcessSuccess from '../views/wizard-layout-3/ApplicationProcessSuccess.vue'
import ApplicationProcessFailure from '../views/wizard-layout-3/ApplicationProcessFailure.vue'
import AssessmentProcessSuccess from '../views/wizard-layout-3/AssessmentProcessSuccess.vue'
import AssessmentProcessFailure from '../views/wizard-layout-3/AssessmentProcessFailure.vue'
import AssessmentProcessFailureYellow from '../views/wizard-layout-3/AssessmentProcessFailureYellow.vue'
import Blog from '../views/blog/Main.vue'
import ViewLoanApplication from '../views/view-loan-application/Main.vue'
import Login from '../views/login/Main.vue'
import ForgotPassword from '../views/login/ForgotPassword.vue'
import Register from '../views/register/Main.vue'
import MemberRegistration from '../views/register/MemberRegistration.vue'
import ErrorPage from '../views/error-page/Main.vue'
import YourLoanList from '../views/your-loan-list/Main.vue'
import MemberList from '../views/member-list/Main.vue'
import FileUpload from '../views/file-upload/Main.vue'
import RequiredForms from '../views/required-forms/Main.vue'
import FinancialStatement from '../views/financials/Main.vue'
import Loan508 from '../views/508-loan/Main.vue'
import Loan7a from '../views/seven-a/Main.vue'
import AssetsTabs from '../views/financials/tabs/assets.vue'
import LiabilitiesTabs from '../views/financials/tabs/liabilities.vue'
import NotesPayable from '../views/financials/tabs/notes-payable.vue'
import StocksBonds from '../views/financials/tabs/stocks-bonds.vue'
import RealEstate from '../views/financials/tabs/real-estate.vue'
import NotesPayableForm from '../views/financials/tabs/form/notes-payable-form.vue'
import StocksBondsForm from '../views/financials/tabs/form/stocks-bonds-form.vue'
import RealEstateForm from '../views/financials/tabs/form/real-estate-form.vue'

import Performance from '../views/performance/Main.vue'
import EightAEvaluation from '../views/eight-a/Main.vue'

import IncomeStatement from '../views/performance/income-statement.vue'
import BalanceSheet from '../views/performance/balance-sheet.vue'
import CashFlow from '../views/performance/cash-flow.vue'

import AssetsValuation from '../views/financial-statement-forms/balance-sheet/assets.vue'
import LiabilityValuation from '../views/financial-statement-forms/balance-sheet/liability.vue'
import EquitiesValuation from '../views/financial-statement-forms/balance-sheet/equities.vue'

import FinancingValuation from '../views/financial-statement-forms/cash-flow/financing.vue'
import InvestingValuation from '../views/financial-statement-forms/cash-flow/investing.vue'
import OperatingValuation from '../views/financial-statement-forms/cash-flow/operating.vue'


import ExpensesValuation from '../views/financial-statement-forms/income-statement/expenses.vue'
import GainsValuation from '../views/financial-statement-forms/income-statement/gains.vue'
import LosesValuation from '../views/financial-statement-forms/income-statement/loses.vue'
import RevenueValuation from '../views/financial-statement-forms/income-statement/revenue.vue';

import BalanceSheetValuation from '../views/financial-statement-forms/balance-sheet.vue'
import IncomeStatementValuation from '../views/financial-statement-forms/income-statement.vue'
import CashFlowStatementValuation from '../views/financial-statement-forms/cash-flow.vue'


import MainValuation from '../views/financial-statement-forms/Main.vue'
import AccountNow from '../views/now-account/Main.vue'

import TestDataTable from '../components/data-table/Example.vue'
import TestFormSlider from '../components/form-slider/Example.vue'

import {projectAuth} from '../firebase/config';


// Auth Guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  console.log('Current User in Auth Guard: ', user);
  if(!user) {
    next({ name: 'login' });
  } else {
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'MainTemplate',
    component: MainTemplate,
    beforeEnter: requireAuth,
    redirect: to => {
      let user = projectAuth.currentUser;

      if(!user) {
        return '/login';
      } else {
        return { name: 'portal-page' }
      }
    },
    children: [
      {
        path: 'portal',
        name: 'portal-page',
        component: PortalPage,
      },
      {
        path: '/member-dashboard',
        name: 'view-member-dashboard',
        component: ViewMemberDashboard,
        props: true
      },
      {
        path: '/financial-statement',
        name: 'financial-statement',
        component: FinancialStatement,
      },
      {
        path: '/financial-statement/assets',
        name: 'assets',
        component: AssetsTabs,
      },
      {
        path: '/financial-statement/liabilities',
        name: 'liabilities',
        component: LiabilitiesTabs,
      },
      {
        path: '/financial-statement/notes-payable',
        name: 'notes-payable',
        component: NotesPayable,
      },
      {
        path: '/financial-valuation/main',
        name: 'main-valuation',
        component: MainValuation,
      },
      {
        path: '/now-account/main',
        name: 'now-account',
        component: AccountNow,
      },
      {
        path: '/financial-valuation/balance-sheet-valuation',
        name: 'balance-sheet-valuation',
        component: BalanceSheetValuation,
      },
      {
        path: '/financial-valuation/income-statement-valuation',
        name: 'income-statement-valuation',
        component: IncomeStatementValuation,
      },
      {
        path: '/financial-valuation/cash-flow-statement-valuation',
        name: 'cash-flow-statement-valuation',
        component: CashFlowStatementValuation,
      },
      {
        path: '/financial-valuation/assets',
        name: 'assets-valuation',
        component: AssetsValuation,
      },
      {
        path: '/financial-valuation/liabilities',
        name: 'liability-valuation',
        component: LiabilityValuation,
      },
      {
        path: '/financial-valuation/equities',
        name: 'equities-valuation',
        component: EquitiesValuation,
      },


      {
        path: '/financial-valuation/cash-flow/financing',
        name: 'financing-valuation',
        component: FinancingValuation,
      },
      {
        path: '/financial-valuation/cash-flow/investing',
        name: 'investing-valuation',
        component: InvestingValuation,
      },
      {
        path: '/financial-valuation/cash-flow/operating',
        name: 'operating-valuation',
        component: OperatingValuation,
      },




      {
        path: '/financial-valuation/income-statement/expenses',
        name: 'expenses-valuation',
        component: ExpensesValuation,
      },
      {
        path: '/financial-valuation/income-statement/gains',
        name: 'gains-valuation',
        component: GainsValuation,
      },
      {
        path: '/financial-valuation/income-statement/loses',
        name: 'loses-valuation',
        component: LosesValuation,
      },
      {
        path: '/financial-valuation/income-statement/revenue',
        name: 'revenue-valuation',
        component: RevenueValuation,
      },

      {
        path: '/financial-statement/notes-payable-form',
        name: 'notes-payable-form',
        component: NotesPayableForm,
      },
      {
        path: '/financial-statement/stocks-bonds',
        name: 'stocks-bonds',
        component: StocksBonds,
      },
      {
        path: '/financial-statement/notes-payable-form',
        name: 'stocks-bonds-form',
        component: StocksBondsForm,
      },
      {
        path: '/financial-statement/real-estate',
        name: 'real-estate',
        component: RealEstate,
      },
      {
        path: '/financial-statement/real-estate-form',
        name: 'real-estate-form',
        component: RealEstateForm,
      },


      {
        path: 'performance',
        name: 'performance',
        component: Performance,
      },
      {
        path: '/performance/income-statement',
        name: 'income-statement',
        component: IncomeStatement,
      },
      {
        path: '/performance/balance-sheet',
        name: 'balance-sheet',
        component: BalanceSheet,
      },
      {
        path: '/performance/cash-flow',
        name: 'cash-flow',
        component: CashFlow,
      },

      {
        path: '/packages/loans',
        name: 'loan-packages',
        component: PackagePage,
      },
      {
        path: 'file-manager',
        name: 'side-menu-file-manager',
        component: FileManager
      },
      {
        path: 'calendar',
        name: 'side-menu-calendar',
        component: Calendar
      },
      {
        path: 'crud-data-list',
        name: 'side-menu-crud-data-list',
        component: CrudDataList
      },
      {
        path: 'crud-form',
        name: 'side-menu-crud-form',
        component: CrudForm,
        children: [
          {
            path: 'edit-user/:id',
            name: 'EditUser',
            component: CrudForm,
            props: true,
            alias: ['/edit-user/:id']
          },
        ],
        props: true
      },
      {
        path: 'invite-user',
        name: 'invite-user',
        component: InviteUser,
      },
      {
        path: 'user-profile',
        name: 'user-profile',
        component: UserProfile
      },
// Let's be sure that we change the naming convention here
      {
        path: ':appID/section-1',
        name: 'side-menu-7a-section-1',
        component: LoanBusinessInfo,
        props: true,
      },
      {
        path: ':appID/section-2',
        name: 'side-menu-7a-section-2',
        component: IndividualOwner,
        props: true,
      },
      {
        path: ':appID/section-3',
        name: 'side-menu-7a-section-3',
        component: EntityOwner,
        props: true,
      },
// end of code fix and check
      {
        path: 'about-you',
        name: 'about-you',
        component: AboutYou
      },
      {
        path: 'business-info',
        name: 'business-info',
        component: BusinessInfo
      },
      {
        path: 'financial-info',
        name: 'financial-info',
        component: FinancialInfo
      },
      {
        path: 'novice-financial-info',
        name: 'side-menu-novice-financial-info',
        component: NoviceFinancialInfo
      },
      {
        path: 'application-process-wizard',
        name: 'side-menu-wizard-layout-3',
        component: ApplicationProcess
      },
      {
        path: 'application-success',
        name: 'side-menu-application-success',
        component: ApplicationProcessSuccess
      },
      {
        path: 'application-failure',
        name: 'side-menu-application-failure',
        component: ApplicationProcessFailure
      },
      {
        path: ':appID/assessment-success',
        name: 'application-assessment-success',
        component: AssessmentProcessSuccess,
        props: true
      },


      {
        path: ':appID/assessment-failure',
        name: 'side-menu-assessment-failure-red',
        component: AssessmentProcessFailure,
        props: true,
      },
      {
        path: ':appID/assessment-failure-yellow',
        name: 'side-menu-assessment-failure-yellow',
        component: AssessmentProcessFailureYellow,
        props: true,
      },

      {
        path: 'blog',
        name: 'blog',
        component: Blog
      },


      {
        path: ':appID/view-loan-application',
        name: 'view-loan-application',
        component: ViewLoanApplication,
        props: true
      },
      // {
      //   path: 'your-loan-list',
      //   name: 'your-loan-list',
      //   component: YourLoanList
      // },
      {
        path: '7a-loan',
        name: '7a-loan',
        component: Loan7a,
      },
      {
        path: '508-loan',
        name: '508-loan',
        component: Loan508
      },
      {
        path: ':org?/member-list',
        name: 'member-list',
        component: MemberList,
        props: true
      },
      {
        path: 'file-upload',
        name: 'side-menu-file-upload',
        component: FileUpload
      },
      {
        path: 'required-forms',
        name: 'side-menu-required-forms',
        component: RequiredForms

      },
      {
        path: 'eight-a-evaluation',
        name: 'side-menu-eight-a-evaluation',
        component: EightAEvaluation

      },
      {
        path: 'eight-a-evaluation2',
        name: 'side-menu-eight-a-evaluation2',
        component: MainValuation

      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/member-register',
    name: 'member-register',
    component: MemberRegistration,
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router