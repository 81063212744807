<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="Tinker Tailwind HTML Admin Template"
              :src="require(`@/assets/images/archymedes-owl-logo-WEB-01.png`)"
              style="width: 40%"
            />
          </a>
          <div class="my-auto">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              Forgot your password?
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              That's alright! Enter your email and we'll send you a link to reset it!
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Password Reset
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              Forgot your password? That's alright! Pick a new one here!
            </div>
            <div class="intro-x mt-8">
              <div class="input-form">
                <input
                  type="email"
                  class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                  :class="{ 'border-theme-21': validate.email.$error }"
                  placeholder="Email"
                  v-model.trim="validate.email.$model"
                />
                <template v-if="validate.email.$error">
                  <div
                    v-for="(error, index) in validate.email.$errors"
                    :key="index"
                    class="text-theme-21"
                  >
                    {{ error.$message }}
                  </div>
                </template>
              </div>
              <!-- <div class="text-theme-21 mt-2">{{error}}</div> -->
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <!-- <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Remember me</label
                >
              </div> -->
              <!-- <a href="">Forgot Password?</a> -->
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-primary py-3 px-4 xl:mr-3 align-top"
                @click="handleSubmit"
                v-if="!isPending"
              >
                Send Reset Email
              </button>
              <button class="btn btn-dark mr-1 ml-2 mb-2" v-else>
                Sending...
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>

              <button
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
                @click="handleCancelClick"
                v-if="!isPending"
              >
                Sign in
              </button>
              <button class="btn btn-dark mr-1 ml-2 mb-2" v-else>
                Sending...
                <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>

  <!-- BEGIN: Success Notification Content -->
  <div
    id="success-notification-content"
    class="toastify-content hidden flex"
  >
    <CheckCircleIcon class="text-theme-20" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Password Reset Email Sent!</div>
      <div class="text-gray-600 mt-1">
        Check your email to reset your password!
      </div>
    </div>
  </div>
  <!-- END: Success Notification Content -->

  <!-- BEGIN: Failed Notification Content -->
  <div
    id="failed-notification-content"
    class="toastify-content hidden flex"
  >
    <XCircleIcon class="text-theme-21" />
    <div class="ml-4 mr-4">
      <div class="font-medium">Something went wrong...</div>
      <div class="text-gray-600 mt-1">
        Check the email you entered!
      </div>
    </div>
  </div>
  <!-- END: Failed Notification Content -->
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue';
import useLogin from '@/composables/useLogin';
import { useRouter } from 'vue-router';
import {
  required,
  email
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Toastify from 'toastify-js';

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup(props, context) {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    });

    const formData = reactive({
      email: null,
    });

    const rules = {
      email: {
        required,
        email
      }
    };

    const validate = useVuelidate(rules, toRefs(formData));

    const router = useRouter();

    const enterPortal = () => {
      router.push({ name: 'portal-page' });
    }

    const {error, login, isPending, sendPasswordResetEmail } = useLogin();

    const handleSubmit = async () => {
      validate.value.$touch()
      if(!validate.value.$invalid) {
        await sendPasswordResetEmail(formData.email);
        if(!error.value) {
          Toastify({
              node: cash('#success-notification-content')
              .clone()
              .removeClass('hidden')[0],
              duration: 8000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast();
        }
      } else {
        Toastify({
          node: cash('#failed-notification-content')
          .clone()
          .removeClass('hidden')[0],
          duration: 10000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast();
      }
    }

    const handleCancelClick = () => {
      router.push({ name: 'login' });
    }

    return {
      ...toRefs(formData), validate, error, isPending,
      handleSubmit, enterPortal, handleCancelClick,
    }
  }
})
</script>
