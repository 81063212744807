<template>
  <div class=''>
    <div class=''>
      <a class='btn btn-primary mb-4  text-blue-600 hover:text-blue-800 visited:text-purple-600' href='javascript:'
         data-toggle='modal' data-target='#exampleModalLabel' @click='addAction()'>
        <PlusIcon class='w-4 h-4 mr-1' />
        New {{ storeValue }}
      </a>
      <div class='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded'
           style='background-color: #fafafa;'>
        <div class='px-12 py-12 flex-auto'>
          <div class='tab-content tab-space'>
            <div v-bind:class="{'hidden': countAssetValuation !== 0 }">
              <div class='text-center'>
                No {{ storeValue }} Records
              </div>
            </div>
            <div v-bind:class="{'hidden': countAssetValuation === 0}">
              <div class='intro-y col-span-12 overflow-auto lg:overflow-visible'>
                <table class='table table-report -mt-2'>
                  <thead>
                  <tr>
                    <th class='whitespace-nowrap'>Category</th>
                    <th class='text-center whitespace-nowrap'>Amount</th>
                    <th class='text-center whitespace-nowrap'>Action</th>
                    <!--    <th class="text-center whitespace-nowrap">ACTIONS</th> -->
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for='(values , index) in storeObject' class='intro-x'>
                    <td class='w-40 uppercase'>
                      {{ values.description }}
                    </td>
                    <td class='w-40 text-center'>
                      ${{ values.value }}
                    </td>
                    <td class='w-40 text-center'>
                      <a href='javascript:' data-toggle='modal' data-target='#exampleModalLabel' @click='editAction( values.description, values.value, index )'>
                        <EditIcon class='w-4 h-4 mr-1' />
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class='grid grid-cols-3'>
      <div v-if='showPrevButton'>
        <a class='col-start-1 bg-primary-1 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded'
           href='javascript:'
           @click='prevAction'>
          Prev
        </a>
      </div>
      <div v-else >
        <a class='col-start-1 bg-primary-1 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded'
           href='javascript:'
           @click='homeAction'>
          Dashboard
        </a>
      </div>
      <div class='col-start-3 col-end-4 flex gap-4 justify-end'>
        <div v-if='showNextButton'>
          <a class='bg-primary-1 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded' href='javascript:'
             @click='nextAction'>
            Next
          </a>
        </div>
        <div v-if='props.saveButton'>
          <a class='bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded' href='javascript:'
             @click='homeAction'>
            Save
          </a>
        </div>
        <div v-else>
          <a class='bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded' href='javascript:'
             @click='homeAction'>
            Done
          </a>
        </div>
      </div>
    </div>
  </div>


  <div id='exampleModalLabel'
       class='modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto' tabindex='-1'
       aria-hidden='true' @click.self='resetDeleteID()'>
    <div class='modal-dialog modal-fullscreen relative w-auto pointer-events-none' style='width: 60%;'>
      <div
        class='modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current'>
        <div
          class='modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md'>
          <h5 class='text-xl font-medium leading-normal text-gray-800'>Edit {{ storeValue }}</h5>
          <button type='button'
                  class='btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline'
                  data-dismiss='modal'
                  aria-label='Close'>X
          </button>
        </div>
        <div class='modal-body p-0'>
          <div class='
                    px-5
                    sm:px-20
                    mt-1
                    pt-1
                    dark:border-dark-5
                  '>
            <div class='grid grid-cols-12 gap-4 gap-y-5 mt-5'>
              <div class='intro-y col-span-12 sm:col-span-6'>
                <label for='input-wizard-9' class='form-label'>Title</label>
                <input id='input-wizard-9' type='text' class='form-control' v-model='localDescription' />
                <!--              <template v-if="errorValidation.accountTitle !== ''">-->
                <!--                <small class="text-theme-21 mt-2">-->
                <!--                  {{errorValidation.accountTitle}} <br>-->
                <!--                </small>-->
                <!--              </template>-->
              </div>
              <div class='intro-y col-span-12 sm:col-span-6'>
                <label for='input-wizard-9' class='form-label'>Value</label>
                <input id='input-wizard-9' type='text' class='form-control' v-model='localValue' />
                <!--              <template v-if="errorValidation.assetValue !== ''">-->
                <!--                <small class="text-theme-21 mt-2">-->
                <!--                  {{errorValidation.assetValue}} <br>-->
                <!--                </small>-->
                <!--              </template>-->
              </div>
            </div>
          </div>


          <div class='text-right mt-5'>
            <button class='btn btn-outline-secondary w-24 mr-1' data-dismiss='modal' aria-label='Cancel'>
              Cancel
            </button>
            <button class='btn btn-primary w-24 mr-1' data-dismiss='modal' aria-label='Save' @click='save'>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, computed, defineProps, onMounted } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import { setup } from 'firebase-functions/lib/setup'

const localDescription = ref('')
const localValue = ref(0)
const localObjectArray = ref([])
const localIndex = ref(-1)

const store = useStore()
const router = useRouter()

const editData = ref(false)

const obj = ref(null)
const foundObject = ref(null)


const props = defineProps({
  storeObject: {
    type: Object
    // required: true
  },
  storeValue: {
    type: String
    // required: true
  },
  nextButton: {
    type: String
    // required: true
  },
  prevButton: {
    type: String
    // required: true
  },
  saveButton: {
    type: Boolean
    // required: true
  }
})

function prevAction() {
  router.push(props.prevButton)
}

function nextAction() {
  router.push(props.nextButton)
}

function homeAction() {
  router.push('/eight-a-evaluation2')
}

function save() {
  if (editData.value) {
    props.storeObject[localIndex.value].description = localDescription.value
    props.storeObject[localIndex.value].value = localValue.value
    store.dispatch('valuation/submitFirebase')
  } else {
    store.commit('valuation/addItem' + props.storeValue, { description: localDescription.value, value: localValue.value })
    store.dispatch('valuation/submitFirebase')
  }

  editData.value = false;
  console.log("props.storeObject:" + JSON.stringify(props.storeObject));
}


function editAction(parmDescription, parmValue, index) {
  editData.value = true

  localDescription.value = parmDescription
  localValue.value = parmValue
  localIndex.value = index

  localDescription.value = props.storeObject[index].description
  localValue.value =  props.storeObject[index].value

  foundObject.value = props.storeObject[index];

}

function addAction() {
  editData.value = false;
  localDescription.value = ""
  localValue.value = 0
  localIndex.value = -1
  foundObject.value = [];
}

const showPrevButton = computed(function() {
  return undefined !== props.prevButton
})

const showNextButton = computed(function() {
  return undefined !== props.nextButton
})

const countAssetValuation = computed(function() {
  return props.storeObject.length
})


</script>
<style>


.modal-header,
.modal-footer {
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  border-bottom: 1px solid #dee2e6;
  border-radius: 5px 5px 0 0;
}

.modal-body {
  padding: 20px;
}


</style>
