import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'



const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('loan7a');


const state = () => {
  // TODO: must deal with date input type

  return {
    sevenAQuestion1: true,
    sevenAQuestion2: false,
    sevenAQuestion3: true,
    sevenAQuestion4: false,
    sevenAQuestion5: true,
    sevenAQuestion5a: '                                   ',
    sevenAQuestion5b1: '',
    sevenAQuestion5b2: '',
    sevenAQuestion5b3: '',
    sevenAQuestion6: false,
    sevenAQuestion7: false,
    sevenAQuestion8: false,
    sevenAQuestion9: false,
    sevenAQuestion10: false,
    sevenAQuestion11: false,
    sevenAQuestion12: false,
    sevenAQuestion13: false,
  }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    const {
      user
    } = getUser();
    const application = await addDocWithGenId(this.state.loan7a, user._value.email);
  }
}



// mutations
const mutations = {
  setSevenAQuestion1(state, value) {
    this.state.loan7a.sevenAQuestion1 = value;
  },
  setSevenAQuestion2(state, value) {
    this.state.loan7a.sevenAQuestion2 = value;
  },
  setSevenAQuestion3(state, value) {
    this.state.loan7a.sevenAQuestion3 = value;
  },
  setSevenAQuestion4(state, value) {
    this.state.loan7a.sevenAQuestion4 = value;
  },

  setSevenAQuestion5(state, value) {
    this.state.loan7a.sevenAQuestion5 = value;
  },
  setSevenAQuestion5a(state, value) {
    this.state.loan7a.sevenAQuestion5a = value;
  },
  setSevenAQuestion5b1(state, value) {
    this.state.loan7a.sevenAQuestion5b1 = value;
  },
  setSevenAQuestion5b2(state, value) {
    this.state.loan7a.sevenAQuestion5b2 = value;
  },
  setSevenAQuestion5b3(state, value) {
    this.state.loan7a.sevenAQuestion5b3 = value;
  },

  setSevenAQuestion6(state, value) {
    this.state.loan7a.sevenAQuestion6 = value;
  },
  setSevenAQuestion7(state, value) {
    this.state.loan7a.sevenAQuestion7 = value;
  },
  setSevenAQuestion8(state, value) {
    this.state.loan7a.sevenAQuestion8 = value;
  },
  setSevenAQuestion9(state, value) {
    this.state.loan7a.sevenAQuestion9 = value;
  },
  setSevenAQuestion10(state, value) {
    this.state.loan7a.sevenAQuestion10 = value;
  },
  setSevenAQuestion11(state, value) {
    this.state.loan7a.sevenAQuestion11 = value;
  },
  setSevenAQuestion12(state, value) {
    this.state.loan7a.sevenAQuestion12 = value;
  },
  setSevenAQuestion13(state, value) {
    this.state.loan7a.sevenAQuestion13 = value;
  },


  setLoan7aStateObj(state, value) {
    this.state.loan7a = value;
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}