const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'portal-page',
        title: 'Salpicadero'
      },
      // 'devider',
      // {
      //   icon: 'ThumbsUpIcon',
      //   pageName: 'side-menu-eight-a-evaluation2',
      //   title: 'Aptitud'
      // },
      // 'devider',
      // {
      //   icon: 'FileTextIcon',
      //   pageName: 'side-menu-eight-a-evaluation',
      //   title: '8(a) Evaluation'
      // },
      'devider',
      {
        icon: 'FileTextIcon',
        pageName: 'side-menu-required-forms',
        title: 'Formularios requeridos'
      },
      'devider',
      {
        icon: 'PieChartIcon',
        pageName: 'financial-statement',
        title: 'Financiero',
        subMenu: [
          {
            icon: 'TagIcon',
            pageName: 'assets',
            title: 'Activo'
          },
          {
            icon: 'CreditCardIcon',
            pageName: 'liabilities',
            title: 'Pasivo'
          },
          {
            icon: 'FileIcon',
            pageName: 'notes-payable',
            title: 'Pagarés'
          },
          // {
          //   icon: 'PieChartIcon',
          //   pageName: 'stocks-bonds',
          //   title: 'Acciones Bonos'
          // },
          // {
          //   icon: 'PenToolIcon',
          //   pageName: 'real-estate',
          //   title: 'Realestate'
          // }

        ]
      },
      'devider',
      {
        icon: 'HardDriveIcon',
        pageName: 'side-menu-file-manager',
        title: 'Archivo'
      },
      // 'devider',
      // {
      //   icon: 'PieChartIcon',
      //   pageName: 'performance',
      //   title: 'Performance'
        // subMenu: [
        //   {
        //     icon: 'TagIcon',
        //     pageName: 'income-statement',
        //     title: 'Income Statement',
        //   },
        //   {
        //     icon: 'CreditCardIcon',
        //     pageName: 'balance-sheet',
        //     title: 'Balance Sheet',
        //   },
        //   {
        //     icon: 'CreditCardIcon',
        //     pageName: 'cash-flow',
        //     title: 'Cash Flow',
        //   }
        // ]
      // },
      'devider',
      {
        icon: 'DollarSignIcon',
        pageName: '508-loan',
        title: 'Aplicación',
        subMenu: [
          {
            icon: 'FileIcon',
            pageName: '7a-loan',
            title: 'Préstamo 7a'
          },
          {
            icon: 'FileIcon',
            pageName: '508-loan',
            title: 'Préstamo 504'
          },
          {
            icon: 'FileIcon',
            pageName: 'now-account',
            title: 'NOW Account'
          }
          ]
      },
      'devider',
      {
        icon: 'PackageIcon',
        pageName: 'loan-packages',
        title: 'Paquetes'
      },
      // {
      //   icon: 'DollarSignIcon',
      //   pageName: 'your-loan-list',
      //   title: 'Your Loans',
      // },
      // 'devider',
      // {
      //   icon: 'HardDriveIcon',
      //   pageName: 'side-menu-file-manager',
      //   title: 'Archivo'
      // },
      // 'devider',
      // {
      //   icon: 'EditIcon',
      //   pageName: 'side-menu-crud-data-list',
      //   title: 'Usuario'
      // },
      // {
      //   icon: 'UsersIcon',
      //   pageName: 'member-list',
      //   title: 'Miembros'
      // }

    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}