<template>
<div class="
      px-5
      sm:px-20
      mt-10
      pt-10
      border-t border-gray-200
      dark:border-dark-5
    ">


  <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5 pb-10">

    <div class="intro-y col-span-12 text-center">
      <h1>{{$t('message.application504Sec2Heading')}}</h1>
    </div>


    <div class="intro-y col-span-12 pb-10">
      <label class="font-bold">{{$t('message.application504Sec2LoanQuestion1')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-1" class="form-check-input" type="radio" :checked="showExistingSbaLoan" name="existingSbaLoan-choice" @click="showExistingSbaLoanData(true)" />
        <label class="form-check-label" for="radio-switch-1">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-2" class="form-check-input" type="radio" :checked="!showExistingSbaLoan" @click="showExistingSbaLoanData(false)" name="existingSbaLoan-choice" />
        <label class="form-check-label" for="radio-switch-2">No</label>
      </div>

      <div v-show="showExistingSbaLoan">
        <div class="grid grid-cols-12 gap-4 gap-y-5">
          <div class="intro-y col-span-12 mt-10 font-bold">
            Please enter additional information
          </div>
          <div class="intro-y col-span-6">
            <label class="form-label" for="text-1">Enter loan number</label>
            <input id="text-1" class="form-control" type="text" v-model='existingSbaLoanLoanNumber' />
          </div>
          <div class="intro-y col-span-6">
            <label class="form-label" for="text-1">Status</label>
            <input id="text-1" class="form-control" type="text" v-model='existingSbaLoanStatus' />
          </div>
        </div>
      </div>




    </div>




    <div class="intro-y col-span-12 sm:col-span-12 pb-10">
      <label class="font-bold">{{$t('message.application504Sec2LoanQuestion2')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-3" class="form-check-input" type="radio" :checked="showArrested6Months" name="arrested6Months-choice" @click="showArrested6MonthsData(true)" />
        <label class="form-check-label" for="radio-switch-33">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-4" class="form-check-input" type="radio" :checked="!showArrested6Months" name="arrested6Months-choice" @click="showArrested6MonthsData(false)" />
        <label class="form-check-label" for="radio-switch-4">No</label>
      </div>
      <div v-if="showArrested6Months">
        <div v-if="showArrested6MonthsFileUpload">
          <div class="grid grid-cols-3 mt-5 gap-4 gap-y-5">
            <div class="bg-gray-800 py-1 w-11/12 rounded text-center border-b-4 border-red-400 flex justify-center ...">
              <p class="text-sm w-11/12 text-white">
                Document uploaded successfully.
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-4 gap-y-5">
          <div class="intro-y col-span-12 mt-10 font-bold">
            Please enter additional information
          </div>
          <div class="intro-y col-span-6">
            <UploadFile :hasUploaded='store.state.loan504.section3.arrested6Months.fileUploaded' :question="'question2'" :questionType="'arrested6Months'" :section="'section3'" />
          </div>
        </div>
      </div>
    </div>







    <div class="intro-y col-span-12 sm:col-span-12 pb-10">
      <label class="font-bold">{{$t('message.application504Sec2LoanQuestion3')}}</label><br />
      <label class="font-bold">{{$t('message.application504Sec2LoanQuestion3Option1')}}</label><br />
      <label class="font-bold">{{$t('message.application504Sec2LoanQuestion3Option2')}}</label><br />
      <label class="font-bold">{{$t('message.application504Sec2LoanQuestion3Option3')}}</label><br />
      <label class="font-bold">{{$t('message.application504Sec2LoanQuestion3Option4')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-5" class="form-check-input" type="radio" value="true" name="criminalOffense-choice" :checked="showCriminalOffense" @click="showCriminalOffenseData(true)" />
        <label class="form-check-label" for="radio-switch-5">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-6" class="form-check-input" type="radio" value="false" name="criminalOffense-choice" :checked="!showCriminalOffense" @click="showCriminalOffenseData(false)" />
        <label class="form-check-label" for="radio-switch-6">No</label>
      </div>
      <div v-if="showCriminalOffense">
        <div class="grid grid-cols-12 gap-7 gap-y-5">
          <div class="intro-y col-span-12 mt-10 font-bold">
            Please enter additional information
          </div>

          <div class="intro-y col-span-3">
            <div class="relative mx-auto">
              <label class="form-label" for="text-date">Date</label>
              <div class="absolute rounded-l w-10 h-10 flex items-center justify-center bg-slate-100 border text-slate-500 dark:bg-darkmode-700 dark:border-darkmode-800 dark:text-slate-400">
                <CalendarIcon class="w-4 h-4" />
              </div>
              <Litepicker id="text-date" v-model="criminalOffenseDate" :options="{
                              format: 'MM-DD-YYYY',
                              autoApply: true,
                              showWeekNumbers: false,
                              dropdowns: {
                                minYear: 1930,
                                maxYear: null,
                                months: true,
                                years: true,
                              },
                            }" class="form-control pl-12" />
            </div>
          </div>


          <div class="intro-y col-span-3">
            <label class="form-label" for="text-1">Location</label>
            <input id="text-1" class="form-control" type="text" v-model="criminalOffenseLocation" />
          </div>
          <div class="intro-y col-span-3">
            <label class="form-label" for="text-1">Level of charge</label>
            <input id="text-1" class="form-control" type="text" v-model="criminalOffenseLevelOfCharge" />
          </div>
        </div>
        <div class="grid grid-cols-12 gap-4 gap-y-5 mt-10">
          <div class="intro-y col-span-3">
            <label class="form-label" for="text-1">Sentence</label>
            <input id="text-1" class="form-control" type="text" v-model="criminalOffenseSentence" />
          </div>
          <div class="intro-y col-span-3">
            <label class="form-label" for="text-1">Fine</label>
            <input id="text-1" class="form-control" type="text" v-model="criminalOffenseFine" />
          </div>
          <div class="intro-y col-span-3">
            <label class="form-label" for="text-1">Name used when charged</label>
            <input id="text-1" class="form-control" type="text" v-model="criminalOffenseNameWhenCharged" />
          </div>
          <div class="intro-y col-span-3">
            <label class="form-label" for="text-1">Unpaid fine</label>
            <input id="text-1" class="form-control" type="text" v-model="criminalOffenseUnpaidFind" />
          </div>
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 sm:col-span-12 pb-10">
      <label class="font-bold">{{$t('message.application504Sec2LoanQuestion3')}}</label>
      <div class="form-check mt-2">
        <input id="radio-switch-7" class="form-check-input" type="radio" :checked="showPreviousGovernmentFinancing" @click="showPreviousGovernmentFinancingData(true)" name="previousGovernmentFinancing-choice" />
        <label class="form-check-label" for="radio-switch-7">Yes</label>
      </div>
      <div class="form-check mt-2">
        <input id="radio-switch-8" class="form-check-input" type="radio" :checked="!showPreviousGovernmentFinancing" @click="showPreviousGovernmentFinancingData(false)" name="previousGovernmentFinancing-choice" />
        <label class="form-check-label" for="radio-switch-8">No</label>
      </div>
      <div v-if="showPreviousGovernmentFinancing">
        <div class="grid grid-cols-12 gap-4 gap-y-5">
          <div class="intro-y col-span-12 mt-10 font-bold">
            Please enter additional information
          </div>
          <div class="intro-y col-span-6">
            <UploadFile :hasUploaded='store.state.loan504.section3.previousGovernmentFinancing.fileUpload' :question="'question4'" :questionType="'previousGovernmentFinancing'" :section="'section3'" />
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>


<script>
import {
  computed,
  ref,
  defineComponent,
  provide,
  onMounted
} from 'vue'
import {
  useStore
} from '@/store'
import CurrencyInput from "@/components/currency-input/Main.vue";
import {
  useRoute,
  useRouter
} from "vue-router";
import firebase from "firebase";
import {
  projectStorage
} from "../../../firebase/config";
import getUser from '@/composables/getUser';
import getCurrentProfileDetails from '@/composables/getCurrentUserProfileDetails';
import Modal from '@/components/modal/Main.vue';
import useStorage from '@/composables/useStorage';
import {
  helper as $h
} from '@/utils/helper'
import * as icons from "lucide-vue-next";

import UploadFile from "@/components/upload-file/Main"

export default {
  components: {
    CurrencyInput,
    UploadFile,
  },
  setup() {
    console.log("Section 3");

    const {
      url,
      filePath,
      error,
      uploadImage,
      uploadData
    } = useStorage();
    const store = useStore()
    const router = useRouter();
    // const dropzoneSingleRef = ref();
    // const dropzoneValidationRef = ref();
    const {
      collect,
      getCurrentProfileDetailsData
    } = getCurrentProfileDetails();
    const route = useRoute();
    const {
      user
    } = getUser();
    const documentType = ref();


    const showExistingSbaLoan = ref();
    const showArrested6Months = ref();
    const showCriminalOffense = ref();
    const showPreviousGovernmentFinancing = ref();
    const showPreviousGovernmentFinancingFileUpload = ref();
    const showArrested6MonthsFileUpload = ref();

    // set initial values from database/firebase
    showExistingSbaLoan.value = store.state.loan504.section3.existingSbaLoan.result;
    showArrested6Months.value = store.state.loan504.section3.arrested6Months.result;
    showCriminalOffense.value = store.state.loan504.section3.criminalOffense.result;
    showPreviousGovernmentFinancing.value = store.state.loan504.section3.previousGovernmentFinancing.result;
    showPreviousGovernmentFinancingFileUpload.value = store.state.loan504.section3.previousGovernmentFinancing.fileUpload;
    showArrested6MonthsFileUpload.value = store.state.loan504.section3.arrested6Months.fileUpload;

    function showName(event, questionNumber, questionType) {
      const files = event.target.files;
      uploadData(files[0], {
        filename: `${questionNumber}${$h.capitalizeFirstLetter(questionType)}.pdf`,
        dir1: '504loan',
        dir2: 'section3'
      })
      eval(`show${$h.capitalizeFirstLetter(questionType)}FileUpload.value = true`);
      store.commit(`loan504/set${$h.capitalizeFirstLetter(questionType)}FileUpload`, true)
    };



    function getCurrentDateStr() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();


      return mm + '/' + dd + '/' + yyyy;
    }


    function showExistingSbaLoanData(value) {
      showExistingSbaLoan.value = value;
      store.commit('loan504/setExistingSbaLoan', value)
      store.commit('loan504/setExistingSbaLoanUpdatedDate', getCurrentDateStr())
    }

    function showArrested6MonthsData(value) {
      showArrested6Months.value = value;
      store.commit('loan504/setArrested6Months', value)
      store.commit('loan504/setArrested6MonthsUpdatedDate', getCurrentDateStr())
    }

    function showCriminalOffenseData(value) {
      showCriminalOffense.value = value;
      store.commit('loan504/setCriminalOffense', value);
      store.commit('loan504/setCriminalOffenseUpdatedDate', getCurrentDateStr());
    }

    function showPreviousGovernmentFinancingData(value) {
      showPreviousGovernmentFinancing.value = value;
      store.commit('loan504/setPreviousGovernmentFinancing', value);
      store.commit('loan504/setPreviousGovernmentFinancingUpdatedDate', getCurrentDateStr());
    }


    const previousGovernmentFinancing = computed({
      get() {
        return store.state.loan504.section3.previousGovernmentFinancing.result;
      },
      set(value) {
        store.commit('loan504/setPreviousGovernmentFinancing', (value == "true"))
        store.commit('loan504/setPreviousGovernmentFinancingUpdatedDate', value)
      }
    })



    const criminalOffenseDate = computed({
      get() {
        return store.state.loan504.section3.criminalOffense.date;
      },
      set(value) {
        store.commit('loan504/setCriminalOffenseDate', value)
        store.commit('loan504/setCriminalOffenseUpdatedDate', getCurrentDateStr())
      }
    })

    const criminalOffenseLocation = computed({
      get() {
        return store.state.loan504.section3.criminalOffense.location;
      },
      set(value) {
        store.commit('loan504/setCriminalOffenseLocation', value)
        store.commit('loan504/setCriminalOffenseUpdatedDate', getCurrentDateStr())
      }
    })

    const criminalOffenseLevelOfCharge = computed({
      get() {
        return store.state.loan504.section3.criminalOffense.levelOfCharge;
      },
      set(value) {
        store.commit('loan504/setCriminalOffenseLevelOfCharge', value)
        store.commit('loan504/setCriminalOffenseUpdatedDate', getCurrentDateStr())
      }
    })

    const criminalOffenseSentence = computed({
      get() {
        return store.state.loan504.section3.criminalOffense.sentence;
      },
      set(value) {
        store.commit('loan504/setCriminalOffenseSentence', value)
        store.commit('loan504/setCriminalOffenseUpdatedDate', getCurrentDateStr())
      }
    })


    const criminalOffenseFine = computed({
      get() {
        return store.state.loan504.section3.criminalOffense.fine;
      },
      set(value) {
        store.commit('loan504/setCriminalOffenseFine', value)
        store.commit('loan504/setCriminalOffenseUpdatedDate', getCurrentDateStr())
      }
    })


    const criminalOffenseNameWhenCharged = computed({
      get() {
        return store.state.loan504.section3.criminalOffense.nameWhenCharged;
      },
      set(value) {
        store.commit('loan504/setCriminalOffenseNameWhenCharged', value)
        store.commit('loan504/setCriminalOffenseUpdatedDate', getCurrentDateStr())
      }
    })


    const criminalOffenseUnpaidFind = computed({
      get() {
        return store.state.loan504.section3.criminalOffense.unpaidFind;
      },
      set(value) {
        store.commit('loan504/setCriminalOffenseUnpaidFine', value)
        store.commit('loan504/setCriminalOffenseUpdatedDate', getCurrentDateStr())
      }
    })


    const existingSbaLoanLoanNumber = computed({
      get() {
        return store.state.loan504.section3.existingSbaLoan.loanNumber;
      },
      set(value) {
        console.log(value);
        store.commit('loan504/setExistingSbaLoanNumber', value)
        store.commit('loan504/setExistingSbaLoanUpdatedDate', getCurrentDateStr())
      }
    })


    const existingSbaLoanStatus = computed({

      get() {
        return store.state.loan504.section3.existingSbaLoan.status;
      },
      set(value) {
        store.commit('loan504/setExistingSbaLoanStatus', value)
        store.commit('loan504/setExistingSbaLoanUpdatedDate', getCurrentDateStr())
      }
    })


    onMounted(() => {

      showExistingSbaLoanData(store.state.loan504.section3.existingSbaLoan.result);
      showArrested6MonthsData(store.state.loan504.section3.arrested6Months.result);
      showCriminalOffenseData(store.state.loan504.section3.criminalOffense.result);
      //  showPreviousGovernmentFinancingData(store.state.loan504.section3.previousGovernmentFinancing.result);

    })

    return {
      store,

      showExistingSbaLoan,
      showArrested6Months,
      showCriminalOffense,

      showPreviousGovernmentFinancing,


      showExistingSbaLoanData,
      showArrested6MonthsData,
      showCriminalOffenseData,
      showPreviousGovernmentFinancingData,
      showPreviousGovernmentFinancingFileUpload,
      showArrested6MonthsFileUpload,
      previousGovernmentFinancing,
      existingSbaLoanLoanNumber,
      existingSbaLoanStatus,


      criminalOffenseDate,
      criminalOffenseLocation,
      criminalOffenseLevelOfCharge,
      criminalOffenseSentence,
      criminalOffenseFine,
      criminalOffenseNameWhenCharged,
      criminalOffenseUnpaidFind,
      showName,

    }
  }
}
</script>

<style>
</style>