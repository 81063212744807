import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'


import { useAsyncState } from '@vueuse/core'
// SASS Theme
import './assets/sass/app.scss'
import { projectAuth } from './firebase/config'
import useCollection from '@/composables/useCollection'
import { createI18n } from 'vue-i18n'
import VueCookies from 'vue-cookies'
import businessInformation from '@/views/required-forms/Steps/BusinessInformation.vue'

let app


export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en  ' +
    '',
  messages: {
    en: {
      message: {
        login: 'Login',
        signup: 'Sign Up',
        email: 'Email',
        password: 'Password',
        forgotPassword: 'Forgot Password',
        finance: 'Finance',
        fileManager: 'File Manager',
        dashboard: 'Dashboard',
        debtCoverageRatio: 'Debt Coverage Ratio',
        yearsInBusiness: 'Years In Business',
        creditScore: 'Credit Score',
        businessStrength: 'Business Strength',
        fitness: 'Fitness',
        cashFlow: 'Cash Flow',
        incomeStatement: 'Income Statement',
        balanceSheet: 'Balance Sheet',
        leverage: 'Leverage',
        liquidity: 'Liquidity',
        profitable: 'Profitable',
        valuation: 'Valuation',
        completeFormFitness: 'Complete the following forms',

        reviewTitle: 'Review Your Information',
        ownerInformationHeading: 'Owner Information',
        ownerInformationFirstName: 'First Name',
        ownerInformationLastName: 'Last Name',
        ownerInformationMiddleName: 'Middle Name',
        ownerInformationAddress1: 'Address 1',
        ownerInformationAddress2: 'Address 2',
        ownerInformationCity: 'City',
        ownerInformationState: 'State',
        ownerInformationZipCode: 'Zip Code',
        ownerInformationBirthDate: 'Date of Birth',
        ownerInformationSS: 'Social Security Number',
        ownerInformationCitizen: 'Citizen',
        ownerInformationEmail: 'Email *',
        ownerInformationPhone: 'Phone Number',
        ownerInformationCreditScore: 'Credit Score',
        ownerInformationGender: 'Gender',
        ownerInformationEthnicity: 'Ethnicity',
        ownerInformationVeteran: 'Veteran',
        ownerInformationRace: 'Race',
        ownerInformationQuestion1: 'If you are a 50% or more owner of the Applicant, are you more than 60 days delinquent on any obligation to pay child support arising under an administrative order, court order, or repayment agreement between the holder and a custodial parent or repayment agreement between the holder and a state agency providing child support enforcement service?',
        ownerInformationQuestion2: 'Are you previously suspended, debarred, proposed for debarment, declared ineligible, or voluntarily excluded from participation in this transaction by any Federal Department or agency?',
        ownerInformationQuestion3: 'Are you presently subject to an indictment?',
        ownerInformationQuestion4: 'Have you been arrested in the past six months?',
        ownerInformationQuestion5: 'Have you ever been convicted?',
        ownerInformationQuestion6: 'Are you a U.S. citizen',


        businessInformationHeading: 'Business Information',
        businessInformationEntityType: 'Entity Type',
        businessInformationIndustryType: 'Industry Type',
        businessInformationCompanyName: 'Company Name',
        businessInformationDBA: 'DBA Name',
        businessInformationAddress1: 'Address 1',
        businessInformationAddress2: 'Address 2',
        businessInformationCity: 'City',
        businessInformationState: 'State',
        businessInformationZipCode: 'Zip Code',
        businessInformationBusinessContactNumber: 'Business Contact Number',
        businessInformationEmail: 'Email',
        businessInformationWebsite: 'Website',
        businessInformationYearsInBusiness: 'Years In Business',
        businessInformationTaxId: 'Tax ID',
        businessInformationDUNS: 'DUNS Number',


        sourceOfIncomeHeading: 'Source of Income',
        sourceOfIncomeSalary: 'Source of Income Statement',
        sourceOfIncomeNetInvestmentIncome: 'Net Investment Income',
        sourceOfIncomeRealEstateIncome: 'Real Estate Income',
        sourceOfIncomeLifeInsuranceLoanAgainstLifeInsurance: 'Life Insurance Loan Against Life Insurance',
        sourceOfIncomeTotalNetIncome: 'Total Net Income',


        continuousLiabilities: 'Continuous Liabilities',
        continuousLiabilitiesOtherIncomeAsEndorserOrCoMaker: 'Other Income As Endorser or Co-maker',
        continuousLiabilitiesLegalClaimsAndJudgmentsProvisionForFederal: 'Legal Claims and Judgments Provision for Federal',
        continuousLiabilitiesIncomeTax: 'Income Tax',
        continuousLiabilitiesOtherSpecialDebt: 'Other Special Debt',

        application504Sec1Heading: 'Section 1 - Answer the following questions and initial to the right of each answer.',
        application504Sec1LoanQuestion1: '1. Does the Small Business Applicant have any Afﬁliates?',
        application504Sec1LoanQuestion2: '2. Have the Small Business Applicant or any Afﬁliates ever obtained or applied for direct guaranteed loan from SBA, or from any other Federal, State or local government loan program or been a guarantor on such a loan?',
        application504Sec1LoanQuestion3: '3. Has an application for this Project previously been submitted to the SBA by any CDC or Lender in connection with any SBA program?',
        application504Sec1LoanQuestion4: '4. Has the Applicant business ever declared bankruptcy?',
        application504Sec1LoanQuestion5: '5. Is the Applicant business involved in any pening lawsuits?',
        application504Sec1LoanQuestion6: '6. Is the Applicant business owned by one of the following?',
        application504Sec1LoanStatement: 'If the Applicant is owned by an ESOP (Employee Stock Ownership Plan) or 401(k) plan (including a Rollover as Business Start-Up (ROBS) plan), Applicant must provide evidence to the CDC that the Applicant, ESOP, and/or the 401(k) plan are in compliance with all applicable Government Agencies (e.g., IRS, Treasury, and Department of Labor) requirements and that it will comply with all relevant operating and reporting requirements.',


        application504Sec2Heading: 'Section 3 - Answer the following questions and initial to the right of each answer.',
        application504Sec2LoanQuestion1: '1. Do you have an ownership interest in any other entity that has existing SBA loans?',
        application504Sec2LoanQuestion2: '2. Have you been arrested in the last six months for any criminal offense?',
        application504Sec2LoanQuestion3: '3. For any criminal offense (other than minor vehicle violation) have you ever:',
        application504Sec2LoanQuestion3Option1: 'a. been convicted',
        application504Sec2LoanQuestion3Option2: 'b. pleaded guilty',
        application504Sec2LoanQuestion3Option3: 'c. pleaded nolo contendere',
        application504Sec2LoanQuestion3Option4: 'd. been placed on any form of parole or probation (including probation before judgement)?',
        application504Sec2LoanQuestion4: '4. Has the Applicant or any afﬁliated company of the Applicant as well as any Associate of the Applicant received any previous government ﬁnancing?',


        nowAccountQuestion1: 'What are your total number of invoices per month?',
        nowAccountQuestion2: 'What are your average invoice sizes?',
        nowAccountQuestion3: 'What are the average days your invoices are outstanding?',
        nowAccountQuestion4: 'What are your total number of customers?',
        nowAccountQuestion5: 'What are your average invoice terms?',
        nowAccountQuestion6: 'What are the annual dollar volume of discount, returns and allowances?',
        nowAccountQuestion7: 'Special Invoice Types, Select:',
        nowAccountQuestion7Option1: 'Progress Billing',
        nowAccountQuestion7Option2: 'Retainage',
        nowAccountQuestion7Option3: 'Government Sales',
        nowAccountQuestion7Option4: 'Consignment Sales',
        nowAccountQuestion7Option5: 'Billing prior to completion',
        nowAccountQuestion8: 'Do you use sub-contractors?',
        nowAccountQuestion9: 'Are there documentation required by the client to accompany invoices for payment?',
        nowAccountQuestion10: 'Do you have any commercial loans?',
        nowAccountQuestion11: 'Do you have any payroll, state or federal taxes past due?',
        nowAccountQuestion12: 'Do you ever filed for bankruptcy?',


        sevenAQuestion1: `Is the Applicant or if the Applicant is structured as an Eligible Passive Company (EPC) and Operating Company (OC), both the EPC
        and OC, or any Associate of the Applicant presently suspended, debarred, proposed for debarment, declared ineligible, voluntarily
        excluded from participation in a transaction by any Federal department or agency, or presently involved in any bankruptcy?`,
        sevenAQuestion2: `Is the Applicant, any Associate of the Applicant, or any business owned by them or any Affiliates (per 13 CFR 121.301(f)), currently
        delinquent or have ever defaulted on a direct or guaranteed loan from SBA, or another Federal agency loan program (including, but not
        limited to USDA, B&I, FSA, FHA, EDA), or been a guarantor on such a loan`,
        sevenAQuestion3: `Is the Applicant or any owner of the Applicant an owner of any other business? If yes, list all such businesses (including their TINs),
        percentage of ownership, and describe the relationship on a separate sheet identified as addendum A.`,
        sevenAQuestion4: `Is the Applicant or any Associate of the Applicant presently incarcerated, on probation, on parole, or presently subject to an indictment
        for a felony or any crime involving or relating to financial misconduct or a false statement? (if “Yes” the Applicant is not eligible for
        SBA financial assistance.)`,
        sevenAQuestion5: `Are any of the Applicant's products and/or services exported (directly or indirectly), or is there a plan to begin exporting (directly or
        indirectly) as a result of this loan, or is this an Export Working Capital Program (EWCP)* loan? If “Yes,” answer questions 5.a) and
        b) below, if “No” move to question 6`,
        sevenAQuestion5a: `Provide the estimated total export sales this loan will support. `,
        sevenAQuestion5b: `List of principal countries of Export (list at least 1):`,
        sevenAQuestion6: `Has the Applicant paid or committed to pay a fee to the Lender or a third party to assist in the preparation of the loan application or
        application materials, or has the Applicant paid or committed to pay a referral agent or broker a fee? If “Yes” provide details to your
        Lender (the name of the third party and the amount of the fee). The Applicant is not required to obtain or pay for unwanted services.`,
        sevenAQuestion7: `Are any of the Applicant’s revenues derived from gambling, loan packaging, lending activities, lobbying activities, or from the sale of
        products or services, or the presentation of any depiction, displays or live performances, of a prurient sexual nature? If “Yes,” provide
        details under a separate attachment.`,
        sevenAQuestion8: `Is any sole proprietor, partner, officer, director, stockholder with a 10 percent or more interest in the Applicant an SBA employee or a
        Household Member of an SBA employee? (13 CFR 105.204). “Household Member” means spouse and minor children of an
        employee, all blood relations of the employee and any spouse who resides in the same place of abode with the employee (13 CFR
        §105.201(d))`,
        sevenAQuestion9: `Is any employee, owner, partner, attorney, agent, owner of stock, officer, director, creditor or debtor of the Applicant a former SBA
        employee who has been separated from SBA for less than one year prior to the request for financial assistance? (13 CFR 105.203)`,
        sevenAQuestion10: `Is any sole proprietor, general partner, officer, director, or stockholder with a 10 percent or more interest in the Applicant, or a
        household member of such individual, a member of Congress, or an appointed official or employee of the legislative or judicial branch
        of the Federal Government? (13 CFR 105.301(c))`,
        sevenAQuestion11: `Is any sole proprietor, general partner, officer, director, or stockholder with a 10 percent or more interest in the Applicant, or a
        household member of such individual, a Federal Government employee or Member of the Military having a grade of at least GS-13 or
        higher (or Military equivalent)? (13 CFR 105.301(a))
        SBA Form 1919 (09/2023) 2`,
        sevenAQuestion12: `Is any sole proprietor, general partner, officer, director, or stockholder with a 10 percent or more interest in the Applicant, or a
        household member of such individual, a member or employee of a Small Business Advisory Council or a SCORE volunteer? (13 CFR
        105.302(a)`,
        sevenAQuestion13: `Is the Applicant, any owner of the Applicant, or any business owned by them (Affiliates), presently involved in any legal action (including divorce)? If yes, provide details.`
      }
    },
    es: {
      message: {
        login: 'Acceso',
        signup: 'Inscribirse',
        email: 'Correo Electrónico',
        password: 'Contraseña',
        forgotPassword: 'Has olvidado tu contraseña',
        finance: 'Finanzas',
        fileManager: 'Administrador de archivos',
        dashboard: 'Salpicadero',
        debtCoverageRatio: 'Ratio de cobertura de la deuda',
        yearsInBusiness: 'años en el negocio',
        creditScore: 'Puntaje de crédito',
        businessStrength: 'Salud Empresarial',
        fitness: 'Aptitud',
        cashFlow: 'Flujo de caja',
        incomeStatement: 'Cuenta de resultados',
        balanceSheet: 'Equilibrar',
        leverage: 'Apalancamiento',
        liquidity: 'Liquidez',
        profitable: 'Rentable',
        valuation: 'Valoración',
        completeFormFitness: 'Complete los siguientes formularios:',


        reviewTitle: 'Revisa tu información',
        ownerInformationHeading: 'Información del propietario',
        ownerInformationFirstName: 'Nombre',
        ownerInformationLastName: 'Apellido',
        ownerInformationMiddleName: 'Segundo nombre',
        ownerInformationAddress1: 'Dirección 1',
        ownerInformationAddress2: 'Dirección 2',
        ownerInformationCity: 'Ciudad',
        ownerInformationState: 'Estado',
        ownerInformationZipCode: 'Código postal',
        ownerInformationBirthDate: 'Fecha de nacimiento',
        ownerInformationSS: 'Número de Seguro Social',
        ownerInformationCitizen: 'Ciudadano',
        ownerInformationEmail: 'Correo electrónico',
        ownerInformationPhone: 'Número de teléfono',
        ownerInformationCreditScore: 'Puntaje de crédito',
        ownerInformationGender: 'Género',
        ownerInformationEthnicity: 'Origen étnico',
        ownerInformationVeteran: 'Veterano',
        ownerInformationRace: 'Raza',
        ownerInformationQuestion1: 'Si usted es propietario del 50% o más del Solicitante, ¿tiene más de 60 días de mora en cualquier obligación de pagar la manutención de los hijos que surja en virtud de una orden administrativa, una orden judicial o un acuerdo de pago entre el titular y un padre con custodia o un acuerdo de pago entre el titular y una agencia estatal que proporciona manutención de los hijos servicio de ejecución?',
        ownerInformationQuestion2: '¿Ha sido previamente suspendido, inhabilitado, propuesto para la inhabilitación, declarado inelegible, o excluido voluntariamente de la participación en esta transacción por cualquier Departamento o agencia Federal?',
        ownerInformationQuestion3: '¿Está actualmente sujeto a una acusación?',
        ownerInformationQuestion4: '¿Ha sido arrestado en los últimos seis meses?',
        ownerInformationQuestion5: '¿Alguna vez ha sido condenado?',
        ownerInformationQuestion6: '¿Es usted ciudadano estadounidense?',

        businessInformationHeading: 'Información de Negocios',
        businessInformationEntityType: 'Tipo de Entidad',
        businessInformationIndustryType: 'Tipo de Industria',
        businessInformationCompanyName: 'Nombre de la Compañía',
        businessInformationDBA: 'Nombre de la DBA',
        businessInformationAddress1: 'Dirección 1',
        businessInformationAddress2: 'Dirección 2',
        businessInformationCity: 'Ciudad',
        businessInformationState: 'Estado',
        businessInformationZipCode: 'Código Postal',
        businessInformationBusinessContactNumber: 'Número de Contacto de Negocios',
        businessInformationEmail: 'Correo Electrónico',
        businessInformationWebsite: 'Sitio Web',
        businessInformationYearsInBusiness: 'Años en Negocio',
        businessInformationTaxId: 'ID de Impuesto',
        businessInformationDUNS: 'Número DUNS',


        sourceOfIncomeHeading: 'Fuente de Ingresos',
        sourceOfIncomeSalary: 'Declaración de Fuente de Ingresos',
        sourceOfIncomeNetInvestmentIncome: 'Ingresos Netos de Inversión',
        sourceOfIncomeRealEstateIncome: 'Ingresos de Bienes Raíces',
        sourceOfIncomeLifeInsuranceLoanAgainstLifeInsurance: 'Préstamo de Seguro de Vida Contra Seguro de Vida',
        sourceOfIncomeTotalNetIncome: 'Ingreso Neto Total',


        continuousLiabilities: 'Responsabilidades Continuas',
        continuousLiabilitiesOtherIncomeAsEndorserOrCoMaker: 'Otros Ingresos Como Aval o Co-hacedor',
        continuousLiabilitiesLegalClaimsAndJudgmentsProvisionForFederal: 'Provisiones para Federal de Reclamaciones Legales y Juicios',
        continuousLiabilitiesIncomeTax: 'Impuesto a la Renta',
        continuousLiabilitiesOtherSpecialDebt: 'Otra Deuda Especial',

        application504Sec1Heading: 'Sección 1 - Responda a las siguientes preguntas e inicie a la derecha de cada respuesta.',
        application504Sec1LoanQuestion1: '1. ¿El solicitante de pequeñas empresas tiene algún afiliado?',
        application504Sec1LoanQuestion2: '2. ¿El solicitante de pequeñas empresas o alguno de los afiliados ha obtenido o solicitado un préstamo directo o garantizado de la SBA, o de cualquier otro programa de préstamos del gobierno federal, estatal o local o ha sido garante de dicho préstamo?',
        application504Sec1LoanQuestion3: '3. ¿Se ha presentado previamente una solicitud para este Proyecto a la SBA por cualquier CDC o Prestamista en relación con cualquier programa de la SBA?',
        application504Sec1LoanQuestion4: '4. ¿El negocio del solicitante ha declarado bancarrota alguna vez?',
        application504Sec1LoanQuestion5: '5. ¿El negocio del solicitante está involucrado en demandas pendientes?',
        application504Sec1LoanQuestion6: '6. ¿El negocio del solicitante es propiedad de uno de los siguientes?',
        application504Sec1LoanStatement: 'Si el solicitante es propiedad de un plan ESOP (Plan de propiedad de acciones para empleados) o un plan 401(k) (incluida una reinversión como empresa emergente (ROBS)), el Solicitante debe proporcionar evidencia a los CDC de que el Solicitante, el ESOP y/o el plan 401(k) cumplen con todos los requisitos aplicables de las Agencias Gubernamentales (por ejemplo, IRS, Tesoro y Departamento de Trabajo) y que Cumplir con todos los requisitos operativos y de presentación de informes pertinentes.',

        application504Sec2Heading: 'Sección 3: Responda a las siguientes preguntas y anote sus iniciales a la derecha de cada respuesta.',
        application504Sec2LoanQuestion1: '1. ¿Tiene algún interés de propiedad en otra entidad que tenga préstamos de la SBA existentes?',
        application504Sec2LoanQuestion2: '2. ¿Has sido arrestado en los últimos seis meses por algún delito penal?',
        application504Sec2LoanQuestion3: '3. ¿Has sido inculpado en algún delito penal (aparte de las violaciones de tráfico menores)?',
        application504Sec2LoanQuestion3Option1: 'a. sido condenado',
        application504Sec2LoanQuestion3Option2: 'b. se declaró culpable',
        application504Sec2LoanQuestion3Option3: 'c. se declaró nolo contendere',
        application504Sec2LoanQuestion3Option4: 'd. ha sido puesto en libertad condicional o libertad vigilada (incluyendo la libertad anterior al juicio)?',
        application504Sec2LoanQuestion4: '4. ¿El Solicitante, alguna empresa afiliada al Solicitante o algún Asociado del Solicitante ha recibido previamente financiamiento gubernamental?',

        nowAccountQuestion1: '¿Cuál es el número total de facturas por mes?',
        nowAccountQuestion2: '¿Cuál es el tamaño promedio de tus facturas?',
        nowAccountQuestion3: '¿Cuántos días en promedio están pendientes tus facturas?',
        nowAccountQuestion4: '¿Cuál es el número total de tus clientes?',
        nowAccountQuestion5: '¿Cuáles son tus términos promedio de facturación?',
        nowAccountQuestion6: '¿Cuál es el volumen anual en dólares de descuentos, devoluciones y asignaciones?',
        nowAccountQuestion7: 'Tipos Especiales de Facturas, Seleccionar:',
        nowAccountQuestion7Option1: 'Facturación progresiva',
        nowAccountQuestion7Option2: 'Retención',
        nowAccountQuestion7Option3: 'Ventas gubernamentales',
        nowAccountQuestion7Option4: 'Ventas en consignación',
        nowAccountQuestion7Option5: 'Facturación antes de la finalización',
        nowAccountQuestion8: '¿Utilizas subcontratistas?',
        nowAccountQuestion9: '¿Se requiere documentación por parte del cliente para acompañar las facturas para el pago?',
        nowAccountQuestion10: '¿Tienes algún préstamo comercial?',
        nowAccountQuestion11: '¿Tienes algún atraso en el pago de impuestos salariales, estatales o federales?',
        nowAccountQuestion12: '¿Has presentado alguna vez una quiebra?',


        sevenAQuestion1: `Es el Solicitante o si el Solicitante está estructurado como una Compañía Pasiva Elegible (EPC) y una Compañía Operadora (OC), tanto la EPC
        y OC, o cualquier Asociado del Solicitante actualmente suspendido, inhabilitado, propuesto para inhabilitación, declarado no elegible, voluntariamente
        excluido de la participación en una transacción por parte de cualquier departamento o agencia federal, o actualmente involucrado en alguna quiebra?`,
        sevenAQuestion2: `Está el Solicitante, cualquier Asociado del Solicitante o cualquier negocio de su propiedad o de sus Afiliados (según 13 CFR 121.301(f)), actualmente
        morosos o alguna vez han incumplido con un préstamo directo o garantizado de la SBA u otro programa de préstamos de una agencia federal (incluidos, entre otros)
        limitado a USDA, B&I, FSA, FHA, EDA), o ha sido garante de dicho préstamo"`,
        sevenAQuestion3: `El Solicitante o cualquier propietario del Solicitante es propietario de algún otro negocio? En caso afirmativo, enumere todas esas empresas (incluidos sus TIN),
        porcentaje de propiedad, y describir la relación en una hoja separada identificada como apéndice A.`,
        sevenAQuestion4: `Está el Solicitante o cualquier Asociado del Solicitante actualmente encarcelado, en libertad condicional, en libertad condicional o actualmente sujeto a una acusación?
        por un delito grave o cualquier delito que involucre o esté relacionado con una mala conducta financiera o una declaración falsa? (Si responde “Sí”, el Solicitante no es elegible para
        asistencia financiera de la SBA.)`,
        sevenAQuestion5: `Algunos de los productos y/o servicios del Solicitante se exportan (directa o indirectamente), o existe un plan para comenzar a exportar (directa o indirectamente)?
        indirectamente) como resultado de este préstamo, o se trata de un préstamo del Programa de Capital de Trabajo para Exportaciones (EWCP)*? En caso afirmativo, responda las preguntas 5.a) y
        b) a continuación, si responde “No”, pase a la pregunta 6`,
        sevenAQuestion5a: `Proporcione las ventas de exportación totales estimadas que respaldará este préstamo.`,
        sevenAQuestion5b: `Lista de principales países de Exportación (enumerar al menos 1):`,
        sevenAQuestion6: `El Solicitante ha pagado o se ha comprometido a pagar una tarifa al Prestamista o a un tercero para ayudar en la preparación de la solicitud de préstamo o
        materiales de solicitud, o el Solicitante ha pagado o se ha comprometido a pagar una tarifa a un agente o corredor de referencia? Si responde “Sí”, proporcione detalles a su
        Prestamista (el nombre del tercero y el monto de la tarifa). El Solicitante no está obligado a obtener ni pagar por servicios no deseados.`,
        sevenAQuestion7: `Are any of the Applicant’s revenues derived from gambling, loan packaging, lending activities, lobbying activities, or from the sale of
        products or services, or the presentation of any depiction, displays or live performances, of a prurient sexual nature? If “Yes,” provide
        details under a separate attachment.`,
        sevenAQuestion8: ` Es algún propietario único, socio, funcionario, director o accionista con una participación del 10 por ciento o más en el Solicitante un empleado de la SBA o un
        ¿Miembro del hogar de un empleado de la SBA? (13 CFR 105.204). “Miembro del hogar” significa cónyuge e hijos menores de una
        empleado, todos los parientes consanguíneos del empleado y cualquier cónyuge que resida en el mismo lugar de residencia con el empleado (13 CFR
        §105.201(d))`,
        sevenAQuestion9: `¿Es algún empleado, propietario, socio, abogado, agente, propietario de acciones, funcionario, director, acreedor o deudor del Solicitante un ex SBA?
        ¿Empleado que ha estado separado de la SBA por menos de un año antes de la solicitud de asistencia financiera? (13 CFR 105.203)`,
        sevenAQuestion10: `Es algún propietario único, socio general, funcionario, director o accionista con una participación del 10 por ciento o más en el Solicitante, o un
        miembro del hogar de dicha persona, un miembro del Congreso o un funcionario o empleado designado del poder legislativo o judicial
        del Gobierno Federal? (13 CFR 105.301(c))`,
        sevenAQuestion11: `Es cualquier propietario único, socio general, funcionario, director o accionista con una participación del 10 por ciento o más en el Solicitante, o un
        miembro del hogar de dicha persona, un empleado del gobierno federal o miembro de las fuerzas armadas que tenga un grado de al menos GS-13 o
        superior (o equivalente militar)? (13 CFR 105.301(a))
        Formulario 1919 de la SBA (09/2023) 2`,
        sevenAQuestion12: `Es algún propietario único, socio general, funcionario, director o accionista con una participación del 10 por ciento o más en el Solicitante, o un
        ¿miembro del hogar de dicha persona, miembro o empleado de un Consejo Asesor de Pequeñas Empresas o voluntario de SCORE? (13 CRF
        105.302(a)`,
        sevenAQuestion13: `El Solicitante, algún propietario del Solicitante o algún negocio de su propiedad (Afiliados) está actualmente involucrado en alguna acción legal (incluido el divorcio)? En caso afirmativo, proporcione detalles.`
      }
    }
  }
})


const {
  error,
  getCollectionFinancialsByDocumentId,
  getCollectionByDocumentId,
  addDoc,
  getCollection,
  isPending,
  newDoc,
  documents: document
} = useCollection()

projectAuth.onAuthStateChanged((user) => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .use(store)
      .use(i18n)
      .use(VueCookies)

    globalComponents(app)
    utils(app)
    app.mount('#app')
  }


  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.getIdToken(true)
      user.admin = (idTokenResult.claims.admin != undefined) ? idTokenResult.claims.admin : false

      // getCollectionFinancialsByDocumentId('financials', user.email);
      getCollectionByDocumentId('loan7a', user.email)
      getCollectionByDocumentId('income-statement', user.email)
      getCollectionByDocumentId('balance-sheet', user.email)
      getCollectionByDocumentId('cash-flow', user.email)
      getCollectionByDocumentId('required-form', user.email)
      getCollectionByDocumentId('financials', user.email)
      getCollectionByDocumentId('loan504', user.email)
      getCollectionByDocumentId('asset-valuation', user.email)
      getCollectionByDocumentId('valuation', user.email)
      getCollectionByDocumentId('liability-valuation', user.email)
      getCollectionByDocumentId('equity-valuation', user.email)
      getCollectionByDocumentId('now-account', user.email)
    })
  }


})