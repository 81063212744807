import { ref } from 'vue';
import {projectAuth, projectFirestore } from '../firebase/config';

const getCurrentUserProfileDetails = () => {
// refs
  const user = ref(projectAuth.currentUser);
  const collect = ref([]);
  const error = ref();


  const getCurrentProfileDetailsData = async  (email) => {
    console.log("getCurrentProfileDetailsData:" + email);
    await projectFirestore.collection('profileDetails').where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          collect.value.push({...doc.data(), id: doc.id});
          console.log("getCurrentUserProfileDetails.getCurrentProfileDetailsData - gotdata");
          error.value = null;
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  const getResume = async  () => {
    console.log("here:1");
    console.log("here:" + projectAuth.currentUser);
    await projectFirestore.collection('profileDetails').where("email", "==", projectAuth.currentUser.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          collect.value.push({...doc.data(), id: doc.id});
          console.log(doc.data());
          error.value = null;
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }


  // auth changes
  projectAuth.onAuthStateChanged(_user => {
    console.log('User state change. Current user is:', _user)

    if (_user != null)
      user.value = _user
  });

    return { user, collect, getCurrentProfileDetailsData };
}

export default getCurrentUserProfileDetails
