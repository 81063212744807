import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser'



const {
  error,
  addDoc,
  addDocWithGenId,
  getCollection,
  isPending,
  newDoc,
  documents: applications
} = useCollection('loan504');


const state = () => {
  // TODO: must deal with date input type

  return {
    section2: {
      affiliates: {
        result: false,
        fileUpload: false,
        updatedDate: '',
      },
      directGuaranteedLoan: {
        result: false,
        fileUpload: false,
        updatedDate: '',
      },
      previouslySubmitted: {
        result: false,
        lenderNameLoanProgram:'',
        updatedDate: '',
      },
      declaredBankruptcy: {
        result: false,
        fileUpload: false,
        updatedDate: '',
      },
      pendingLawsuits: {
        result: false,
        updatedDate: '',
      },
      businessOwnedBy: {
        result: false,
        type: 'ESOP',
        updatedDate: '',
      },
    },
    section3: {
      existingSbaLoan: {
        result: false,
        loanNumber: 0,
        status: '',
        updatedDate: '',
      },
      arrested6Months: {
        result: false,
        fileUpload: false,
        updatedDate: '',
      },
      criminalOffense: {
        result: false,
        date:'',
        location:'',
        levelOfCharge:'',
        sentence:'',
        fine:'',
        nameWhenCharged:'',
        unpaidFind:'',
        updatedDate: '',
      },
      // declaredBankruptcy: {
      //   result: false,
      //   updatedDate: '',
      // },
      // pendingLawsuits: {
      //   result: false,
      //   updatedDate: '',
      // },
      previousGovernmentFinancing: {
        result: false,
        fileUpload: false,
        updatedDate: '',
      }
    }
  }
}


// getters
const getters = {}

// actions
const actions = {
  async submitFirebase(context) {
    const {
      user
    } = getUser();
    const application = await addDocWithGenId(this.state.loan504, user._value.email);
  }
}



// mutations
const mutations = {
  setAffiliates(state, value) {
    this.state.loan504.section2.affiliates.result = value;
  },
  setAffiliatesUpdatedDate(state, value) {
    this.state.loan504.section2.affiliates.updatedDate = value;
  },
  setAffiliatesFileUpload(state, value) {
    this.state.loan504.section2.affiliates.fileUpload = value;
  },


  setDirectGuaranteedLoan(state, value) {
    this.state.loan504.section2.directGuaranteedLoan.result = value;
  },
  setDirectGuaranteedLoanUpdatedDate(state, value) {
    this.state.loan504.section2.directGuaranteedLoan.updatedDate = value;
  },
  setDirectGuaranteedLoanFileUpload(state, value) {
    this.state.loan504.section2.directGuaranteedLoan.fileUpload = value;
  },


  setPreviouslySubmitted(state, value) {
    this.state.loan504.section2.previouslySubmitted.result = value;
  },
  setPreviouslySubmittedLenderNameLoanProgram(state, value) {
    this.state.loan504.section2.previouslySubmitted.lenderNameLoanProgram = value;
  },
  setPreviouslySubmittedUpdatedDate(state, value) {
    this.state.loan504.section2.previouslySubmitted.updatedDate = value;
  },

  setDeclaredBankruptcy(state, value) {
    this.state.loan504.section2.declaredBankruptcy.result = value;
  },
  setDeclaredBankruptcyUpdatedDate(state, value) {
    this.state.loan504.section2.declaredBankruptcy.updatedDate = value;
  },
  setDeclaredBankruptcyFileUpload(state, value) {
    this.state.loan504.section2.declaredBankruptcy.fileUpload = value;
  },


  setPendingLawsuits(state, value) {
    this.state.loan504.section2.pendingLawsuits.result = value;
  },
  setPendingLawsuitsUpdatedDate(state, value) {
    this.state.loan504.section2.pendingLawsuits.updatedDate = value;
  },

  setBusinessOwnedBy(state, value) {
    this.state.loan504.section2.businessOwnedBy.result = value;
  },
  setBusinessOwnedByType(state, value) {
    this.state.loan504.section2.businessOwnedBy.type = value;
  },
  setBusinessOwnedByUpdatedDate(state, value) {
    this.state.loan504.section2.businessOwnedBy.updatedDate = value;
  },




  setExistingSbaLoan(state, value) {
    this.state.loan504.section3.existingSbaLoan.result = value;
  },
  setExistingSbaLoanLocation(state, value) {
    this.state.loan504.section3.existingSbaLoan.location = value;
  },
  setExistingSbaLoanNumber(state, value) {
    this.state.loan504.section3.existingSbaLoan.loanNumber = value;
  },
  setExistingSbaLoanStatus(state, value) {
    this.state.loan504.section3.existingSbaLoan.status = value;
  },
  setExistingSbaLoanUpdatedDate(state, value) {
    this.state.loan504.section3.existingSbaLoan.updatedDate = value;
  },

  setArrested6Months(state, value) {
    this.state.loan504.section3.arrested6Months.result = value;
  },
  setArrested6MonthsUpdatedDate(state, value) {
    this.state.loan504.section3.arrested6Months.updatedDate = value;
  },
  setArrested6MonthsFileUpload(state, value) {
    this.state.loan504.section3.arrested6Months.fileUploaded = value;
  },
  setCriminalOffense(state, value) {
    this.state.loan504.section3.criminalOffense.result = value;
  },

  setCriminalOffenseDate(state, value) {
    this.state.loan504.section3.criminalOffense.date = value;
  },

  setCriminalOffenseLocation(state, value) {
    this.state.loan504.section3.criminalOffense.location = value;
  },

  setCriminalOffenseLevelOfCharge(state, value) {
    this.state.loan504.section3.criminalOffense.levelOfCharge = value;
  },

  setCriminalOffenseSentence(state, value) {
    this.state.loan504.section3.criminalOffense.sentence = value;
  },

  setCriminalOffenseFine(state, value) {
    this.state.loan504.section3.criminalOffense.fine = value;
  },

  setCriminalOffenseNameWhenCharged(state, value) {
    this.state.loan504.section3.criminalOffense.nameWhenCharged = value;
  },

  setCriminalOffenseUnpaidFine(state, value) {
    this.state.loan504.section3.criminalOffense.unpaidFind = value;
  },
  setCriminalOffenseUpdatedDate(state, value) {
    this.state.loan504.section3.criminalOffense.updatedDate = value;
  },

  setPreviousGovernmentFinancing(state, value) {
    this.state.loan504.section3.previousGovernmentFinancing.result = value;
  },
  setPreviousGovernmentFinancingUpdatedDate(state, value) {
    this.state.loan504.section3.previousGovernmentFinancing.updatedDate = value;
  },
  setPreviousGovernmentFinancingFileUpload(state, value) {
    this.state.loan504.section3.previousGovernmentFinancing.fileUpload = value;
  },



  setLoan504StateObj(state, value) {
    this.state.loan504 = value;
  }



}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
